<template>
  <BittsDrawer
    :visible="showDrawer"
    :info="integrationInfo"
    :show-footer="true"
    wrapper-class="c-copilot-settings-drawer"
    @closed="onDrawerClosed"
  >
    <template #content>
      <div v-if="hasManageIntegrations" class="copilot_drawer">
        <BittsLoading :is-loading="loading">
          <div>
            <div class="px-24">
              <div class="flex items-center gap-8">
                <FontAwesomeIcon
                  :icon="['far', 'arrow-right-arrow-left']"
                  class="text-secondary-accent w-16 h-16"
                />
                <p class="copilot_drawer__section__header font-bold">
                  Customize Data in Crossbeam Copilot
                </p>
                <BillingCTA
                  v-if="isFreeTier"
                  size="x-small"
                  data-testid="crossbeam-copilot-settings-cta"
                  :billing-interaction="billingCta"
                />
              </div>
              <div class="copilot_drawer__section__description">
                <p>
                  Select how you want to push your data and your partner data
                  into Crossbeam Copilot. These settings will apply anywhere you
                  have Crossbeam Copilot set up.
                </p>
                <p>
                  Note: This does not count towards your record export limit
                </p>
                <BittsLink
                  text="Learn more about this"
                  url="https://help.crossbeam.com/en/articles/9167877-crossbeam-copilot-overview"
                  :open-in-new-tab="true"
                  :show-arrow="true"
                  class="mt-2"
                />
              </div>
              <PopulationSelectionTree
                :integration-profile-settings="newPopulationSettings"
                :class="isFreeTier && 'disabled'"
                setting-type="crossbeam_copilot"
                :is-saving="savingSettings"
                :expanded-keys="['my-data', 'partner-data']"
                :disabled="isFreeTier"
                @save-changes="onSettingsUpdated"
              />
            </div>
          </div>
        </BittsLoading>
      </div>
    </template>
    <template v-if="hasManageIntegrations" #footer>
      <BittsButton
        :disabled="savingSettings"
        :loading="savingSettings"
        :text="saveButtonText"
        data-testid="drawer-action-button"
        size="large"
        class="w-full"
        @click="saveSettings"
      />
    </template>
  </BittsDrawer>
</template>
<script setup>
import {
  BittsButton,
  BittsDrawer,
  BittsLink,
  BittsLoading,
} from '@crossbeam/bitts';

import { isEqual } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import BillingCTA from '@/components/billing/BillingCTA.vue';
import PopulationSelectionTree from '@/components/salesforce-widget/PopulationSelectionTree.vue';

import useAuth from '@/composables/useAuth';
import useIteratively from '@/composables/useIteratively';
import { BILLING_CTAS, EVENT_SITES } from '@/constants/analytics';
import { STANDARD_POPULATION_TYPES } from '@/constants/standard_populations';
import {
  useBillingStore,
  useFlashesStore,
  useIntegrationProfileStore,
  usePopulationsStore,
} from '@/stores';

const integrationInfo = computed(() => {
  return {
    domain: 'crossbeam.com',
    status: 'Active',
    title: 'Crossbeam Copilot Settings',
  };
});

const integrationProfileStore = useIntegrationProfileStore();
const flashesStore = useFlashesStore();
const router = useRouter();
const route = useRoute();
const billingStore = useBillingStore();
const populationsStore = usePopulationsStore();
const showDrawer = ref(true);
const loading = ref(true);
const savingSettings = ref(false);
const { iteratively } = useIteratively();

// The mutating value that changes before saving
const newPopulationSettings = ref(null);

const { isFreeTier } = storeToRefs(billingStore);

const integration = computed(() => route.params.integration);
const billingCta = computed(() => {
  if (integration.value === 'salesforce') {
    return {
      cta: BILLING_CTAS.SALESFORCE_WIDGET,
      event_site: EVENT_SITES.CROSSBEAM_COPILOT_SETTINGS_SALESFORCE,
    };
  }
  return {
    cta: BILLING_CTAS.BILLING,
    event_site: EVENT_SITES.CROSSBEAM_COPILOT_SETTINGS_CHROME,
  };
});

async function fetchSettings() {
  loading.value = true;
  await integrationProfileStore.readySync;
  await populationsStore.initPopulationsStore();

  if (existingIntegrationProfile.value) {
    newPopulationSettings.value = {
      standard_population_settings:
        existingIntegrationProfile.value.standard_population_settings,
      population_settings: existingIntegrationProfile.value.population_settings,
    };
    loading.value = false;
  } else {
    initProfileSettings();
  }
}

const { hasPermission } = useAuth();
const hasManageIntegrations = hasPermission('manage:integrations');

const existingIntegrationProfile = computed(() =>
  integrationProfileStore.getIntegrationProfileByType('crossbeam_copilot'),
);

const saveButtonText = computed(() => {
  if (isFreeTier.value) return 'Close';
  return savingSettings.value ? 'Saving Changes' : 'Save';
});

async function onSettingsUpdated(payload) {
  newPopulationSettings.value = payload;
}

async function saveSettings() {
  if (isFreeTier.value) {
    onDrawerClosed();
    return;
  }

  const initialSettings = newPopulationSettings.value;
  savingSettings.value = true;
  await nextTick();

  const outPayload = {
    population_settings: newPopulationSettings.value.population_settings.map(
      (p) => ({
        population_id: p.population_id,
        is_partner_population: p.is_partner_population,
        is_included: p.is_included,
      }),
    ),
    standard_population_settings:
      newPopulationSettings.value.standard_population_settings.map((p) => ({
        standard_type: p.standard_type,
        is_included: p.is_included,
      })),
  };

  if (isEqual(initialSettings, outPayload)) {
    onDrawerClosed();
    return;
  }

  if (existingIntegrationProfile.value) {
    try {
      await integrationProfileStore.putIntegrationProfileSettings(
        existingIntegrationProfile.value.profile_id,
        outPayload,
      );
      flashesStore.addSuccessFlash({
        message: 'Successfully saved Crossbeam Copilot settings',
      });
      integrationProfileStore.refreshIntegrationProfileStore();
      iteratively.userSavesCopilotIntegrationSetting({
        integration_name: integration.value,
        event_site: EVENT_SITES.CROSSBEAM_COPILOT_SETTINGS_DRAWER,
      });
      onDrawerClosed();
    } catch (_err) {
      onDrawerClosed();
      flashesStore.addErrorFlash({
        message: 'Your Crossbeam Copilot settings could not be saved',
      });
    } finally {
      savingSettings.value = false;
    }
  }
}

async function onDrawerClosed() {
  showDrawer.value = false;
  await router.push({
    name: 'integrations',
    query: {
      ...route.query,
    },
  });
}

async function initProfileSettings() {
  loading.value = true;
  // If there isn't an appropriate integration profile, make a new one and post it.
  const popSettings = populationsStore.populations
    .map((pop) => {
      return {
        population_id: pop.id,
        is_partner_population: false,
        is_included: true,
      };
    })
    .concat(
      populationsStore.partnerPopulations.map((pop) => {
        return {
          population_id: pop.id,
          is_partner_population: true,
          is_included: true,
        };
      }),
    );

  const standardPopSettings = STANDARD_POPULATION_TYPES.map((pop) => ({
    standard_type: pop,
    is_included: true,
  }));

  const payload = {
    integration_type: 'crossbeam_copilot',
    population_settings: popSettings,
    standard_population_settings: standardPopSettings,
  };

  try {
    await integrationProfileStore.postIntegrationProfileSettings(payload);
    await integrationProfileStore.refreshIntegrationProfileStore();
    newPopulationSettings.value = {
      standard_population_settings: popSettings,
      population_settings: standardPopSettings,
    };
  } catch (err) {
    flashesStore.addErrorFlash({ message: err.message });
  } finally {
    loading.value = false;
  }
}

onMounted(async () => {
  await fetchSettings();
});
</script>

<style lang="pcss">
.copilot_drawer .c-bitts-empty-state-large-border {
  @apply w-auto;
}

.copilot_drawer {
  @apply py-16;
}

.copilot_drawer__section__header {
  @apply text-base text-neutral-800 leading-6;
}

.copilot_drawer__section__description {
  @apply text-sm text-neutral-600 gap-6 flex flex-col;
}

.copilot_drawer__empty-state {
  @apply m-24 p-24 text-center border;
  border-color: theme('colors.neutral.300');
  border-radius: 16px;
}

.copilot_drawer__empty-state--description {
  @apply mb-16 text-neutral-500;
}

.copilot_drawer .c-bitts-empty-state-title {
  @apply text-neutral-900;
}

.copilot_drawer .c-bitts-empty-state-large-border {
  @apply w-auto;
}

.c-copilot-settings-drawer
  .ant-drawer-wrapper-body
  .ant-drawer-body
  div
  div
  header
  div
  .bitts-drawer__header-title {
  @apply font-bold;
}
</style>

<template>
  <div class="downgrade-information-view">
    <h3> You'll be downgraded to the Explorer Plan </h3>
    <ul class="renewal-info" data-testid="renewal-info">
      <li>
        You will stay on your current plan until your scheduled renewal date ({{
          subscriptionRenewalDate
        }})
      </li>
      <li>
        After that, you will be downgraded to our
        <BittsLink text="free plan" url="https://www.crossbeam.com/pricing/" />
      </li>
      <li>
        Based on our
        <BittsLink
          text="billing & payment terms"
          url="https://www.crossbeam.com/legal/terms/payment-terms/"
        />
        no feeds paid will be refunded
      </li>
    </ul>
    <h3> You'll be limited to 3 Full Access seats </h3>
    <DowngradeGraph :warning="true" data-testid="core-seat-warning">
      You can only have 3 users on Crossbeam
      <span v-if="coreSeatCount > 3"
        >(you currently have <span class="font-bold">{{ coreSeatCount }}</span
        >)
      </span>
    </DowngradeGraph>
    <h3> You'll lose access to Crossbeam for Sales </h3>
    <DowngradeGraph :warning="true" data-testid="core-seat-warning">
      All users with Full Access seats will lose access to Crossbeam for Sales
    </DowngradeGraph>
    <DowngradeGraph :warning="true" data-testid="core-seat-warning">
      All users with Sales seats will be removed from your account
    </DowngradeGraph>
    <h3> You'll have new lower plan limits </h3>
    <DowngradeGraph :warning="true">
      You'll be limited to
      <BittsTooltip class="inline-flex" placement="bottom">
        <span class="tooltip-copy">Standard Account Mapping </span>
        <template #title>
          Reporting with a single partner that cannot be saved or have
          notifications
        </template>
      </BittsTooltip>
      (you can currently <b>Advanced Account Map</b>)
    </DowngradeGraph>
    <DowngradeGraph :warning="true">
      You'll be limited to 1 offline partner (you currently have
      <b>{{ offlinePartners.length }}</b
      >)
    </DowngradeGraph>
    <DowngradeGraph :warning="true">
      You'll be limited to
      <BittsTooltip class="inline-flex" placement="bottom">
        <span class="tooltip-copy">100 records per export</span>
        <template #title> Export reports with 100 results or less </template>
      </BittsTooltip>
    </DowngradeGraph>
    <h3> You'll lose access to these features </h3>
    <DowngradeGraph data-testid="custom-pop-warning">
      Custom Populations
      <span v-if="customPopCount"
        >(you currently have <b>{{ customPopCount }}</b
        >)</span
      >
    </DowngradeGraph>
    <DowngradeGraph data-testid="integrations-warning">
      Outbound Integrations
      <span v-if="outboundIntegrationsCount"
        >(you currently have <b>{{ outboundIntegrationsCount }}</b
        >)</span
      >
    </DowngradeGraph>
    <DowngradeGraph data-testid="integrations-warning">
      <BittsTooltip class="inline-flex" placement="bottom">
        <span class="tooltip-copy">Partner Impact</span>
        <template #title>
          Aggregate Win Rate, Opp Size, and Time to Close to calculate Partner
          Impact and pinpoint crucial partners and accounts
        </template>
      </BittsTooltip>
    </DowngradeGraph>
    <DowngradeGraph data-testid="integrations-warning">
      Shared Lists
      <span v-if="lists.length"
        >(you currently have <b>{{ lists.length }}</b
        >)</span
      >
    </DowngradeGraph>
  </div>
</template>

<script setup>
import { BittsLink, BittsTooltip } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import DowngradeGraph from '@/components/billing/DowngradeGraph.vue';

import useIntegrations from '@/composables/useIntegrations';
import useSeats from '@/composables/useSeats';
import {
  useBillingStore,
  useCollaborateStore,
  usePartnersStore,
  usePopulationsStore,
} from '@/stores';

const billingStore = useBillingStore();
const populationsStore = usePopulationsStore();
const collaborateStore = useCollaborateStore();
const partnersStore = usePartnersStore();

const { lists } = storeToRefs(collaborateStore);
const { offlinePartners } = storeToRefs(partnersStore);
const { subscriptionRenewalDate } = storeToRefs(billingStore);
const { installedConnectorIntegrations } = useIntegrations();
const { coreSeatCount } = useSeats();

const customPopCount = computed(
  () => populationsStore.getCustomPopulations().length,
);
const outboundIntegrationsCount = computed(
  () => installedConnectorIntegrations.length,
);
</script>

<style lang="pcss" scoped>
h3 {
  @apply text-neutral-text-strong text-m font-bold mb-12 mt-28;
}

p {
  @apply flex items-center gap-8 mb-16 text-neutral-text;
}

.tooltip-copy {
  @apply underline decoration-dashed underline-offset-4 decoration-neutral-text-placeholder;
}

.renewal-info {
  @apply flex flex-col gap-10 ml-16 mt-8;
  li {
    @apply text-neutral-text list-disc pl-12;
    &::marker {
      @apply text-neutral-accent;
    }
  }
}
</style>

<template>
  <div class="c-card-org-icons flex">
    <div
      v-for="org in orgs.slice(0, 8)"
      :id="`${keySegment}_partner_${org.id}`"
      :key="`${keySegment}_partner_${org.id}`"
      :class="['c-card-org-icons__icon', orgIconClasses]"
    >
      <BittsTooltip>
        <router-link :to="link(org)">
          <BittsAvatar
            :id="`${keySegment}_partner_${org.id}`"
            :org="org"
            :is-own="org.id === currentOrg.id"
            :show-initials="true"
            size="small"
            shape="square"
          />
        </router-link>
        <template #title>
          {{ org.name }} {{ currentOrg.id === org.id ? `(You)` : null }}
        </template>
      </BittsTooltip>
    </div>
    <BittsPopover
      v-if="orgs.length > 8"
      trigger="hover"
      placement="bottomRight"
    >
      <div
        class="rounded-bts-sm text-brand-blue px-4 mr-4 border border-solid border-neutral-200 font-bold pt-2"
      >
        +{{ orgs.length - 8 }}
      </div>
      <template #content>
        <div class="c-card-org-icons__popover-partner-orgs">
          <div
            v-for="org in orgs.slice(8)"
            :key="org.id"
            class="c-card-org-icons__popover-partner-org"
          >
            <BittsAvatar
              :id="`${keySegment}_partner_${org.id}`"
              :org="org"
              :is-own="org.id === currentOrg.id"
              :show-initials="true"
              size="small"
              shape="square"
              class="mr-8"
            />
            <span
              >{{ org.name }}
              {{ currentOrg.id === org.id ? `(You)` : null }}</span
            >
          </div>
        </div>
      </template>
    </BittsPopover>
  </div>
</template>

<script>
import { BittsAvatar, BittsPopover, BittsTooltip } from '@crossbeam/bitts';

import useAuth from '@/composables/useAuth';

export default {
  name: 'CardOrgIcons',
  components: {
    BittsAvatar,
    BittsPopover,
    BittsTooltip,
  },
  props: {
    orgs: {
      type: Array,
      required: true,
    },
    keySegment: {
      type: String,
      required: true,
    },
    orgIconClasses: {
      type: [Array, String],
      default: null,
    },
  },
  setup() {
    const { currentOrg } = useAuth();

    return { currentOrg };
  },
  methods: {
    link(org) {
      if (org.id === this.currentOrg.id) {
        return {
          name: 'team',
        };
      }
      return {
        name: 'partner_details',
        params: {
          partner_org_id: org.id,
        },
      };
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-card-org-icons {
  margin: 12px 0px;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 512px;
  text-overflow: ellipsis;
}

.c-card-org-icons__icon {
  @apply leading-none;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 0px 4px;
}

.c-card-org-icons__popover-partner-orgs {
  @apply p-8;
}

.c-card-org-icons__popover-partner-org {
  @apply flex items-center mb-4;
}
</style>

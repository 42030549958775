import { CORE, SALES } from '@/constants/team';

enum SeatType {
  sales = SALES,
  core = CORE,
}

export enum SeatRequestStatus {
  rejected = 'rejected',
  accepted = 'accepted',
  pending = 'pending',
}

export type SeatRequest = {
  id: string;
  first_name: string;
  last_name: string;
  message?: string;
  type: SeatType;
  email: string;
  created_at: string;
  org_name: string;
  org_domain: string;
  status: SeatRequestStatus;
};

<template>
  <div
    :class="{
      open: visible,
      [`bitts-modal--${variant}`]: true,
    }"
    class="o-bitts-modal"
    @click="closeModal(true)"
  >
    <dialog
      ref="dialog"
      :class="{ mobile: isMobile }"
      :open="visible"
      @click.stop
    >
      <FontAwesomeIcon
        v-if="showCloseButton && !confirmationModal"
        class="close-icon"
        :class="{ disabled: actionLoading }"
        :icon="['fas', 'xmark']"
        @click.stop="closeModal()"
      />
      <div class="o-bitts-modal__header">
        <div class="o-bitts-modal__header__image">
          <!-- This slot may also be used for SVGS, components, etc -->
          <slot name="image">
            <div v-if="icon" :class="[iconColor]" class="icon-background">
              <FontAwesomeIcon class="icon" :icon="icon" />
            </div>
          </slot>
        </div>
        <slot name="header">
          <h3 class="o-bitts-modal__title" :class="{ 'pt-8': !icon }">
            {{ title }}
          </h3>
          <p v-if="description" class="o-bitts-modal__description">
            {{ description }}
          </p>
        </slot>
      </div>
      <div class="o-bitts-modal__content">
        <BittsLoading :is-loading="loading">
          <div class="o-bitts-modal__content--background">
            <slot />
          </div>
        </BittsLoading>
      </div>
      <div class="o-bitts-modal__footer">
        <slot name="footer">
          <slot name="secondary-button">
            <BittsButton
              v-if="backButton"
              data-testid="bitts-modal--back-button"
              class="o-bitts-modal__back-button"
              text="Go Back"
              size="large"
              type="neutral"
              variant="ghost"
              :left-icon="['fas', 'arrow-left']"
              :disabled="actionLoading"
              @click.stop="emit('back')"
            />
            <BittsButton
              v-else
              data-testid="bitts-modal--secondary-button"
              class="o-bitts-modal__secondary-button"
              :text="secondaryButtonText"
              size="large"
              type="neutral"
              variant="fill"
              :disabled="actionLoading"
              @click.stop="() => closeModal()"
            />
          </slot>
          <slot name="primary-button">
            <BittsButton
              data-testid="bitts-modal--primary-button"
              :loading="actionLoading"
              :type="primaryButtonType"
              class="o-bitts-modal__primary-button"
              :text="primaryButtonText"
              size="large"
              @click.stop="emit('action')"
            />
          </slot>
        </slot>
      </div>
    </dialog>
  </div>
</template>

<script lang="ts">
import { useScreenSize } from '@crossbeam/pointbreak';

import { computed, ref } from 'vue';

import BittsButton, { BittsButtonType } from '../BittsButton/BittsButton.vue';
import BittsLoading from '../BittsLoading/BittsLoading.vue';
import { BittsIcon } from '../types';

export type BittsModalTwoVariant =
  | 'primary'
  | 'data'
  | 'tall'
  | 'confirm'
  | 'info';
export interface BittsModalTwoProps {
  title: string;
  showCloseButton?: boolean;
  width?: number;
  variant?: BittsModalTwoVariant;
  description?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  primaryButtonType?: Exclude<BittsButtonType, 'upsell' | 'white'>;
  icon?: BittsIcon;
  iconColor?: 'neutral' | 'info';
  backButton?: boolean;
  /** Controls whether the modal can be closed by clicking outside of it. */
  useMaskToClose?: boolean;
  loading?: boolean;
  actionLoading?: boolean;
}
</script>
<script setup lang="ts">
const props = withDefaults(defineProps<BittsModalTwoProps>(), {
  showCloseButton: true,
  width: 600,
  variant: 'primary',
  description: undefined,
  primaryButtonText: 'Save',
  secondaryButtonText: 'Cancel',
  primaryButtonType: 'primary',
  icon: undefined,
  iconColor: 'neutral',
  backButton: false,
  useMaskToClose: false,
  loading: false,
  actionLoading: false,
});

const emit = defineEmits(['closed', 'action', 'back']);

const dialog = ref<HTMLDialogElement | null>(null);
const { isMobile } = useScreenSize();

const visible = defineModel({ type: Boolean, default: true });

function closeModal(fromMask = false) {
  if (props.actionLoading || (fromMask && !props.useMaskToClose)) return;
  if (dialog.value) {
    dialog.value.close();
    visible.value = false;
  }
  emit('closed');
}

const computedWidth = computed(() => `${props.width}px`);
const confirmationModal = computed(() => props.variant === 'confirm');
</script>
<style lang="pcss" scoped>
.o-bitts-modal {
  @apply fixed left-0 top-0 flex items-center h-[100vh] w-[100vw];
  &.open {
    @apply bg-black/30 z-[1040]; /* Higher than everything except for other Ant-D dropdowns, etc. */
  }
  &:not(.open) {
    @apply z-[-1];
  }
  dialog {
    @apply pb-16 rounded-16 opacity-100 border-neutral-border border shadow-overlay overflow-auto;
    width: v-bind(computedWidth);

    &:not(.mobile) {
      max-height: calc(100% - 24px);
    }

    &.mobile {
      @apply w-full h-full rounded-none flex flex-col;

      .o-bitts-modal__footer {
        @apply mt-auto;
      }
    }

    .close-icon {
      @apply absolute top-24 right-24 cursor-pointer text-neutral-text w-24 h-24;
      &:hover {
        @apply opacity-70;
      }
    }

    .o-bitts-modal__header {
      @apply px-24 pb-16 pt-24;
      .o-bitts-modal__title {
        @apply text-neutral-text-strong font-bold text-xl mb-4 px-16 text-center w-full;
      }

      .o-bitts-modal__description {
        @apply text-neutral-text mt-4 text-m text-center;
      }

      .icon-background {
        @apply rounded-full w-120 h-120 px-24 mt-16 pt-12 mb-8 bg-top-gradient from-neutral-accent/20 to-neutral-accent/0  text-neutral-accent;
        .icon {
          @apply mt-12 w-64 h-64;
        }
        &.neutral {
          @apply text-neutral-accent from-neutral-accent/20 to-neutral-accent/0;
        }
        &.info {
          @apply text-info-accent from-info-accent/20 to-info-accent/0;
        }
      }
    }

    .o-bitts-modal__content {
      @apply py-16 flex-1;
    }

    .o-bitts-modal__footer {
      @apply flex justify-between pt-16 px-24 gap-24;
    }
  }

  /* The different types of modals */
  &.bitts-modal--primary {
    dialog {
      .o-bitts-modal__header {
        @apply px-24 pb-16 pt-24 bg-top-gradient from-neutral-accent/20 to-neutral-accent/0;
      }
    }

    .o-bitts-modal__footer {
      @apply border-t border-neutral-border;
    }
    .o-bitts-modal__header {
      @apply flex flex-col items-center;
    }
    .o-bitts-modal__content {
      @apply mb-24 mx-24 rounded-8;
    }
  }

  &.bitts-modal--data {
    .o-bitts-modal__content {
      @apply mx-24;
    }
    .o-bitts-modal__header {
      .o-bitts-modal__description {
        @apply mt-12 text-left;
      }
    }
    .o-bitts-modal__header {
      @apply border-b border-neutral-border;
    }
    .o-bitts-modal__footer {
      @apply border-t border-neutral-border;
    }
  }

  &.bitts-modal--tall {
    .o-bitts-modal__header {
      @apply flex flex-col items-center;
      .o-bitts-modal__description {
        @apply mt-12 mx-16;
      }
    }
    .o-bitts-modal__content--background {
      @apply pb-24 px-24 pt-16 bg-neutral-50 rounded-8;
    }
    .o-bitts-modal__content {
      @apply pb-24 px-40 bg-neutral-background;
    }
    .o-bitts-modal__footer {
      @apply bg-neutral-background pb-16 rounded-b-16;
      .c-bitts-btn {
        @apply flex-1;
      }
    }
    dialog {
      @apply pb-0;
    }
  }

  &.bitts-modal--info,
  &.bitts-modal--confirm {
    dialog {
      @apply w-[400px];
      &.mobile {
        @apply w-full h-full rounded-none;
      }
    }
    .o-bitts-modal__header {
      @apply flex flex-col items-center;
    }
    .o-bitts-modal__description {
      @apply mb-24;
    }
    .o-bitts-modal__content {
      @apply hidden;
    }
    .o-bitts-modal__footer {
      @apply pb-16 border-t border-neutral-border mt-auto;
      .c-bitts-btn {
        @apply flex-1;
      }
    }
    dialog {
      @apply pb-0;
    }
  }

  &.bitts-modal--info {
    .o-bitts-modal__secondary-button {
      @apply hidden;
    }
    .o-bitts-modal__footer {
      @apply border-none;
    }
  }
}
</style>

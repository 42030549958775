<template>
  <div class="account-locked">
    <div class="flex items-center justify-between mt-16 mx-16">
      <div class="w-[200px]">
        <BittsSvg class="h-24" svg="logo" />
      </div>
      <BittsButton type="neutral" text="Logout" @click="handleLogout" />
    </div>
    <div class="account-locked__content">
      <BittsSvg svg="lockedOut" class="mb-32" />
      <div>
        <h1>
          {{ disabledReason }}
        </h1>
        <p class="description">
          {{ disabledDescription }}
        </p>
      </div>
      <div class="flex gap-16 mt-40 justify-center">
        <BittsButton
          data-testid="contact-support"
          text="Contact Support"
          variant="outline"
          size="large"
          @click="contactSupport"
        />
        <BittsButton
          v-if="!sunsetting"
          text="Update Billing Info"
          size="large"
          url="/logout"
          @click="updateBillingInfo"
        />
        <component :is="!isAdmin ? BittsTooltip : 'div'" v-else>
          <div>
            <BittsButton
              :disabled="!isAdmin"
              placement="bottom"
              data-testid="downgrade-perks"
              text="Unlock Account"
              size="large"
              @click="$emit('downgrading')"
            />
          </div>
          <template #title> An admin needs to do this </template>
        </component>
      </div>
    </div>
  </div>
  <EditPaymentModal
    cancel-destination="account-locked"
    :visible="editCard"
    @update-successful="editCard = false"
    @cancel="editCard = false"
  />
</template>

<script setup>
import { BittsButton, BittsSvg, BittsTooltip } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import EditPaymentModal from '@/pages/billing/EditPaymentModal.vue';

import useAuth from '@/composables/useAuth';
import { intercomOrEmailMessage } from '@/utils';

defineEmits(['downgrading']);

const props = defineProps({
  sunsetting: {
    type: Boolean,
    default: false,
  },
});

useHead({ title: 'Payment Overdue' });

const editCard = ref(false);

const router = useRouter();

async function updateBillingInfo() {
  editCard.value = true;
}
const { currentOrg, currentAuth } = useAuth();

const isAdmin = computed(() => currentAuth.value.role.name === 'Admin');

const disabledReason = computed(() =>
  props.sunsetting
    ? `Your account is temporarily ${props.sunsetting ? 'locked' : 'disabled'} until you take action`
    : 'Your account is temporarily disabled because your payment failed after 28 days',
);
const disabledDescription = computed(() =>
  props.sunsetting
    ? "Your access to paid features on Crossbeam's limited-time early adopter program has ended. Please downgrade your account or contact us for support."
    : 'It happens to the best of us! Update your payment or contact our support team with any questions.',
);

function contactSupport() {
  const reason = props.sunsetting
    ? 'inaction on early adopter perks'
    : 'failed payment';

  intercomOrEmailMessage(
    'sales@crossbeam.com',
    `Re-activate ${currentOrg.value.name}`,
    `Hello, I'd like to talk to someone about my account, ${currentOrg.value.name}, which is temporarily disabled due to ${reason}`,
  );
}

function handleLogout() {
  router.push({ name: 'logout' });
}
</script>

<style lang="pcss" scoped>
.account-locked {
  @apply flex flex-col h-full;
}

.account-locked__content {
  @apply max-w-[750px] m-auto flex flex-col items-center justify-center h-full;
}

.account-locked h1 {
  @apply text-xxxl text-neutral-text-strong text-center font-bold;
  font: 'Usual';
}
.account-locked h2 {
  @apply text-neutral-text text-center;
}

.description {
  @apply text-lg text-neutral-text text-center mt-16 max-w-[600px] m-auto;
}
</style>

import { DEVICE_UUID as FAKE_DEVICE_UUID } from '@test/constants';

import appConfig from '@/config';
import { EVENT_SITES } from '@/constants/analytics';
import { captureException } from '@/errors';
import iteratively from '@/itly';
import { ls } from '@/local_storage';
import { useSalesIntelStore } from '@/salesintel/stores/SalesIntelStore';

// note, we use a segment plugin with iteratively, so it will load segment.
// Since our prod segment key has a fullstory integration set up via the segment UI, it will also
// load fullstory for prod

export default function useIteratively() {
  const copilotTrackingObject = (
    trackingObject,
    currentUser,
    currentOrg,
    includeDeviceId,
    includeUserInfo,
    includeSource,
  ) => {
    const salesIntelStore = useSalesIntelStore();
    if (!trackingObject.properties) trackingObject.properties = {};
    if (includeDeviceId) {
      const uuid = ls.deviceUUID.get();
      trackingObject.properties.device_id = uuid || '';
    }

    const userEmail = currentUser?.email;
    if (userEmail) trackingObject.properties.email = userEmail;
    if (includeUserInfo) {
      const userId = currentUser?.id;
      if (userId) trackingObject.properties.user_id = `${userId}`;
      const orgId = currentOrg?.id;
      if (orgId) trackingObject.properties.organization_id = `${orgId}`;
    }
    if (includeSource && salesIntelStore.source) {
      trackingObject.properties.source = salesIntelStore.source;
    }
    return trackingObject;
  };

  const appTrackingObject = (trackingObject, currentUser, currentOrg) => {
    let uuid = ls.deviceUUID.get() || '';
    if (import.meta.env.VITEST === 'true') uuid = FAKE_DEVICE_UUID;
    const userEmail = currentUser?.email || '';
    const userId = currentUser?.id?.toString() || '';
    const organizationId = currentOrg?.id?.toString() || '';

    if (!trackingObject.properties) trackingObject.properties = {};
    trackingObject.properties.email = userEmail;
    trackingObject.properties.user_id = userId;
    trackingObject.properties.organization_id = organizationId;
    trackingObject.properties.device_id = uuid;

    if (!trackingObject.properties.event_site)
      trackingObject.properties.event_site = EVENT_SITES.MISSING_EVENT_SITE;
    return trackingObject;
  };

  const isInitialized = iteratively.itly.itly.options;

  const loadIteratively = () => {
    if (!isInitialized) {
      iteratively.load({ environment: appConfig.iteratively.environment });
    }
  };

  const setTrackingOptions = (currentUser, currentOrg, isCopilot) => {
    const originalTrackingFunc = iteratively.itly.track;
    /* Overwrite the track function to add more info
     * We're sending the organization ID, device ID, user ID, and email
     * properties automatically. If an event_site properties is not supplied,
     * we tell analytics with MISSING_EVENT_SITE.
     * We are intentionally swallowing iteratively errors so as to not crash the application */

    iteratively.itly.track = (
      trackingObject = {},
      {
        includeDeviceId = true,
        includeUserInfo = false,
        includeSource = false,
      } = {},
    ) => {
      try {
        const updatedTrackingObject = isCopilot
          ? copilotTrackingObject(
              trackingObject,
              currentUser,
              currentOrg,
              includeDeviceId,
              includeUserInfo,
              includeSource,
            )
          : appTrackingObject(trackingObject, currentUser, currentOrg);
        originalTrackingFunc(updatedTrackingObject);
      } catch (err) {
        if (import.meta.env.DEV) {
          console.log(err);
        } else {
          captureException(err);
        }
      }
    };
  };

  const captureLoggedInUser = (currentUser, currentOrg) => {
    if (currentUser?.id) {
      iteratively.identify(currentUser.id.toString(), {
        email: currentUser.email,
      });
    }
    if (currentOrg?.id) {
      iteratively.group(currentOrg.id.toString(), {
        name: currentOrg.name,
      });
    }
  };

  return {
    iteratively,
    loadIteratively,
    setTrackingOptions,
    captureLoggedInUser,
  };
}

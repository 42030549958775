import axios from 'axios';
import { each, find, orderBy } from 'lodash';
import { defineStore } from 'pinia';

import { STANDARD_POPULATION_TYPES } from '@/constants/standard_populations';
import { captureException } from '@/errors';
import urls from '@/urls';

export const usePopulationsStore = defineStore('Populations', {
  state: () => {
    return {
      initPromise: null,
      populations: [],
      inactivePopulations: [],
      populationStats: [],
      partnerPopulations: [],
      uniqueRecordCount: null,
    };
  },
  getters: {
    getStandardPopulations: (state) => () => {
      return state.populations.filter((pop) =>
        STANDARD_POPULATION_TYPES.includes(pop.standard_type),
      );
    },
    getCustomPopulations: (state) => () => {
      return state.populations.filter(
        (pop) => !STANDARD_POPULATION_TYPES.includes(pop.standard_type),
      );
    },
    getPopulationById: (state) => (id) => {
      return state.populations.find((pop) => pop.id === id);
    },
    getPopulationByName: (state) => (name) => {
      return state.populations.find(
        (pop) => pop.name?.toLowerCase() === name?.toLowerCase(),
      );
    },
    getPartnerPopulationById: (state) => (id) => {
      return state.partnerPopulations.find((pop) => pop.id === id);
    },
    getPartnerPopulationsByOrg: (state) => (partnerOrgId) => {
      return state.partnerPopulations.filter(
        (pop) => pop.organization_id === partnerOrgId,
      );
    },
    getPopulationByStandardType: (state) => (type) => {
      return state.populations.find((pop) => pop.standard_type === type);
    },
    getPartnerPopulationsByStandardType: (state) => (type) => {
      return state.partnerPopulations.filter(
        (population) => population.standard_type === type,
      );
    },
    getPopulationIds: (state) => {
      return state.populations.map((pop) => pop.id);
    },
  },
  actions: {
    async refreshPopulationsStore() {
      try {
        const [
          populationResponse,
          inactivePopsResponse,
          populationStatsResponse,
          partnerPopResponse,
        ] = await Promise.all([
          axios.get(urls.populations.all_v3({ onlyInactive: false })),
          axios.get(urls.populations.all_v3({ onlyInactive: true })),
          axios.get(urls.population_stats.all),
          axios.get(urls.populations.allPartnerPops),
        ]);
        this.populationsLoadingComplete({
          populations: populationResponse.data.items,
          inactivePopulations: inactivePopsResponse.data.items,
          populationStats: populationStatsResponse.data.items,
          partnerPopulations: partnerPopResponse.data.items,
        });
        await this.loadUniqueRecordCount();
      } catch (xhr) {
        captureException(xhr);
      }
    },
    async refreshPartnerPopulations() {
      try {
        const partnerPopResponse = await axios.get(
          urls.populations.allPartnerPops,
        );
        this.partnerPopulations = partnerPopResponse.data.items;
      } catch (xhr) {
        captureException(xhr);
      }
    },
    async initPopulationsStore() {
      if (!this.initPromise) this.initPromise = this.refreshPopulationsStore();
      return this.initPromise;
    },
    async loadUniqueRecordCount() {
      const resp = await axios.get(urls.recordStats);
      this.uniqueRecordCount = resp.data.unique_record_count;
    },
    populationsLoadingComplete({
      populations,
      inactivePopulations,
      populationStats,
      partnerPopulations,
    }) {
      this.populations = sortPops(populations, populationStats);
      this.inactivePopulations = sortPops(inactivePopulations, populationStats);
      this.populationStats = populationStats;
      this.partnerPopulations = partnerPopulations;
    },
    removePopulation(id) {
      this.populations = this.populations.filter((s) => s.id !== id);
    },
    upsertPopulation(population) {
      this.populations = this.populations
        .filter((s) => s.id !== population.id)
        .concat(population);
    },
  },
});

function sortPops(populations, populationStats) {
  return orderBy(
    each(populations, (pop) => {
      pop.meta =
        find(populationStats, (popStats) => {
          return pop.id === popStats.population_id;
        }) || {};
    }),
    ['name'],
    ['asc'],
  );
}

<template>
  <Loading :is-loading="!allStoresReady">
    <div class="c-seat-selection">
      <p class="text-neutral-text-weak mb-12">
        {{ stepLabel }}. Select Seats
      </p>
      <div
        :class="{
          'flex-col': isSmall,
          'flex-row': !isSmall,
        }"
        class="c-seat-selection__content"
      >
        <BittsCard
          v-for="(section, i) in copySections"
          :key="`seat-copy__${i}`"
          :no-padding="false"
          class="c-full-access-seats"
        >
          <div class="h-full flex flex-col">
            <div class="flex justify-between items-center">
              <h3 class="title">
                {{ section.title }}
              </h3>
              <BittsUserStack
                v-if="section.type === FULL_ACCESS || salesSeatCount"
                size="small"
                class="cursor-pointer"
                :users="section.type === FULL_ACCESS ? coreUsers : salesUsers"
                @click="handleUserStackClick"
              />
            </div>
            <p class="description">
              {{ section.description }}
            </p>
            <p v-if="preferredCrm" class="preferred-crm">
              <BittsSvg
                :svg="isHubspot ? 'hubspotIcon' : 'salesforceIcon'"
                class="w-16"
              />
              <i> Includes {{ friendlyName }} widget access </i>
            </p>
            <div class="mt-auto mb-16">
              <span class="text-neutral-text-strong font-bold text-lg">{{
                seatCost(section.type)
              }}</span>
              <span class="text-neutral-text">per seat/{{ period }}</span>
            </div>
            <div
              class="flex items-start xl:items-center flex-col xl:flex-row justify-between gap-16"
            >
              <BittsNumber
                v-if="section.type === FULL_ACCESS"
                v-model="coreSeats"
                :disabled="false"
                :default-value="coreMinimumAndDefault"
                :min="coreMinimumAndDefault"
                :max="100"
                data-testid="core-seat-incrementer"
              />
              <BittsNumber
                v-else
                v-model="salesSeats"
                :disabled="false"
                :default-value="salesMinimumAndDefault"
                :min="salesMinimumAndDefault"
                :max="100"
                data-testid="sales-seat-incrementer"
              />
              <BittsTooltip
                v-if="
                  section.type === FULL_ACCESS
                    ? coreSeatsToReclaim
                    : salesSeatsToReclaim
                "
                placement="bottomLeft"
              >
                <BittsTag color="early-adopter" size="x-small" variant="leaf">
                  {{
                    section.type === FULL_ACCESS
                      ? coreSeatsToReclaim
                      : salesSeatsToReclaim
                  }}
                  {{
                    pluralize(
                      section.type === FULL_ACCESS
                        ? coreSeatsToReclaim
                        : salesSeatsToReclaim,
                      'seat',
                    )
                  }}
                  available to reclaim
                </BittsTag>
                <template #title>
                  Seats removed from your plan can be added back today for $0
                  and will renew at their cost on your renewal date
                </template>
              </BittsTooltip>
            </div>
          </div>
        </BittsCard>
      </div>
    </div>
  </Loading>
</template>

<script setup>
import {
  BittsCard,
  BittsNumber,
  BittsSvg,
  BittsTag,
  BittsTooltip,
  BittsUserStack,
} from '@crossbeam/bitts';
import { useScreenSize } from '@crossbeam/pointbreak';

import { pluralize } from 'humanize-plus';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import useBilling from '@/composables/useBilling';
import useSeats from '@/composables/useSeats';
import { FULL_ACCESS, SEAT_COSTS, V4_BILLING_COPY } from '@/constants/billing';
import {
  HS3_DATA_SOURCE_TYPE,
  HUBSPOT_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { useTeamStore } from '@/stores';
import { centsToDollars } from '@/utils';

const router = useRouter();
const teamStore = useTeamStore();
const { coreSeats: currentCoreSeats, salesSeats: currentSalesSeats } =
  storeToRefs(teamStore);
const {
  coreSeatCount,
  salesSeatCount,
  coreSeatsToReclaim,
  salesSeatsToReclaim,
} = useSeats();

const props = defineProps({
  period: {
    type: String,
    required: true,
  },
});

const coreSeats = defineModel('coreSeats', { type: Number, default: 0 });
const salesSeats = defineModel('salesSeats', { type: Number, default: 0 });

const route = useRoute();
const { isSmall } = useScreenSize();
const { allStoresReady } = useBilling();
const { currentOrg } = useAuth();

/* Show SF widget unless they explicitly have chosen Hubspot as their CRM */
const preferredCrm = computed(() => currentOrg.value.preferred_crm);
const isHubspot = computed(() =>
  [HS3_DATA_SOURCE_TYPE, HUBSPOT_DATA_SOURCE_TYPE].includes(preferredCrm.value),
);
const friendlyName = computed(() =>
  isHubspot.value ? 'Hubspot' : 'Salesforce',
);

const isBuyingConnector = computed(
  () =>
    route.name === 'self-serve-purchase-connector' ||
    route.name === 'upgrade-locked',
);
const coreMinimumAndDefault = computed(() =>
  isBuyingConnector.value ? coreSeatCount.value : 0,
);
const salesMinimumAndDefault = computed(() =>
  isBuyingConnector.value ? salesSeatCount.value : 0,
);

const coreUsers = computed(() =>
  currentCoreSeats.value.map((u) => u.user || {}),
);
const salesUsers = computed(() =>
  currentSalesSeats.value.filter((u) => !u.role_id).map((u) => u.user || {}),
);

async function handleUserStackClick() {
  await router.push({ name: 'team' });
}

const stepLabel = computed(() => (isBuyingConnector.value ? 2 : 1));

const copySections = computed(() => [
  V4_BILLING_COPY.connectorUpgrade.fullAccessSeats,
  V4_BILLING_COPY.connectorUpgrade.salesSeats,
]);
function seatCost(seatType) {
  return centsToDollars(SEAT_COSTS[seatType][props.period]);
}
</script>
<style lang="pcss" scoped>
.c-seat-selection {
  @apply mb-40;
}
.c-seat-selection__content {
  @apply flex gap-24;
}
.c-full-access-seats {
  @apply flex-1 relative;
}
</style>
<style lang="pcss">
.c-full-access-seats {
  @apply p-24;
  .title {
    @apply text-neutral-text-strong font-bold text-m mb-4;
  }
  .description {
    @apply text-neutral-text max-w-[90%] mb-24;
  }
  .preferred-crm {
    @apply flex gap-12 text-neutral-text-weak mb-24;
  }
  .feature-copy {
    @apply my-24 flex flex-col gap-6 text-neutral-text ml-4;
  }
}
</style>
<style lang="pcss">
.c-seat-selection__user-stack-tooltip {
  .ant-tooltip-inner {
    @apply max-w-[150px];
  }
}
</style>

<template>
  <Loading :is-loading="!ready">
    <BittsLayout :is-page="true">
      <template #content>
        <PageTitle title="Plan & Billing" />
        <ExportLimitCallout />
        <SeatSummaryCard />
        <RecordExportSummaryCardTwo />
        <ExplorerCard v-if="isFreeTier" />
        <ConnectorCard v-else-if="isConnectorTier" />
        <EnterpriseCard v-else-if="isEnterpriseTier" />
        <ExploreBillingPlans v-if="!isEnterpriseTier" />
        <router-view-wrapper />
        <!-- The upgrade modal -->
      </template>
    </BittsLayout>
  </Loading>
</template>
<script setup>
import { BittsLayout } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { onBeforeMount } from 'vue';

import ConnectorCard from '@/components/billing/ConnectorCard.vue';
import EnterpriseCard from '@/components/billing/EnterpriseCard.vue';
import ExploreBillingPlans from '@/components/billing/ExploreBillingPlans.vue';
import ExplorerCard from '@/components/billing/ExplorerCard.vue';
import ExportLimitCallout from '@/components/billing/ExportLimitCallout.vue';
import RecordExportSummaryCardTwo from '@/components/billing/RecordExportSummaryCardTwo.vue';
import SeatSummaryCard from '@/components/billing/SeatSummaryCard.vue';
import PageTitle from '@/components/PageTitle.vue';

import { captureException } from '@/errors';
import { allReady, useBillingStore, useTeamStore } from '@/stores';

useHead({ title: 'Billing' });

const billingStore = useBillingStore();
const teamsStore = useTeamStore();
const { hasSubscription, isFreeTier, isEnterpriseTier, isConnectorTier } =
  storeToRefs(billingStore);

const ready = allReady(teamsStore, billingStore);

onBeforeMount(async () => {
  try {
    const apis = [
      teamsStore.refreshTeamStore(),
      billingStore.refreshBillingStore({ syncChargify: true }),
    ];

    /* If a connector account with subscription, populate the card information into the store */
    if (hasSubscription.value)
      apis.push(billingStore.refreshPortalAndPaymentInfo());
    await Promise.all(apis);
  } catch (err) {
    captureException(err);
  }
});
</script>

/* Step Types */
export const CONNECT_DATA = 'connect_data';
export const INVITE_PARTNER = 'invite_partner';
export const CREATE_POPULATION = 'create_population';
export const SHARE_DATA = 'share_data';
export const ACCOUNT_MAPPING = 'account_mapping';
export const INVITE_TEAM_MEMBERS = 'invite_team_members';

/* Step Statuses */
export const PROCESS = 'process';
export const WAIT_ON_PRECONDITION = 'wait_on_precondition';
export const WAIT = 'wait';
export const ERROR = 'error';
export const FINISH = 'finish';

/* Step->Data Map */
export const STEPS = {
  [INVITE_PARTNER]: {
    order: 0,
    name: INVITE_PARTNER,
    route: 'create_proposal',
    permission: 'manage:partnerships',
    friendly_name: 'Connect with a partner',
    is_complete: false,
    wait_on_precondition: false,
    waitOnPreconditionDescription:
      'This step will be complete once an organization accepts your partnership request.',
    title: 'Add Partners',
    description:
      'Invite your partners to collaborate and share data with you on Crossbeam.',
    buttonText: 'Invite Partner',
    completed: {
      descriptionPartial: 'Manage your partnerships ',
      descriptionLinkText: 'on the Partners page.',
      route: 'partners',
    },
  },
  [CONNECT_DATA]: {
    order: 1,
    name: CONNECT_DATA,
    route: 'data-sources',
    permission: 'manage:data-sources',
    friendly_name: 'Connect your data',
    is_complete: false,
    description:
      'Add a data source to begin creating Populations to share with your partners.',
    wait_on_precondition: false,
    waitOnPreconditionDescription:
      'Your data is processing and can take about 30 minutes. Once your data has finished processing, you will receive an email notifying you that the sync is complete.',
    needsFieldsSelection: false,
    needsFieldsSelectionDescription: 'Finish setting up your data source.',
    error: false,
    errorDescription: 'Something went wrong adding your data source.',
    title: 'Add Data',
    buttonText: 'Go to Data Sources Page',
    completed: {
      descriptionPartial: 'Manage your data ',
      descriptionLinkText: 'on the Data Sources page.',
      route: 'data-sources',
    },
  },
  [CREATE_POPULATION]: {
    order: 2,
    name: CREATE_POPULATION,
    route: 'create_population',
    permission: 'manage:populations',
    friendly_name: 'Create a population',
    is_complete: false,
    title: 'Create Populations',
    description:
      'Create Populations of your data to share the exact information you want.',
    buttonText: 'Create Population',
    completed: {
      descriptionPartial: 'Create additional populations ',
      descriptionLinkText: 'on the Populations page.',
      route: 'populations',
    },
  },
  [SHARE_DATA]: {
    order: 3,
    name: SHARE_DATA,
    route: 'populations',
    permission: 'manage:sharing',
    friendly_name: 'Share your data',
    is_complete: false,
    title: 'Share Data',
    description:
      'Define sharing rules to tie it all together, and begin account mapping with partners!',
    waitOnPreconditionDescription:
      'Set a population to "Sharing Data" or "Overlap counts" in order to complete this step.',
    buttonText: 'Go to Populations page',
    completed: {
      descriptionPartial: 'Manage your sharing settings ',
      descriptionLinkText: 'on the Populations page.',
      route: 'populations',
    },
  },
  [ACCOUNT_MAPPING]: {
    order: 4,
    name: ACCOUNT_MAPPING,
    permission: 'manage:reports',
    is_complete: false,
    wait_on_precondition: true,
    waitOnPreconditionDescription:
      "You can view a partnership summary and create a report when you're partnered with an organization and both sharing data.",
    title: 'Account Mapping',
    description:
      'Explore partner overlaps and save reports to take action on insights.',
    completed: {
      descriptionPartial: 'Find more partners to map accounts with ',
      descriptionLinkText: 'on the Partners page.',
      route: 'partners',
    },
  },
  [INVITE_TEAM_MEMBERS]: {
    order: 5,
    name: INVITE_TEAM_MEMBERS,
    route: 'team',
    permission: 'manage:members',
    is_complete: false,
    title: 'Invite Team Members',
    description:
      'Boost collaboration with your team by adding them to Crossbeam',
    buttonText: 'Go to Team Page',
    completed: {
      descriptionPartial: 'Manage your team members ',
      descriptionLinkText: 'on the Team page.',
      route: 'team',
    },
  },
};

/* Onboarding V3 */
export const PROGRESS = 'progress';
export const PENDING = 'pending';
export const COMPLETE = 'complete';
export const ERRORED = 'errored';
export const LOCKED = 'locked';
export const NOT_SYNCED = 'not_synced';
export const NEEDS_SHEET = 'needs_sheet';
export const DELETING = 'deleting';
export const NEEDS_PREVIOUS = 'needs_previous';
export const CALCULATING = 'calculating';

/* Returns list of objects indicating whether a feature is supported or not */
export function makeFeatureList(features, supported) {
  return features.map((feature, i) => ({
    [feature]: supported[i],
  }));
}

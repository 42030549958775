import axios from 'axios';
import { reactive, toRefs } from 'vue';

import urls from '@/urls';

function getTitleInformation({
  sendingOrg = {},
  receivingOrg = {},
  currentOrg,
}) {
  const isReceivingOrg = receivingOrg.id === currentOrg.id;
  const counterpartOrg = isReceivingOrg ? sendingOrg : receivingOrg;
  return { isReceivingOrg, counterpartOrg };
}

export const REVOKED_USER = {
  id: -1,
  first_name: 'Deactivated',
  last_name: 'User',
  email: 'revoked@crossbeam.com',
  logo_url: new URL('../assets/DeactivatedUser.jpg', import.meta.url).href,
};

export const DIRECTIONALITY_OPTIONS = [
  {
    svg: 'request',
    text: 'Request',
    value: 'request',
    defaultTitle: ({ sendingOrg, receivingOrg, currentOrg }) => {
      const { isReceivingOrg, counterpartOrg } = getTitleInformation({
        sendingOrg,
        receivingOrg,
        currentOrg,
      });
      const direction = isReceivingOrg ? 'from' : 'to';
      if (!counterpartOrg) {
        return '';
      }
      return `Request ${direction} ${counterpartOrg.name}`;
    },
  },
  {
    svg: 'partners',
    text: 'Assist',
    value: 'assist',
    defaultTitle: ({ sendingOrg, receivingOrg, currentOrg }) => {
      const { isReceivingOrg, counterpartOrg } = getTitleInformation({
        sendingOrg,
        receivingOrg,
        currentOrg,
      });
      const action = isReceivingOrg ? 'Assistance from' : 'Assisting';
      if (!counterpartOrg) {
        return '';
      }
      return `${action} ${counterpartOrg.name}`;
    },
  },
  {
    svg: 'threads',
    text: 'Discussion',
    value: 'discussion',
    defaultTitle: ({ sendingOrg, receivingOrg, currentOrg }) => {
      const { counterpartOrg } = getTitleInformation({
        sendingOrg,
        receivingOrg,
        currentOrg,
      });
      if (!counterpartOrg) {
        return '';
      }
      return `Discussion with ${counterpartOrg.name}`;
    },
  },
];

const getThreadsTimelineByRecordId =
  (state) =>
  async ({ sourceId, recordId, orgId }) => {
    try {
      state.errors.getThreadTimeline = null;
      state.threadTimelineLoading = true;
      const url = urls.threads.timelineByRecord(sourceId, recordId);
      const response = await axios.get(url);
      const {
        data: { items: threadTimeline },
      } = response;

      const timelineLookup = threadTimeline.reduce(
        (finalLookup, { timeline, message, thread }) => {
          const { id: timelineId, event_type: eventType } = timeline;

          const {
            organization_id: timelineOrgId,
            partner_organization_id: timelinePartnerOrgId,
          } = thread;

          timeline.otherOrgInThread =
            orgId === timelineOrgId ? timelinePartnerOrgId : timelineOrgId;

          if (eventType !== 'message_deleted') {
            finalLookup[timelineId] = {
              timeline,
              message,
              thread,
            };
          }
          return finalLookup;
        },
        {},
      );

      state.threadTimelineLookup = timelineLookup;
      // We are using the threadTimeline as reference for now to emulate
      // the new route linked to all things kafka timeline.
      return threadTimeline.reduce((acc, { timeline, thread }) => {
        const {
          organization_id: timelineOrgId,
          partner_organization_id: timelinePartnerOrgId,
        } = thread;

        const otherOrgInThreadId =
          orgId === timelineOrgId ? timelinePartnerOrgId : timelineOrgId;

        // this function will be refactored as needed when get the new
        // route and more events
        if (timeline.event_type !== 'message_deleted') {
          acc.push({
            otherOrgInThreadId,
            id: timeline.id,
            isPrivate: timeline.is_private,
            eventType: timeline.event_type,
            type: 'thread',
          });
        }
        return acc;
      }, []);
    } catch (err) {
      state.errors.getThreadsTimelineByRecordId = err;
    } finally {
      state.threadTimelineLoading = false;
    }
  };

export default function useThreads() {
  const state = reactive({
    threadTimelineLoading: false,
    threadTimelineLookup: {},

    errors: {
      getThreadsTimelineByRecordId: null,
    },
  });

  return {
    state: toRefs(state),
    getThreadsTimelineByRecordId: getThreadsTimelineByRecordId(state),
  };
}

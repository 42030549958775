export default {
  records: {
    addToCrm: (crmRecordId) => `/v0.1/contacts/${crmRecordId}/add-to-crm`,
    partnerContacts: (crmRecordId, usePopSettings) =>
      `/v0.1/records/${crmRecordId}/partner-contacts?use_pop_settings=${usePopSettings}&use_copilot_config=${usePopSettings}`,
    plays: (crmRecordId) =>
      `/v0.1/records/${crmRecordId}/plays?use_pop_settings=true&use_copilot_config=true`,
    playsNextSteps: (crmRecordId, playType) =>
      `/v0.1/records/${crmRecordId}/plays/${playType}/next-steps?use_pop_settings=true&use_copilot_config=true`,
    playsNextStepsAsync: (crmRecordId, playType) =>
      `/muncher/api-sse/v0.1/records/${crmRecordId}/plays/${playType}/next-steps-async`,
    playsNextStepsCache: (crmRecordId, playType) =>
      `/v0.1/records/${crmRecordId}/plays/${playType}/next-steps-cache`,
    playsNextStepsApproval: (crmRecordId, playType) =>
      `/v0.1/records/${crmRecordId}/plays/${playType}/next-steps/approval`,
    playsNextStepsApprovalReason: (crmRecordId, playType) =>
      `/v0.1/records/${crmRecordId}/plays/${playType}/next-steps/approval-reason`,
    account: (crmRecordId) =>
      `/v1/records/accounts/search?record_id=${crmRecordId}`,
    accountHighlights: (crmRecordId) =>
      `/v0.1/records/${crmRecordId}/account-highlights?use_pop_settings=true&use_copilot_config=true`,
    ecosystemInsights: (crmRecordId) =>
      `/v0.1/records/${crmRecordId}/ecosystem-insights`,
    getRecord: (domain) => `/v0.1/records/search?domain=${domain}`,
  },
  attribution: {
    overlaps: (crmRecordId) =>
      `/v1/overlaps/accounts/search?record_id=${crmRecordId}&use_pop_settings=true&use_copilot_config=true`,
  },
};

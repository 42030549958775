<template>
  <div v-if="!!exportLimit" class="report-export-summary-card">
    <div class="report-export-summary-card__title"> Record Export Summary </div>
    <BittsContainer>
      <div class="report-export-summary-card__summary-greenfield">
        <span class="text-neutral-text-weak mb-20">
          We only count unique records once, no matter how many times you export
          them. Record exports come from:
        </span>
        <div class="flex item-center">
          <FontAwesomeIcon
            :icon="['far', 'file-export']"
            :style="{ height: '16px', width: '16x', color: 'currentColor' }"
            :class="iconColorMappedToLimitStatus"
            class="mr-8"
          />
          <span class="text-neutral-text">
            Exporting Reports (Overlaps and Greenfield)
          </span>
        </div>
        <div
          class="flex items-center"
          :class="{
            'mb-24': !enabledPaidIntegrations.length || !isEnterpriseTier,
          }"
        >
          <FontAwesomeIcon
            :icon="['far', 'cube']"
            :style="{ height: '16px', width: '16x', color: 'currentColor' }"
            :class="iconColorMappedToLimitStatus"
            data-testid="outbound-integrations-icon"
            class="mr-8"
          />
          <p class="text-neutral-text"> Outbound Integrations </p>
        </div>
        <div
          v-if="enabledPaidIntegrations.length && isEnterpriseTier"
          class="flex flex-col items-start mb-24"
        >
          <div
            v-for="integration in enabledPaidIntegrations"
            :key="integration.name"
            class="flex mb-8 items-center"
          >
            <div>
              <img
                :src="logoUrl(integration.avatar)"
                :width="16"
                :height="16"
                class="mr-8"
              />
            </div>
            <span class="text-neutral-text">
              {{ integration.name }}
            </span>
          </div>
        </div>
        <div class="h-24">
          <BittsLink
            text="Learn more about record export limits"
            url="https://help.crossbeam.com/en/articles/8399864-record-export-limits"
          />
        </div>
      </div>
      <div class="report-export-summary-card__org-info-greenfield">
        <div>
          <div class="report-export-summary-card__org-info-section mb-4">
            Records Exported
          </div>
          <div
            v-if="limitRenewalDate"
            class="text-neutral-text"
            data-testid="limit-renewal-date-greenfield"
          >
            This limit resets at your plan renewal on {{ limitRenewalDate }}
          </div>
        </div>
        <div class="flex flex-col">
          <div
            :class="exportLimitStatus"
            class="report-export-summary-card__progress-bar-greenfield mb-8"
          >
            <span />
          </div>
          <div class="report-export-summary-card__progress-bar-info">
            <div
              :class="textColorMappedToLimitStatus"
              class="text-base"
              data-testid="limit-used-percent"
            >
              {{ limitUsedPercent }}
            </div>
            <div class="text-neutral-text text-base">
              {{ `${recordsExportedFormatted}/${exportLimit}` }}
            </div>
          </div>
        </div>
        <br v-if="enabledPaidIntegrations.length && isEnterpriseTier" />
        <div
          class="report-export-summary-card__org-info-section text-neutral-text-weak font-normal flex-col md:flex-row"
        >
          Need more record exports?
          <BittsButton
            size="x-small"
            text="Talk to Sales"
            variant="outline"
            :left-icon="['fas', 'wand-magic-sparkles']"
            @click="
              handleBillingInteraction({
                cta: BILLING_CTAS.RECORD_EXPORT_LIMIT,
                talkToSalesReason: 'Need more record exports',
                event_site: EVENT_SITES.BILLING_EXPORT_CARD_TALK_TO_SALES,
              })
            "
          />
        </div>
      </div>
    </BittsContainer>
  </div>
</template>
<script setup>
import { BittsButton, BittsContainer, BittsLink } from '@crossbeam/bitts';

import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import useBilling from '@/composables/useBilling';
import useIntegrations from '@/composables/useIntegrations';
import useRecordExportLimits from '@/composables/useRecordExportLimits';
import { BILLING_CTAS, EVENT_SITES } from '@/constants/analytics';
import { useBillingStore } from '@/stores';

const { handleBillingInteraction } = useBilling();
const {
  endDate,
  exportLimit,
  recordsExported,
  limitUsedPercent,
  exportLimitStatus,
} = useRecordExportLimits();

const billingStore = useBillingStore();
const { isEnterpriseTier } = storeToRefs(billingStore);

const { allEnabledIntegrations } = useIntegrations();
const enabledPaidIntegrations = computed(() =>
  allEnabledIntegrations.value.filter((integration) =>
    ['enterprise', 'connector'].includes(integration.tier),
  ),
);

const limitRenewalDate = computed(() =>
  endDate.value ? DateTime.fromISO(endDate.value).toFormat('DDD') : '--',
);

const recordsExportedFormatted = computed(() => recordsExported.value ?? '--');

function logoUrl(avatar) {
  return new URL(`../../assets/pngs/partner-cloud/${avatar}`, import.meta.url)
    .href;
}

const iconColorMappedToLimitStatus = computed(() => {
  if (exportLimitStatus.value === 'limit-reached') {
    return 'text-danger-accent';
  }
  if (exportLimitStatus.value === 'over-90') {
    return 'text-accent-accent';
  }
  return 'text-info-accent';
});

const textColorMappedToLimitStatus = computed(() => {
  if (exportLimitStatus.value === 'limit-reached') {
    return 'text-danger-text';
  }
  if (exportLimitStatus.value === 'over-90') {
    return 'text-accent-text';
  }
  return 'text-info-text';
});
</script>

<style lang="pcss" scoped>
.report-export-summary-card__info-title {
  @apply text-neutral-text-strong font-bold;
}
.report-export-summary-card__org-info {
  @apply flex flex-col gap-16;
}
.report-export-summary-card__org-info-number {
  @apply text-neutral-text-weak font-normal;
}
.report-export-summary-card__org-info-section {
  @apply flex items-center justify-between text-neutral-text-strong font-bold text-base;
}
.report-export-summary-card__progress-bar-greenfield {
  @apply bg-neutral-background-disabled w-full relative h-8 rounded-2xl;
  span {
    @apply block h-full rounded-2xl;
    background: var(
      --background-info-gradient-medium,
      linear-gradient(
        90deg,
        theme(colors.info.accent) 0%,
        theme(colors.info.text) 100%
      )
    );
    width: v-bind(limitUsedPercent);
  }
  &.limit-reached {
    span {
      background: linear-gradient(
        90deg,
        theme(colors.danger.accent) 0%,
        theme(colors.danger.text) 100%
      );
    }
  }
  &.over-90 {
    span {
      background: linear-gradient(
        90deg,
        theme(colors.accent.accent) 0%,
        theme(colors.accent.text) 100%
      );
    }
  }
}

.report-export-summary-card__progress-bar-info {
  @apply flex justify-between items-center text-xs text-neutral-text-weak pt-2;
}
.report-export-summary-card__summary-greenfield,
.report-export-summary-card__org-info-greenfield {
  @apply w-full md:w-1/2 p-24 grid;
}
.report-export-summary-card__summary-greenfield {
  @apply border-b md:border-r md:border-b-0 border-solid border-neutral-border gap-8 rounded-t-bts-lg md:rounded-l-bts-lg md:rounded-tr-none;
  background: linear-gradient(
    105deg,
    theme(colors.neutral.background-weak) 0%,
    theme(colors.neutral.background-weak / 0) 100%
  );
}
.report-export-summary-card__title {
  @apply mb-16 text-neutral-text-strong text-lg font-bold;
}
</style>
<style lang="pcss">
.report-export-summary-card {
  @apply mb-40;
  .bitts-container {
    @apply p-0 flex flex-col md:flex-row;
  }
}
</style>

<template>
  <div class="c-review-fields">
    <p v-if="showSummary" class="c-review-fields__summary">
      These are the fields we’d like to sync into Crossbeam. If you want to edit
      this further you can customize below.
    </p>
    <div
      class="c-review-fields__tree"
      :class="{ 'c-review-fields__tree--limit-height': limitHeight }"
    >
      <ReviewFieldsBranch
        v-for="object in objects"
        :key="object"
        :sources="sources"
        :object="object"
        :all-checked-field-ids="allCheckedFieldIds"
        :all-source-fields="allSourceFields"
        :required-columns="requiredColumns"
      />
    </div>
    <div v-if="showButtons" class="flex justify-between gap-16 mt-24">
      <BittsButton
        size="large"
        text="Customize"
        type="neutral"
        data-testid="preselect-fields-customize-button"
        @click="handleCustomize"
      />
      <BittsButton
        size="large"
        data-testid="preselect-fields-sync-button"
        text="Sync Now"
        @click="handleSync"
      />
    </div>
  </div>
</template>
<script setup>
import { BittsButton } from '@crossbeam/bitts';

import { computed, onMounted, ref } from 'vue';

import ReviewFieldsBranch from '@/components/data-sources/data-templates/ReviewFieldsBranch.vue';

import { getRequiredColumns } from '@/constants/data_sources';

const emit = defineEmits(['customize', 'sync']);
const props = defineProps({
  feed: {
    type: Object,
    required: true,
  },
  sources: {
    type: Array,
    required: true,
  },
  allCheckedFieldIds: {
    type: Array,
    required: true,
  },
  showButtons: {
    type: Boolean,
    default: false,
  },
  showSummary: {
    type: Boolean,
    default: false,
  },
  limitHeight: {
    type: Boolean,
    default: false,
  },
});

const requiredColumns = ref({});

onMounted(async () => {
  requiredColumns.value = await getRequiredColumns(props.feed);
});

const objects = computed(() => props.sources.map((s) => s.table).sort());

const allSourceFields = computed(() => {
  return props.sources.reduce((acc, source) => {
    const fields = source.fields.map((f) => ({ ...f, object: source.table }));
    acc.push(...fields);
    return acc;
  }, []);
});

function handleCustomize() {
  emit('customize', { allCheckedFieldIds: props.allCheckedFieldIds });
}

/* Build the payload by comparing which fields have changed
against those that have not, and by sending the sync status
of every object */
function handleSync() {
  const payload = {
    feedId: props.feed.id,
    sources: [],
    fields: [],
  };

  const syncingObjects = new Set([]);

  for (const sourceField of allSourceFields.value) {
    const id = sourceField.id;
    const wasChecked = sourceField.is_visible && sourceField.is_filterable;
    const isChecked = props.allCheckedFieldIds.includes(id);

    if (isChecked) syncingObjects.add(sourceField.object);
    if (!wasChecked && isChecked) {
      payload.fields.push({
        id: sourceField.id,
        is_filterable: true,
        is_visible: true,
      });
    } else if (wasChecked && !isChecked) {
      payload.fields.push({
        id: sourceField.id,
        is_filterable: false,
        is_visible: false,
      });
    }
  }

  for (const object of objects.value) {
    const id = props.sources.find((s) => s.table === object).id;
    payload.sources.push({
      id,
      sync_enabled: syncingObjects.has(object),
    });
  }

  emit('sync', payload);
}
</script>
<style lang="pcss" scoped>
.c-review-fields {
  @apply w-full;
}

.c-review-fields__summary {
  @apply text-neutral-text text-m mb-24;
}
.c-review-fields__tree {
  @apply border-neutral-border border p-16 rounded-8 pb-12;
}

.c-review-fields__tree--limit-height {
  @apply max-h-[600px] overflow-auto;
}
</style>

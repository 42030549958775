<template>
  <BittsEmptyState
    :title="props.title"
    :font-awesome-icon="pngName ? null : ['fad', 'wand-magic-sparkles']"
    :font-awesome-icon-class="pngName ? '' : 'text-upsell-accent'"
    :font-awesome-icon-style="{
      height: '40px',
      width: '40px',
      color: 'currentColor',
    }"
    :png-name="pngName"
    png-width="424px"
    class="upsell-overlay"
  >
    <template #subtitle>
      <div class="text-neutral-text">
        {{ props.text }}
      </div>
      <BittsButton
        :left-icon="['fas', 'wand-magic-sparkles']"
        type="upsell"
        size="small"
        text="Upgrade"
        class="mt-16"
        @click="handleBillingInteraction(props.billingInteraction)"
      />
    </template>
  </BittsEmptyState>
</template>

<script setup>
import { BittsButton, BittsEmptyState } from '@crossbeam/bitts';

import useBilling from '@/composables/useBilling';
import { billingInteractionValidator } from '@/constants/billing';

const { handleBillingInteraction } = useBilling();

const props = defineProps({
  pngName: {
    type: String,
    default: null,
  },
  text: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  billingInteraction: {
    type: Object,
    required: true,
    validator: billingInteractionValidator,
  },
});
</script>

<style scoped lang="pcss">
.upsell-overlay {
  @apply w-full h-full absolute z-30;
  background: linear-gradient(
    180deg,
    theme(colors.white / 0) 0%,
    theme(colors.neutral.background) 55.08%
  );
}
</style>
<style lang="pcss">
.upsell-overlay {
  .c-bitts-empty-state-large {
    @apply w-full;
  }
  &.c-bitts-empty-state-large-border {
    @apply border-none rounded-none;
  }
}
</style>

<template>
  <Loading :is-loading="!ready || !seatRequest">
    <BittsCard class="c-user-request-card" :no-padding="false">
      <div class="card-header">
        <BittsAvatar
          size="medium"
          :user="{
            first_name: seatRequest.first_name,
            last_name: seatRequest.last_name,
          }"
          :show-initials="true"
          class="items-center"
        />
        <div class="c-user-request-card-details">
          <div
            data-testid="user-request-name"
            class="c-user-request-card__name"
          >
            {{ seatRequest.first_name }} {{ seatRequest.last_name }}
          </div>
          <div class="c-user-request-card__email">
            {{ seatRequest.email }}
          </div>
        </div>
        <BittsTag
          v-if="seatRequest.type === CORE"
          data-testid="user-request-core-tag"
          class="max-w-[120px]"
          variant="rounded"
        >
          <template #pre>
            <BittsSvg class="w-12 h-12" svg="logoNoText" />
          </template>
          <span> Full Access </span>
        </BittsTag>
        <BittsTag
          v-else
          data-testid="user-request-sales-tag"
          color="success"
          variant="rounded"
        >
          <template #pre>
            <FontAwesomeIcon class="w-12 h-12" :icon="['fak', 'sales']" />
          </template>
          <span> Sales </span>
        </BittsTag>
      </div>
      <p
        v-if="seatRequest.message"
        class="c-user-request-card__message"
        data-testid="user-request-message"
      >
        {{ seatRequest.message }}
      </p>
    </BittsCard>
  </Loading>
</template>

<script setup>
import { BittsAvatar, BittsCard, BittsSvg, BittsTag } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { CORE } from '@/constants/team';
import { useSeatRequestsStore } from '@/stores';

const route = useRoute();
const email = computed(() => route.query.emails);

const seatRequestsStore = useSeatRequestsStore();
const { ready } = storeToRefs(seatRequestsStore);

const seatRequest = computed(() =>
  seatRequestsStore.getSeatRequestByEmail(email.value),
);
</script>
<style lang="pcss" scoped>
.c-user-request-card {
  .card-header {
    @apply flex gap-8 justify-between;

    .c-user-request-card-details {
      @apply flex-1 flex flex-col items-start justify-center;
    }

    .c-user-request-card__name {
      @apply text-neutral-text-strong font-bold text-m;
    }

    .c-user-request-card__email {
      @apply text-neutral-text text-base;
    }
  }

  .c-user-request-card__message {
    @apply italic text-neutral-text mt-16;
  }
}
</style>
<style lang="pcss">
.c-user-request-card {
  .bitts-tag .ant-tag {
    @apply flex items-center gap-4 h-24;
  }
}
</style>

import baseColors from './base-tailwind-colors.json' assert { type: 'json' }

export default function buildTailwindTheme () {
  const baseConfig = {
    extend: {
      backgroundImage: {
        'top-gradient': 'radial-gradient(136.96% 69.92% at 50.09% -12.42%, var(--tw-gradient-stops))',
      },
      strokeWidth: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
      },
      width: {
        480: '480px',
        250: '250px',
      },
      borderRadius: {
        3: '3px',
        4: '4px',
        6: '6px',
        8: '8px',
        12: '12px',
        16: '16px',
        'bts-xs': '2px',
        'bts-sm': '4px',
        'bts-base': '6px',
        'bts-md': '8px',
        'bts-lg': '16px',
        'bts-xl': '24px',
        'bts-xxl': '32px',
      },
      opacity: {
        20: '.2',
        40: '.4',
        70: '.7',
      },
      boxShadow: {
        component: '0px 2px 3px rgba(13, 25, 42, 0.05)',
        overlay: '0px 4px 8px rgba(13, 25, 42, 0.2)',
      },
    },
    minWidth: {
      0: '0',
      '1/4': '25%',
      '1/2': '50%',
      '3/4': '75%',
      full: '100%',
      300: '300px',
    },
    maxWidth: {
      0: '0',
      '1/4': '25%',
      '1/2': '50%',
      '7/10': '70%',
      '3/4': '75%',
      full: '100%',
      160: '160px',
      content: 'max-content',
    },
    minHeight: {
      0: '0',
      '1/4': '25%',
      '1/2': '50%',
      '3/4': '75%',
      full: '100%',
      screen: '100vh',
      96: '96px',
    },
    maxHeight: {
      0: '0',
      '1/4': '25%',
      '1/2': '50%',
      '3/4': '75%',
      full: '100%',
      100: '100px',
      120: '120px',
      160: '160px',
      180: '180px',
    },
    borderWidth: {
      DEFAULT: '1px',
      0: '0px',
      2: '2px',
      3: '3px',
      4: '4px',
      6: '6px',
      8: '8px',
    },
    fontSize: {
      xs: ['10px', '16px'],
      sm: ['12px', '16px'],
      base: ['14px', '24px'],
      m: ['16px', '24px'],
      lg: ['20px', '24px'],
      xl: ['24px', '32px'],
      xxl: ['32px', '40px'],
      xxxl: ['40px', '48px'],
    },
    fontWeight: {
      bold: 600,
      normal: 400,
    },
    spacing: {
      px: '1px',
      0: '0',
      1: '1px',
      2: '2px',
      3: '3px',
      4: '4px',
      5: '5px',
      6: '6px',
      8: '8px',
      9: '9px',
      10: '10px',
      12: '12px',
      14: '14px',
      16: '16px',
      18: '18px',
      20: '20px',
      24: '24px',
      25: '25px',
      28: '28px',
      30: '30px',
      32: '32px',
      36: '36px',
      40: '40px',
      44: '44px',
      48: '48px',
      52: '52px',
      56: '56px',
      60: '60px',
      64: '64px',
      68: '68px',
      72: '72px',
      80: '80px',
      95: '95px',
      96: '96px',
      100: '100px',
      120: '120px',
      160: '160px',
      180: '180px',
      240: '240px',
      260: '260px',
      300: '300px',
      350: '350px',
      bts1: '8px',
      bts2: '12px',
      bts3: '16px',
      bts4: '24px',
      bts5: '32px',
      bts6: '40px',
    },
  }

  const {
    blue,
    denim,
    green,
    red,
    neutral,
    orange,
    pink,
    teal,
    violet,
    yellow,
  } = baseColors

  const ACCENT = 'accent'
  const BACKGROUND = 'background'
  const BACKGROUND_WEAK = 'background-weak'
  const BACKGROUND_MEDIUM = 'background-medium'
  const BACKGROUND_STRONG = 'background-strong'
  const BACKGROUND_DISABLED = 'background-disabled'
  const BACKGROUND_TOOLTIP = 'background-tooltip'

  const BORDER = 'border'
  const BORDER_FOCUS = 'border-focus'
  const BORDER_PRESSED = 'border-pressed'

  const TEXT = 'text'
  const TEXT_WEAK = 'text-weak'
  const TEXT_STRONG = 'text-strong'
  const TEXT_BUTTON = 'text-button'
  const TEXT_LINK_HOVER = 'text-link-hover'
  const TEXT_PLACEHOLDER = 'text-placeholder'

  const tokenObjects = {
    neutral: {
      ...baseColors.neutral,
      [ACCENT]: neutral[400],
      [BACKGROUND]: neutral[100],
      [BACKGROUND_DISABLED]: neutral[200],
      [BACKGROUND_MEDIUM]: neutral[400],
      [BACKGROUND_WEAK]: neutral[50],
      [BACKGROUND_STRONG]: neutral[900],
      [BACKGROUND_TOOLTIP]: neutral[700],
      [BORDER]: neutral[300],
      [BORDER_FOCUS]: blue[300],
      [TEXT]: neutral[600],
      [TEXT_BUTTON]: neutral[600],
      [TEXT_PLACEHOLDER]: neutral[400],
      [TEXT_STRONG]: neutral[900],
      [TEXT_WEAK]: neutral[500],
    },
    accent: {
      [BACKGROUND_WEAK]: orange[50],
      [BORDER]: orange[300],
      [ACCENT]: orange[400],
      [TEXT]: orange[600],
      [TEXT_STRONG]: orange[900],
    },
    beta: {
      [BACKGROUND_WEAK]: pink[50],
      [BORDER]: pink[300],
      [ACCENT]: pink[400],
      [BACKGROUND_MEDIUM]: pink[400],
      [TEXT]: pink[600],
      [TEXT_STRONG]: pink[900],
    },
    danger: {
      [BACKGROUND_WEAK]: red[50],
      [BORDER_FOCUS]: red[300],
      [BORDER_PRESSED]: red[300], // to be deprecated
      [ACCENT]: red[400],
      [TEXT_BUTTON]: red[600],
      [BACKGROUND_MEDIUM]: red[400],
      [TEXT]: red[600],
      [TEXT_STRONG]: red[900],
    },
    info: {
      [BACKGROUND_WEAK]: teal[50],
      [BACKGROUND]: teal[100],
      [BORDER]: teal[300],
      [ACCENT]: teal[400],
      [TEXT]: teal[600],
      [TEXT_STRONG]: teal[900],
    },
    primary: {
      [BACKGROUND_WEAK]: blue[50],
      [BORDER]: blue[300],
      [BORDER_PRESSED]: neutral[400], // to be deprecated
      [ACCENT]: blue[400],
      [BACKGROUND_MEDIUM]: blue[400],
      [TEXT_BUTTON]: blue[800],
      [TEXT]: blue[600],
      [TEXT_STRONG]: blue[900],
      [TEXT_LINK_HOVER]: denim[300],
    },
    secondary: {
      [BACKGROUND_WEAK]: denim[50],
      [BACKGROUND]: denim[100],
      [BACKGROUND_MEDIUM]: denim[300],
      [BORDER]: denim[300],
      [ACCENT]: denim[400],
      [TEXT]: denim[600],
      [TEXT_STRONG]: denim[900],
    },
    success: {
      [BACKGROUND_WEAK]: green[50],
      [BACKGROUND]: green[100],
      [BORDER]: green[300],
      [BORDER_FOCUS]: green[300],
      [BACKGROUND_MEDIUM]: green[400],
      [ACCENT]: green[400],
      [TEXT]: green[600],
      [TEXT_STRONG]: green[900],
    },
    upsell: {
      [BACKGROUND_WEAK]: violet[50],
      [BACKGROUND]: violet[200],
      [BORDER]: violet[300],
      [BACKGROUND_MEDIUM]: violet[400],
      [ACCENT]: violet[400],
      [TEXT]: violet[600],
      [TEXT_STRONG]: violet[900],
    },
    warning: {
      [BACKGROUND_WEAK]: yellow[50],
      [BACKGROUND]: yellow[100],
      [BORDER]: yellow[300],
      [BORDER_FOCUS]: yellow[300],
      [ACCENT]: yellow[500],
      [TEXT]: yellow[700],
      [TEXT_STRONG]: yellow[900],
    },
  }

  return {
    ...baseConfig,
    colors: {
      ...baseColors,
      ...tokenObjects,
      'brand-blue': denim[400],
    },
  }
}

<template>
  <div class="flex flex-col gap-16">
    <div class="item">
      <span class="item-header">Plan Status</span>
      <div data-testid="renewal-info" class="flex items-center gap-8">
        <span class="text-neutral-text-weak"
          >{{ isSubscriptionCancelled ? 'Ends' : 'Renews' }} on
          {{ subscriptionRenewalDate }}</span
        >
        <BittsTag
          :color="color"
          class="account-status mr-[-6px]"
          size="small"
          variant="rounded"
        >
          • <span data-testid="account-status">{{ accountStatus }}</span>
        </BittsTag>
      </div>
    </div>
    <div v-if="isSubscriptionCancelled" class="item">
      <span class="item-header">Last Invoice Paid</span>
      <div data-testid="cancellation-info" class="text-neutral-text-weak">
        {{ lastPaidDate }} •
        <span :class="`text-success-text`" class="font-bold">{{
          lastPaidAmountReadable
        }}</span>
      </div>
    </div>
    <div v-else class="item">
      <span class="item-header">Next Invoice</span>
      <div data-testid="subscription-info" class="text-neutral-text-weak">
        {{ subscriptionNextBillingDate }} •
        <span :class="`text-${color}-text`" class="font-bold">{{ cost }}</span>
      </div>
    </div>
    <div v-if="hasSubscription && !isSubscriptionCancelled" class="item">
      <span class="item-header">Payment Method</span>
      <div
        data-testid="card-info"
        class="flex items-center gap-4 text-neutral-text-weak"
      >
        <FontAwesomeIcon
          :icon="['fad', 'credit-card']"
          :style="{ height: '18px', width: '18px' }"
        />
        {{ capitalize(creditCardType) }} (•••• {{ creditCardLastFourDigits }})
      </div>
    </div>
    <div
      v-if="hasSubscription && !isSubscriptionCancelled"
      class="flex items-center gap-16 mt-auto"
    >
      <BittsButton
        text="Edit payment method"
        type="neutral"
        variant="outline"
        data-testid="edit-payment"
        class="self-start mt-auto"
        size="small"
        @click="handleEditPayment"
      />
      <BittsButton
        text="View invoices"
        type="neutral"
        variant="outline"
        data-testid="edit-payment"
        class="self-start mt-auto"
        size="small"
        @click="handleViewInvoices"
      />
    </div>
    <BittsButton
      v-else-if="onLatestBilling && isSubscriptionCancelled"
      class="mt-auto mr-auto"
      size="small"
      data-testid="connector-restore-button"
      text="Restore Plan"
      @click="handleRestorePlan"
    />
  </div>
</template>

<script setup>
import { BittsButton, BittsTag } from '@crossbeam/bitts';

import { capitalize } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import useHasFeature from '@/composables/useHasFeature';
import useIteratively from '@/composables/useIteratively';
import { BILLING_CTAS, CTA_2 } from '@/constants/analytics';
import { ACCOUNT_STATUS_MAP } from '@/constants/billing';
import { useBillingStore } from '@/stores';
import { centsToDollars } from '@/utils';

const billingStore = useBillingStore();
const {
  hasSubscription,
  subscriptionRenewalDate,
  subscriptionNextBillingDate,
  creditCardLastFourDigits,
  creditCardType,
  grandTotal,
  billingPortalURL,
  isSubscriptionCancelled,
  lastPaidDate,
  lastPaidAmount,
  subscriptionStatus,
} = storeToRefs(billingStore);

const { iteratively } = useIteratively();
const { onLatestBilling } = useHasFeature();
const accountStatus = computed(
  () => ACCOUNT_STATUS_MAP[subscriptionStatus.value]?.text,
);
const color = computed(
  () => ACCOUNT_STATUS_MAP[subscriptionStatus.value]?.color,
);

const lastPaidAmountReadable = computed(() =>
  centsToDollars(lastPaidAmount.value),
);
const cost = computed(() => centsToDollars(grandTotal.value));

const router = useRouter();
async function handleEditPayment() {
  await router.push({ name: 'edit-payment' });
}

function handleViewInvoices() {
  window.open(billingPortalURL.value, '_blank');
}

async function handleRestorePlan() {
  iteratively.userClickedRestorePlan({
    cta: BILLING_CTAS.BILLING,
    cta_2: CTA_2.PLAN_SUMMARY,
  });
  await router.push({ name: 'restore-plan' });
}
</script>
<style lang="pcss">
.item {
  @apply text-base sm:text-m flex justify-between;
}

.item-header {
  @apply text-neutral-text-strong font-bold;
}
</style>

import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { EVENT_SITES } from '@/constants/analytics';
import { OLAPS_ONLY } from '@/constants/data_shares';
import { captureException } from '@/errors';
import {
  useDataSharesStore,
  useFlashesStore,
  usePartnersStore,
  usePopulationsStore,
  useUserFavoritesStore,
} from '@/stores';
import urls from '@/urls';
import { sortByKey } from '@/utils';

import useIteratively from './useIteratively';

export default function usePartnerManagement() {
  const route = useRoute();

  const userFavoritesStore = useUserFavoritesStore();
  const populationsStore = usePopulationsStore();
  const partnersStore = usePartnersStore();
  const flashesStore = useFlashesStore();
  const dataSharesStore = useDataSharesStore();

  const { populations } = storeToRefs(populationsStore);

  const { iteratively } = useIteratively();

  const fromPartner = computed(() => route.query.partner_suggestion === 'true');
  const fromUniversal = computed(
    () => route.query.universal_suggested === 'true',
  );

  const getDiscoverableTeam = async ({ publicInviteCode }) => {
    try {
      const url = urls.prospectivePartnerTeam.byInviteCode(publicInviteCode);
      const response = await axios.get(url);
      const prospectivePartnerTeam = response.data.items
        .map((partner) => {
          const name = `${partner.first_name} ${partner.last_name}`;
          return {
            ...partner,
            name,
            label: name,
            value: partner.id,
          };
        })
        .sort(sortByKey('name'));
      return prospectivePartnerTeam;
    } catch (error) {
      const msg = error.message;
      flashesStore.addErrorFlash(msg);
    }
  };

  function makePayload({ company, contact, inviteMessage }) {
    const payload = {};

    /* TODO: This is legacy from when we used to support customizing the sharing
     * settings for populations on a partnership request */
    if (populations.value.length === 0) payload.sharing_level = OLAPS_ONLY;
    else {
      const popShares = populations.value
        .map((population) => ({
          population_id: population.id,
          sharing_level: 'use_defaults',
        }))
        .sort((a, b) => a.id > b.id);
      payload.population_shares =
        dataSharesStore.populationSharesToServer(popShares);
    }

    payload.from_partner_suggestion = fromPartner.value || fromUniversal.value;
    payload.invite_message = inviteMessage;

    /* Discoverable organizations + users */
    if (company.public_invite_code && contact.first_name && contact.last_name) {
      return {
        ...payload,
        public_invite_code: company.public_invite_code,
        contact_first_name: contact.first_name,
        contact_last_name: contact.last_name,
        contact_name: `${contact.first_name} ${contact.last_name}`,
      };
    }

    /* Discoverable organization only */
    if (company.public_invite_code) {
      return {
        ...payload,
        public_invite_code: company.public_invite_code,
      };
    }

    /* Neither organization nor users are discoverable */
    return {
      ...payload,
      contact_company: company.name,
      contact_email: contact.email,
      clearbit_domain: company.domain,
      contact_name: null,
    };
  }

  function iterativelyHandler({ inviteMessage }) {
    let inviteSource = 'partner invite form';
    if (fromUniversal.value) inviteSource = 'universal suggested';
    if (fromPartner.value) inviteSource = 'partner suggestion';

    const iterativelyPayload = {
      invite_type: inviteSource,
      invite_message: inviteMessage || '',
      event_site: fromUniversal.value
        ? EVENT_SITES.UNIVERSAL_SEARCH_RESULT
        : EVENT_SITES.PARTNER_PROPOSAL_MODAL,
    };
    iteratively.userSentPartnerInvite(iterativelyPayload);
  }

  const sendInvitation = async ({ company, contact, inviteMessage }) => {
    try {
      const url = urls.proposals.create_v0_3;
      const payload = makePayload({ company, contact, inviteMessage });
      await axios.post(url, payload);
      iterativelyHandler({ inviteMessage });
      flashesStore.addSuccessFlash({ message: 'Invitation sent' });
      await partnersStore.refreshPartnersStore();
    } catch (err) {
      flashesStore.addUnhandledError(err);
    }
  };

  async function createOfflinePartner({ company, website }) {
    try {
      const { data } = await axios.post(urls.offlinePartners.create, {
        organization: {
          name: company.name,
          is_discoverable: false,
          users_discoverable: false,
          url: website,
          clearbit_domain: company.domain,
        },
      });
      flashesStore.addSuccessFlash({
        message: 'Offline partner successfully created',
      });
      await partnersStore.refreshPartnersStore();
      return data;
    } catch (err) {
      captureException(err);
      flashesStore.addUnhandledError(err);
    }
  }

  async function deletePartnership({ partner }) {
    try {
      const deletePartnershipUrl = urls.partners.deletePartnership(
        partner.uuid,
      );
      const url = partner.offline_partner
        ? `${deletePartnershipUrl}?offline_partner_org_uuid=${partner.uuid}`
        : deletePartnershipUrl;
      await axios.delete(url);
      flashesStore.addSuccessFlash({
        message: `${partner.name} is no longer a partner`,
        description: 'It will take a few minutes to fully delete their data',
      });
      await Promise.all([
        partnersStore.refreshPartnersStore(),
        userFavoritesStore.refreshUserFavoritesStore(),
      ]);
    } catch (err) {
      captureException(err);
      flashesStore.addErrorFlash(
        'We were not able to delete your partnership, please try again',
      );
    }
  }

  return {
    deletePartnership,
    getDiscoverableTeam,
    sendInvitation,
    createOfflinePartner,
  };
}

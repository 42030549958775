<template>
  <div v-if="showFooter" class="c-card-footer hover:opacity-70 cursor-pointer">
    <span class="c-card-footer__text">
      <span class="text-brand-blue flex items-center" @click="toggle">
        {{ toggleText[mode] }}
        <FontAwesomeIcon
          :icon="['fas', 'chevron-left']"
          :style="{ height: '10px', width: '10px', color: 'currentColor' }"
          class="c-card-footer__toggle"
          :class="{ 'c-card-footer__toggle--expanded': mode === 'expanded' }"
        />
      </span>
    </span>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';

import { useDataSharesStore, usePartnersStore } from '@/stores';

export default {
  name: 'CardFooter',
  props: {
    item: {
      type: Object,
      required: true,
    },
    toggle: {
      type: Function,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(usePartnersStore, ['getPartnerOrgById']),
    showFooter() {
      return this.item.is_ours && this.item.data_share_ids.length > 0;
    },
    footerText() {
      return {
        expanded: 'Showing all attributes.',
        collapsed: `Hiding attributes from ${this.partnersText}.`,
      };
    },
    toggleText() {
      return {
        expanded: 'Hide expanded information',
        collapsed: 'Show expanded information',
      };
    },
    partnersText() {
      const text = this.item.data_share_ids.map((dataShareId) => {
        return this.getPartnerOrgById(
          this.getDataShareById(dataShareId).partner_org_id,
        ).name;
      });
      if (text.length > 2) {
        text[text.length - 1] = 'and '.concat(text[text.length - 1]);
        return text.join(', ');
      }
      return text.join(' and ');
    },
  },
  methods: {
    ...mapActions(useDataSharesStore, ['getDataShareById']),
  },
};
</script>
<style lang="pcss" scoped>
.c-card-footer {
  font-size: 12px;
  font-style: italic;
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.c-card-footer__toggle {
  transform: rotate(270deg);
  @apply ml-8 cursor-pointer text-brand-blue hover:opacity-70;
}

.c-card-footer__toggle--expanded {
  transform: rotate(90deg);
}
</style>

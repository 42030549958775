<template>
  <BittsModal
    :visible="showDeleteRoleModal"
    name="delete-role"
    :title="`Delete Role`"
    :loading="!ready"
    save-text="Migrate and delete"
    confirm-type="danger"
    :disabled="!chosenRoleId"
    @closed="modalClosed"
    @saved="deleteRole"
  >
    <template #content>
      <div v-if="roleUserCount === 0" class="text-center text-sm mb-18">
        The {{ candidateName }} role has no users assigned to it. Select which
        role you want to migrate any pending user invitations to when the
        {{ candidateName }} role is deleted.
      </div>
      <div v-else class="text-center text-sm mb-18">
        The {{ candidateName }} role has <b>{{ roleUserText }}</b> assigned to
        it. Select which role you want to migrate them to when the
        {{ candidateName }}
        role is deleted.
      </div>
      <BittsRadioGroupCards
        :initial-value="chosenRoleId"
        :options="eligibleRoles"
        @change="selectRole"
      >
        <template #label="{ option }">
          <div class="text-neutral-text-strong font-bold mb-2">
            {{ option.name }}
          </div>
        </template>
      </BittsRadioGroupCards>
      <BittsAlert
        v-if="chosenRoleId && roleUserCount !== 0"
        class="my-12"
        color="warning"
        :message="`This action will migrate ${roleUserText} to the ${chosenRoleName}
  role and delete the ${candidateName} role. This action cannot be undone.`"
      />
    </template>
  </BittsModal>
</template>
<script>
import { BittsAlert, BittsModal, BittsRadioGroupCards } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { pull } from 'lodash';
import { mapActions as mapPiniaActions, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import {
  allReady,
  useFlashesStore,
  useRolesStore,
  useTeamStore,
} from '@/stores';
import urls from '@/urls';

export default {
  name: 'DeleteRoleModal',
  components: {
    BittsAlert,
    BittsModal,
    BittsRadioGroupCards,
  },
  setup() {
    const route = useRoute();
    const roleId = route.params.role_id;
    const rolesStore = useRolesStore();
    const teamStore = useTeamStore();
    const ready = allReady(teamStore, rolesStore);

    const { allRoles } = storeToRefs(rolesStore);

    const chosenRoleId = ref(null);
    const deleteCandidateRole = computed(() => rolesStore.getRoleById(roleId));
    const candidateName = computed(() => deleteCandidateRole.value?.name);
    const eligibleRoles = computed(() =>
      pull(allRoles.value, deleteCandidateRole.value).map((role) => {
        return { ...role, value: role.id };
      }),
    );
    const chosenRoleName = computed(
      () => rolesStore.getRoleById(chosenRoleId.value)?.name,
    );

    const roleUserCount = computed(
      () => teamStore.getAuthsByRoleId(roleId).length,
    );
    const roleUserText = computed(() => {
      const count = teamStore.getAuthsByRoleId(roleId).length;
      return `${count} ${count === 1 ? 'user' : 'users'}`;
    });

    useHead({
      title: candidateName,
      titleTemplate: 'Delete Role - %s',
    });

    return {
      ready,
      chosenRoleId,
      chosenRoleName,
      deleteCandidateRole,
      candidateName,
      roleId,
      eligibleRoles,
      roleUserCount,
      roleUserText,
    };
  },
  data() {
    return {
      loading: true,
      showDeleteRoleModal: true,
    };
  },
  async created() {
    this.chosenRoleId = null;
  },
  methods: {
    ...mapPiniaActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapPiniaActions(useTeamStore, ['refreshTeamStore']),
    ...mapPiniaActions(useRolesStore, ['refreshRolesStore']),
    modalClosed() {
      this.showDeleteRoleModal = false;
      this.$router.push({ name: 'roles' });
    },
    selectRole(roleId) {
      this.chosenRoleId = roleId;
    },
    async deleteRole() {
      this.loading = true;
      const url = urls.roles.delete(this.deleteCandidateRole.id);
      const payload = { data: { replacement_role_id: this.chosenRoleId } };

      try {
        await axios.delete(url, payload);
        this.addSuccessFlash({ message: `Role ${this.candidateName} Removed` });
      } catch (_err) {
        this.addErrorFlash({ message: 'That role could not be deleted' });
        await this.$router.push({ name: 'roles' });
        this.showDeleteRoleModal = false;
        return;
      } finally {
        await Promise.all([this.refreshRolesStore(), this.refreshTeamStore()]);
        this.showDeleteRoleModal = false;
        this.loading = false;
        await this.$router.push({ name: 'roles' });
      }
    },
  },
};
</script>

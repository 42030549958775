<template>
  <div class="timeline-event" :data-testid="`timeline-event-${activity.type}`">
    <BittsAvatar
      v-if="orgInActivity"
      :org="orgInActivity"
      :show-initials="true"
      size="small"
      class="mr-16"
    />
    <div class="w-full">
      <AttributionTimelineDynamicContent
        :activity="activity"
        :account-name="accountName"
      />
      <slot name="footer" />
      <div class="timeline-event__info">
        <div class="w-14" :class="{ 'h-14': !isXbeamSvg }">
          <component
            :is="dynamicIcon"
            v-bind="dynamicIconProps"
            data-testid="activity-icon"
            :class="{ 'text-neutral-400': !isXbeamSvg }"
          />
        </div>
        <span class="timeline-event__info-dot">•</span>
        <component
          :is="hasDeepLink ? 'button' : 'span'"
          class="text-neutral-500 text-sm"
          data-testid="activity-link"
          @[addClickHandler]="onClickDeepLink"
        >
          {{ friendlySourceText }}
        </component>
      </div>
    </div>
  </div>
</template>

<script setup>
import { BittsAvatar, BittsSvg } from '@crossbeam/bitts';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

import useAuth from '@/composables/useAuth';
import useIteratively from '@/composables/useIteratively';
import useTimelineContent from '@/composables/useTimelineContent';
import { EVENT_SITES } from '@/constants/analytics';
import {
  ATTRIBUTION_EVENTS_PARTNER_AVATAR_LOOKUP,
  ATTRIBUTION_EVENT_TYPES,
} from '@/constants/attribution';
import { usePartnersStore } from '@/stores';

import AttributionTimelineDynamicContent from './AttributionTimelineDynamicContent.vue';

const { currentOrg } = useAuth();
const { iteratively } = useIteratively();

const { ACTIVITY_SOURCE_ICON_BY_ACTIVITY_SOURCE } = useTimelineContent();

const partnerStore = usePartnersStore();

const props = defineProps({
  activity: {
    type: Object,
    default: null,
  },
  accountName: {
    type: String,
    default: '',
  },
});

const icon = computed(
  () =>
    ACTIVITY_SOURCE_ICON_BY_ACTIVITY_SOURCE[props.activity.type] ||
    'logoNoText',
);
const isXbeamSvg = computed(() => !Array.isArray(icon.value));

const dynamicIcon = computed(() =>
  isXbeamSvg.value ? BittsSvg : FontAwesomeIcon,
);
const dynamicIconProps = computed(() =>
  isXbeamSvg.value ? { svg: icon.value } : { icon: icon.value },
);

const orgInActivity = computed(() =>
  ATTRIBUTION_EVENTS_PARTNER_AVATAR_LOOKUP[props.activity.type]
    ? partnerStore.getPartnerOrgByUuid(
        props.activity.partner_organization_uuid,
      ) || { name: null }
    : currentOrg.value,
);

const friendlySourceText = computed(() => {
  const source =
    {
      [ATTRIBUTION_EVENT_TYPES.PARTNER_MENTION]: 'Gong',
      [ATTRIBUTION_EVENT_TYPES.REQUEST_SENT]: 'Crossbeam for Sales',
      [ATTRIBUTION_EVENT_TYPES.REQUEST_RECEIVED]: 'Crossbeam for Sales',
      [ATTRIBUTION_EVENT_TYPES.OVERLAP_ENTRY]: 'Partner CRM',
      [ATTRIBUTION_EVENT_TYPES.OVERLAP_EXIT]: 'Partner CRM',
      [ATTRIBUTION_EVENT_TYPES.PARTNERSTACK_LEAD_CREATED]: 'PartnerStack',
    }[props.activity.type] || 'Crossbeam';
  return `${source} Activity`;
});

const hasDeepLink = computed(() => !!props.activity.data?.deep_link_url);
const addClickHandler = computed(() => (hasDeepLink.value ? 'click' : null));

function onClickDeepLink() {
  if (!hasDeepLink.value) return;
  iteratively.userInteractedWithTimeline({
    cta: friendlySourceText.value,
    cta_2: orgInActivity.value.name,
    event_site: EVENT_SITES.ATTRIBUTION_TIMELINE_EVENT,
  });
  window.open(props.activity.data.deep_link_url, '_blank');
}
</script>

<style scoped lang="pcss">
.timeline-event {
  @apply flex py-16 border-l border-neutral-border px-16;
}

.timeline-event__info {
  @apply flex items-center leading-4 gap-8;
}

.timeline-event__info-dot {
  @apply text-neutral-text-weak;
}
</style>

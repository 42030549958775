<template>
  <div class="c-chargify-form">
    <div
      v-if="loading && canSubmitToChargify"
      class="flex justify-center items-center h-[240px]"
    >
      <img class="w-64" src="/images/gifs/loading.gif" />
    </div>
    <div v-show="!loading" class="h-full flex flex-col justify-between">
      <div v-show="!onlyFooter">
        <div :class="`px-${xMargin}`">
          <slot name="payer-info" />
        </div>
        <form
          :id="CHARGIFY_TARGET_NODE"
          ref="chargifyForm"
          class="flex flex-col"
        >
          <!-- Each of these divs will be populated by Chargify's APIs. They are all separate iFrames -->
          <div :class="`px-${xMargin}`">
            <div>
              <slot name="info-header" />
              <div class="flex gap-16 w-full">
                <div id="chargify-firstname" class="flex-1" />
                <div id="chargify-lastname" class="flex-1" />
              </div>
              <div id="chargify-number" />
              <div
                class="chargify-fields"
                :class="[isSmall ? 'gap-12' : 'gap-16']"
              >
                <div id="chargify-month" />
                <div id="chargify-year" />
                <div id="chargify-cvv" />
                <div id="chargify-zip" :class="{ 'col-span-full': isSmall }" />
              </div>
            </div>
            <slot name="cost-breakdown" />
          </div>
          <Disclaimer :class="`px-${xMargin}`" />
        </form>
      </div>
      <div
        :class="{
          'z-40 fixed w-full flex flex-col-reverse items-center gap-16 pr-36 pb-24':
            isSmall,
          [`px-${xMargin}`]: true,
          'flex items-center justify-between border-t-none gap-24 pb-0':
            editing,
        }"
        class="form-buttons"
      >
        <BittsButton
          type="neutral"
          size="large"
          variant="outline"
          data-testid="chargify-cancel-button"
          text="Cancel"
          :class="{
            'w-full': isSmall,
            'flex-1': editing,
          }"
          @click="$emit('cancel')"
        />
        <BittsButton
          :text="submitButtonText"
          size="large"
          data-testid="chargify-form-submit-button"
          :disabled="disabled || fetchingToken"
          :loading="fetchingToken"
          :class="{
            'w-full': isSmall,
            'flex-1': editing,
          }"
          @click.prevent="handleSubmitForm"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { BittsButton } from '@crossbeam/bitts';
import { useScreenSize } from '@crossbeam/pointbreak';

import buildTailwindTheme from '@/../../../tailwind-theme-builder';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import Disclaimer from '@/components/billing/Disclaimer.vue';

import appConfig from '@/config';
import { captureException } from '@/errors';
import { useFlashesStore } from '@/stores';
import { waitForElement } from '@/utils';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  submitButtonText: {
    type: String,
    default: 'Pay now',
  },
  fieldColumns: {
    type: Number,
    default: 2,
  },
  canSubmitToChargify: {
    type: Boolean,
    default: false,
  },
  fetchingToken: {
    type: Boolean,
    default: false,
  },
  editing: {
    type: Boolean,
    default: false,
  },
});
const { isSmall } = useScreenSize();

const router = useRouter();
const emit = defineEmits([
  'fetching-token',
  'token',
  'token-failure',
  'cancel',
  'next-screen',
]);

const chargifyClient = new Chargify();
const chargifyForm = ref('chargifyForm');

const onlyFooter = computed(() => !props.canSubmitToChargify);
const xMargin = computed(() => {
  if (isSmall.value && !props.editing) return 16;
  if (!isSmall.value && !props.editing) return 64;
  return 0;
});

const CHARGIFY_TARGET_NODE = 'chargify-form';
const tailwindTheme = buildTailwindTheme();

/* Styles for ChargifyJS */
const style = {
  field: {},
  input: {
    fontFamily: 'Helvetica Neue',
    'border-width': '1px',
    'border-style': 'solid',
    'border-color': tailwindTheme.colors.neutral.border,
    placeholder: { color: tailwindTheme.colors.neutral.accent },
    borderRadius: '8px',
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingLeft: '16px',
    marginTop: '-2px',
    boxShadow: tailwindTheme.extend.boxShadow.component,
  },
  message: {
    color: tailwindTheme.colors.red[700],
  },
  label: {
    fontSize: '12px',
    color: tailwindTheme.colors.neutral['text-strong'],
  },
};

const message = 'This field is not valid';

const CHARGIFY_CONFIG = {
  selector: CHARGIFY_TARGET_NODE,
  publicKey: appConfig.chargify.publicKey,
  type: 'card',
  serverHost: appConfig.chargify.serverHost,
  addressDropdowns: true,
  hideCardImage: true /* Hides card SVG in card input */,
  requiredLabel: '',
  optionalLabel: '(Optional)',
  fields: {
    firstName: {
      selector: '#chargify-firstname',
      label: 'First Name',
      placeholder: 'First Name',
      required: true,
      message,
      style,
    },
    lastName: {
      selector: '#chargify-lastname',
      label: 'Last Name',
      placeholder: 'Last Name',
      required: true,
      message,
      style,
    },
    number: {
      selector: '#chargify-number',
      label: 'Credit card number',
      placeholder: '•••• •••• •••• ••••',
      message,
      style,
    },
    cvv: {
      selector: '#chargify-cvv',
      label: 'CVV',
      placeholder: 'CVV',
      message,
      required: true,
      style: {
        ...style,
        input: {
          ...style.input,
          paddingLeft: '14px',
          paddingRight: '14px',
        },
      },
    },
    month: {
      selector: '#chargify-month',
      label: 'Expiry Month',
      placeholder: 'MM',
      message,
      style,
    },
    year: {
      selector: '#chargify-year',
      label: 'Expiry Year',
      placeholder: 'YYYY',
      message,
      style,
    },
    zip: {
      selector: '#chargify-zip',
      label: 'Zip Code',
      placeholder: 'Zip Code',
      required: true,
      message,
      style,
    },
  },
};

const flashesStore = useFlashesStore();
onMounted(async () => {
  try {
    await waitForElement(`#${CHARGIFY_TARGET_NODE}`);
    chargifyClient.load(CHARGIFY_CONFIG);
    await waitForIframes();
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash('Could not connect to payment portal');
    await router.push({ name: 'billing' });
  }
});

onUnmounted(() => {
  try {
    chargifyClient.unload({
      selector: CHARGIFY_TARGET_NODE,
      publicKey: appConfig.chargify.publicKey,
      type: 'card',
      serverHost: appConfig.chargify.serverHost,
    });
  } catch (err) {
    captureException(err);
  }
});

async function handleSubmitForm() {
  if (!props.canSubmitToChargify) {
    emit('next-screen');
    return;
  }

  try {
    emit('fetching-token');
    const token = await handleGetToken();
    emit('token', token);
  } catch (err) {
    loading.value = false;
    emit('token-failure', err);
  }
}
function handleGetToken() {
  const form = chargifyForm.value;
  return new Promise((resolve, reject) => {
    chargifyClient.token(
      form,
      (token) => {
        resolve(token);
      },
      (error) => {
        reject(error);
      },
    );
  });
}

/* We wait for Chargify's iFrames to finish loading before we turn off the loading spinner. */
const loading = ref(true);
async function waitForIframes() {
  let loaded = 0;
  let needsLoad = 0;
  function handleLoad() {
    loaded += 1;
    if (needsLoad === loaded) {
      loading.value = false;
    }
  }
  const selectors = Object.values(CHARGIFY_CONFIG.fields)
    .map((f) => `${f.selector} iframe`)
    .slice(0, -1);
  const promises = selectors.map((s) => waitForElement(s));
  await Promise.all(promises);
  const iFrames = selectors.map((s) => document.querySelector(s));
  needsLoad = selectors.length;
  for (const iFrame of iFrames) {
    iFrame.addEventListener('load', handleLoad, true);
  }
}
</script>
<style lang="pcss">
.c-chargify-form {
  #chargify-cvv > iframe:nth-child(1) {
    @apply outline-none !important;
  }

  #chargify-form > iframe {
    @apply w-full outline-none !important;
  }

  #chargify-form {
    display: flex;
  }

  #chargify-form iframe {
    width: 100% !important;
  }

  .form-buttons {
    @apply flex py-24 border-t border-neutral-border px-80 sticky bottom-0 bg-white;
  }

  .chargify-fields {
    @apply grid;
    grid-template-columns: repeat(v-bind(fieldColumns), 1fr);
  }
}
</style>

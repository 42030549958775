<template>
  <component
    :is="avatarComponent"
    :size="size"
    :org="partner"
    icon-slot-bg-color="bg-gradient-to-b from-primary-background-weak to-white"
    :is-icon="isOffline"
    :is-own="false"
    :is-user-main-avatar="false"
    :show-initials="true"
  >
    <template #icon>
      <FontAwesomeIcon
        v-if="isOffline"
        :icon="['fas', 'ghost']"
        class="text-primary-accent h-10 w-10"
      />
    </template>
  </component>
</template>

<script setup>
import { BittsAvatar, BittsAvatarPip } from '@crossbeam/bitts';

import { computed } from 'vue';

import { usePartnersStore } from '@/stores';

const props = defineProps({
  partnerUuid: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: 'medium',
  },
});

const partner = computed(() =>
  partnersStore.getPartnerOrgByUuid(props.partnerUuid),
);
const avatarComponent = computed(() =>
  isOffline.value && props.size !== 'x-small' ? BittsAvatarPip : BittsAvatar,
);
const partnersStore = usePartnersStore();
const isOffline = computed(() => partner.value?.offline_partner);
</script>

<template>
  <div :class="cellClasses" class="amm-cell">
    <!-- Overlaps Only Cell -->
    <BittsTooltip
      v-if="cell.isPartnerPopOverlapsOnly"
      :class="innerCellClasses"
      class="relative"
      :include-cta="hasManageSharing"
      :button-text="ctaButtonText"
      default-slot-classes="h-full"
      @cta-clicked="onCtaClicked"
    >
      <div class="h-full flex flex-col items-center justify-center">
        <div class="overlaps-number">
          {{ cellText }}
        </div>
        <div class="overlaps-text">
          {{ cellSubtext }}
        </div>
      </div>
      <template #title>
        {{ ctaTextTitle }}
      </template>
    </BittsTooltip>
    <!-- Report Cell -->
    <div
      v-else-if="cell.hasReport && !cellDisabledForPotRev"
      :class="innerCellClasses"
      class="has-report"
    >
      <button
        data-testid="amm-cell__clickable"
        class="flex flex-col items-center justify-center w-full h-full"
        @click="onSelectCell"
      >
        <div class="overlaps-number">
          {{ cellText }}
        </div>
        <div class="overlaps-text">
          {{ cellSubtext }}
        </div>
      </button>
    </div>
    <!-- No Report Cell -->
    <div v-else :class="innerCellClasses" class="no-report">
      <div
        class="flex flex-col justify-center h-full w-full text-center no-report-popover"
      >
        <div
          :class="{
            'mt-24': isCutOffBottom,
            'flex-row-reverse': isPotentialRevenueView,
          }"
          class="flex justify-center items-center w-full h-full gap-4"
        >
          <p class="text-neutral-accent opacity-100">
            {{ isPotentialRevenueView ? 'Not calculated' : 'None' }}
          </p>
          <BittsTooltip v-bind="emptyTooltipProps">
            <FontAwesomeIcon
              :icon="
                isPotentialRevenueView
                  ? ['far', 'info-circle']
                  : ['far', 'question-circle']
              "
              :style="{ height: '12px', width: '12px' }"
              aria-hidden="true"
              class="text-neutral-accent"
            />
            <template #title>
              {{ tooltipText }}
            </template>
          </BittsTooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BittsTooltip } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { defineComponent, inject } from 'vue';

import useAuth from '@/composables/useAuth';
import usePartnerOverlapData from '@/composables/usePartnerOverlapData';
import useReports from '@/composables/useReports';
import {
  OVERLAP_DATA_STATE_KEY,
  POTENTIAL_REVENUE,
  TOTAL_OVERLAPS,
} from '@/constants/amm_grid';
import { EVENT_SITES } from '@/constants/analytics';
import { LD_TRACKING_KEYS } from '@/constants/feature_flags';
import {
  useBillingStore,
  useDataRequestsStore,
  useFeatureFlagStore,
} from '@/stores';
import { formatNumberWithCommas } from '@/utils';

const ONLY_POP_INFO = 2;

export default defineComponent({
  name: 'AMMCell',
  components: { BittsTooltip },
  inject: ['clickToSelectGridCell'],
  props: {
    cell: {
      type: Object,
      default() {
        return {
          name: '',
          population: {},
          partnerPop: {},
        };
      },
    },
    currentAmmState: {
      type: String,
      default: TOTAL_OVERLAPS,
      validator(value) {
        return [TOTAL_OVERLAPS, POTENTIAL_REVENUE].indexOf(value) !== -1;
      },
    },
    numColumns: {
      type: Number,
      default: 3,
    },
    numRows: {
      type: Number,
      default: 3,
    },
    popSources: {
      type: Array,
      default: () => [],
    },
    columnIndex: {
      type: Number,
      default: null,
    },
    rowIndex: {
      type: Number,
      default: null,
    },
    columnIncrementDisabled: {
      type: Boolean,
      default: false,
    },
    rowIncrementDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open-request-data-modal', 'change-tab'],
  setup() {
    const { selectedAMMGridCell, hasReportChanged } = inject(
      OVERLAP_DATA_STATE_KEY,
    );
    const { trackReportClicked } = useReports();
    const { hasUsableOppsData, reportURL } = usePartnerOverlapData();
    const { isFreeTier } = storeToRefs(useBillingStore());
    const { trackViaLaunchDarkly } = useFeatureFlagStore();
    const { hasPermission } = useAuth();

    const { outboundShareRequests } = storeToRefs(useDataRequestsStore());
    return {
      hasReportChanged,
      hasUsableOppsData,
      reportURL,
      selectedAMMGridCell,
      trackReportClicked,
      outboundShareRequests,
      isFreeTier,
      trackViaLaunchDarkly,
      hasPermission,
    };
  },
  data() {
    return {
      currView: this.currentAmmState,
      isRequestingSharedData: false,
    };
  },
  computed: {
    areColumnsSmallerThanFullWidth() {
      return this.numColumns < 3;
    },
    cellClasses() {
      return {
        'amm-cell__bottom-cutoff': this.isCutOffBottom,
        'amm-cell__right-cutoff': this.isCutOffRight,
        'border-r border-neutral-border border-solid':
          this.areColumnsSmallerThanFullWidth && this.isInLastColumn,
        'border-b-0': this.isInLastRow,
      };
    },
    cellDisabledForPotRev() {
      return (
        this.currentAmmState === POTENTIAL_REVENUE &&
        !this.hasUsableOppsData(this.popSources)
      );
    },
    cellInfoByView() {
      return {
        [TOTAL_OVERLAPS]: {
          class: 'amm-cell-inner-overlaps',
          value: this.numOverlaps,
          subtext: this.cell.isPartnerPopOverlapsOnly
            ? 'Overlap Counts'
            : 'Overlaps',
        },
        [POTENTIAL_REVENUE]: {
          class: 'amm-cell-inner-potential-revenue',
          value: this.cell.isPartnerPopOverlapsOnly
            ? '--'
            : this.potentialRevenue,
          subtext: `${
            this.cell.isPartnerPopOverlapsOnly ? '--' : this.openOpps
          } Opportunities`,
        },
      };
    },
    cellText() {
      return this.cellInfoByView[this.currView].value;
    },
    cellSubtext() {
      return this.cellInfoByView[this.currView].subtext;
    },
    emptyTooltipProps() {
      let props = { placement: 'bottom' };
      if (this.isPotentialRevenueView) {
        props = {
          ...props,
          buttonText: 'Learn More',
          includeCta: true,
          learnMoreLink:
            'https://help.crossbeam.com/en/articles/6797349-potential-revenue',
        };
      }
      return props;
    },
    innerCellClasses() {
      const classes = {
        'amm-cell-inner': true,
        'cursor-not-allowed':
          !this.cell.hasReport || this.cellDisabledForPotRev,
        'amm-cell-inner__report': this.cell.hasReport,
        'overlaps-only': this.cell.isPartnerPopOverlapsOnly,
        'amm-cell__right-cutoff': this.isCutOffRight,
        'mb-0 h-[51px] rounded-b-none':
          this.isCutOffBottom && this.cell.hasReport,
        'opacity-50': this.cell.isDisabled,
        'amm-cell-selected-cell': this.isSelectedCell,
        'border-none': !this.cell.hasReport || this.cellDisabledForPotRev,
      };
      classes[this.cellInfoByView[this.currView].class] = true;
      return classes;
    },
    isCutOffBottom() {
      return this.isInLastRow && !this.rowIncrementDisabled;
    },
    isCutOffRight() {
      return !this.columnIncrementDisabled && this.isInLastColumn;
    },
    isInLastRow() {
      return this.rowIndex === this.numRows - 1;
    },
    isInLastColumn() {
      return this.columnIndex === this.numColumns - 1;
    },
    isPotentialRevenueView() {
      return this.currView === POTENTIAL_REVENUE;
    },
    isSelectedCell() {
      if (!this.selectedAMMGridCell) return false;
      return (
        this.selectedAMMGridCell.our_population_id ===
          this.cell.our_population_id &&
        this.selectedAMMGridCell.partner_population_id ===
          this.cell.partner_population_id
      );
    },
    numOverlaps() {
      return this.getFormattedNumberOrEmptyString(this.cell.overlaps);
    },
    openOpps() {
      return this.getFormattedNumberOrEmptyString(this.cell.openOpps) || '0';
    },
    potentialRevenue() {
      return this.cell.potentialRevenue
        ? `$${this.getWholeDollarAmount(this.cell.potentialRevenue)}`
        : '$0';
    },
    tooltipText() {
      return this.isPotentialRevenueView
        ? 'Potential revenue could not be calculated. Check to make sure your CRM is connected and syncing opportunity fields.'
        : 'No overlaps exist';
    },
    dataAlreadyRequested() {
      return !!this.outboundShareRequests.find(
        (shareRequest) =>
          shareRequest.partner_org_id === this.cell.partner_organization_id &&
          shareRequest.partner_population_id ===
            this.cell.partner_population_id &&
          shareRequest.status === 'pending',
      );
    },
    hasManageSharing() {
      return this.hasPermission('manage:sharing');
    },
    ctaTextTitle() {
      return !this.hasManageSharing
        ? 'This partner is not sharing data with you'
        : this.dataAlreadyRequested
          ? 'You’ve already requested data for this population from this partner'
          : 'This partner is not sharing data with you. Request data from this partner to compare data in a report';
    },
    ctaButtonText() {
      return this.dataAlreadyRequested
        ? 'View Shared with You'
        : 'Request data';
    },
  },
  watch: {
    currentAmmState() {
      this.currView = this.currentAmmState;
    },
    selectedAMMGridCell() {
      if (
        !this.selectedAMMGridCell ||
        Object.keys(this.selectedAMMGridCell) > ONLY_POP_INFO
      )
        return;
      if (this.isSelectedCell) this.selectedAMMGridCell = this.cell;
    },
  },
  methods: {
    async onSelectCell() {
      if (!this.clickToSelectGridCell) {
        const populationId = this.cell.our_population_id;
        const partnerPopulationId = this.cell.partner_population_id;
        const gridType = this.currentAmmState;
        const url = this.reportURL(
          {
            populationId,
            partnerPopulationId,
            gridType,
            popSources: this.popSources,
          },
          true,
        );
        if (this.cell.overlaps) {
          this.trackReportClicked({
            eventSite: EVENT_SITES.AMM_CELL,
            gridType,
            populationId,
            partnerPopulationId,
            ...this.cell,
          });
        }
        if (!this.isFreeTier) {
          this.trackViaLaunchDarkly(LD_TRACKING_KEYS.EI_AMM_CLICK);
        }
        await this.$router.push(url);
      } else {
        this.hasReportChanged = true;
        this.selectedAMMGridCell = this.cell;
      }
    },
    getWholeDollarAmount(num) {
      return this.getFormattedNumberOrEmptyString(Math.round(num));
    },
    getFormattedNumberOrEmptyString(num) {
      if (num || num === 0) {
        return formatNumberWithCommas(num);
      }
      return '';
    },
    onCtaClicked() {
      this.dataAlreadyRequested
        ? this.routeToSharedWithYou()
        : this.$emit('open-request-data-modal', {
            orgId: this.cell.partner_organization_id,
            partnerPop: this.cell.partnerPop,
          });
    },
    routeToSharedWithYou() {
      this.$route.name === 'partner_details'
        ? this.$emit('change-tab')
        : this.$router.push({
            name: 'partner_details',
            params: { partner_org_id: this.cell.partner_organization_id },
            query: { tab: 'shared_with_you' },
          });
    },
  },
});
</script>

<style lang="pcss" scoped>
.amm-cell {
  border-right-width: 1px;
  border-bottom-width: 1px;
  @apply border-r border-solid border-neutral-border h-[70px] w-full;

  &.is-in-last-row {
    border-bottom: none;
  }

  &:last-of-type {
    border-right: none;
  }
  &.is-in-last-row:last-of-type {
    .amm-cell-inner {
      @apply rounded-br-bts-lg;
    }
  }
}

.amm-cell-inner {
  @apply h-[62px] m-4 rounded-bts-md cursor-pointer p-2;

  &.amm-cell-inner-overlaps {
    @apply bg-info-background-weak;
  }
  &.amm-cell-inner-potential-revenue {
    @apply bg-upsell-background-weak;
  }
  .overlaps-text {
    @apply text-neutral-text text-sm;
  }
  .overlaps-number {
    @apply text-neutral-text-strong text-m font-bold;
  }

  &.overlaps-only {
    @apply bg-warning-background-weak cursor-not-allowed;
  }

  &:hover:not(.overlaps-only) {
    @apply border-2 border-solid border-neutral-border-focus p-0;
  }

  &.amm-cell-selected-cell,
  &:hover:not(.overlaps-only) {
    @apply border-2 border-solid border-info-accent;
  }

  &.no-report {
    @apply bg-white border-none cursor-not-allowed;
    &:hover {
      @apply border-none;
    }
  }

  .no-report-popover {
    @apply relative text-neutral-300;

    p {
      @apply text-neutral-500;
    }

    p {
      @apply opacity-50;
    }

    &:hover {
      @apply text-brand-blue;

      p {
        @apply text-brand-blue;
      }

      p,
      svg {
        @apply opacity-100;
      }
    }
  }
}

.amm-cell__bottom-cutoff {
  box-shadow: inset 0px -16px 24px -12px
    theme(colors.neutral.background-strong / 0.16);
  height: 55px;
  .amm-cell-inner {
    box-shadow: inset 0px -12px 24px -12px
      theme(colors.neutral.background-strong / 0.16);
    @apply h-[47px];
    &:not(.no-report) {
      @apply pt-14;
      &:hover {
        @apply border-b-0 pt-10;
      }
    }
  }
}

.amm-cell__right-cutoff {
  @apply w-[140px] min-w-[140px];
  box-shadow: inset -16px 0px 24px -12px
    theme(colors.neutral.background-strong / 0.16);
}

.amm-cell__right-cutoff.amm-cell__bottom-cutoff {
  box-shadow: inset -16px -16px 24px -12px theme(colors.neutral.background-strong /
        0.16);
  amm-cell-inner {
    box-shadow: inset -16px -12px 24px -12px theme(colors.neutral.background-strong /
          0.16);
    @apply h-[47px];
  }
}
</style>

<style lang="pcss">
.amm-cell-inner {
  .trigger {
    display: block !important;
    height: 100%;
  }
}

.amm-cell {
  .ant-popover-inner-content {
    @apply w-[360px] p-8;
  }
}
</style>

<template>
  <BittsLayout :is-page="true" variant="half" class="connector-upgrade-page">
    <template #left>
      <WelcomeBackCopy />
    </template>
    <template #right>
      <BittsLoading
        label="Processing restoration. Please do not close the page..."
        :is-loading="loading"
      >
        <div class="right-content">
          <h1>Restore Crossbeam Connector</h1>
          <BittsCallout
            :icon="['fad', 'magic-wand-sparkles']"
            :title="`Your plan will renew on ${subscriptionRenewalDate}`"
            subtitle="You will be charged the price below on this renewal date and keep all of your existing features and users in your account"
            type="info"
            class="my-40"
            size="small"
          />
          <CostSummary
            class="cost-summary"
            :core-seat-count="coreSeatLimit"
            :cost-per-core-seat="costPerCoreSeat"
            :core-seats-total-cost="coreSeatTotalCosts"
            :sales-seat-count="salesSeatLimit"
            :cost-per-sales-seat="costPerSalesSeat"
            :sales-seats-total-cost="salesSeatTotalCosts"
            :period="billingPeriod"
            :summary-total="componentCost"
            summary-header="Amount Due on Renewal"
          />
          <div
            data-testid="cost-summary-alert"
            class="summary-info"
            :class="{ [period]: true }"
          >
            <span
              v-html="
                injectStrings(
                  V4_BILLING_COPY.connectorUpgrade.upgradeCard.alert[period],
                  { values: injections, bold: true },
                )
              "
            />
          </div>
          <Disclaimer />
          <BittsButton
            size="large"
            text="Restore Plan"
            data-testid="restore-plan-button"
            class="w-full mb-16"
            @click="handleRestoreConnector"
          />
          <BittsButton
            size="large"
            variant="outline"
            data-testid="cancel-button"
            text="Keep Downgrade"
            class="w-full"
            @click="handleClose"
          />
        </div>
      </BittsLoading>
    </template>
  </BittsLayout>
</template>

<script setup>
import {
  BittsButton,
  BittsCallout,
  BittsLayout,
  BittsLoading,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import CostSummary from '@/components/billing/CostSummary.vue';
import Disclaimer from '@/components/billing/Disclaimer.vue';
import WelcomeBackCopy from '@/components/billing/WelcomeBackCopy.vue';

import useBilling from '@/composables/useBilling';
import useIteratively from '@/composables/useIteratively';
import useSeats from '@/composables/useSeats';
import { BILLING_CTAS, CTA_2 } from '@/constants/analytics';
import { V4_BILLING_COPY } from '@/constants/billing';
import { useBillingStore } from '@/stores';
import { injectStrings } from '@/utils';

useHead({ title: 'Restore Plan - Crossbeam' });

const router = useRouter();
const { iteratively } = useIteratively();

const billingStore = useBillingStore();
const {
  subscriptionType,
  subscriptionRenewalDate,
  subscriptionDay,
  billingPeriod,
  componentCost,
  isMonthly,
} = storeToRefs(billingStore);
const {
  coreSeatLimit,
  costPerCoreSeat,
  coreSeatTotalCosts,
  salesSeatLimit,
  costPerSalesSeat,
  salesSeatTotalCosts,
} = useSeats();

async function handleClose() {
  iteratively.userClickedRestorePlan({
    cta: BILLING_CTAS.BILLING,
    cta_2: CTA_2.RESTORE_PLAN_PAGE,
  });
  await router.push({ name: 'billing' });
}

const loading = ref(false);
const { restoreConnector } = useBilling();
async function handleRestoreConnector() {
  loading.value = true;
  const success = await restoreConnector({ period: subscriptionType.value });
  loading.value = false;
  if (success) await router.push({ name: 'billing' });
}

/* Green/Orange cost alert callout */
const period = isMonthly.value ? 'month' : 'year';
const injections = computed(() =>
  isMonthly.value
    ? [subscriptionDay.value, subscriptionRenewalDate.value]
    : ['10%'],
);
</script>

<style lang="pcss" scoped>
.right-content {
  @apply px-24 my-24 lg:px-80 lg:pt-48;
  h1 {
    @apply text-neutral-text-strong text-lg font-bold mb-24;
  }
}
.summary-info {
  @apply rounded-8 px-16 py-8 text-center my-24;
  &.year {
    @apply bg-success-background-weak text-success-text;
  }
  &.month {
    @apply bg-accent-background-weak text-accent-text;
  }
}
</style>

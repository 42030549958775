<template>
  <Loading :is-loading="!ready">
    <div
      class="c-debooking-table-role-cell"
      :class="{ disabled: isRowDisabled }"
    >
      <span class="text-neutral-text-strong" data-testid="role-name">
        {{ activeRole.name }}
      </span>
    </div>
  </Loading>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue';

import useAuthOrInvite from '@/composables/useAuthOrInvite';
import { EXPLORER_SEATS } from '@/constants/billing';
import { NO_ACCESS_ROLE } from '@/constants/team';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const authOrInvite = ref(null);
onBeforeMount(() => {
  authOrInvite.value = props.params.data;
  context.value = props.params.context;
});

const { id, role, ready } = useAuthOrInvite(authOrInvite);

const isRowDisabled = computed(
  () =>
    !context.value.checkAll &&
    !context.value.users?.has(id.value) &&
    context.value.users?.size >= EXPLORER_SEATS,
);
const activeRole = computed(() => role.value ?? NO_ACCESS_ROLE);

const context = ref(null);
</script>

<style lang="pcss">
.c-debooking-table-role-cell {
  @apply flex items-center h-64;
}
</style>

<template>
  <div :class="calloutClass" class="bitts-callout">
    <slot name="left-item">
      <FontAwesomeIcon :class="iconClass" :icon="icon" :style="iconStyle" />
    </slot>
    <div class="bitts-callout__text">
      <slot name="title">
        <p
          :class="fontSize"
          class="bitts-callout__title"
          data-testid="bitts-callout-title"
        >
          {{ title }}
        </p>
      </slot>
      <slot name="subtitle">
        <p :class="subtitleFontSize" class="bitts-callout__subtitle">
          {{ subtitle }}
          <BittsLink
            v-if="learnMoreInfo"
            :text="learnMoreInfo.text || 'Learn more'"
            :url="learnMoreInfo.url"
          />
        </p>
      </slot>
      <slot name="bottom-item" />
    </div>
    <slot name="right-item">
      <div class="ml-auto flex items-center">
        <BittsButton
          v-if="actionText"
          :class="ACTION_CLASS_MAP[size]"
          :left-icon="actionIcon"
          :size="buttonSize"
          :text="actionText"
          :type="actionButtonType"
          class="ml-8"
          @click="handleAction"
        />
        <BittsButton
          v-if="dismiss"
          class="ml-8"
          variant="ghost"
          type="neutral"
          size="x-small"
          :center-icon="['fas', 'x']"
          @click="handleDismiss"
        />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

import BittsButton, { BittsButtonType } from '../BittsButton/BittsButton.vue';
import BittsLink from '../BittsLink/BittsLink.vue';
import { BittsIcon } from '../types';

const CALLOUT_TYPES = [
  'info',
  'neutral',
  'warning',
  'danger',
  'upsell',
] as const;
const CALLOUT_SIZES = ['x-small', 'small', 'medium'] as const;

interface LearnMoreInfo {
  text: string;
  url: string;
}

interface Props {
  actionButtonType?: BittsButtonType;
  actionIcon?: BittsIcon;
  actionText?: string;
  dismiss?: boolean;
  icon?: NonNullable<BittsIcon>;
  learnMoreInfo?: LearnMoreInfo | null;
  size?: (typeof CALLOUT_SIZES)[number];
  subtitle?: string;
  title?: string;
  type?: (typeof CALLOUT_TYPES)[number];
}

const props = withDefaults(defineProps<Props>(), {
  actionButtonType: 'primary',
  actionIcon: null,
  actionText: '',
  dismiss: false,
  icon: () => ['fad', 'circle-info'],
  learnMoreInfo: null,
  size: 'medium',
  subtitle: '',
  title: '',
  type: 'neutral',
});

const emit = defineEmits(['bitts-callout-action', 'bitts-callout-dismiss']);

const handleAction = () => {
  emit('bitts-callout-action');
};

const handleDismiss = () => {
  emit('bitts-callout-dismiss');
};

const MAIN_CLASS_SIZE = {
  'x-small': ['py-8', 'pr-16', 'pl-12', 'rounded-lg'],
  small: ['py-16', 'px-16', 'rounded-2xl'],
  medium: ['py-16', 'px-16', 'rounded-2xl'],
};

const ACTION_CLASS_MAP = {
  'x-small': ['px-8', 'my-2'],
  small: ['px-10', 'my-2'],
  medium: ['px-12', 'my-6'],
};

const calloutClass = computed(() =>
  [`bg-${props.type}-background-weak`].concat(
    MAIN_CLASS_SIZE[props.size] || [],
  ),
);
const iconClass = computed(() => [
  `text-${props.type}-accent`,
  props.size === 'x-small' ? 'mr-8' : 'mr-12',
]);
const fontSize = computed(() => {
  switch (props.size) {
    case 'x-small':
      return 'text-sm';
    case 'small':
      return ['text-sm', 'pb-4'];
    case 'medium':
      return 'text-m';
    default:
      return '';
  }
});
const subtitleFontSize = computed(() => {
  if (props.size === 'medium') return 'text-base';
  return fontSize.value;
});
const iconStyle = computed(() =>
  props.size === 'x-small'
    ? { height: '16px', width: '16px' }
    : { height: '24px', width: '24px' },
);
const buttonSize = computed(() => {
  switch (props.size) {
    case 'x-small':
    case 'small':
      return 'x-small';
    case 'medium':
      return 'small';
    default:
      return 'medium';
  }
});
</script>

<style scoped lang="pcss">
.bitts-callout {
  @apply flex items-center;
  .bitts-callout__text {
    @apply flex flex-col;

    .bitts-callout__title {
      @apply text-neutral-text-strong font-bold;
    }
    .bitts-callout__subtitle {
      @apply text-neutral-600;
    }
  }
}
</style>

import { computed } from 'vue';

import { BIG_CSV_FILE_LIMIT_500MB } from '@/constants/feature_flags';
import { useFeatureFlagStore } from '@/stores';

export default function useCSV() {
  const featureFlagStore = useFeatureFlagStore();
  const maxFileUploadLimit = computed(() => {
    return featureFlagStore.hasFeatureFlag(BIG_CSV_FILE_LIMIT_500MB) ? 500 : 15;
  });

  return {
    maxFileUploadLimit,
  };
}

<template>
  <div class="o-dropdown-menu">
    <div
      :class="{
        'o-dropdown-menu__button--disabled': disabled,
        'cursor-pointer': !disabled,
      }"
      class="o-dropdown-menu__button"
      @click.prevent="toggleMenu"
    >
      <slot :show-dropdown="showDropdown" name="dropdown-button" />
    </div>
    <div
      v-if="showDropdown"
      v-click-away="closeClickaway"
      :class="{
        'o-dropdown-menu__content--right': right,
        'o-dropdown-menu__content--full-width': contentFullWidth,
        'o-dropdown-menu__content--extra-short': contentExtraShort,
        'o-dropdown-menu__content--short': contentShort,
        'o-dropdown-menu__content--medium': contentMedium,
        'dropdown-content': useScopedStyles,
        [dropdownContentClass]: true,
      }"
      class="o-dropdown-menu__content"
      @click="closeClick"
    >
      <slot :close-dropdown="closeDropdown" />
    </div>
  </div>
</template>

<script>
import { mixin as VueClickAway } from 'vue3-click-away';

export default {
  mixins: [VueClickAway],
  props: {
    right: {
      type: Boolean,
      default: false,
    },
    useScopedStyles: {
      type: Boolean,
      required: false,
      default: false,
    },
    contentFullWidth: {
      type: Boolean,
      default: false,
    },
    contentExtraShort: {
      type: Boolean,
      default: false,
    },
    contentShort: {
      type: Boolean,
      default: false,
    },
    contentMedium: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeControl: {
      type: String,
      required: false,
      default: 'self',
    },
    showDropdownParent: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeOnClick: {
      type: Boolean,
      default: true,
    },
    dropdownContentClass: {
      type: String,
      default: null,
    },
  },
  emits: [
    'dropdown-opened',
    'toggle-self-clicked',
    'toggle-menu',
    'dropdown-clickaway',
    'dropdown-closed',
    'dropdown-click',
  ],
  data() {
    return {
      showDropdownSelf: false,
    };
  },
  computed: {
    showDropdown() {
      return this.closeControl === 'self'
        ? this.showDropdownSelf
        : this.showDropdownParent;
    },
  },
  watch: {
    showDropdown(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.$emit('dropdown-opened');
      }
    },
  },
  created() {
    // this closes ALL open dropdowns
    // but since only one should be open at a time it shouldn't be an issue?
    // only used with column cascader
    // this.$root.$on('force-close-dropdown', this.closeDropdown)
  },
  methods: {
    toggleMenu() {
      if (this.closeControl === 'self' && !this.disabled) {
        this.showDropdownSelf = !this.showDropdownSelf;
        this.$emit('toggle-self-clicked');
      } else {
        this.$emit('toggle-menu');
      }
    },
    closeClickaway() {
      if (this.closeControl === 'self') {
        this.showDropdownSelf = false;
      } else {
        this.$emit('dropdown-clickaway');
      }
      this.$emit('dropdown-closed');
    },
    closeClick() {
      if (!this.closeOnClick) {
        return;
      }
      if (this.closeControl === 'self') {
        this.showDropdownSelf = false;
      } else {
        this.$emit('dropdown-click');
      }
      this.$emit('dropdown-closed');
    },
    closeDropdown() {
      this.showDropdownSelf = false;
      this.$emit('dropdown-click');
      this.$emit('dropdown-closed');
    },
  },
};
</script>

<style scoped lang="pcss">
.dropdown-content {
  @apply border-solid border-neutral-200 m-0;
  border-width: 1px;
  box-shadow: none;
}
</style>

<template>
  <BittsModal
    class="c-partner-stack-modal"
    :save-text="showAlternativeOption ? 'Next' : 'Submit Lead'"
    title="Refer Leads to PartnerStack"
    :visible="true"
    :disabled="isSubmitBtnDisabled"
    :destroy-on-close="true"
    :loading="modalLoading"
    :saving="isSaving"
    :width="600"
    @saved="
      showAlternativeOption ? handleNextButtonClicked() : submitReferral()
    "
    @closed="onClose"
  >
    <template #content>
      <div class="c-partner-stack-modal__content">
        <BittsSelect
          v-if="!hasPartnerStackPartnershipKey"
          v-model="selectedPartner"
          class="mb-16"
          form-label="Partner"
          :options="partnershipOptions"
          option-type="company"
          placeholder="Select Partner"
          none-remaining-message="Partner not found in PartnerStack"
          @update:model-value="onFetchForm"
        />
        <BittsSelect
          v-else
          v-model="selectedPartner"
          :class="!selectedForm ? 'mb-24' : 'mb-16'"
          form-label="Partner"
          :options="partnershipOptions"
          option-type="company"
          placeholder="Select Partner"
          :disabled="!!selectedPartnerKey && !selectedForm"
          :allow-clear="!selectedForm"
          none-remaining-message="Partner not found in PartnerStack"
        />
        <BittsDivider v-if="showAlternativeOption" text="Or" />
        <BittsInput
          v-if="showAlternativeOption"
          v-model="selectedPartnerKey"
          :form-label="{
            title: 'PartnerStack Identifier',
            helpText:
              'Partner key is the case-sensitive unique identifier for your partner. You can find the partner key on the partner\'s profile.',
            placement: 'topLeft',
          }"
          class="mb-16"
          :disabled="!!selectedPartner"
          placeholder="Enter a partner_key, internal partnership_key or email"
          name="PartnerSearchInput"
          :status="partnershipSearchError ? 'danger' : 'default'"
          danger-text="This partner was not found, please verify that your information is correct"
          @update:model-value="partnershipSearchError = false"
        />
        <div
          v-if="selectedForm"
          data-testid="c-partner-stack-modal__form-content"
        >
          <div v-for="item in orderedFields" :key="item.position">
            <BittsFormLabel
              v-if="item.type === DATETIME"
              :label="{
                title: capitalizeFormLabel(item.name),
                secondaryText: item.required ? 'Required' : 'Optional',
              }"
            />
            <component
              v-bind="bittsComponentProps(item)"
              :is="bittsComponentMap(item)"
              v-if="!item.hidden"
              v-model="formDetails[item.internal_name]"
              class="mb-16"
              :class="{
                'c-partner-stack-modal__date-picker': item.type === DATETIME,
              }"
              :status="requiredErrors[item.internal_name] ? 'danger' : null"
              :danger-text="requiredErrors[item.internal_name]"
              @change="(val) => onSelection(val, item)"
              @blur="handleBlur(item)"
            >
              <template v-if="item.type === CHECKBOX" #default>
                <BittsCheckbox
                  v-for="(opt, i) in item.options"
                  :key="i"
                  :label="opt"
                  @input="(checked) => onOptionChecked(checked, opt, item)"
                />
              </template>
            </component>
          </div>
        </div>
      </div>
    </template>
  </BittsModal>
</template>
<script setup>
import {
  BittsCheckbox,
  BittsCheckboxGroup,
  BittsDivider,
  BittsFormLabel,
  BittsInput,
  BittsModal,
  BittsRangePicker,
  BittsSelect,
  BittsTextArea,
} from '@crossbeam/bitts';

import axios from 'axios';
import { isEmpty, sortBy } from 'lodash';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import useIteratively from '@/composables/useIteratively';
import { EVENT_SITES } from '@/constants/analytics';
import { PARTNERSTACK_PARTNERSHIP_KEY } from '@/constants/feature_flags';
import { captureException } from '@/errors';
import { useFeatureFlagStore, useFlashesStore } from '@/stores';
import urls from '@/urls';
import { isEmailValid } from '@/utils';

const DATETIME = 'datetime';
const PICKLIST = 'picklist';
const CHECKBOX = 'checkbox';
const TEXTAREA = 'textarea';

const props = defineProps({
  selectedRecord: {
    type: Object,
    required: true,
  },
});

const flashesStore = useFlashesStore();
const ffStore = useFeatureFlagStore();

const { iteratively } = useIteratively();
const route = useRoute();

const partnerships = ref([]);
const selectedForm = ref(null);
const modalLoading = ref(false);
const isSaving = ref(false);

const selectedPartnerKey = ref(undefined);

const hasPartnerStackPartnershipKey = computed(() =>
  ffStore.hasFeatureFlag(PARTNERSTACK_PARTNERSHIP_KEY),
);
const partnershipSearchError = ref(false);

async function getPartnerships(cnt = 0, nextValue = null, opts = []) {
  const {
    data: { has_more: hasMore, items, start_after: startAfter },
  } = await axios.get(urls.partnerStack.partnerships, {
    params: { start_after: nextValue },
  });
  if (items.length) opts.push(...items);
  if (hasMore && cnt < 5) {
    cnt += 1;
    await getPartnerships(cnt, startAfter, opts);
  }
  return opts;
}

onMounted(async () => {
  modalLoading.value = true;
  try {
    const items = await getPartnerships();
    partnerships.value = items;
  } catch (e) {
    captureException(e);
  }
  modalLoading.value = false;
});

const partnershipOptions = computed(() => {
  return partnerships.value.map((partnership) => {
    const { team, group_key: groupKey, partner_id: partnerId } = partnership;
    return {
      ...partnership,
      label: team,
      value: `${groupKey}__${partnerId}__${team}`,
    };
  });
});

const showAlternativeOption = computed(
  () => hasPartnerStackPartnershipKey.value && !selectedForm.value,
);

const formDetails = ref({});

const emit = defineEmits(['closed', 'saved']);

function bittsComponentMap(item) {
  switch (item.type) {
    case TEXTAREA:
      return BittsTextArea;
    case PICKLIST:
      return BittsSelect;
    case CHECKBOX:
      return BittsCheckboxGroup;
    case DATETIME:
      return BittsRangePicker;
    default:
      return BittsInput;
  }
}

function bittsComponentProps(item) {
  return {
    prompt: 'Select Field',
    placement: 'bottom',
    options: formatOptions(item),
    mountToBody: false,
    parentClass: '',
    formLabel: {
      title: capitalizeFormLabel(item.name),
      secondaryText: item.required ? 'Required' : 'Optional',
    },
    placeholder: item.placeholder_text || item.name,
    name: item.name,
    useSingleDatePicker: true,
    format: 'YYYY-MM-DD',
  };
}

function capitalizeFormLabel(name) {
  const lowercasedName = name.toLowerCase().split(' ');
  const capitalizedName = lowercasedName.map(
    (word) => word[0].toUpperCase() + word.slice(1),
  );
  return capitalizedName.join(' ');
}

const fieldResponse = ref([]);
const requiredFields = computed(() =>
  fieldResponse.value
    .filter((field) => field.required)
    .map((field) => field.internal_name),
);
const orderedFields = computed(() => sortBy(fieldResponse.value, ['position']));

const selectedPartnerGroup = ref(undefined);
const selectedPartnerId = ref(undefined);
const selectedPartner = ref(undefined);

function handleNextButtonClicked() {
  return selectedPartner.value
    ? onFetchForm(selectedPartner.value)
    : onPartnerKeySelected();
}

async function onFetchForm(value) {
  modalLoading.value = true;
  const [groupKey, partnerId, team] = value.split('__');
  const { data } = await axios.get(
    urls.partnerStack.formFieldsByGroup(groupKey),
  );
  selectedPartnerGroup.value = groupKey;
  selectedPartner.value = team;
  selectedPartnerId.value = partnerId;
  fieldResponse.value = data.fields;
  selectedForm.value = true;
  modalLoading.value = false;
}

async function onPartnerKeySelected() {
  partnershipSearchError.value = false;
  const { data } = await axios.get(
    urls.partnerStack.partnershipSearch(selectedPartnerKey.value),
  );
  if (!data?.partnership) {
    partnershipSearchError.value = true;
    return;
  }
  const { group_key: groupKey, partner_id: partnerId, team } = data.partnership;
  onFetchForm(`${groupKey}__${partnerId}__${team}`);
}

function onOptionChecked(checked, opt, item) {
  if (formDetails.value[item.internal_name] === undefined)
    formDetails.value[item.internal_name] = [];
  if (checked) {
    formDetails.value[item.internal_name] = [
      ...formDetails.value[item.internal_name],
      opt,
    ];
  } else {
    formDetails.value[item.internal_name] = formDetails.value[
      item.internal_name
    ].filter((sel) => sel !== opt);
  }
}

function onSelection(selection, item) {
  if (requiredErrors.value[item.internal_name])
    delete requiredErrors.value[item.internal_name];
  if (item.type === DATETIME) {
    const epochTimeInMS = new Date(selection).getTime();
    formDetails.value[item.internal_name] = epochTimeInMS;
  }
}

function formatOptions(item) {
  if (item.type === PICKLIST)
    return Object.values(item.options).map((opt) => ({
      value: opt,
      label: opt,
    }));
  return Object.values(item.options).map((opt) => ({
    checked: opt,
    label: opt,
  }));
}

const emptyFieldsRemoved = computed(() => {
  Object.keys(formDetails.value).forEach((key) => {
    if (formDetails.value[key] === '') delete formDetails.value[key];
  });
  return Object.keys(formDetails.value);
});

const requiredFieldsAreMissing = computed(() => {
  return !requiredFields.value.every((field) =>
    emptyFieldsRemoved.value.includes(field),
  );
});

const isSelectedPartnerKeyValid = computed(
  () => selectedPartnerKey.value && !partnershipSearchError.value,
);

const isSubmitBtnDisabled = computed(() => {
  if (
    showAlternativeOption.value &&
    (isSelectedPartnerKeyValid.value || selectedPartner.value)
  )
    return false;
  return (
    !selectedForm.value ||
    requiredFieldsAreMissing.value ||
    !isEmpty(requiredErrors.value)
  );
});

const requiredErrors = ref({});

function handleBlur(item) {
  if (item.required && formDetails.value[item.internal_name] === undefined) {
    requiredErrors.value[item.internal_name] = `${item.name} is required`;
    return;
  }
  if (item.internal_name === 'email' && !isEmailValid(formDetails.value?.email))
    requiredErrors.value[item.internal_name] = 'Please provide a valid email';
  if (
    ['number', 'currency'].includes(item.type) &&
    isNaN(formDetails.value[item.internal_name])
  )
    requiredErrors.value[item.internal_name] = 'Must be a valid number';
  if (
    !formDetails.value[item.internal_name] &&
    !requiredFields.value.includes(item.internal_name)
  )
    delete requiredErrors.value[item.internal_name];
}

function onClose(response) {
  formDetails.value = {};
  selectedPartnerGroup.value = undefined;
  selectedPartner.value = undefined;
  selectedPartnerId.value = undefined;
  selectedForm.value = null;
  fieldResponse.value = [];
  requiredErrors.value = {};
  if (response) {
    emit('saved', response);
  } else {
    emit('closed');
  }
}

async function submitReferral() {
  const payload = {
    master_id: props.selectedRecord.recordId,
    group_key: selectedPartnerGroup.value,
    partner_id: selectedPartnerId.value,
    source_id: props.selectedRecord.sourceId,
    data: formDetails.value,
  };
  let response = null;
  isSaving.value = true;
  try {
    const { data } = await axios.post(
      urls.partnerStack.submitReferral,
      payload,
    );
    response = data;
    response.rowId = props.selectedRecord.rowId;
    flashesStore.addSuccessFlash({
      message: 'Your Lead has successfully been submitted to PartnerStack',
    });
    iteratively.userClicksPartnerstackSubmitLead(
      formattedIterativelyPayload.value,
    );
    onClose(response);
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({ message: err?.response?.data?.error });
  } finally {
    isSaving.value = false;
  }
}

const formattedIterativelyPayload = computed(() => {
  const payload = {
    partnerstack_partner: selectedPartner.value,
    company_name: formDetails.value.company,
    contact_first_name: formDetails.value.first_name,
    contact_last_name: formDetails.value.last_name,
    contact_email: formDetails.value.email,
    submit_lead_location: route.path.includes('reports') ? 'Report' : 'List',
    event_site: EVENT_SITES.PARTNERSTACK_MODAL_SAVE_BUTTON,
  };
  if (props.selectedRecord?.report_id)
    payload.report_id = props.selectedRecord.report_id;
  if (props.selectedRecord?.listId)
    payload.list_id = props.selectedRecord.listId;
  return payload;
});
</script>
<style lang="pcss">
.c-partner-stack-modal__content {
  @apply mb-16 max-h-[610px] px-2 overflow-y-scroll;

  div > .c-bitts-input__wrapper {
    @apply w-full;
  }

  .c-partner-stack-modal__date-picker {
    .ant-picker {
      @apply rounded-8 border-neutral-border shadow-component outline outline-1 w-full py-8 text-neutral-text outline-transparent;
      .ant-picker-input > input::placeholder {
        @apply text-neutral-text-placeholder;
      }
      &.ant-picker-focused {
        @apply outline-1 border border-neutral-border-focus outline-neutral-border-focus border-solid outline;
      }
    }
  }
}
</style>

<template>
  <loading
    :is-loading="loading"
    loading-container-class="h-screen"
    class="c-login-and-registration"
  >
    <div class="c-login-and-registration__form">
      <div v-if="hasOrgToPartnerWith">
        <BittsAvatar
          :org="publicInviteOrg || proposal?.sending_organization"
          :is-own="false"
          :show-initials="true"
          size="medium"
          shape="square"
          class="mb-24"
        />
        <p
          class="c-login-and-registration__title mb-8 text-neutral-text-strong text-xl text-center"
        >
          Partner with {{ partnerOrgName }} on Crossbeam
        </p>
        <p class="text-base text-neutral-600 mb-40 text-center">
          {{ partnerOrgName }} is using Crossbeam to supercharge their
          partnerships. Sign up to partner with them and thousands of other
          companies.
        </p>
      </div>
      <div
        v-else
        class="c-login-and-registration__title mb-24 text-neutral-text-strong text-xl"
      >
        {{
          isFromReveal
            ? 'Create your account for free'
            : 'Sign Up for Crossbeam'
        }}
      </div>
      <div
        class="c-login-and-registration__tos"
        :class="{
          'bg-neutral-background-weak': !acceptTos,
          'bg-secondary-background-weak': acceptTos,
        }"
      >
        <BittsCheckbox
          :checked="acceptTos"
          class="p-2 mr-8"
          @input="acceptTos = !acceptTos"
        />
        <span>
          I have read and agree to the
          <BittsLink
            url="https://www.crossbeam.com/terms"
            text="Crossbeam Terms of Service"
          />
          and the
          <BittsLink
            url="https://www.crossbeam.com/privacy"
            text="Crossbeam Privacy Policy"
          />
        </span>
      </div>
      <component
        :is="acceptTos ? 'div' : 'BittsTooltip'"
        v-bind="tooltipProps"
        placement="bottom"
      >
        <BittsButton
          class="mb-8 w-full"
          type="neutral"
          data-testid="google-sign-up-button"
          text="Continue with Google"
          :disabled="!acceptTos"
          @click="loginGoogle"
        >
          <template #icon>
            <img
              class="h-12 w-12 mr-12"
              :class="{ 'opacity-50': !acceptTos }"
              src="@/assets/google-icon.png"
            />
          </template>
        </BittsButton>
        <template #title>
          {{ disabledLoginText }}
        </template>
      </component>
      <BittsDivider
        text="or"
        class="c-login-and-registration__divider"
        :class="{
          'opacity-50': !acceptTos,
        }"
      />
      <form class="text-m" @submit.prevent="submitForm">
        <BittsInput
          v-model="email"
          :disabled="!acceptTos"
          name="userEmail"
          form-label="Email Address"
          placeholder="Work Email Address"
          data-testid="email-input"
          :status="v$.email.$errors.length ? 'danger' : 'default'"
          :danger-text="v$.email.$errors?.at(-1)?.$message || ''"
          class="mb-16"
        />
        <div
          :class="{ 'opacity-50': !acceptTos }"
          class="text-left w-full text-neutral-text-strong font-bold text-sm mb-4"
        >
          Full Name
        </div>
        <div class="flex gap-16">
          <BittsInput
            v-model="firstName"
            :disabled="!acceptTos"
            name="userFirstName"
            data-testid="first-name"
            placeholder="First Name"
            class="mb-16"
          />
          <BittsInput
            v-model="lastName"
            :disabled="!acceptTos"
            name="userLastName"
            data-testid="last-name"
            placeholder="Last Name"
            class="mb-16"
          />
        </div>
        <LiveFeedbackValidationWrapper
          :field="v$.password"
          :validation-messages="liveFeedbackMessages('password')"
          :content-class="['flex', 'flex-wrap']"
        >
          <template #description>
            All passwords must meet the following:
          </template>
          <BittsInput
            v-model="v$.password.$model"
            :disabled="!acceptTos"
            name="userPassword"
            type="password"
            data-testid="password-input"
            form-label="Password"
            placeholder="Password"
            class="text-left mb-4"
            @update:model-value="(update) => !!update || v$.password.$reset()"
          />
        </LiveFeedbackValidationWrapper>
        <PasswordStrengthBar
          v-if="v$.password.$dirty"
          :password="v$.password.$model"
          :limit-visible-score="v$.password.$invalid ? 2 : null"
          limit-feedback="Try adding uppercase characters, numbers, and symbols"
          class="mb-24 mt-12"
        />
        <LiveFeedbackValidationWrapper
          :field="v$.passwordCheck"
          :show-validator="v$.password.$dirty"
          :validation-messages="liveFeedbackMessages('passwordCheck')"
          class="mb-16"
        >
          <BittsInput
            v-model="v$.passwordCheck.$model"
            :disabled="!acceptTos"
            name="userPasswordCheck"
            type="password"
            data-testid="password-check-input"
            form-label="Confirm Password"
            placeholder="Confirm Password"
            class="text-left mb-4 mt-16"
          />
        </LiveFeedbackValidationWrapper>
        <BittsAlert
          v-if="errorMessage"
          color="error"
          :message="'Error creating your account'"
          :description="errorMessage"
          class="text-left mb-16"
        />
        <component
          :is="canSignUp ? 'div' : 'BittsTooltip'"
          v-bind="tooltipProps"
          placement="bottom"
        >
          <div>
            <BittsButton
              data-testid="normal-sign-up-button"
              text="Create account"
              type="primary"
              :disabled="!canSignUp"
              class="w-full"
              @click="submitForm"
            />
          </div>
          <template #title>
            {{ disabledLoginText }}
          </template>
        </component>
      </form>

      <div class="c-login-and-registration__already-exists">
        <router-link
          :to="{ name: 'login', query: { next: nextUrl || inviteNextUrl } }"
        >
          Already have {{ isFromReveal ? 'a Crossbeam' : 'an' }} account?
          <span class="text-secondary-text hover:opacity-80">
            Log in
            <FontAwesomeIcon
              :icon="['far', 'arrow-up-right']"
              :style="{ height: '14px', width: '14px' }"
              class="text-secondary-text"
            />
          </span>
        </router-link>
        <div v-if="isFromReveal">
          Already have a Reveal account?
          <BittsLink
            url="https://app.reveal.co/register"
            text="Log in here"
            open-in-new-tab
          />
        </div>
      </div>
    </div>
  </loading>
</template>

<script>
import {
  BittsAlert,
  BittsAvatar,
  BittsButton,
  BittsCard,
  BittsCheckbox,
  BittsContainer,
  BittsDivider,
  BittsInput,
  BittsLink,
  BittsSvg,
  BittsTooltip,
} from '@crossbeam/bitts';

import { useVuelidate } from '@vuelidate/core';
import {
  email as emailVal,
  helpers,
  minLength,
  required,
  sameAs,
} from '@vuelidate/validators';
import axios from 'axios';
import { debounce } from 'lodash';
import { ref } from 'vue';

import LiveFeedbackValidationWrapper from '@/components/LiveFeedbackValidationWrapper.vue';
import PasswordStrengthBar from '@/components/registration/PasswordStrengthBar.vue';

import { captureException } from '@/errors';
import { ls } from '@/local_storage';
import urls from '@/urls';

export default {
  name: 'RegistrationForm',
  components: {
    BittsAvatar,
    BittsButton,
    BittsContainer,
    BittsCheckbox,
    BittsDivider,
    BittsInput,
    BittsCard,
    BittsSvg,
    BittsAlert,
    LiveFeedbackValidationWrapper,
    PasswordStrengthBar,
    BittsTooltip,
    BittsLink,
  },
  props: {
    nextUrl: {
      type: String,
      default: null,
    },
    proposalAcceptanceCode: {
      type: String,
      default: '',
    },
    publicInviteCode: {
      type: String,
      default: null,
    },
    invitingOrg: {
      type: Object,
      default: null,
    },
    inviteNextUrl: {
      type: String,
      default: null,
    },
    isFromReveal: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const email = ref('');
    const password = ref('');
    const passwordCheck = ref('');
    const firstName = ref('');
    const lastName = ref('');

    const rules = {
      email: {
        required: helpers.withMessage('Please enter your work email', required),
        email: helpers.withMessage(
          'Please enter a valid email address',
          emailVal,
        ),
        domainAllowed: helpers.withMessage(
          'Please enter a work email',
          (_value, _, vm) => vm.isEmailDomainAllowed,
        ),
      },
      password: {
        required,
        minLength: minLength(12),
        includesLowercase: (str) => str.toUpperCase() !== str,
        includesUppercase: (str) => str.toLowerCase() !== str,
        includesSpecialCharacter: (str) => {
          return ['!', '@', '#', '$', '%', '^', '&', '*'].some((v) =>
            str.includes(v),
          );
        },
        includesNumber: (str) => /\d/.test(str),
        doesNotIncludeEmail: (str) => {
          if (!email.value) return true;
          const [emailName, emailDomain] = email.value.split('@');
          const hasUserEmail = !!emailName && str.includes(emailName);
          const hasEmailDomain = !!emailDomain && str.includes(emailDomain);
          return !(hasEmailDomain || hasUserEmail);
        },
      },
      passwordCheck: { sameAsPassword: sameAs(password) },
      firstName: helpers.withMessage('First name is required', required),
      lastName: helpers.withMessage('Last name is required', required),
    };

    const v$ = useVuelidate(
      rules,
      {
        email,
        password,
        firstName,
        lastName,
        passwordCheck,
      },
      { $lazy: true },
    );

    return {
      firstName,
      lastName,
      email,
      password,
      passwordCheck,
      v$,
    };
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      acceptTos: false,
      // By default we set isEmailDomainAllowed to true so that we don't show
      // an issue to the user until we have actually checked whether it's
      // valid.
      isEmailDomainAllowed: true,
      checkDomainValidityDebounced: null,
      domainCheckPromise: null,
      proposal: null,
      publicInviteOrg: null,
      playButtonHover: false,
    };
  },
  computed: {
    wrappedNextUrl() {
      return this.$router.resolve(this.nextUrl || '/').href;
    },
    hasOrgToPartnerWith() {
      return (
        this.publicInviteOrg ||
        (this.proposal && this.proposal.sending_organization)
      );
    },
    partnerOrgName() {
      if (!this.hasOrgToPartnerWith) {
        return '';
      }
      return this.publicInviteOrg
        ? this.publicInviteOrg.name
        : this.proposal.sending_organization.name;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    allFieldsNonEmptyAndPasswordValid() {
      return !!(
        this.password &&
        !this.v$.password.$invalid &&
        this.passwordCheck &&
        !this.v$.passwordCheck.$invalid &&
        this.email &&
        !!this.firstName &&
        !!this.lastName
      );
    },
    canSignUp() {
      return this.acceptTos && this.allFieldsNonEmptyAndPasswordValid;
    },
    tooltipProps() {
      return !this.canSignUp
        ? {
            trigger: 'hover',
            placement: 'top',
            hideArrow: false,
          }
        : null;
    },
    disabledLoginText() {
      if (!this.acceptTos) return 'Oops, please accept our terms of service!';
      if (!this.email || !this.firstName || !this.lastName)
        return 'Oops, please complete each field!';
      if (!this.password) return 'Oops, you need to enter a password!';
      if (this.v$.password.$invalid)
        return 'Oops, you need to create a stronger password!';
      if (this.v$.passwordCheck.$invalid)
        return 'Oops, your passwords must match!';
      return '';
    },
  },
  watch: {
    email() {
      if (this.v$.email.email) {
        this.isEmailDomainAllowed = true;
        this.checkDomainValidityDebounced();
      }
    },
  },
  async created() {
    this.loading = true;
    this.email = this.$route.query.email || this.invitingOrg?.email;
    this.checkDomainValidityDebounced = debounce(this.checkDomainValidity, 300);
    if (this.proposalAcceptanceCode) {
      await this.loadProposal();
    }
    if (this.publicInviteCode) {
      await this.loadPublicInviteOrg();
    }
    this.loading = false;
  },
  methods: {
    async loadProposal() {
      const url = urls.proposals.byAcceptanceCode(this.proposalAcceptanceCode);
      try {
        const response = await axios.get(url);
        if (response.data) {
          this.proposal = response.data;
          ls.proposalAcceptInfo.set({
            acceptance_code: this.proposalAcceptanceCode,
            proposal: this.proposal,
          });
        }
      } catch (error) {
        captureException(error);
        this.proposal = null;
      }
    },
    loginGoogle() {
      this.loading = true;
      ls.nextUrl.set(this.nextUrl || this.inviteNextUrl);
      ls.newSession.set(true);
      this.$auth.googleLogin();
    },
    liveFeedbackMessages(inputType) {
      const validationMessages = {
        password: {
          minLength: {
            message: 'At least 12 characters',
          },
          includesLowercase: {
            message: 'Lowercase letter',
          },
          includesUppercase: {
            message: 'Uppercase letter',
          },
          includesSpecialCharacter: {
            message: 'Special character (!@#$%^&*)',
          },
          includesNumber: {
            message: 'Numbers',
          },
          doesNotIncludeEmail: {
            message: 'Cannot contain your email',
          },
        },
        passwordCheck: {
          sameAsPassword: {
            message: 'Passwords must match',
          },
        },
      };
      return validationMessages[inputType];
    },
    async loadPublicInviteOrg() {
      const publicInviteUrl = urls.publicInviteCode.getOrgId(
        this.publicInviteCode,
      );
      try {
        const response = await axios.get(publicInviteUrl);
        if (response.data) {
          this.publicInviteOrg = response.data;
          ls.publicInvite.set({
            code: this.publicInviteCode,
            org: this.publicInviteOrg,
          });
        }
      } catch (error) {
        captureException(error);
        this.publicInviteOrg = null;
      }
    },
    async submitForm() {
      if (this.email) this.email = this.email.trim();
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      if (this.domainCheckPromise) {
        this.loading = true;
        try {
          await this.domainCheckPromise;
        } catch (err) {
          captureException(err);
          this.errorMessage =
            'Something went wrong communicating with our ' +
            'servers. Please try again or contact support@crossbeam.com for assistance.';
          this.checkDomainValidityDebounced();
          return;
        } finally {
          this.loading = false;
        }
      }
      // We have to check the form validity again because the domain check may
      // have flipped the validity.
      if (this.v$.$invalid) {
        return;
      }
      this.errorMessage = null;
      try {
        this.loading = true;
        await axios.post(urls.auth0.signUp, {
          email: this.email,
          password: this.password,
          user_metadata: {
            source: 'RegistrationForm',
            first_name: this.firstName,
            last_name: this.lastName,
          },
          invite_code:
            this.$route.params?.code ||
            this.$route.query?.public_invite_code ||
            null,
        });
        // The above route will return successes even if the user exists,
        // and send the emails necessary when navigating to this page
        await this.$router.push({
          name: 'verify_email',
          query: { email: this.email },
        });
      } catch (_err) {
        /* The only error we ever surface from AuthZero on this route relates to user info */
        this.errorMessage =
          'Your password cannot contain user information. Please try using a more random password.';
      } finally {
        this.loading = false;
      }
    },
    async checkDomainValidity() {
      if (!this.email) {
        return false;
      }
      this.domainCheckPromise = axios.get(urls.registration.domain(this.email));
      this.domainCheckPromise.then((response) => {
        this.isEmailDomainAllowed = response.data.is_allowed;
      });
      this.domainCheckPromise.catch((err) => {
        captureException(err);
      });
    },
  },
};
</script>

<style lang="pcss">
.c-login-and-registration {
  @apply m-auto h-full;

  .anticon {
    @apply text-neutral-400;
  }
}

.c-login-and-registration--split {
  @apply h-full;
}

/* B version in A/B Launch Darkly test */
.c-login-and-registration--split .c-login-and-registration__content {
  @apply flex flex-row w-full max-w-full mx-auto h-full;
}

.c-login-and-registration__main {
  @apply flex flex-col w-full justify-center items-center self-start flex-1;
  height: calc(100% - 36px);
}

.c-simple-form__content {
  @apply max-w-[464px];
}
</style>

<style scoped lang="pcss">
.c-login-and-registration__tos {
  @apply px-24 py-8 text-neutral-900 text-sm text-left rounded-16 flex items-center;
}

.c-login-and-registration__divider {
  @apply text-base m-0;
}
.c-login-and-registration__tos__link {
  @apply text-white bg-info-accent mr-2;
  border-radius: 3px;
  padding: 1px;
}

.c-login-and-registration__form {
  @apply flex flex-col justify-center max-w-[464px] text-center px-16 gap-16;
}

.c-login-and-registration__row {
  @apply mb-16;
}

.c-login-and-registration__google-button {
  @apply w-full justify-center;
}

.c-login-and-registration__layer-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-login-and-registration__title {
  font-weight: 600;
  @apply text-xl text-brand-navy;
}

.c-login-and-registration__testimonials {
  @apply flex-1 bg-blue-500 flex flex-col justify-center items-center gap-48;
}

.c-login-and-registration__already-exists {
  @apply text-neutral-text-weak text-base mt-8 mb-24 text-center;
}
</style>

<style lang="pcss">
.c-login-and-registration--split,
.c-login-and-registration {
  .c-bitts-input-label {
    @apply text-neutral-text-strong font-bold;
  }

  .c-bitts-input__input.ant-input-affix-wrapper-disabled.disabled {
    @apply bg-white;
  }
}
</style>

<template>
  <component :is="tooltipOrDiv" v-bind="tooltipProps">
    <div :class="parentHeight" class="bitts-avatar-pip">
      <BittsAvatar
        v-bind="userOrOrg(true)"
        :alt-text-from-parent="altTextFromParent"
        :image-source="imageSource"
        :is-own="isOwn"
        :show-initials="showInitials"
        :size="size"
      />
      <BittsAvatar
        v-if="!isIcon"
        v-bind="userOrOrg(false)"
        :size="pipSize"
        :class="pipContainerSize"
        :avatar-classes="pipOutlineClass"
        :show-initials="true"
        :shape="!isUserMainAvatar ? 'circle' : 'square'"
        class="bitts-avatar-pip__pip"
      />
      <BittsAvatar
        v-else
        :is-icon="true"
        :size="pipSize"
        :avatar-classes="pipOutlineClass"
        :show-border="true"
        :icon-slot-bg-color="iconSlotBgColor"
        shape="circle"
        class="bitts-avatar-pip__pip"
      >
        <template #icon>
          <slot name="icon" />
        </template>
      </BittsAvatar>
    </div>
    <template #title>
      {{ tooltipText }}
      <slot name="additionalInfo" />
    </template>
  </component>
</template>

<script>
import { isProxy, toRaw } from 'vue';

import BittsAvatar from '../BittsAvatar/BittsAvatar.vue';
import BittsTooltip from '../BittsTooltip/BittsTooltip.vue';

export default {
  name: 'BittsAvatarPip',
  components: {
    BittsAvatar,
    BittsTooltip,
  },
  props: {
    altTextFromParent: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['large', 'medium', 'small'].includes(value);
      },
    },
    org: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    imageSource: {
      type: String,
      default: '',
    },
    includeHoverText: {
      type: Boolean,
      default: false,
    },
    isUserMainAvatar: {
      type: Boolean,
      default: true,
    },
    showInitials: {
      type: Boolean,
      default: false,
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    iconSlotBgColor: {
      type: String,
      default: 'white',
    },
    isOwn: {
      type: Boolean,
      default: true,
    },
    hoverTextPlacement: {
      type: String,
      default: 'bottom',
    },
    showTooltipOrgInfo: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    userFromProp() {
      return isProxy(this.user) ? toRaw(this.user) : this.user;
    },
    orgFromProp() {
      return isProxy(this.org) ? toRaw(this.org) : this.org;
    },
    parentHeight() {
      return {
        small: 'h-24',
        medium: 'h-40',
        large: 'h-80',
      }[this.size];
    },
    pipContainerSize() {
      return {
        small: 'leading-2',
        medium: 'leading-4',
        large: 'leading-6',
      }[this.size];
    },
    pipOutlineClass() {
      return {
        small: 'bitts-avatar-pip__outline-small',
        medium: 'bitts-avatar-pip__outline-medium',
        large: 'bitts-avatar-pip__outline-large',
      }[this.size];
    },
    pipSize() {
      return {
        small: 'tiny',
        medium: 'x-small',
        large: 'small',
      }[this.size];
    },
    tooltipOrDiv() {
      return this.includeHoverText ? 'BittsTooltip' : 'div';
    },
    tooltipProps() {
      if (this.includeHoverText) {
        return {
          trigger: 'hover',
          placement: this.hoverTextPlacement,
        };
      }
      return '';
    },
    tooltipText() {
      let text = '';
      // ideal end state here is 'Firstname Lastname at Orgname', per Maddy
      if (this.userFromProp) {
        // non crm case just has name
        if (this.userFromProp.name) {
          text += this.userFromProp.name;
        } else if (this.userFromProp.first_name) {
          text += this.userFromProp.first_name;
          if (this.userFromProp.last_name) {
            text += ` ${this.userFromProp.last_name}`;
          }
        }
        if (
          this.orgFromProp &&
          this.orgFromProp.name &&
          this.showTooltipOrgInfo
        ) {
          text += ` at ${this.orgFromProp.name}`;
        }
      }
      return text;
    },
  },
  methods: {
    userOrOrg(mainAvatar) {
      if (mainAvatar) {
        return this.isUserMainAvatar
          ? { user: this.userFromProp }
          : { org: this.orgFromProp };
      }
      return !this.isUserMainAvatar
        ? { user: this.userFromProp }
        : { org: this.orgFromProp };
    },
  },
};
</script>

<style lang="pcss">
.bitts-avatar-pip {
  position: relative;
  .bitts-avatar-pip__pip {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .bitts-avatar-pip__outline-large {
    outline: solid 4px white;
  }
  .bitts-avatar-pip__outline-medium {
    outline: solid 2px white;
  }
  .bitts-avatar-pip__outline-small {
    outline: solid 1px white;
  }
}
</style>

<template>
  <loading :is-loading="pageLoading" />
</template>

<script>
import { useHead } from '@unhead/vue';
import axios from 'axios';
import { mapActions, mapState } from 'pinia';

import { FRIGG_THRESHOLD } from '@/constants/frigg';
import {
  useFlashesStore,
  usePartnerCloudStore,
  usePartnersStore,
} from '@/stores';
import { useRootStore } from '@/stores/RootStore';
import urls from '@/urls';

export default {
  setup() {
    useHead({ title: 'Oauth Redirect' });
    const { loadUserProfile } = useRootStore();

    return { loadUserProfile };
  },

  data() {
    return {
      pageLoading: true,
      friggType: this.$route.params.integration_type,
    };
  },
  computed: {
    ...mapState(usePartnerCloudStore, ['getAvailableIntegrationByType']),
    ...mapState(usePartnersStore, ['overlapCounts']),
    tooPopular() {
      return this.overlapCounts.overlap_usage?.total > FRIGG_THRESHOLD;
    },
  },
  async created() {
    await this.refreshPartnerCloudStore();
    const { integration_type: friggType } = this.$route.params;
    const availableIntegration = this.getAvailableIntegrationByType(friggType);
    if (this.tooPopular || !availableIntegration) {
      this.redirectToIntegrations();
      return;
    }
    try {
      const authCode = this.$route.query.code;
      const { data: friggInfo } = await axios.post(urls.frigg.all, {
        integration_type: friggType,
        authorization_details: { code: authCode },
      });
      await this.loadUserProfile();
      if (availableIntegration.hasUserConfig || friggInfo.needs_config) {
        this.$router.push({
          name: 'frigg-integration',
          params: {
            integration_type: friggType,
          },
        });
      } else {
        this.redirectToIntegrations();
      }
    } catch (err) {
      this.addErrorFlash(err);
      this.redirectToIntegrations();
    } finally {
      this.pageLoading = false;
    }
  },
  methods: {
    ...mapActions(useFlashesStore, ['addErrorFlash']),
    ...mapActions(usePartnerCloudStore, ['refreshPartnerCloudStore']),
    redirectToIntegrations() {
      this.$router.push({ name: 'integrations' });
    },
  },
};
</script>

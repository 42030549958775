import { defineStore } from 'pinia';
export const useExtensionStore = defineStore('ExtensionStore', {
  state: () => {
    return {
      parentDomain: '',
      ignoredList: [],
      parentWebsite: '',
    };
  },
  getters: {
    isIgnored() {
      return (domain) => {
        return this.ignoredList.includes(domain);
      };
    },
    searchForDomain() {
      return (query) => {
        return this.ignoredList.filter((item) =>
          item.toLowerCase().includes(query.toLowerCase()),
        );
      };
    },
  },
  actions: {
    addIgnored(domain) {
      this.ignoredList.push(domain.toLowerCase());
      this.updateIgnoredList();
    },
    removeIgnored(domain) {
      this.ignoredList = this.ignoredList.filter((item) => item !== domain);
      this.updateIgnoredList();
    },
    toggleIgnore(domain) {
      if (this.isIgnored(domain)) {
        this.removeIgnored(domain);
      } else {
        this.addIgnored(domain);
      }
    },
    initIgnoredList() {
      window.parent.postMessage({ type: 'get_domain' }, '*');
      window.addEventListener('message', (event) => {
        if (event.data.type === 'ignored_websites') {
          this.ignoredList = event.data.list ?? [];
        } else if (event.data.type === 'get_domain') {
          this.parentDomain = event.data.domain;
          this.parentWebsite = event.data.website;
        }
      });
    },
    updateIgnoredList() {
      window.parent.postMessage(
        {
          type: 'set_ignored_websites',
          data: { ignoredWebsites: [...this.ignoredList] },
        },
        '*',
      );
    },
    initExtension() {
      this.initIgnoredList();
    },
  },
});

<template>
  <BittsCard title-text="General Settings" class="organization-settings-card">
    <div class="card-content">
      <BittsSelect
        v-model="preferredCrm"
        form-label="What CRM do you use?"
        dropdown-class-name="organization-settings-card__preferred-crm"
        class="max-w-[350px]"
        :options="crmDropdownContent"
        :has-opt-groups="true"
        :read-only="!hasScope('write:organization')"
        :searchable="false"
        option-type="svg"
        data-testid="preferred-crm-select"
        placeholder="Select CRM"
      />
      <div v-if="hasTempEI" data-testid="fiscal-year-settings">
        <BittsSelect
          v-model="fiscalYearSetting"
          form-label="What is your fiscal year?"
          class="max-w-[350px] mb-8"
          :searchable="false"
          :options="fiscalYearOptions"
          data-testid="fiscal-year-select"
        />
        <p class="text-neutral-text-weak">
          Note: Changes will apply after the next data sync
        </p>
      </div>
      <div class="organization-settings-card__discover">
        <div
          v-for="item in discoverabilityInfo"
          :key="item.title"
          :class="{
            'organization-settings-card__user':
              item.title === 'Make Users Discoverable',
          }"
        >
          <BittsSwitch
            :data-testid="item['data-testid']"
            :disabled="item.isDisabled"
            :initial-value="item.isChecked"
            :is-checked="item.isChecked"
            :use-is-checked-prop="true"
            :label="item.title"
            :description="item.description"
            @change="item.onChange"
          />
        </div>
        <BittsButton
          text="Save Changes"
          size="small"
          data-testid="save-button"
          :disabled="inputsDisabled || !settingsHaveChanged"
          class="self-start"
          @click="saveGeneralSettings"
        />
      </div>
    </div>
  </BittsCard>
</template>

<script setup>
import {
  BittsButton,
  BittsCard,
  BittsSelect,
  BittsSwitch,
} from '@crossbeam/bitts';

import axios from 'axios';
import { computed, ref } from 'vue';

import useAuth from '@/composables/useAuth';
import useEcosystemInsights from '@/composables/useEcosystemInsights';
import usePreferredCrm from '@/composables/usePreferredCrm';
import { useFlashesStore } from '@/stores';
import { useRootStore } from '@/stores/RootStore';
import urls from '@/urls';

const rootStore = useRootStore();
const flashesStore = useFlashesStore();

const { currentOrg, hasScope } = useAuth();

const { hasTempEI } = useEcosystemInsights();

const orgAndUsersDiscoverable = computed(
  () => currentOrg.value.is_discoverable && currentOrg.value.users_discoverable,
);

const inputsDisabled = computed(() => !hasScope('write:organization'));

const discoverability = ref(currentOrg.value.is_discoverable ?? false);
const userDiscoverability = ref(orgAndUsersDiscoverable.value ?? false);
const fiscalYearSetting = ref(currentOrg.value.fiscal_year_start ?? 1);

const fiscalYearSettingHasChanged = computed(() => {
  if (!currentOrg.value.fiscal_year_start && fiscalYearSetting.value === 1)
    return false;
  return fiscalYearSetting.value !== currentOrg.value.fiscal_year_start;
});

const preferredCrmSettingHasChanged = computed(() => {
  if (!currentOrg.value.preferred_crm && !preferredCrm.value) return false;
  return preferredCrm.value !== currentOrg.value.preferred_crm;
});

const settingsHaveChanged = computed(() => {
  return (
    discoverability.value !== currentOrg.value.is_discoverable ||
    userDiscoverability.value !== orgAndUsersDiscoverable.value ||
    preferredCrmSettingHasChanged.value ||
    (hasTempEI.value && fiscalYearSettingHasChanged.value)
  );
});

const discoverabilityInfo = computed(() => {
  return [
    {
      title: 'Discoverable',
      'data-testid': 'org-discoverability',
      description:
        "Allow other companies to see that you're on Crossbeam and send partnership requests.",
      isChecked: discoverability.value,
      isDisabled: inputsDisabled.value,
      onChange: (checked) => {
        discoverability.value = checked;
        if (!checked && userDiscoverability.value) {
          userDiscoverability.value = false;
        }
      },
    },
    {
      title: 'Make Users Discoverable',
      'data-testid': 'user-discoverability',
      description:
        'Allow users with manage partnership permissions to receive partnership requests directly',
      isChecked: userDiscoverability.value,
      isDisabled: inputsDisabled.value || !discoverability.value,
      onChange: (value) => {
        userDiscoverability.value = discoverability.value ? value : false;
      },
    },
  ];
});

const fiscalYearLabels = [
  'January - December',
  'February - January',
  'March - February',
  'April - March',
  'May - April',
  'June - May',
  'July - June',
  'August - July',
  'September - August',
  'October - September',
  'November - October',
  'December - November',
];

const fiscalYearOptions = computed(() =>
  fiscalYearLabels.map((label, index) => ({
    label,
    value: index + 1,
  })),
);

async function saveGeneralSettings() {
  try {
    const payload = {
      is_discoverable: discoverability.value,
      users_discoverable: userDiscoverability.value,
      preferred_crm: preferredCrm.value,
    };

    if (hasTempEI.value) {
      payload.fiscal_year_start = fiscalYearSetting.value;
    }

    await axios.patch(urls.org.patch, payload);
    await rootStore.loadUserProfile({ initialLogin: false });
    flashesStore.addSuccessFlash({ message: 'Changes Saved' });
  } catch (xhr) {
    flashesStore.addUnhandledError(xhr);
  }
}

/* Preferred CRM Selection */
const { crmDropdownContent, preferredCrm } = usePreferredCrm();
</script>

<style scoped lang="pcss">
.organization-settings-card__discover {
  @apply flex flex-col gap-24;
}
.organization-settings-card__discover-info {
  @apply flex flex-col;
}
.organization-settings-card__discover-section {
  @apply flex justify-between items-center;
}
.organization-settings-card__user {
  @apply pl-24 border-l border-solid border-neutral-border;
}

h3 {
  @apply font-bold text-neutral-text-strong mb-4;
}

p {
  @apply text-neutral-text text-sm;
}
</style>

<style lang="pcss">
.organization-settings-card {
  .card-content {
    @apply px-16 pb-16 flex flex-col gap-24;
  }
  .bitts-card__title {
    @apply text-lg text-neutral-text-strong font-bold;
  }
  .c-bitts-divider {
    @apply border-neutral-border;
  }
}
.organization-settings-card__preferred-crm {
  .ant-select-item-option {
    @apply mx-4;
  }
}
</style>

<template>
  <div class="c-toasts md:w-auto">
    <TransitionGroup name="bitts-toasts" tag="div">
      <BittsToast
        v-for="flash in flashesWithAcceptance"
        :key="flash.uuid"
        :action="flash.info.action"
        :type="flash.category"
        :message="flash.info.message"
        :description="flash.info.description"
        :hide-icon="Boolean(flash.info.link)"
        class="toast-anim"
        @dismiss-toast="dismissFlash(flash)"
      >
        <template v-if="flash.info.link" #link>
          <a
            :href="flash.info.link.url"
            class="text-white border-b border-solid border-white opacity-75"
            >{{ flash.info.link.text }}</a
          >.
        </template>
      </BittsToast>
    </TransitionGroup>
  </div>
</template>

<script>
import { BittsToast } from '@crossbeam/bitts';

import { mapActions, mapState } from 'pinia';

import { useFlashesStore } from '@/stores';

export default {
  name: 'Toasts',
  components: { BittsToast },
  computed: {
    ...mapState(useFlashesStore, ['flashes']),
    flashesWithAcceptance() {
      return this.flashes.map((flash) => {
        if (!flash.accepted) {
          this.acceptFlash(flash);
        }
        return flash;
      });
    },
  },
  methods: {
    ...mapActions(useFlashesStore, ['acceptFlash', 'dismissFlash']),
  },
};
</script>
<style lang="pcss">
.c-toasts {
  top: 118px;
  position: fixed;
  z-index: 1036; /* Go above bitts modal */
  @apply w-full md:w-[480px];
}
@media (min-width: 768px) {
  .c-toasts {
    right: 40px;
  }
}
.toast-anim {
  transition: margin-top 0.25s;
  &:first-child {
    margin-top: -45px;
  }
}
.bitts-toasts-enter {
  transform: translateX(40px);
}
.bitts-toasts-leave-to {
  transform: translateY(-40px);
}

.bitts-toasts-enter-active,
.bitts-toasts-leave-active {
  transition: all 0.5s;
}
.bitts-toasts-enter,
.bitts-toasts-leave-to {
  opacity: 0;
}
</style>

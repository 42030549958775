<template>
  <BittsCard :no-padding="false" class="c-incoming-share-request-card">
    <div class="flex w-full justify-between">
      <div class="flex flex-col mr-16 gap-2">
        <BittsAvatarItem size="small" :title :user :is-own="false" />
        <p class="text-neutral-text text-sm" data-testid="message">
          {{ incomingShareRequest?.message }}
        </p>
        <p class="text-neutral-text-weak text-xs" data-testid="timeSent">
          Requested {{ timeSent }}
        </p>
      </div>
      <div class="flex gap-8 items-center">
        <BittsButton
          text="Ignore"
          size="x-small"
          type="danger"
          variant="ghost"
          @click="ignoreRequest"
        />
        <BittsButton
          text="Edit sharing"
          size="x-small"
          @click="emits('clicked-edit-sharing')"
        />
      </div>
    </div>
  </BittsCard>
</template>
<script setup>
import { BittsAvatarItem, BittsButton, BittsCard } from '@crossbeam/bitts';

import { DateTime } from 'luxon';
import { computed } from 'vue';

import { timeSince } from '@/date_time_utils';
import { useDataRequestsStore } from '@/stores';

const props = defineProps({
  incomingShareRequest: {
    type: Object,
    default: () => {},
  },
});

const emits = defineEmits(['clicked-edit-sharing', 'ignored-data-request']);

const dataRequestsStore = useDataRequestsStore();
async function ignoreRequest() {
  await dataRequestsStore.ignoreShareRequest(
    props.incomingShareRequest.id.toString(),
  );
  await dataRequestsStore.refreshDataRequestsStore();
  emits('ignored-data-request');
}

const userName = computed(() =>
  props.incomingShareRequest?.user_first_name &&
  props.incomingShareRequest.user_last_name
    ? `${props.incomingShareRequest.user_first_name} ${props.incomingShareRequest.user_last_name}`
    : `${props.incomingShareRequest.user_email}`,
);
const title = computed(
  () => `${userName.value} requested data from this population`,
);
const user = computed(() => ({
  email: props.incomingShareRequest?.user_email,
}));

const timeSent = computed(() => {
  const date = DateTime.fromISO(props.incomingShareRequest?.created_at);
  return date.isValid ? timeSince(date) : '';
});
</script>
<style scoped lang="pcss">
.c-incoming-share-request-card {
  @apply bg-info-background-weak border-none mt-16 p-12;
}
</style>

import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { HUBSPOT_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import { initStore } from '@/stores/store-utils';
import urls from '@/urls';

export const useDataSourcesStore = defineStore('DataSources', () => {
  const dataSources = ref([]);

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    const response = await axios.get(urls.dataSources.all);
    dataSources.value = response.data.items;
  });

  refresh({ useCache: true });

  function getDataSource(type) {
    return dataSources.value.find((opt) => opt.type === type);
  }

  const supportedDataSources = computed(() =>
    filterDataSources(dataSources.value, true),
  );
  const unsupportedDataSources = computed(() => {
    return filterDataSources(dataSources.value, false).filter((dataSource) => {
      if (dataSource.type === HUBSPOT_DATA_SOURCE_TYPE) return false;
      return true;
    });
  });
  const hasMultipleDataSources = computed(() => dataSources.value.length === 1);
  const getOneDataSource = computed(() =>
    dataSources.value.length ? dataSources.value[0] : [],
  );

  return {
    error,
    ready,
    readySync,
    running,
    refreshDataSourcesStore: refresh,
    dataSources,
    getDataSource,
    supportedDataSources,
    unsupportedDataSources,
    hasMultipleDataSources,
    getOneDataSource,
  };
});

const filterDataSources = (dataSources, supported) => {
  return dataSources.filter((ds) =>
    supported ? ds.is_supported : !ds.is_supported,
  );
};

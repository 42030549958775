<template>
  <div
    :class="cardClasses"
    class="bitts-checkbox-card"
    @click="clickableWrapper && clickHandler()"
  >
    <div class="flex items-center">
      <div
        :class="{
          'mt-2': !description && bittsCheckboxUpgrade,
        }"
        class="mr-12"
      >
        <BittsCheckbox
          :checked="checked"
          :default-checked="defaultChecked"
          :disabled="disabled"
          @input="!clickableWrapper && clickHandler()"
        />
      </div>
      <div class="bitts-checkbox__content">
        <slot name="label">
          <p>{{ label }}</p>
        </slot>
        <div
          v-if="description"
          class="bitts-checkbox__description"
          v-html="description"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import BittsCheckbox from '../BittsCheckbox/BittsCheckbox.vue';

interface Props {
  bittsCheckboxUpgrade?: boolean;
  clickableWrapper?: boolean;
  defaultChecked?: boolean;
  description?: string;
  disabled?: boolean;
  label: string;
}

const props = withDefaults(defineProps<Props>(), {
  bittsCheckboxUpgrade: false,
  clickableWrapper: true,
  defaultChecked: false,
  description: '',
  disabled: false,
  label: '',
});

const checked = ref(props.defaultChecked);

const cardClasses = computed(() => {
  return {
    'cursor-pointer': props.clickableWrapper && !props.disabled,
    'bitts-checkbox-card--disabled': props.disabled,
  };
});

const emit = defineEmits(['input']);
function clickHandler() {
  if (props.bittsCheckboxUpgrade && props.disabled) return;
  checked.value = !checked.value;
  emit('input', checked.value);
}
</script>

<style lang="pcss">
.bitts-checkbox-card {
  @apply border border-neutral-border rounded-bts-md px-12 py-8;

  .bitts-checkbox__content {
    @apply text-base leading-6 text-neutral-text-strong;
  }

  &:hover:not(.bitts-checkbox-card--disabled) {
    @apply bg-neutral-background-disabled bg-blend-overlay bg-opacity-[0.24];
    &:not(.ant-checkbox-checked):not(.ant-checkbox-disabled) {
      .ant-checkbox-inner {
        @apply border-2 border-info-accent;
      }
    }
    &:not(.ant-checkbox-disabled) {
      &:focus,
      :focus-within {
        .ant-checkbox-inner {
          @apply border-2 border-neutral-border-focus;
        }
      }
    }
  }

  .bitts-checkbox {
    @apply mb-4;
  }

  &:focus,
  :focus-within {
    &:not(.ant-checkbox-disabled) {
      .ant-checkbox-inner {
        @apply border-2 border-neutral-border-focus;
      }
    }
  }

  .bitts-checkbox__description {
    @apply text-sm text-neutral-text leading-4;
  }

  &.bitts-checkbox-card--disabled {
    @apply cursor-not-allowed opacity-50;
    &:hover {
      @apply border-neutral-border;
    }
    .ant-checkbox-disabled {
      .ant-checkbox-inner {
        @apply opacity-100;
      }
    }
  }

  a {
    @apply text-brand-blue underline;
  }
}
</style>

import { orderBy } from 'lodash';
import { storeToRefs } from 'pinia';

import { HIDDEN, PARTNER_POPULATION_RULE } from '@/constants/data_shares';
import { MDM_TYPES } from '@/constants/mdm';
import {
  useDataSharesStore,
  usePartnersStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';

export default function usePopulations() {
  const populationsStore = usePopulationsStore();
  const sourcesStore = useSourcesStore();
  const dataSharesStore = useDataSharesStore();
  const partnersStore = usePartnersStore();

  const { getPartnerPopulationById } = storeToRefs(populationsStore);
  const { outgoingSharingRuleMap } = storeToRefs(dataSharesStore);
  const { partnerOrgs } = storeToRefs(partnersStore);

  function addMetadataToPopulation(pop, partnerId = null) {
    let metadata = { ...pop, ...getDefaultUsage(pop) };
    if (partnerId)
      metadata = { ...metadata, ...getPartnerMetadata(pop, partnerId) };
    else {
      const { populationSetting } = getPopAndPartnerSettings(pop.id);
      metadata = { ...metadata, ...copySourceFieldIds(populationSetting) };
    }
    return metadata;
  }

  function sourcesWithFields(sourceFields = [], isPartner = false) {
    const MDM_TYPES_IN_ORDER = [
      MDM_TYPES.ACCOUNT,
      MDM_TYPES.LEAD,
      MDM_TYPES.USER,
      MDM_TYPES.CONTACT,
      MDM_TYPES.DEAL,
    ];
    const sourcesById = sourceFields.reduce((sourceMap, sourceField) => {
      // if its partner, its the whole field. otherwise its the id
      const field = isPartner
        ? sourceField
        : sourcesStore.getSourceFieldById(sourceField);
      const sourceId = field?.source_id;
      if (!sourceId) return sourceMap;
      if (!sourceMap[sourceId]) {
        sourceMap[sourceId] = {
          fields: [],
          source: isPartner ? field : sourcesStore.getSourceById(sourceId),
        };
      }
      sourceMap[sourceId].fields.push(field);
      return sourceMap;
    }, {});
    const sources = Object.keys(sourcesById);
    const sourceFieldsByMdmType = sources.reduce((mdmTypeMap, sourceId) => {
      if (!sourceId || !sourcesById[sourceId]?.source) return mdmTypeMap;
      const mdmType = sourcesById[sourceId].source.mdm_type;
      if (!mdmTypeMap[mdmType]) {
        const fields = [...sourcesById[sourceId].fields];
        mdmTypeMap[mdmType] = {
          fields: orderBy(fields, [
            (field) => field.display_name.toLowerCase(),
          ]),
          table: sourcesById[sourceId].source.table,
        };
      }
      return mdmTypeMap;
    }, {});
    return MDM_TYPES_IN_ORDER.reduce((sourcesWithFieldsInOrder, mdmType) => {
      if (sourceFieldsByMdmType[mdmType])
        sourcesWithFieldsInOrder.push(sourceFieldsByMdmType[mdmType]);
      return sourcesWithFieldsInOrder;
    }, []);
  }

  function getPopAndPartnerSettings(popId, partnerId) {
    const partnerSetting = outgoingSharingRuleMap.value.partner?.[
      partnerId
    ]?.find((share) => share.population_id === popId);
    const populationSetting =
      outgoingSharingRuleMap.value.population?.[popId]?.find(
        (share) => share.population_id === popId,
      ) || POP_SETTING_DEFAULT;
    return { partnerSetting, populationSetting };
  }

  function getPopulationToPopulationSettings(population, partnerId) {
    const overrides = populationToPopulationOverrides(population.id, partnerId);
    return overrides.map((dataShare) => {
      return {
        partnerPopulation: getPartnerPopulationById.value(
          dataShare.partner_population_id,
        ),
        ...copySourceFieldIds(dataShare),
      };
    });
  }

  function getDefaultUsage(population) {
    const partnerOverrides = outgoingSharingRuleMap.value.partner || {};
    const partnersWithOverrides = Object.keys(partnerOverrides).reduce(
      (partners, id) => {
        const containsOverrides = partnerOverrides[id].some(
          (share) => share.population_id === population.id,
        );
        if (containsOverrides) partners.push(id);
        return partners;
      },
      [],
    );
    const partnersUsingDefaults = partnerOrgs.value.filter(
      (org) => !partnersWithOverrides.includes(org.id.toString()),
    );
    return { partnersUsingDefaults };
  }

  const POP_SETTING_DEFAULT = {
    dataShare: { visibility: HIDDEN },
    source_field_ids: [],
    visibility: HIDDEN,
  };

  function getPartnerMetadata(population, partnerId) {
    const { partnerSetting, populationSetting } = getPopAndPartnerSettings(
      population.id,
      partnerId,
    );
    const populationToPopulationSettings = getPopulationToPopulationSettings(
      population,
      partnerId,
    );
    const appliedSetting =
      partnerSetting || populationSetting || POP_SETTING_DEFAULT;
    const { dataShare, selectedSourceFieldIds, sourceFields, visibilityText } =
      copySourceFieldIds(appliedSetting);
    return {
      dataShare,
      partnerSetting,
      populationSetting,
      populationToPopulationSettings,
      selectedSourceFieldIds,
      sourceFields,
      visibilityText,
    };
  }

  function populationToPopulationOverrides(populationId, partnerId) {
    if (!partnerId) return [];
    const partnerPopOverrides =
      outgoingSharingRuleMap.value[PARTNER_POPULATION_RULE];
    if (!partnerPopOverrides) return [];
    const partnerPopOverridesList = Object.values(partnerPopOverrides).flat();
    return partnerPopOverridesList.filter(
      (override) =>
        override.partner_org_id === partnerId &&
        override.population_id === populationId,
    );
  }

  function copySourceFieldIds(dataShare = {}) {
    const selectedSourceFieldIds = dataShare.source_field_ids
      ? [...dataShare.source_field_ids]
      : [];
    return { dataShare, selectedSourceFieldIds };
  }

  return {
    addMetadataToPopulation,
    getDefaultUsage,
    getPopAndPartnerSettings,
    getPopulationToPopulationSettings,
    sourcesWithFields,
  };
}

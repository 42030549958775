<template>
  <Transition
    v-if="withTransition"
    name="fade"
    mode="out-in"
    @after-leave="onTransitionEnd"
  >
    <!-- eslint-disable-next-line -->
    <template #default>
      <div v-if="isLoading" :class="loadingContainerClass" class="c-loading">
        <img :style="{ width: iconWidth }" src="/images/gifs/loading.gif" />
        <span>
          {{ label }}
        </span>
        <span class="c-loading__sub-label">
          {{ subLabel }}
        </span>
      </div>
      <slot v-else />
      <slot name="modal" />
    </template>
  </Transition>
  <div v-else>
    <div v-if="isLoading" :class="loadingContainerClass" class="c-loading">
      <img :style="{ width: iconWidth }" src="/images/gifs/loading.gif" />
      <span>
        {{ label }}
      </span>
      <span class="c-loading__sub-label">
        {{ subLabel }}
      </span>
    </div>
    <slot v-else />
    <slot name="modal" />
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
      default: '',
    },
    iconWidth: {
      type: String,
      default: '64px',
    },
    loadingContainerClass: {
      type: String,
      default: '',
    },
    withTransition: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['transition-ended'],
  methods: {
    onTransitionEnd() {
      this.$emit('transition-ended');
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-loading {
  @apply text-brand-navy;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.c-loading__sub-label {
  @apply text-brand-red;
  font-size: 12px;
}

.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-150 ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
</style>

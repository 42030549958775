<template>
  <div class="c-template-selector">
    <BittsPopover
      placement="right"
      trigger="hover"
      class="c-template-selector__popover"
    >
      <div>
        <button
          data-testid="c-templates-selector"
          :class="{
            active: isActive,
            disabled: !isSatisfied,
          }"
          @click="handleTemplateSelection"
        >
          <div class="flex items-center gap-8 w-full">
            <div class="flex-1">
              <div class="c-templates-selector__header">
                <h3 class="c-template-selector__title">
                  {{ template.friendly_name }}
                </h3>
                <BittsTag
                  variant="rounded"
                  size="x-small"
                  :color="
                    isActive
                      ? 'transition-none bg-opacity-10 bg-neutral-text-weak'
                      : 'neutral'
                  "
                >
                  {{ fieldCount }} fields
                </BittsTag>
              </div>
              <div
                v-if="template.summary"
                class="flex flex-row items-center gap-4"
              >
                <FontAwesomeIcon
                  :icon="['fas', 'lock-keyhole-open']"
                  :style="{ height: '8px', width: '7px' }"
                  class="c-template-selector__icon"
                />
                <p class="c-template-selector__summary">
                  <i>{{ template.summary }}</i>
                </p>
              </div>
              <p class="c-template-selector__description">
                {{ template.description }}
              </p>
            </div>
            <FontAwesomeIcon
              :icon="['fas', isActive ? 'check' : 'plus']"
              :style="{ width: '16px', height: '16px' }"
              class="c-template-selector__indicator"
              :class="
                isActive ? 'text-info-accent' : 'text-neutral-text-button'
              "
            />
          </div>
        </button>
      </div>
      <template #content>
        <TemplateTooltipContent
          :template="template"
          :is-satisifed="isSatisfied"
        />
      </template>
    </BittsPopover>
  </div>
</template>
<script setup>
import { BittsPopover, BittsTag } from '@crossbeam/bitts';

import { computed } from 'vue';

import TemplateTooltipContent from '@/components/data-sources/data-templates/TemplateTooltipContent.vue';

const emit = defineEmits(['select']);
const props = defineProps({
  feed: {
    type: Object,
    required: true,
  },
  template: {
    type: Object,
    required: true,
  },
  activeTemplates: {
    type: Array,
    required: true,
  },
  satisfiedTemplates: {
    type: Array,
    required: true,
  },
});

const isSatisfied = computed(() =>
  props.satisfiedTemplates.includes(props.template.type),
);
const isActive = computed(() =>
  props.activeTemplates.includes(props.template.type),
);

const fieldCount = computed(() => {
  return Object.values(props.template[props.feed.integration.type]).reduce(
    (acc, fields) => {
      acc.push(...fields);
      return acc;
    },
    [],
  ).length;
});

function handleTemplateSelection() {
  if (!isSatisfied.value || isActive.value) return;
  emit('select', { type: props.template.type });
}
</script>

<style lang="pcss" scoped>
.c-template-selector button {
  @apply bg-neutral-background-weak flex items-center justify-between p-8 pl-12 rounded-8 w-full;
  @apply border-2 border-neutral-background-weak cursor-default;

  /* On hover for non-active, non-disabled templates, apply blue colors */
  &:hover:not(.disabled):not(.active) {
    @apply border-info-accent cursor-pointer;

    .c-template-selector__indicator {
      @apply text-info-accent !important;
    }
  }
}

.c-template-selector button.active {
  @apply bg-info-background-weak border-info-background-weak;
}

.c-templates-selector__header {
  @apply flex items-center gap-8;
}

.c-template-selector__title {
  @apply text-neutral-text-strong font-bold text-left normal-case;
}

.c-template-selector__description {
  @apply text-left text-sm text-neutral-600;
}

.c-template-selector__summary {
  @apply text-left text-sm text-neutral-500;
}
.c-template-selector__icon {
  @apply text-left text-sm text-neutral-400;
}

.c-template-selector__indicator {
  @apply mr-12;
}
</style>

<style lang="pcss">
/* Set a max width on the actual popover component and remove border spacing */
.c-template-selector__popover {
  .ant-popover {
    @apply z-[1037] max-w-[300px];
    .ant-popover-inner {
      @apply border-none;
    }
  }
}
</style>

<template>
  <div :class="getClasses" class="bitts-input-select">
    <BittsFormLabel
      v-if="formLabel"
      class="bitts-input-select__label"
      :label="formLabel"
      :disabled="disabled"
    />
    <multiselect
      v-bind="dynamicProps()"
      v-model="selectedOptions"
      class="bitts-input-select__input"
      :disabled="disabled"
      :open-direction="openDirection"
      :options="options"
      :hide-selected="hideSelected"
      :multiple="multiple"
      :label="label"
      :taggable="taggable"
      :searchable="searchable"
      :internal-search="internalSearch"
      :loading="isLoadingOptions"
      :placeholder="placeholder"
      :preserve-search="preserveSearch"
      :tag-position="tagPosition"
      :track-by="trackBy"
      :allow-empty="allowEmpty"
      @tag="addTag"
      @input="onInput"
      @search-change="onSearchChange"
      @close="(val) => onClose(val)"
    >
      <template #noOptions>
        {{ noOptionsText }}
      </template>

      <template #singleLabel="props">
        <div v-if="getSvgName" class="flex items-center pr-8">
          <BittsSvg :svg="getSvgName(props.option)" class="w-16 h-16" />
          <div class="ml-8">
            {{ props.option.name }}
          </div>
        </div>
        <div v-else-if="integrationIcon" class="flex items-center">
          <div v-if="integrationType(props.option)">
            <img
              :src="integrationType(props.option)"
              :height="16"
              :width="16"
            />
          </div>

          <div class="ml-8">
            {{ props.option.name }}
          </div>

          <div
            v-if="
              props.option.displayName &&
              showSfUrl &&
              props.option.name === 'Salesforce'
            "
            class="salesforce-url"
          >
            {{ props.option.displayName }}
          </div>
        </div>

        <div v-else class="flex items-center">
          <BittsAvatar
            v-if="hasAvatarOptions"
            v-bind="orgOrUserProps(props.option)"
            :size="avatar.size"
            :shape="avatar.shape"
            class="mr-8 mb-3"
          />

          <span class="bitts-input-select__simple-label">
            {{ getOptionText(props.option) }}
          </span>
        </div>
      </template>

      <template #clear>
        <button
          v-if="showClear"
          class="bitts-input-select__clear-selection-btn"
          @mousedown.prevent.stop="clear"
        >
          <FontAwesomeIcon
            :icon="['far', 'circle-xmark']"
            :style="{
              height: '12px',
              width: '12px',
              color: 'currentColor',
              'vertical-align': 'middle',
              display: 'block',
            }"
            aria-hidden="true"
            class="bitts-input-select__clear-selection-icon text-neutral-border"
          />
        </button>
      </template>
      <template #option="props">
        <div v-if="getSvgName" class="flex items-center pr-8">
          <BittsSvg :svg="getSvgName(props.option)" :height="16" :width="16" />
        </div>
        <div v-else-if="integrationIcon" class="pl-12">
          <div v-if="integrationType(props.option)">
            <img
              :src="integrationType(props.option)"
              :height="16"
              :width="16"
            />
          </div>

          <FontAwesomeIcon
            v-if="isAction(props.option)"
            :icon="['fas', 'plus']"
            :style="{ width: '18px', color: 'currentColor' }"
            class="text-neutral-accent"
          />
        </div>

        <div v-else-if="hasAvatarOptions" class="flex items-center mr-8">
          <BittsAvatar
            v-bind="orgOrUserProps(props.option)"
            :key="
              props.option.uuid ||
              props.option.id ||
              props.option.name ||
              props.option.label
            "
            :size="avatar.size"
            :shape="avatar.shape"
          />
        </div>

        <BittsCheckbox
          v-else-if="showCheckbox"
          :checked="getCheckboxValue(props.option)"
        />

        <!-- not using label prop in bitts-checkbox as it doesn't play nice with multiselect -->
        <span v-if="!tagHelpText">{{ getOptionText(props.option) }}</span>

        <div v-else class="flex justify-between w-full">
          <div :class="props.option.isTag && 'bitts-input-select__option-text'">
            {{ getOptionText(props.option) }}
          </div>

          <div v-if="props.option.isTag" class="bitts-input-select__help-text">
            {{ tagHelpText }}
          </div>
        </div>
        <div v-if="props.option.tag" class="flex justify-end w-full">
          <BittsTag v-bind="props.option.tag" size="x-small">
            {{ props.option.tag.text }}
          </BittsTag>
        </div>
        <span
          v-if="useSecondaryText && !props.option.isTag"
          :class="secondaryTextClass"
        >
          {{ useSecondaryText(props.option) }}
        </span>

        <span
          v-if="props.option.name === 'Salesforce' && showSfUrl"
          class="salesforce-url--option"
        >
          {{ props.option.displayName }}
        </span>

        <FontAwesomeIcon
          v-if="props.option.$isDisabled && props.option.loading"
          :icon="['fa', 'spinner']"
          :style="{ height: '18px;', width: '18px', color: 'currentColor' }"
          class="mr-8 animate-spin"
        />
      </template>

      <template #tag="props">
        <BittsChip
          :danger="validator && !validator(getOptionText(props.option))"
          :text="getOptionText(props.option)"
          :org="getOptionOrg(props.option)"
          class="mr-4"
          :clickable-icon="copyTagContent"
          @click="copyTagContent ? clickAndCopy(props) : onTagRemove(props)"
          @icon-click="onTagRemove(props)"
        />
      </template>

      <template #caret="{ toggle }">
        <span @mousedown.prevent.stop="toggle">
          <FontAwesomeIcon
            :icon="['fas', 'chevron-down']"
            :style="{ height: '8px', width: '8px', color: 'currentColor' }"
            class="bitts-input-select__clear-selection-btn bitts-input-select__caret text-neutral-text-placeholder"
          />
        </span>
      </template>

      <template #noResult>
        {{ noResultText }}
      </template>
      <template #afterList>
        <slot name="button" />
      </template>
    </multiselect>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { toRaw } from 'vue';
import { Multiselect } from 'vue-multiselect';

import BittsAvatar from '../BittsAvatar/BittsAvatar.vue';
import BittsCheckbox from '../BittsCheckbox/BittsCheckbox.vue';
import BittsChip from '../BittsChip/BittsChip.vue';
import BittsFormLabel from '../BittsFormLabel/BittsFormLabel.vue';
import BittsSvg from '../BittsSvg/BittsSvg.vue';
import BittsTag from '../BittsTag/BittsTag.vue';

import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  name: 'BittsInputSelect',
  components: {
    Multiselect,
    BittsAvatar,
    BittsFormLabel,
    BittsChip,
    BittsCheckbox,
    BittsTag,
    FontAwesomeIcon,
    BittsSvg,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    // prevent search from clearing when dropdown is closed
    preserveSearch: {
      type: Boolean,
      default: false,
    },
    openDirection: {
      type: String,
      default: 'bottom',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initialSelectedOptions: {
      type: Array,
      default: () => [],
    },
    initialSingleOption: {
      type: Object,
      default: () => {},
    },
    clearOnInput: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    hasRawLabel: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    showSfUrl: {
      type: Boolean,
      default: false,
    },
    hideClear: {
      type: Boolean,
      default: false,
    },
    internalSearch: {
      type: Boolean,
      default: true,
    },
    isLoadingOptions: {
      type: Boolean,
      default: false,
    },
    hideSelected: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    getSvgName: {
      type: Function,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    tagPosition: {
      type: String,
      default: 'top',
    },
    trackBy: {
      type: String,
      default: '',
    },
    addTag: {
      type: Function,
      default: () => {},
    },
    integrationIcon: {
      type: Boolean,
      default: false,
    },
    hasAvatarOptions: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    validator: {
      type: Function,
      default: null,
    },
    noResultText: {
      type: String,
      default: 'No results found. Try searching for something else',
    },
    noOptionsText: {
      type: String,
      default: 'List is empty.',
    },
    label: {
      type: String,
      default: '',
    },
    formLabel: {
      type: [Object, String],
      default: '',
    },
    useDropdownValue: {
      type: Boolean,
      default: false,
    },
    dropdownValue: {
      type: [Array, Object],
      default: null,
    },
    avatar: {
      type: Object,
      default() {
        return { shape: 'circle', size: 'x-small' };
      },
      validator(obj) {
        // The avatar object must include valid
        // values for shape and size
        return (
          ['shape', 'size'].every((value) =>
            Object.keys(obj).includes(value),
          ) &&
          ['large', 'medium', 'small', 'x-small'].includes(obj.size) &&
          ['circle', 'square'].includes(obj.shape)
        );
      },
    },
    allowCustomOption: {
      type: Boolean,
      default: false,
    },
    customOptionLabel: {
      type: Function,
      default(option) {
        return option.label;
      },
    },
    // for when we want an additional key (not the label) to show up in the option
    // AND we want it styled differently from the label
    useSecondaryText: {
      type: Function,
      default: null,
    },
    secondaryTextClass: {
      type: String,
      default: '',
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    tagHelpText: {
      type: String,
      default: '',
    },
    copyTagContent: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'selected-options-change',
    'clear',
    'input',
    'search-change',
    'close',
  ],
  data() {
    return {
      selectedOptions: [],
    };
  },
  computed: {
    getClasses() {
      let classes = '';
      if (this.rounded) {
        classes += 'bitts-input-select__rounded ';
      }
      if (this.disabled) {
        classes += 'cursor-not-allowed';
      }
      return classes;
    },
    hasSelectedOptions() {
      if (Array.isArray(this.selectedOptions)) {
        return this.selectedOptions.length;
      }
      return this.selectedOptions;
    },
    showClear() {
      return (
        !this.disabled &&
        ((!this.taggable && this.hasSelectedOptions && !this.hideClear) ||
          (this.taggable && this.allowCustomOption && this.hasSelectedOptions))
      );
    },
  },
  watch: {
    selectedOptions(val) {
      this.$emit('selected-options-change', val);
    },
    dropdownValue(val) {
      // having lots of issues generally with this component and v-model
      // most of the time we can just use user input/initial input to solve these
      // but if the input needs to be manually cleared not by the user it poses an issue
      // so I added this dropdownValue/useDropdownValue prop to act as that while
      // not being disruptive to other places where we use this component
      if (this.useDropdownValue) {
        this.selectedOptions = val;
      }
    },
  },
  created() {
    this.selectedOptions =
      this.initialSingleOption || this.initialSelectedOptions;
  },
  methods: {
    copyTextFallback(textToCopy) {
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;

      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      document.execCommand('copy');
      document.body.removeChild(textArea);
    },
    onClose(val) {
      this.$emit('close', val);
    },
    isAction({ name }) {
      if (name === 'Add New Data Source') return true;
      return false;
    },
    dynamicProps() {
      const props = {
        closeOnSelect: false,
      };

      if (!this.multiple || this.closeOnSelect) {
        props.closeOnSelect = true;
      }

      return props;
    },
    orgOrUserProps(orgOrUser) {
      return orgOrUser.first_name
        ? {
            user: orgOrUser,
            showInitials: true,
          }
        : {
            org: orgOrUser,
          };
    },
    clear() {
      this.selectedOptions = [];
      this.$emit('clear');
    },
    integrationType(options) {
      if (this.integrationIcon && options.data) {
        let name = toRaw(options.data.integration).type;
        if (name === 'hubspot_v3') name = 'hubspot';
        return new URL(
          `../../../assets/svg/data-sources/${name}-icon.svg`,
          import.meta.url,
        ).href;
      }
    },
    onInput(input) {
      this.$emit('input', input);
      if (this.clearOnInput) {
        this.clear();
      }
    },
    onSearchChange(searchQuery) {
      this.$emit('search-change', searchQuery);
    },
    getOptionText(option) {
      if (option.isTag && option.label && this.allowCustomOption) {
        if (this.hasRawLabel) return option.label;
        return this.customOptionLabel(option.label);
      }
      return option.name ? option.name : option;
    },
    getOptionOrg(option) {
      return this.hasAvatarOptions ? option : null;
    },
    getCheckboxValue(option) {
      if (this.selectedOptions && Array.isArray(this.selectedOptions)) {
        return !!this.selectedOptions.find((item) => item.name === option.name);
      } else if (this.selectedOptions && !this.multiple) {
        return this.selectedOptions.name === option.name;
      }
      return false;
    },
    onTagRemove(props) {
      props.remove(props.option);
    },
    clickAndCopy(props) {
      let valToCopy;
      if (typeof props.option === 'string') {
        valToCopy = props.option;
      }
      if (typeof props.option === 'object') {
        valToCopy = props.option.name || null;
      }
      if (valToCopy) this.copyToClipBoard(valToCopy);
    },
    async copyToClipBoard(textToCopy) {
      if (!navigator.clipboard) {
        this.copyTextFallback(textToCopy);
        return;
      }
      await navigator.clipboard.writeText(textToCopy);
    },
  },
};
</script>

<style lang="pcss">
.bitts-input-select {
  .salesforce-url {
    @apply text-neutral-text-weak text-sm pl-16 mr-0 pr-0 flex-1 text-right;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .salesforce-url--option {
    @apply text-neutral-text-weak text-sm pl-4 flex-1 text-right;
    max-width: 180px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .multiselect--active .bitts-input-select__caret {
    transform: rotate(180deg);
  }
  .multiselect__tag {
    display: inline-flex;
    align-items: center;
    margin-bottom: 8px !important;
    border-radius: 16px;
    @apply text-neutral-text-strong border border-blue-100 text-sm bg-neutral-background;
  }

  .multiselect__tags {
    @apply border-2 border-neutral-background-disabled;
    border-radius: 6px;
  }

  .multiselect__single {
    @apply text-base p-0;
  }

  .multiselect__input {
    font-size: 14px !important;
    padding: 0 !important;
  }

  .multiselect__placeholder {
    margin: 0 !important;
    padding: 0 !important;
    @apply text-neutral-text-placeholder;
  }

  .multiselect__tag-icon {
    font-weight: 300;
  }

  .multiselect__tag-icon:hover {
    background: none;
  }

  .multiselect__tag-icon:after {
    font-size: 18px;
    @apply text-brand-blue;
  }

  .multiselect__tag-icon:hover:after {
    @apply text-brand-blue;
  }

  .multiselect__option {
    padding: 0 8px;
    margin: 8px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    @apply text-neutral-text-strong text-base font-normal;
  }

  .multiselect__option--highlight {
    @apply bg-primary-background-weak text-neutral-text-strong;

    &::after {
      display: none;
    }
  }

  .multiselect__option--selected {
    @apply text-neutral-text-strong;
    background: none !important;

    &.multiselect__option--highlight {
      @apply text-neutral-text-strong;

      &:hover {
        @apply text-neutral-text-strong;
      }
    }

    &::after {
      display: none;
    }
  }

  /* Position loading spinner if present */
  .multiselect__option.multiselect__option--disabled {
    @apply flex justify-between;
  }

  /* carrot */
  .multiselect__select {
    display: none;
  }

  .bitts-checkbox {
    .ant-checkbox-wrapper .ant-checkbox {
      @apply mb-4;
    }
    margin-right: 12px;
  }

  .bitts-chip {
    margin-bottom: 8px;
  }

  .bitts-input-select__clear-selection-btn {
    position: absolute;
    z-index: 10;
    top: 14px;
    right: 32px;
    .bitts-input-select__clear-selection-icon {
      @apply text-neutral-border;
    }
  }
  .bitts-input-select__caret {
    top: 16px;
    right: 12px;
  }

  .multiselect__content-wrapper {
    @apply border-2 border-solid border-neutral-background-disabled rounded-md my-4;
  }

  .multiselect--active .multiselect__current,
  .multiselect--active .multiselect__input,
  .multiselect--active .multiselect__tags {
    @apply rounded-md;
  }

  .multiselect--disabled {
    background: none;
    .multiselect__tags,
    .multiselect__single {
      @apply bg-neutral-background;
    }
    .multiselect__placeholder {
      @apply text-neutral-text-strong;
    }
  }
}

.bitts-input-select__rounded {
  .multiselect__tags {
    border-radius: 24px !important;
    border: 1px solid theme(colors.neutral.border) !important;
  }

  .mutliselect__content-wrapper {
    border-top: 1px solid theme(colors.neutral.border) !important;
    border-radius: 5px !important;
  }

  .multiselect__content {
    @apply rounded-t-bts-sm;
  }
}
</style>

import { computed } from 'vue';

import { ADMIN_PANEL } from '@/constants/team';
import { useRootStore } from '@/stores/RootStore';

export default function useAuth() {
  const store = useRootStore();
  const currentAuth = computed(() => store.currentAuth);

  return {
    hasScope: (scope) => store.hasScope(scope),
    hasPermission: (permission) => store.hasPermission(permission),
    resourcePermission: (resource) => store.resourcePermission(resource),
    currentAuth,
    isAdminPanelUser: computed(
      () => currentAuth.value?.authorizer_type === ADMIN_PANEL,
    ),
    authorizations: computed(() => store.authorizations),
    isLoggedIn: computed(() => store.isLoggedIn),
    currentOrg: computed(() => store.currentOrg),
    currentUser: computed(() => store.currentUser),
    teamAndPartnerOrgUsers: computed(() => store.teamAndPartnerOrgUsers),
    userCanTryToLinkAccounts: computed(() => store.userCanTryToLinkAccounts),
    initializationFailed: computed(() => store.initializationFailed),
    isAdmin: computed(() => currentAuth.value?.role.name === 'Admin'),
    inMaintenanceMode: computed(() => !!store.maintenanceModeEvent),
    preferredCRM: computed(() => store.currentOrg?.preferred_crm),
  };
}

<template>
  <BittsModal
    :width="width"
    name="map-new-columns-modal"
    :class="{
      'c-map-new-columns--confirm': isConfirming,
      'c-map-new-columns--form': !isConfirming,
    }"
    :show-buttons="false"
    :visible="true"
    @closed="close"
  >
    <template #title>
      <div class="map-new-columns-modal__title">
        <h1>{{ isConfirming ? 'Confirm your Selections' : 'Map Columns' }}</h1>
      </div>
    </template>
    <template v-if="!isConfirming" #subtitle>
      <div class="map-new-columns-modal__subtitle">
        Map {{ ACCOUNT_OWNER_DISPLAY }} information to create targeted reports
        and communication with your partners.
      </div>
    </template>
    <template #content>
      <div class="c-map-new-columns__content">
        <MapNewColumnsForm
          :source-id="sourceId"
          @confirming="toggleConfirming"
          @success="handleSuccess"
          @cancel="close"
        />
      </div>
    </template>
  </BittsModal>
</template>

<script>
import { BittsModal } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { mapActions } from 'pinia';

import MapNewColumnsForm from '@/components/data-sources/MapNewColumnsForm.vue';

import useAuth from '@/composables/useAuth';
import { EVENT_SITES } from '@/constants/analytics';
import { GOOGLE_SHEETS_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import { ACCOUNT_OWNER_DISPLAY, AO_DISPLAY } from '@/constants/mdm';
import {
  useFeedsStore,
  useFileUploadsStore,
  useFlashesStore,
  useSourcesStore,
} from '@/stores';

export default {
  name: 'MapNewColumns',
  components: {
    MapNewColumnsForm,
    BittsModal,
  },
  props: {
    sourceId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    useHead({
      title: 'Map New Columns - Crossbeam',
    });
    const { hasScope } = useAuth();

    return {
      ACCOUNT_OWNER_DISPLAY,
      hasScope,
    };
  },
  data() {
    return {
      isConfirming: false,
      width: 500,
    };
  },
  computed: {
    isGoogleSheet() {
      const feedId = this.getSourceById(this.sourceId).feed_id;
      const feed = this.getFeedById(feedId);
      return feed?.integration.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE;
    },
  },
  methods: {
    ...mapActions(useFeedsStore, ['refreshFeedsStore', 'getFeedById']),
    ...mapActions(useFileUploadsStore, ['refreshFileUploadsStore']),
    ...mapActions(useFlashesStore, ['addSuccessFlash']),
    ...mapActions(useSourcesStore, ['refreshSourcesStore', 'getSourceById']),
    async handleSuccess({ source }) {
      if (source) {
        this.$iteratively.userConnectedDataSource({
          event_site: EVENT_SITES.FILE_UPLOAD_MODAL,
          data_source_type: 'file_uploads',
          feed_id: source.feed_id.toString(),
          custom_presets: [],
          initial_sync_selection: '',
        });
      }
      this.addSuccessFlash({
        message: `Your ${AO_DISPLAY} information is being mapped. This may take a few minutes.`,
      });

      const promises = [
        this.refreshFeedsStore(),
        this.refreshFileUploadsStore(),
        this.refreshSourcesStore(),
      ];

      await Promise.all(promises);
      this.close();
    },
    close() {
      this.$router.push({ name: 'data-sources' });
    },
    toggleConfirming() {
      this.isConfirming = !this.isConfirming;
      this.width = this.width === 500 ? 400 : 500;
    },
  },
};
</script>

<style lang="pcss">
.c-map-new-columns--form .c-bitts-modal__content {
  @apply pt-24 md:px-40;
}

.c-map-new-columns--form {
  @apply md:p-24;
}

.c-map-new-columns--confirm .c-bitts-modal__header {
  @apply md:px-24;
}

.c-map-new-columns--confirm {
  @apply md:p-12;
}

.map-new-columns-modal__title {
  @apply flex items-center justify-start gap-8;
  @apply text-xl md:text-lg text-neutral-900 font-bold leading-8;
  @apply px-16 pt-16;
}

.map-new-columns-modal__subtitle {
  @apply px-16;
}
</style>

<template>
  <div class="c-team-table-cell-wrapper c-team-table-user-cell">
    <BittsCheckbox
      v-if="showCheckbox"
      :checked="authOrInvite.isChecked"
      class="mr-8 mt-2"
      data-testid="user-checkbox"
      @input="handlePickUser"
    />
    <BittsAvatar
      :class="{
        'ml-24': !!context?.handlePickUser && !authOrInvite.user,
        'losing-access': isSubscriptionCancelled && authOrInvite.losing_access,
      }"
      v-bind="userAvatarProps()"
      class="mr-8 relative"
      size="medium"
    >
      <template v-if="!authOrInvite.user" #icon>
        <FontAwesomeIcon
          :icon="['fas', 'envelope']"
          class="text-neutral-accent"
          :style="{ height: '20px', width: '20px', color: 'currentColor' }"
        />
      </template>
      <template #additional-icon>
        <div
          v-if="
            authOrInvite.losing_access &&
            !(authOrInvite.authorizer_type === ADMIN_PANEL)
          "
          class="downgrade-icon"
        >
          <FontAwesomeIcon
            :icon="['fas', 'arrow-down-from-arc']"
            class="text-white h-10 w-10"
          />
        </div>
      </template>
    </BittsAvatar>
    <div class="c-team-table-user-cell__user-info">
      <BittsTag
        v-if="!authOrInvite.user"
        size="x-small"
        color="neutral"
        variant="rounded"
        class="mr-auto"
      >
        <span class="capitalize text-neutral-text">{{ tag }}</span>
      </BittsTag>
      <strong v-else class="c-team-table-user-cell__name">
        {{ user.first_name }} {{ user.last_name }}
      </strong>
      <BittsPopover :mount-to-body="true" :autoclose="1000">
        <template #content>
          <p class="p-4"> {{ user.email }} copied to clipboard </p>
        </template>
        <button
          data-id="email-copy-trigger"
          :title="user.email"
          @click="copyEmailToClipboard"
        >
          <p class="c-team-table-user-cell__email">
            {{ user.email }}
          </p>
        </button>
      </BittsPopover>
    </div>
  </div>
</template>

<script>
import {
  BittsAvatar,
  BittsCheckbox,
  BittsPopover,
  BittsTag,
} from '@crossbeam/bitts';

import { mapState } from 'pinia';
import { computed } from 'vue';

import useAuth from '@/composables/useAuth';
import { ADMIN_PANEL } from '@/constants/team';
import { useBillingStore } from '@/stores';
import { copyToClipBoard } from '@/utils';

export default {
  name: 'TeamTableUserCell',
  components: {
    BittsTag,
    BittsCheckbox,
    BittsAvatar,
    BittsPopover,
  },
  setup() {
    const { hasScope } = useAuth();
    const canEditUsers = computed(() => hasScope('write:members'));
    return { canEditUsers };
  },
  data() {
    return {
      authOrInvite: null,
      ADMIN_PANEL,
    };
  },
  computed: {
    ...mapState(useBillingStore, ['isSubscriptionCancelled']),
    user() {
      return this.authOrInvite.user ?? { email: this.authOrInvite.email };
    },
    showCheckbox() {
      return (
        !!this.context?.handlePickUser &&
        this.authOrInvite.user &&
        this.canEditUsers
      );
    },
    tag() {
      return this.authOrInvite.is_sso ? 'Invited (SSO)' : 'Invited';
    },
  },
  beforeMount() {
    this.authOrInvite = this.params.data;
    this.context = this.params.context;
  },
  methods: {
    copyEmailToClipboard() {
      copyToClipBoard(this.user.email);
    },
    userAvatarProps() {
      return this.authOrInvite.user
        ? {
            user: this.user,
            isOwn: true,
            showInitials: true,
          }
        : {
            isIcon: true,
            forceCircle: true,
            iconSlotBgColor: 'bg-neutral-background',
          };
    },
    handlePickUser() {
      /* V4 Billing */
      if (this.context?.handlePickUser) {
        this.context?.handlePickUser(this.authOrInvite);
      }
    },
  },
};
</script>

<style lang="pcss">
.c-team-table-user-cell {
  @apply flex items-center;
}

.c-team-table-user-cell__name {
  @apply text-neutral-text-strong text-m cursor-text;
}

.c-team-table-user-cell__email {
  @apply text-neutral-text max-w-[215px] truncate cursor-pointer;
}

.c-team-table-user-cell__user-info {
  @apply flex flex-col leading-[1rem] gap-2;
}

.downgrade-icon {
  @apply bg-warning-accent border-2 border-white rounded-full h-20 w-20 flex items-center justify-center bottom-[-2px] right-[-2px] absolute;
}

.bitts-avatar.downgraded .bitts-avatar--icon {
  @apply border-warning-border border-2;
}
</style>

<template>
  <div class="c-upsell-tooltip">
    <BittsTooltip
      :cta-icon="['fad', 'magic-wand-sparkles']"
      :button-text="props.buttonText || 'Upgrade'"
      overlay-class="c-upsell-tooltip__overlay"
      :mount-to-body="true"
      :placement="props.placement"
      :include-cta="!props.hideUpsell"
      @cta-clicked="
        handleBillingInteraction(props.billingInteraction, props.billingQuery)
      "
    >
      <slot />
      <template #title>
        <slot name="title" />
      </template>
    </BittsTooltip>
  </div>
</template>

<script setup>
import { BittsTooltip } from '@crossbeam/bitts';

import useBilling from '@/composables/useBilling';
import { billingInteractionValidator } from '@/constants/billing';

const props = defineProps({
  buttonText: {
    type: String,
    default: null,
  },
  placement: {
    type: String,
    default: 'bottomLeft',
  },
  billingInteraction: {
    type: Object,
    required: true,
    validator: billingInteractionValidator,
  },
  billingQuery: {
    type: Object,
    default: () => ({}),
  },
  hideUpsell: {
    type: Boolean,
    deafult: false,
  },
});

const { handleBillingInteraction } = useBilling();
</script>

<style lang="pcss">
.c-upsell-tooltip__overlay {
  .ant-tooltip-inner {
    @apply rounded-bts-md text-white p-8 flex flex-col items-stretch gap-8 bg-neutral-background-tooltip;
  }
  span {
    @apply gap-4 flex items-center;
  }
  .c-bitts-tooltip__button {
    @apply w-full;
  }
}
</style>

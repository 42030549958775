import axios from 'axios';
import { storeToRefs } from 'pinia';
import { reactive, toRefs } from 'vue';

import {
  CACHED,
  CACHE_INITIALIZED,
  CACHING_FAILED,
  DEALS_AMOUNT,
  DEALS_COUNT,
  OVERLAPS_WITH,
  OVERLAP_TIME,
  PARTIALLY_CACHED,
  PARTNERSTACK,
  PARTNER_IMPACT,
  PARTNER_POPULATIONS,
  POPULATIONS,
} from '@/constants/reports';
import {
  useBillingStore,
  useFlashesStore,
  usePartnersStore,
  usePopulationsStore,
  useReportsStore,
} from '@/stores';
import urls from '@/urls';

import useIteratively from './useIteratively';

function transformToColumnData(reportDataItem, partnerOrgs, reportColumns) {
  return reportColumns.map((reportColumn) => {
    switch (reportColumn.column_type) {
      case OVERLAP_TIME:
        if (reportDataItem.overlap_time) {
          return {
            columnType: OVERLAP_TIME,
            isData: true,
            date: reportDataItem.overlap_time,
          };
        }
        break;
      case OVERLAPS_WITH:
        return {
          columnType: OVERLAPS_WITH,
          isData: true,
          orgs: partnerOrgs,
        };
      case DEALS_COUNT:
        return {
          columnType: DEALS_COUNT,
          isData: true,
          count: reportDataItem.open_deals_count,
        };
      case DEALS_AMOUNT:
        return {
          columnType: DEALS_AMOUNT,
          isData: true,
          amount: reportDataItem.open_deals_amount,
        };
      case POPULATIONS:
        return {
          columnType: POPULATIONS,
          isData: true,
          populations: reportDataItem.population_ids,
        };
      case PARTNER_POPULATIONS:
        return {
          columnType: PARTNER_POPULATIONS,
          isData: true,
          partnerPopulations: reportDataItem.partner_population_ids,
        };
      case PARTNERSTACK:
        return {
          columnType: PARTNERSTACK,
          isData: true,
          value: reportDataItem._xb_partnerstack,
        };
      case PARTNER_IMPACT:
        return {
          columnType: PARTNER_IMPACT,
          isData: true,
          partnerOrgs,
          record_name: reportDataItem.record_name,
          master_id: reportDataItem.master_id,
          source_id: reportDataItem.source_id,
          website: reportDataItem._xb_website,
        };
      default:
        // eslint-disable-next-line no-case-declarations
        const matchingSourceFields = reportDataItem.data.filter((dataItem) => {
          // Amount and Total Amount columns share the same source_field_id
          // comparing column_type first since they will be different for these columns
          // sum_aggregate vs source_field
          if (dataItem.column_type !== reportColumn.column_type) return false;
          return (
            dataItem.source_field_id === reportColumn.source_field_id &&
            (dataItem.value || dataItem.value === 0)
          );
        });
        if (matchingSourceFields.length > 0) {
          return {
            columnType: 'source_field',
            isData: true,
            values: matchingSourceFields,
            population_ids: reportDataItem.population_ids || [],
            partner_population_ids: reportDataItem.partner_population_ids || [],
          };
        }
    }
  });
}

function transformToColumnar({ state }) {
  const { getPartnerOrgById } = usePartnersStore();
  const reportColumns = state.reportColumns;
  const results = state.reportDataItems.map((reportDataItem) => {
    let partnerOrgs = [];
    if (reportDataItem.partner_org_ids) {
      partnerOrgs = reportDataItem.partner_org_ids.map((partnerOrgId) => {
        return getPartnerOrgById(partnerOrgId);
      });
    }
    return {
      record_id: reportDataItem.master_id,
      record_name: reportDataItem.record_name,
      partner_org_ids: reportDataItem.partner_org_ids,
      partner_orgs: partnerOrgs,
      source_id: reportDataItem.source_id,
      master_id: reportDataItem.master_id,
      overlap_time: reportDataItem.overlap_time,
      population_ids: reportDataItem.population_ids,
      partner_population_ids: reportDataItem.partner_population_ids,
      column_data: transformToColumnData(
        reportDataItem,
        partnerOrgs,
        reportColumns,
      ),
    };
  });
  return results;
}

const getReportDataItems =
  (state) =>
  async ({
    report = {},
    checkIfUpdateNeeded = false,
    continueLoadingSpinner = false,
  }) => {
    state.errors.getReportDataItems = null;
    if (!checkIfUpdateNeeded) state.isReportLoading = true;
    if (report?.folder_uuid) delete report.folder_uuid;
    try {
      const url = urls.reportData.reportData;
      const response = await axios.post(url, report);
      state.reportColumns = report.columns;
      state.reportDataItems = response.data.items;
      if (checkIfUpdateNeeded) {
        const oldStatus = state.reportStatus;
        state.reportStatus = response.data.cache_status;
        // if the cache statuses are the same, there is no visible change in the report data
        if (oldStatus === state.reportStatus) return state.reportStatus;
        // if we are moving from partial to fully cached, we only need to update pagination
        if (oldStatus === PARTIALLY_CACHED && state.reportStatus === CACHED) {
          const currentPage = state.pagination.page;
          state.pagination = {
            ...response.data.pagination,
            // we don't want to reset the current page the user is on!
            page: currentPage,
          };
          return state.reportStatus;
        }
      }
      state.reportStatus = response.data.cache_status;
      state.pagination = response.data.pagination;
      state.columnarReportDataItems = transformToColumnar({ state });
    } catch (error) {
      state.errors.getReportDataItems = error;
      const flashesStore = useFlashesStore();
      flashesStore.addUnhandledError(error);
      return CACHING_FAILED;
    } finally {
      const stopLoadingSpinner =
        !continueLoadingSpinner || state.reportStatus !== CACHE_INITIALIZED;
      if (stopLoadingSpinner) state.isReportLoading = false;
    }
    return state.reportStatus;
  };

export default function useReports() {
  const populationsStore = usePopulationsStore();
  const { getPartnerPopulationById } = storeToRefs(populationsStore);
  const billingStore = useBillingStore();
  const { isFreeTier } = storeToRefs(billingStore);
  const { iteratively } = useIteratively();

  function getPartnerOrgIdFromPopulation(id) {
    return getPartnerPopulationById.value(id)?.organization_id;
  }

  function onePopPerPartnerOrg(partnerPopIds) {
    const partnerOrgIds = [];
    for (let idx = 0; idx < partnerPopIds.length; idx++) {
      const partnerOrgId = getPartnerOrgIdFromPopulation(partnerPopIds[idx]);
      if (partnerOrgIds.includes(partnerOrgId)) return false;
      partnerOrgIds.push(partnerOrgId);
    }
    return true;
  }

  function trackReportClicked({
    gridType,
    overlaps,
    potentialRevenue,
    populationId,
    partnerPopulationId,
    eventSite,
  }) {
    const partnerPopulation =
      getPartnerPopulationById.value(partnerPopulationId);
    iteratively.userLaunchedQuickAccountMapping({
      partner_population_id: partnerPopulationId.toString(),
      population_id: populationId.toString(),
      partner_id: partnerPopulation.organization_id.toString(),
      qam_type: gridType,
      records_returned: overlaps || 0,
      potential_influence_revenue: potentialRevenue || 0,
      event_site: eventSite,
    });
  }

  function getReportPopulationTreeKey(orgId, popId, type = 'population') {
    if (!popId) return `${type}__${orgId}`;
    return `${type}__${orgId}__${popId}`;
  }

  function paywalled(report = {}) {
    return report.id && isFreeTier.value;
  }

  function isReportNameValid(reportName, reportId) {
    const reportsStore = useReportsStore();
    const reportWithName = reportsStore.getByReportName(reportName);
    if (!reportWithName) return true;
    return reportWithName.id === reportId;
  }

  const state = reactive({
    isReportLoading: false,
    isReportSaving: false,
    isReportDeleting: false,
    reportColumns: [],
    reportDataItems: [],
    reportStatus: null,
    columnarReportDataItems: [],
    pagination: {},
    errors: {
      saveReport: null,
      getReportDataItems: null,
    },
  });

  return {
    state: toRefs(state),
    getReportDataItems: getReportDataItems(state),
    getReportPopulationTreeKey,
    getPartnerOrgIdFromPopulation,
    isReportNameValid,
    onePopPerPartnerOrg,
    paywalled,
    trackReportClicked,
  };
}

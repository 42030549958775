<template>
  <div :class="classNames">
    <div class="bitts-toast__content">
      <FontAwesomeIcon
        v-if="!hideIcon"
        :icon="['far', iconType]"
        :style="{ height: '16px', width: '16px' }"
        class="bitts-toast__icon"
        aria-hidden="true"
      />
      <div class="bitts-toast__title">
        <span class="invisible absolute">
          {{ accessibilityLabel }}
        </span>
        <span class="bitts-toast__message" v-html="message" />
        <div
          v-if="description"
          class="bitts-toast__description"
          :class="hideIcon && 'ml-0'"
        >
          <span v-html="description" />
          <span v-if="$slots.link" class="ml-2">
            <slot name="link" />
          </span>
        </div>
      </div>
      <div class="ml-auto flex items-center">
        <BittsButton
          v-if="action"
          :text="action.text"
          type="white"
          size="x-small"
          variant="outline"
          class="mr-8"
          @click="action.onClick"
        />
        <BittsButton
          variant="ghost"
          type="white"
          size="x-small"
          :center-icon="['fas', 'x']"
          @click="dismissToast"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { capitalize } from 'lodash';
import { computed, onMounted } from 'vue';

import BittsButton from '../BittsButton/BittsButton.vue';

type MessageObjectAction = {
  onClick?: () => void;
  text?: string;
};

const TOAST_TYPES = {
  error: 'error',
  success: 'success',
  info: 'info',
  warning: 'warning',
};

interface Props {
  action?: MessageObjectAction | null;
  description?: string;
  duration?: number;
  hideIcon?: boolean;
  message: string;
  type?: keyof typeof TOAST_TYPES;
}

const props = withDefaults(defineProps<Props>(), {
  action: null,
  description: '',
  duration: 3.5,
  hideIcon: false,
  message: '',
  type: 'success',
});

const accessibilityLabel = computed(() => `${capitalize(props.type)} message`);
const classNames = computed(() => {
  return ['bitts-toast', `bitts-toast-${props.type}`];
});
const iconType = computed(() => {
  return {
    error: 'exclamation-circle',
    success: 'check-circle',
    info: 'info-circle',
    warning: 'exclamation-triangle',
  }[props.type];
});

const emit = defineEmits(['dismiss-toast']);
const durationMs = computed(() =>
  props.description ? 7000 : props.duration * 1000,
);
function dismissToast() {
  emit('dismiss-toast');
}
onMounted(() => {
  setTimeout(() => {
    dismissToast();
  }, durationMs.value);
});
</script>

<style lang="pcss">
.bitts-toast {
  @apply mb-8 bg-neutral-background-strong bg-opacity-80 rounded-8 w-full md:max-w-[400px] shadow-overlay;
  .bitts-toast__icon {
    @apply text-white opacity-50;
  }
  .bitts-toast__content {
    @apply px-8 py-4 flex items-center gap-8;
  }
  .bitts-toast__title {
    @apply flex flex-col items-start;
  }
  .bitts-toast__message {
    @apply grow text-sm text-white font-bold;
  }
  .bitts-toast__description {
    @apply text-white text-sm;
  }
}
</style>

import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import {
  HS3_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
  SNOWFLAKE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import {
  LD_ECOSYSTEM_INSIGHTS,
  LD_VARIATIONS,
  TEMP_EI,
} from '@/constants/feature_flags';
import {
  useFeatureFlagStore,
  useFeedsStore,
  usePartnersStore,
  usePotentialRevenueStore,
  useSourcesStore,
} from '@/stores';
import { useRootStore } from '@/stores/RootStore';

export default function useEcosystemInsights() {
  const partnersStore = usePartnersStore();
  const sourcesStore = useSourcesStore();
  const feedsStore = useFeedsStore();
  const potRevStore = usePotentialRevenueStore();
  const rootStore = useRootStore();
  const { currentQuarter } = storeToRefs(rootStore);
  const { crmFeeds } = storeToRefs(feedsStore);

  const orgMissingFieldsForAttribution = computed(() =>
    Object.values(crmFieldsNotSynced.value).some(
      (missingFields) => missingFields.length > 0,
    ),
  );

  const SALESFORCE_FRIENDLY_NAME = 'Salesforce';
  const HUBSPOT_FRIENDLY_NAME = 'HubSpot';
  const SNOWFLAKE_FRIENDLY_NAME = 'Snowflake';
  const MICROSOFT_DYNAMICS_FRIENDLY_NAME = 'Microsoft Dynamics';

  const ECOSYSTEM_INSIGHTS_REQUIRED_COLUMNS = {
    [SALESFORCE_FRIENDLY_NAME]: {
      Name: true,
      StageName: true,
      CloseDate: true,
      CreatedDate: true,
      IsWon: true,
      IsClosed: true,
    },
    [HUBSPOT_FRIENDLY_NAME]: {
      dealname: true,
      dealstage: true,
      closedate: true,
      createdate: true,
      hs_is_closed_won: true,
      hs_is_closed: true,
    },
    [MICROSOFT_DYNAMICS_FRIENDLY_NAME]: {
      name: true,
      salesstage: true,
      actualclosedate: true,
      createdon: true,
      statecode: true,
    },
    [SNOWFLAKE_FRIENDLY_NAME]: {
      name: true,
      stage_name: true,
      closed_at: true,
      created_at: true,
    },
  };

  const CRM_AMOUNT_FIELD = {
    [SALESFORCE_FRIENDLY_NAME]: 'Amount',
    [HUBSPOT_FRIENDLY_NAME]: 'amount',
    [MICROSOFT_DYNAMICS_FRIENDLY_NAME]: 'budgetamount',
    [SNOWFLAKE_FRIENDLY_NAME]: 'amount',
  };

  const surfaceMissingAmountField = (source, crm) => {
    // an org can configure a custom amount field to use, if they set it for the current CRM, check that the field
    // is visible and return null, otherwise, check if the default amount field for the CRM is visible and return the
    // display name of thd field if not
    if (
      source.id === potRevStore.potentialRevenueSettingsSource?.id &&
      source.fields.find(
        (f) =>
          f.id === potRevStore.potentialRevenueSettingsSourceField?.id &&
          f.is_visible,
      )
    ) {
      return null;
    }
    const defaultField = source.fields.find(
      (f) => f.column === CRM_AMOUNT_FIELD[crm],
    );
    if (defaultField && !defaultField.is_visible) {
      return defaultField.display_name;
    }
  };

  const surfaceMissingFields = (source, crm) => {
    const missingFields = source.fields.reduce((acc, curr) => {
      const requiredFields = ECOSYSTEM_INSIGHTS_REQUIRED_COLUMNS[crm];
      if (requiredFields[curr.column] && !curr.is_visible) {
        acc.push(curr.display_name);
      }
      return acc;
    }, []);
    const missingAmountField = surfaceMissingAmountField(source, crm);
    if (missingAmountField) {
      missingFields.push(missingAmountField);
    }
    return missingFields;
  };

  const crmFieldsNotSynced = computed(() => {
    const result = {
      [SALESFORCE_FRIENDLY_NAME]: [],
      [HUBSPOT_FRIENDLY_NAME]: [],
      [SNOWFLAKE_FRIENDLY_NAME]: [],
      [MICROSOFT_DYNAMICS_FRIENDLY_NAME]: [],
    };
    const oppSources = sourcesStore.getSourcesByMdmType('deal');
    if (!oppSources?.length) return result;
    oppSources.forEach((source) => {
      let crmType;
      if (source.schema.includes('salesforce'))
        crmType = SALESFORCE_FRIENDLY_NAME;
      if (source.schema.includes('hubspot')) crmType = HUBSPOT_FRIENDLY_NAME;
      if (source.schema.includes('snowflake'))
        crmType = SNOWFLAKE_FRIENDLY_NAME;
      if (source.schema.includes('microsoft'))
        crmType = MICROSOFT_DYNAMICS_FRIENDLY_NAME;
      if (crmType) result[crmType] = surfaceMissingFields(source, crmType);
    });
    return result;
  });

  const VALID_ECOSYSTEM_INSIGHTS_CRMS = [
    SALESFORCE_DATA_SOURCE_TYPE,
    MD_DATA_SOURCE_TYPE,
    HS3_DATA_SOURCE_TYPE,
    SNOWFLAKE_DATA_SOURCE_TYPE,
  ];

  const hasValidCrm = computed(() => {
    return VALID_ECOSYSTEM_INSIGHTS_CRMS.some((crm) =>
      crmFeeds.value.some((feed) => feed.integration.type === crm),
    );
  });

  const isMissingRequiredFields = computed(() =>
    Object.values(crmFieldsNotSynced.value).some(
      (missingFields) => missingFields.length > 0,
    ),
  );

  const showDataSyncCTA = computed(
    () => hasValidCrm.value && isMissingRequiredFields.value,
  );

  const getFaultyCrmInfo = computed(() => {
    const [faultyCrm, fields] = Object.entries(
      crmFieldsNotSynced?.value || {},
    ).find(([_crm, fields]) => fields.length > 0) || [null, null];
    return { faultyCrm, fields };
  });

  // feature flag variation helper for multipliers + partner performance project
  const ffStore = useFeatureFlagStore();
  const hasEcosystemMultipliers = computed(() =>
    ffStore.hasFeatureFlag(LD_ECOSYSTEM_INSIGHTS),
  );

  const hasTempEI = computed(() => ffStore.hasFeatureFlag(TEMP_EI));

  const showPercent = computed(() => {
    const { ALL_SCORES_PLUS_PERCENT, FEWER_SCORES_PLUS_PERCENT } =
      LD_VARIATIONS.ld_ecosystem_insights;

    const variation = ffStore.getFlagVariation(LD_ECOSYSTEM_INSIGHTS);

    return (
      variation === ALL_SCORES_PLUS_PERCENT ||
      variation === FEWER_SCORES_PLUS_PERCENT
    );
  });

  const showAllImpactScores = computed(() => {
    const { ALL_SCORES_PLUS_DECIMAL, ALL_SCORES_PLUS_PERCENT } =
      LD_VARIATIONS.ld_ecosystem_insights;

    const variation = ffStore.getFlagVariation(LD_ECOSYSTEM_INSIGHTS);

    return (
      variation === ALL_SCORES_PLUS_PERCENT ||
      variation === ALL_SCORES_PLUS_DECIMAL
    );
  });

  const formatMultiplier = (multiplier) => {
    if (multiplier === null) return null;

    if (showPercent.value) {
      const percentVal = multiplier - 1;
      return percentVal
        ? `${percentVal.toLocaleString('en-US', {
            maximumFractionDigits: 1,
            style: 'percent',
          })}`
        : null;
    }

    return multiplier >= 0
      ? `${multiplier.toLocaleString('en-US', { maximumFractionDigits: 1 })}x`
      : null;
  };

  const partnersByImpactScore = computed(() => {
    return partnersStore.partnerOrgs.reduce((lookup, org) => {
      if (!org.metrics || !org.metrics.impact_score) return lookup;
      const score = org.metrics.impact_score;
      if (!lookup[score]) lookup[score] = [];
      lookup[score].push(org);
      return lookup;
    }, {});
  });

  const isInFirstMonthOfQuarter = computed(() => {
    const now = DateTime.local();
    const oneMonthFromStart = currentQuarter.value?.start?.plus({ months: 1 });

    return now <= oneMonthFromStart;
  });

  return {
    orgMissingFieldsForAttribution,
    hasEcosystemMultipliers,
    hasTempEI,
    showPercent,
    showAllImpactScores,
    partnersByImpactScore,
    crmFieldsNotSynced,
    getFaultyCrmInfo,
    hasValidCrm,
    showDataSyncCTA,
    formatMultiplier,
    isInFirstMonthOfQuarter,
  };
}

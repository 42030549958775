import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import appConfig from '@/config';
import { PC_INTEGRATIONS } from '@/constants/feature_flags';
import { PCLOUD_INTEGRATIONS } from '@/constants/integrations';
import { useFeatureFlagStore } from '@/stores/FeatureFlagsStore';
import { initStore } from '@/stores/store-utils';
import urls from '@/urls';

import { useFlashesStore } from './FlashesStore';

export const PC_FRIGG_INTEGRATIONS = {
  monday: PC_INTEGRATIONS.MONDAY,
  rollworks: PC_INTEGRATIONS.ROLLWORKS,
  hubspot: PC_INTEGRATIONS.HUBSPOT,
};

export const usePartnerCloudStore = defineStore('PartnerCloud', () => {
  const enabledFriggs = ref([]);
  const availableIntegrations = ref([]);
  const enabledTray = ref([]);
  const enabledSalesEdge = ref([]);
  const slackAppIntegration = ref({});
  const partnerStackIntegration = ref({});
  const inProgressInstallation = ref(null);
  const trayStatuses = ref([]);

  const featureFlagStore = useFeatureFlagStore();
  const hasAccess = computed(() =>
    Object.values(PC_FRIGG_INTEGRATIONS).some((flag) =>
      featureFlagStore.hasFeatureFlag(flag),
    ),
  );

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    try {
      const enabledFriggIntegrations = [];
      const availableFetchedIntegrations = [];
      const enabledTrayIntegrations = [];
      const fetchedTraySolutions = {};
      const fetchedTrayInstances = {};
      const enabledSalesEdgeIntegrations = [];

      const [slackResponse, psResponse, trayResponse] = await Promise.all([
        axios.get(urls.slackApp.integration),
        axios.get(urls.partnerStack.integration),
        axios.get(urls.tray),
      ]);

      slackAppIntegration.value = slackResponse.data;
      partnerStackIntegration.value = psResponse.data;

      trayResponse.data.solutions.forEach((solution) => {
        const tag = solution.node.tags.find((tag) => tag.includes('xb'));
        if (tag) fetchedTraySolutions[tag] = { id: solution.node.id };
      });
      trayResponse.data.instances.forEach((instance) => {
        const tag = instance.node.solution.tags.find((tag) =>
          tag.includes('xb'),
        );
        fetchedTrayInstances[tag] = {
          type: instance.node.solution.title,
          needsUpdate:
            instance.node.solutionVersionFlags.requiresUserInputToUpdateVersion,
          ...instance.node,
        };
      });

      trayStatuses.value =
        trayResponse.data.statuses?.length > 0
          ? trayResponse.data.statuses
          : trayStatuses.value;

      let fetchedFriggEntities = {};
      let fetchedFriggIntegrations = [];
      if (hasAccess.value) {
        const { data } = await axios.get(urls.frigg.all);
        fetchedFriggEntities = data.entities;
        fetchedFriggIntegrations = data.integrations;
      }
      /* enabledFriggIntegrations are frigg integrations with status, and id's. We find
          them in the integrations property off of "data" from fetch. availableFriggs are
          all of the integrations found on  the "entities" property off of "data" from fetch.
          We are for now only using the hasUserConfig property from the available integration objects */
      const availableFriggs = {};
      const friggIntegrationsWithStatus = {};

      // building up availableFriggs and friggIntegrationsWithStatus to reference in constant time
      if (fetchedFriggEntities.options) {
        fetchedFriggEntities.options.forEach(
          (frigg) =>
            (availableFriggs[frigg.type] = {
              hasUserConfig: frigg.hasUserConfig,
              display: frigg.display,
            }),
        );
      }

      fetchedFriggIntegrations.forEach(
        (integrationWithInfo) =>
          (friggIntegrationsWithStatus[integrationWithInfo.config.type] =
            integrationWithInfo),
      );

      /*
          By the end of the loop below, we will have everything we need for all components
          stored in two arrays: enabledFriggIntegrations and availableIntegrations
          Each integration object will have at a minimum the information from PCLOUD_INTEGRATIONS
          If it's a frigg integration, it will have the hasUserConfig property coming from "entities"
          If ENABLED frigg integration, it will have id, status, config (used to prefill form)
          */

      PCLOUD_INTEGRATIONS.forEach((pCloudIntegration) => {
        /* basicFriggInfo is currently only using hasUserConfig but leaving as a full
            reference to the object to spread over mergedIntegration if we want to use more
            properties later on. Note that you will need to adjust */

        if (
          pCloudIntegration.type === 'partner_stack' &&
          partnerStackIntegration.value?.is_enabled
        )
          return;

        const basicFriggInfo = availableFriggs[pCloudIntegration.type]
          ? availableFriggs[pCloudIntegration.type]
          : {};

        const basicTrayInfo = fetchedTraySolutions[pCloudIntegration.trayTag]
          ? fetchedTraySolutions[pCloudIntegration.trayTag]
          : {};

        // Integration info that has integration id, status and data to prefill settings form
        const integrationAlreadyCreated = friggIntegrationsWithStatus[
          pCloudIntegration.type
        ]
          ? friggIntegrationsWithStatus[pCloudIntegration.type]
          : {};

        const createdTrayInstance = fetchedTrayInstances[
          pCloudIntegration.trayTag
        ]
          ? fetchedTrayInstances[pCloudIntegration.trayTag]
          : {};

        const mergedIntegration = {
          ...basicTrayInfo,
          ...createdTrayInstance,
          ...pCloudIntegration,
          ...basicFriggInfo,
          ...integrationAlreadyCreated,
        };

        /* Place in enabledFriggIntegrations if there is an integrationAlreadyCreated.
            Using ID because that will only exist on an already created integration
            Also checking at this level if organization has the proper partner cloud
            feature flag for this integration. */
        const isFriggEnabled =
          mergedIntegration.id &&
          featureFlagStore.hasFeatureFlag(mergedIntegration.ff) &&
          mergedIntegration.isFrigg;
        const isTrayEnabled =
          mergedIntegration.isTray && 'enabled' in mergedIntegration;

        /* the `unavailableWithoutFeatureFlag` property let's us hide integrations
            that aren't ready for public consumption but are ready for us to start testing!
            If it doesn't have the property specified, this returns true
            */
        const isAvailable =
          !mergedIntegration.unavailableWithoutFeatureFlag ||
          featureFlagStore.hasFeatureFlag(mergedIntegration.ff);

        if (isFriggEnabled) {
          enabledFriggIntegrations.push(mergedIntegration);
        } else if (isTrayEnabled) {
          enabledTrayIntegrations.push(mergedIntegration);
        } else if (isAvailable) {
          availableFetchedIntegrations.push(mergedIntegration);
        }
      });

      if (featureFlagStore.hasFeatureFlag(PC_INTEGRATIONS.HUBSPOT_WIDGET)) {
        let integrations;
        try {
          const { data } = await axios.get(
            `${appConfig.salesEdgeBaseUrl}/extensions/status/third-party-integration-statuses`,
          );
          integrations = data.integrations;
        } catch (xhr) {
          integrations = [];
        }
        for (const cur of integrations ?? []) {
          if (cur.is_active) {
            enabledSalesEdgeIntegrations.push({
              name: cur.name, // string
              installUser: cur.install_user, // { email: string, name: string, id: integer }
              installDate: cur.install_date, // unix timestamp with 5 decimal places
              isActive: cur.is_active, // boolean
              type: 'salesedge-hubspot',
            });
          }
        }
      }

      enabledFriggs.value = enabledFriggIntegrations;
      availableIntegrations.value = availableFetchedIntegrations;
      enabledTray.value = enabledTrayIntegrations;
      enabledSalesEdge.value = enabledSalesEdgeIntegrations;
    } catch (xhr) {
      const flashesStore = useFlashesStore();
      flashesStore.addErrorFlash({
        message: 'Failed to load some integrations',
        description: `If this error persists, please contact
              <a target="_blank" href="mailto:support@crossbeam.com">support@crossbeam.com</a>.`,
      });
    }
  });

  refresh({ useCache: true });

  function getAvailableIntegrationByType(type) {
    return availableIntegrations.value?.find(
      (integration) => type === integration.type,
    );
  }

  function getEnabledFriggByType(type) {
    return enabledFriggs.value?.find(
      (integration) => type === integration.type,
    );
  }

  function getStatusByInstanceId(id) {
    return trayStatuses.value.find(
      (status) => status.solution_instance_id === id,
    );
  }

  return {
    error,
    ready,
    readySync,
    running,
    refreshPartnerCloudStore: refresh,
    getAvailableIntegrationByType,
    getEnabledFriggByType,
    getStatusByInstanceId,
    enabledFriggs,
    enabledSalesEdge,
    enabledTray,
    slackAppIntegration,
    partnerStackIntegration,
    inProgressInstallation,
    availableIntegrations,
    trayStatuses,
  };
});

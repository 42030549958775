import { storeToRefs } from 'pinia';

import { ACTIVE, EXPLORER_SEATS } from '@/constants/billing';
import {
  useBillingStore,
  useConnectionsStore,
  useOauthApplicationStore,
  usePopulationsStore,
  useRolesStore,
  useTeamStore,
} from '@/stores';
import { useRootStore } from '@/stores/RootStore';

import useSeats from './useSeats';

export const ACTION_REQUIRED_ALERT = {
  icon: ['fad', 'stopwatch'],
  path: 'early-adopter-sunsetting',
  type: 'warning',
  buttonText: 'Learn More',
  message:
    'Action Required: Your early adopter perks are expiring. Choose what to keep by Sept, 30, 2023 or be locked out',
};

export const NO_ACTION_REQUIRED_ALERT = {
  icon: ['fad', 'stopwatch'],
  path: 'early-adopter-sunsetting',
  type: ACTIVE,
  buttonText: 'Learn More',
  message: 'Heads up, your early adopter perks are going away on Sept 30, 2023',
};

export default async function useEarlyAdopter(currentOrg) {
  const populationStore = usePopulationsStore();
  const billingStore = useBillingStore();
  const rolesStore = useRolesStore();
  const teamStore = useTeamStore();
  const connectionsStore = useConnectionsStore();
  const oauthApplicationsStore = useOauthApplicationStore();
  const rootStore = useRootStore();

  if (!currentOrg) {
    currentOrg = rootStore.currentOrg;
  }

  // the salesintel copilot widget needs none of these stores, well it does need billing but it calls that directly
  if (!rootStore.isCopilot) {
    await Promise.all([
      oauthApplicationsStore.readySync,
      connectionsStore.readySync,
      populationStore.initPopulationsStore(),
      rolesStore.readySync,
      teamStore.readySync,
    ]);
  }

  const { isFreeTier } = storeToRefs(billingStore);
  const { salesSeats } = storeToRefs(teamStore);
  const { coreSeatCount } = useSeats();

  const customPops = populationStore.getCustomPopulations().length;
  const extraUsers = Math.max(coreSeatCount.value - EXPLORER_SEATS, 0);

  const hasSso = currentOrg.login_method !== 'default';
  const hasCustomRoles = rolesStore.allRolesWithIsCustom.some(
    (role) => role.is_custom,
  );

  const hasEaPerks =
    isFreeTier.value &&
    (customPops > 0 || extraUsers > 0 || salesSeats.value.length > 0);

  const eaAlert = !hasEaPerks
    ? null
    : extraUsers > 0
      ? ACTION_REQUIRED_ALERT
      : NO_ACTION_REQUIRED_ALERT;

  return {
    hasEaPerks,
    customPops,
    extraUsers,
    hasSso,
    eaAlert,
    hasCustomRoles,
  };
}

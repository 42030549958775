import { DateTime } from 'luxon';

import { ATTRIBUTION_FILTER_QUERY_PARAMS } from '@/constants/attribution';

const {
  CLOSE_DATE_GTE,
  CLOSE_DATE_LTE,
  OPPORTUNITY_AMOUNT_GTE,
  OPPORTUNITY_AMOUNT_LTE,
  MASTER_UUID,
  SALES_STAGE,
  ORDER,
  SORT,
  PARTNER_UUIDS,
} = ATTRIBUTION_FILTER_QUERY_PARAMS;

export const extractFiltersFromQuery = (query) => {
  const startDateFilter = query[CLOSE_DATE_GTE];
  const endDateFilter = query[CLOSE_DATE_LTE];
  const startAmountFilter = query[OPPORTUNITY_AMOUNT_GTE];
  const endAmountFilter = query[OPPORTUNITY_AMOUNT_LTE];
  const params = {};

  const partnerFilter = query[PARTNER_UUIDS];
  if (partnerFilter) {
    const partnerFilterArray = Array.isArray(partnerFilter)
      ? partnerFilter
      : [partnerFilter];
    params[PARTNER_UUIDS] = partnerFilterArray;
  }

  const salesStageFilter = query[SALES_STAGE];
  if (salesStageFilter) {
    const salesStageFilterArray = Array.isArray(salesStageFilter)
      ? salesStageFilter
      : [salesStageFilter];
    params[SALES_STAGE] = salesStageFilterArray;
  }

  if (startDateFilter || endDateFilter) {
    params[CLOSE_DATE_GTE] = startDateFilter;
    params[CLOSE_DATE_LTE] = endDateFilter;
  }

  if (startAmountFilter && endAmountFilter) {
    params[OPPORTUNITY_AMOUNT_GTE] = startAmountFilter;
    params[OPPORTUNITY_AMOUNT_LTE] = endAmountFilter;
  }

  if (query[MASTER_UUID]) params[MASTER_UUID] = query[MASTER_UUID];

  if (query[ORDER] && query[SORT]) {
    params[ORDER] = query[ORDER];
    params[SORT] = query[SORT];
  }
  return params;
};

/**
 * Builds query parameters for the current fiscal quarter based on the starting month of the fiscal year.
 *
 * @param {Object} quarterLookup
 * @returns {Object} - An object with the query parameters for the current fiscal quarter.
 * These params use our new Left Hand Side (LHS) brackets for the query operands.
 * @example
 * // For a fiscal year starting in July:
 * buildCurrentQuarterDateRangeQuery(lookup with fiscal year starting 7);
 * // Returns:
 * // {
 * //   close_date_gte: '20210701',
 * //   close_date_lte: '20210930',
 * // }
 */
export function buildCurrentQuarterDateRangeQuery(quarterLookup) {
  const currentMonth = DateTime.local().month;
  const currentQuarter = Object.values(quarterLookup).find(({ start, end }) => {
    // handle wrapping around the year
    if (start.month > end.month) {
      return currentMonth >= start.month || currentMonth <= end.month;
    }
    return currentMonth >= start.month && currentMonth <= end.month;
  });

  if (!currentQuarter) return;

  return {
    [CLOSE_DATE_GTE]: currentQuarter.start.toFormat('yyyyMMdd'),
    [CLOSE_DATE_LTE]: currentQuarter.end.toFormat('yyyyMMdd'),
  };
}

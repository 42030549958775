<template>
  <div class="c-data-templates">
    <BittsModal
      :class="{
        'c-bitts-modal__customize-fields': isCustomizing,
        'manually-positioned': isCustomizing && !isConfirmingClose,
      }"
      :visible="true"
      :show-buttons="false"
      :use-mask-to-close="false"
      :title="title"
      :loading="loading"
      :width="modalWidth"
      @closed="handleClose"
    >
      <template #content>
        <ConfirmCloseTemplates
          v-show="isConfirmingClose"
          :is-customizing="isCustomizing"
          @cancel="isConfirmingClose = false"
          @confirm="handleConfirmClose"
        />
        <CustomizeFields
          v-show="isCustomizing && !isConfirmingClose"
          :feed="feed"
          :templates="TEMPLATES"
          :controlled-checked-keys="allCheckedFieldIds"
          @review="handleReview"
          @back="handleEasySelect"
        />
        <ReviewFields
          v-show="isReviewing && !isConfirmingClose"
          :all-checked-field-ids="allCheckedFieldIds"
          :feed="feed"
          :sources="sources"
          :show-buttons="true"
          :show-summary="true"
          :limit-height="true"
          @customize="handleCustomize"
          @sync="handleSync"
        />
        <EasySelectFields
          v-show="isEasySelectFields && !isConfirmingClose"
          :feed="feed"
          :sources="sources"
          @review="handleReview"
          @customize="handleCustomize"
        />
      </template>
    </BittsModal>
  </div>
</template>
<script setup>
import { BittsModal } from '@crossbeam/bitts';

import { computed, onMounted, provide, ref } from 'vue';
import { useRouter } from 'vue-router';

import ConfirmCloseTemplates from '@/components/data-sources/data-templates/ConfirmCloseTemplates.vue';
import CustomizeFields from '@/components/data-sources/data-templates/CustomizeFields.vue';
import EasySelectFields from '@/components/data-sources/data-templates/EasySelectFields.vue';
import ReviewFields from '@/components/data-sources/data-templates/ReviewFields.vue';

import useDataTemplates from '@/composables/useDataTemplates';
import useIteratively from '@/composables/useIteratively';
import { EVENT_SITES } from '@/constants/analytics';
import { patchFeed, patchSources } from '@/constants/data_sources';
import { captureException } from '@/errors';
import {
  useFeedsStore,
  useFlashesStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';

const props = defineProps({
  feedId: {
    type: Number,
    required: true,
  },
  customizing: {
    type: Boolean,
    default: false,
  },
  closeRoute: {
    type: String,
    default: '/data-sources',
  },
});

const loading = ref(true);
const router = useRouter();
const feed = ref({});

const flashesStore = useFlashesStore();
const feedsStore = useFeedsStore();
const { initPopulationsStore } = usePopulationsStore();
const sourcesStore = useSourcesStore();

const { RECOMMENDED_TEMPLATE, TEMPLATES } = useDataTemplates();

onMounted(async () => {
  await Promise.all([
    sourcesStore.refreshSourcesStore(),
    feedsStore.refreshFeedsStore(),
  ]);
  await initPopulationsStore();
  feed.value = feedsStore.getFeedById(props.feedId);
  loading.value = false;
});

const sources = computed(() => sourcesStore.getSourcesByFeedId(props.feedId));
const columnToFriendlyNameMap = computed(() =>
  createFriendlyNameMap(sources.value),
);
provide('columnToFriendlyNameMap', columnToFriendlyNameMap);

/* We can't use v-if in this case because that will cause a re-render of the children components
and we want to persist this state between the different views */
const isConfirmingClose = ref(false);
const isReviewing = ref(false);
const isCustomizing = ref(props.customizing && !isConfirmingClose.value);
const isEasySelectFields = computed(
  () => !isReviewing.value && !isCustomizing.value && !isConfirmingClose.value,
);

async function handleClose() {
  if (isConfirmingClose.value || (!isCustomizing.value && !isReviewing.value)) {
    await handleConfirmClose();
  } else {
    isConfirmingClose.value = true;
  }
}

async function handleConfirmClose() {
  await router.push({ path: props.closeRoute });
}

const modalWidth = computed(() => {
  if (isCustomizing.value && !isConfirmingClose.value) return 1200;
  if (isConfirmingClose.value) return 400;
  return 500;
});

const title = computed(() => {
  if (isConfirmingClose.value) return 'Discard Changes?';
  if (isCustomizing.value) return 'Customize Fields';
  if (isReviewing.value)
    return `Review ${allCheckedFieldIds.value.length} Fields`;
  return null;
});

async function handleSync(payload) {
  try {
    loading.value = true;
    await patchSources(payload);

    await patchFeed(feed.value.id, {
      is_paused: false,
      frequency: feed.value.frequency,
    });

    isConfirmingClose.value = true;
    if (!feed.value.initial_sync_complete) trackConnection();
    handleClose();
    if (props.closeRoute !== '/data-sources') {
      flashesStore.addSuccessFlash({
        message: 'Your new fields are syncing',
        description: 'This view will take a few minutes to populate',
      });
    } else flashesStore.addSuccessFlash({ message: 'Settings saved' });
  } catch (err) {
    flashesStore.addErrorFlash({ message: 'Settings could not be saved' });
    captureException(err);
  } finally {
    loading.value = false;
    await Promise.all([
      feedsStore.refreshFeedsStore(),
      sourcesStore.refreshSourcesStore(),
    ]);
  }
}

function handleCustomize(payload) {
  isReviewing.value = false;
  isCustomizing.value = true;
  if (payload.allCheckedFieldIds)
    allCheckedFieldIds.value = payload.allCheckedFieldIds;
  if (payload.selectedPreset) trackedPreset.value = payload.selectedPreset;
}

const allCheckedFieldIds = ref([]);
function handleReview(payload) {
  allCheckedFieldIds.value = payload.newCheckedFields;
  isCustomizing.value = false;
  isReviewing.value = true;

  if (payload.activeTemplates) trackedTemplates.value = payload.activeTemplates;
  if (payload.selectedPreset) trackedPreset.value = payload.selectedPreset;
}

function handleEasySelect() {
  isCustomizing.value = false;
  isReviewing.value = false;
}

/* Event tracking on connection */
const trackedTemplates = ref([]);
const trackedPreset = ref(RECOMMENDED_TEMPLATE.type);
const { iteratively } = useIteratively();
function trackConnection() {
  const iterativelyPayload = {
    event_site: EVENT_SITES.DATA_PRESETS_MODAL,
    data_source_type: feed.value.integration.type,
    feed_id: feed.value.id.toString(),
    custom_presets: trackedTemplates.value,
    initial_sync_selection: trackedPreset.value,
  };

  iteratively.userConnectedDataSource(iterativelyPayload);
}
</script>
<script>
/* Creates a mapping of field names to field nicknames for each table */
export function createFriendlyNameMap(sources) {
  return sources.reduce((acc, source) => {
    return Object.assign(acc, {
      [source.table]: Object.assign(
        {},
        ...source.fields.map((field) => ({ [field.column]: field.nickname })),
      ),
    });
  }, {});
}
</script>
<style lang="pcss">
.c-bitts-modal__customize-fields .c-bitts-modal__content {
  @apply px-0;
}

/* We want to give the big honker modal even more screen real estate */
.manually-positioned {
  .ant-modal {
    top: 25px;
  }
}
</style>

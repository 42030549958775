<template>
  <div v-bind="attributes.top" class="c-bitts-input">
    <slot name="label">
      <BittsFormLabel
        v-if="formLabel"
        :label="formLabel"
        :disabled="disabled"
      />
    </slot>
    <Input
      v-bind="attributes.input"
      ref="bittsInput"
      :value="modelValue"
      :disabled="disabled"
      :placeholder="placeholder"
      :name="name"
      :type="inputType as any"
      :allow-clear="type === 'search'"
      class="c-bitts-input__input"
      :readonly="readOnly"
      :class="{
        danger: status === 'danger',
        success: status === 'success',
        disabled: disabled,
        'read-only': readOnly,
        [size]: true,
      }"
      @input="(e) => emit('update:modelValue', e.target.value)"
      @keydown.enter="(e) => emit('enter-pressed', e)"
    >
      <template #prefix>
        <div
          v-if="!!$slots.prefix || type === 'search'"
          class="c-bitts-input__prefix"
        >
          <slot name="prefix">
            <FontAwesomeIcon
              v-if="type === 'search'"
              :icon="['far', 'magnifying-glass']"
              class="text-info-accent mr-4 cursor-pointer"
              :class="{ 'text-neutral-text-placeholder': disabled }"
              @click="(e: MouseEvent) => emit('enter-pressed', e)"
            />
          </slot>
        </div>
      </template>
      <template #suffix>
        <div
          v-if="!!$slots.suffix || type === 'password' || allowCopy"
          class="c-bitts-input__suffix"
        >
          <slot name="suffix">
            <span v-if="type === 'password'">
              <FontAwesomeIcon
                :icon="['fas', inputType === 'password' ? 'eye-slash' : 'eye']"
                class="text-neutral-text-placeholder cursor-pointer"
                @click="onSwitchVisiblity"
              />
            </span>
            <BittsPopover
              v-if="allowCopy"
              :mount-to-body="true"
              trigger="click"
              :autoclose="1000"
              overlay-class="input-popover"
              placement="top"
              class="input-copy-popover"
            >
              <span @click="copyToClipBoard(modelValue)">
                <FontAwesomeIcon
                  :icon="['fas', 'copy']"
                  class="text-neutral-text-placeholder cursor-pointer"
                />
              </span>
              <template #content>
                <span class="text-white"> Copied to clipboard </span>
              </template>
            </BittsPopover>
          </slot>
        </div>
      </template>
    </Input>
    <BittsStatus
      v-if="status !== 'default'"
      :status="status"
      :disabled="disabled"
      :success-text="successText"
      :danger-text="dangerText"
    />
    <div v-if="caption" class="c-bitts-input__caption">
      {{ caption }}
    </div>
  </div>
</template>

<script lang="ts">
import { Input } from 'ant-design-vue';
import { computed, ref, useAttrs } from 'vue';

import BittsFormLabel from '../BittsFormLabel/BittsFormLabel.vue';
import { FormLabelType } from '../BittsFormLabel/types';
import BittsPopover from '../BittsPopover/BittsPopover.vue';
import BittsStatus, {
  BittsStatusDefaults,
  BittsStatusProps,
} from '../BittsStatus/BittsStatus.vue';
import { BittsSize } from '../types';

export interface BittsInputProps extends BittsStatusProps {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
  type?: 'text' | 'search' | 'password';
  modelValue?: string;
  // eslint-disable-next-line vue/no-unused-properties
  focus?: boolean;
  placeholder?: string;
  formLabel?: FormLabelType | string;
  caption?: string;
  size?: Exclude<BittsSize, 'x-small' | 'large' | 'x-large' | 'tiny'>;
  allowCopy?: boolean;
}
</script>
<script setup lang="ts">
const emit = defineEmits(['update:modelValue', 'enter-pressed']);

const props = withDefaults(defineProps<BittsInputProps>(), {
  readOnly: false,
  type: 'text',
  focus: false,
  modelValue: '',
  placeholder: '',
  formLabel: '',
  caption: '',
  size: 'medium',
  allowCopy: false,
  ...BittsStatusDefaults,
});

/* This let's us bind only the class to the topmost div by default, and
the data-testid will be bound to the input. This makes styling and
testing way easier. */
defineOptions({ inheritAttrs: false });
const rawAttrs = useAttrs();
const attributes = computed(() => {
  const { class: className, ...rest } = rawAttrs;
  return { top: { class: className }, input: { ...rest } };
});

const copyToClipBoard = async (textToCopy: string) => {
  if (!navigator.clipboard) {
    copyTextFallback(textToCopy);
    return;
  }
  await navigator.clipboard.writeText(textToCopy);
};

/* Helper for copyToClipBoard */
const copyTextFallback = (textToCopy: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.execCommand('copy');
  document.body.removeChild(textArea);
};
/* Password visiblity toggle w/ our custom icon */
const inputType = ref(props.type === 'password' ? 'password' : 'text');
function onSwitchVisiblity() {
  inputType.value = inputType.value === 'text' ? 'password' : 'text';
}
</script>

<style lang="pcss">
.c-bitts-input {
  @apply w-full;

  .c-bitts-input__input {
    @apply rounded-8 border-neutral-border shadow-component
    outline outline-1 w-full py-8 text-neutral-text outline-transparent;
    font-family: inherit;

    &.small {
      @apply py-4;
    }

    .ant-input {
      font-family: inherit;
    }

    .ant-input-clear-icon {
      @apply flex items-center;
    }

    .ant-input::placeholder {
      @apply text-neutral-text-placeholder;
    }

    &.success:not(.disabled) {
      @apply outline-success-accent border-success-accent outline-1;
    }

    &.danger:not(.disabled) {
      @apply border-danger-accent outline-danger-accent outline-1;
    }

    &.disabled:not(.read-only) {
      @apply bg-neutral-background-disabled outline-neutral-border border-neutral-border outline-0;
      &:hover,
      &:focus {
        @apply outline-0;
      }
    }

    &.read-only {
      @apply opacity-100 pointer-events-auto;
      &:not(.success, .danger) {
        &:hover,
        &:focus-within {
          @apply outline-1 border border-neutral-border-focus outline-neutral-border-focus border-solid outline;
        }
      }
    }
  }

  .c-bitts-input__prefix,
  .c-bitts-input__suffix {
    @apply flex gap-4;
  }

  .input-copy-popover {
    @apply flex justify-center mx-4 cursor-pointer;
  }
}

.c-bitts-input__caption {
  @apply text-left text-neutral-500 text-sm mt-4;
}

.input-popover.bitts-overflow-menu.ant-popover {
  @apply z-[1035];
  .ant-popover-inner {
    @apply text-sm px-8 py-4 bg-neutral-background-tooltip rounded-bts-md opacity-90 border-none;
  }
}

/* Ant-D has very particular border styles we have to override */
span.c-bitts-input__input.ant-input-affix-wrapper:not(
    .ant-input-affix-wrapper-disabled,
    .disabled,
    .danger,
    .success
  ) {
  &:hover,
  &:focus-within {
    @apply outline-1 border-neutral-border-focus outline-neutral-border-focus outline shadow-none border-solid;
  }
}
</style>

export const SALES = 'sales';
export const REVOPS_OR_PARTNER_OPS = 'revops_or_partner_ops';
export const MARKETING = 'marketing';
export const OTHER = 'other';
export const PARTNERSHIPS = 'partnerships';

export const DEPARTMENTS = [
  { value: MARKETING, label: 'Marketing' },
  { value: PARTNERSHIPS, label: 'Partnerships' },
  { value: REVOPS_OR_PARTNER_OPS, label: 'Rev Ops/Partner Ops' },
  { value: SALES, label: 'Sales' },
  { value: OTHER, label: 'Something else' },
];

export const XBEAM_URL = 'xbeam-url';
export const ACS_URL = 'acs-url';
export const ENTITY_ID = 'entity-id';
export const URLS = [XBEAM_URL, ACS_URL, ENTITY_ID];
export const SSO_ONLY = 'sso_only';
export const SSO_OPTIONAL = 'sso_optional';
export const NO_SSO = 'default';
export const SSO_OPTIONS = [
  {
    label: "Don't Require SSO",
    value: false,
    description:
      'Allow your team to log in with email and password, Google, or SSO.',
  },
  {
    label: 'Require SSO',
    value: true,
    description: 'Only allow your team to log in with SSO.',
  },
];

<template>
  <div class="c-view-options">
    <div class="flex items-center">
      <h3 class="text-lg font-bold text-neutral-text-strong"> View Options </h3>
    </div>
    <div
      class="c-view-options__card rounded-bts-sm border-neutral-100 fullstory-hide dd-privacy-mask"
    >
      <div
        :key="`population_${currentOrg.id}`"
        class="c-view-options__card__row flex items-center justify-between border-neutral-100"
        @click="onChecked('our_population_ids', null)"
      >
        <div class="flex">
          <BittsAvatar
            :org="currentOrg"
            :is-own="true"
            :show-initials="true"
            size="medium"
          />
          <div class="c-view-options__card__row__label">
            <span class="c-view-options__card__row__label__title">
              {{ currentOrg.name }} (You)
            </span>
            <span
              class="c-view-options__card__row__label__subtitle text-brand-blue"
            >
              {{
                myPopulations.map((population) => population.name).join(', ')
              }}
            </span>
          </div>
        </div>
        <BittsCheckbox
          :id="`checkbox_population_${currentOrg.id}`"
          :checked="isChecked('our_population_ids')"
          class="c-view-options__card__row__checkbox"
        />
      </div>
    </div>
    <span
      v-if="partnerPopulations.length > 0"
      class="c-view-options__subtitle text-neutral-500 flex flex-row items-center justify-between"
    >
      <span class="tracking-wider">Partner Data</span>
      <span
        class="text-sm text-brand-blue cursor-pointer normal-case"
        @click="selectOrUnselectAllPartners"
        >{{ selectAllText }}</span
      >
    </span>
    <div
      class="c-view-options__card rounded-bts-sm border-neutral-100 fullstory-hide dd-privacy-mask"
    >
      <div
        v-for="item in partnerPopulations"
        :key="`population_${item.population.id}`"
        class="c-view-options__card__row flex items-center justify-between border-neutral-100"
        @click="onChecked('population_ids', item.population.id)"
      >
        <div class="flex">
          <BittsAvatar
            :org="item.org"
            :is-own="false"
            :show-initials="true"
            size="medium"
          />
          <div class="c-view-options__card__row__label">
            <span class="c-view-options__card__row__label__title">
              <div class="flex">
                <span class="truncate">{{ item.org.name }}</span>
                <MatchIssueFlag
                  v-if="getMatchIssuesForPartner(item.org.id).length"
                  :match-issues="getMatchIssuesForPartner(item.org.id)"
                  class="ml-4 inline"
                />
              </div>
            </span>
            <span
              class="c-view-options__card__row__label__subtitle text-brand-blue"
            >
              {{ item.population.name }}
            </span>
          </div>
        </div>
        <BittsCheckbox
          :id="`checkbox_population_${item.population.id}`"
          :checked="isChecked('population_ids', item.population.id)"
          class="c-view-options__card__row__checkbox"
        />
      </div>
    </div>
    <BittsButton
      text="Report an Incorrect Match"
      size="small"
      class="mt-14"
      :left-icon="['fas', 'exclamation-triangle']"
      left-icon-classes="text-neutral-400"
      type="neutral"
      variant="outline"
      @click="showMatchIssueModal = true"
    />
    <span class="c-view-options__subtitle text-neutral-500 tracking-wider">
      Data Types
    </span>
    <div
      class="c-view-options__card c-view-options__card--data-types rounded-bts-sm border-neutral-100"
    >
      <div
        v-for="(item, index) in dataTypes"
        :key="`data_type_${index}`"
        class="c-view-options__card__row border-neutral-100 px-12 c-view-options__card__row--data-types flex items-center justify-between"
        @click="onChecked('data_type_ids', item.id)"
      >
        <div class="flex">
          <span
            :class="{
              'c-view-options__card__row__column': true,
              'c-view-options__card__row__column--fa':
                item.icon.type === 'FontAwesomeIcon',
            }"
          >
            <FontAwesomeIcon
              :icon="iconType(item)"
              :style="{ height: '14px', width: '14px' }"
              class="text-brand-blue"
            />
          </span>
          <span
            class="c-view-options__card__row__column c-view-options__card__row__column--label pl-8"
          >
            {{
              item.id === 'contacts' && source.mdm_type === 'account'
                ? `${entityName} Contacts`
                : item.label
            }}
          </span>
        </div>
        <BittsCheckbox
          :id="`checkbox_data_type_${index}`"
          :checked="isChecked('data_type_ids', item.id)"
          class="c-view-options__card__row__checkbox"
        />
      </div>
    </div>
    <ReportMatchIssueModal
      v-if="showMatchIssueModal"
      v-model="showMatchIssueModal"
      :source="source"
      :source-id="sourceId"
      :source-primary-key="sourcePrimaryKey"
      :partner-populations="partnerPopulations"
      @closed="showMatchIssueModal = false"
    />
  </div>
</template>

<script>
import { BittsAvatar, BittsButton, BittsCheckbox } from '@crossbeam/bitts';

import { flatten, intersection, sortBy } from 'lodash';
import { mapActions, mapState } from 'pinia';
import { toRaw } from 'vue';

import MatchIssueFlag from '@/components/records/MatchIssueFlag.vue';
import ReportMatchIssueModal from '@/components/records/ReportMatchIssueModal.vue';

import useAuth from '@/composables/useAuth';
import { DATA_TYPES_LABELS } from '@/constants/view_options';
import {
  useDataSharesStore,
  useMatchIssuesStore,
  usePartnersStore,
  usePopulationsStore,
} from '@/stores';

export default {
  name: 'ViewOptions',
  components: {
    BittsAvatar,
    BittsButton,
    BittsCheckbox,
    MatchIssueFlag,
    ReportMatchIssueModal,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
    sourceId: {
      type: [Number, String],
      required: true,
    },
    sourcePrimaryKey: {
      type: String,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    },
  },
  emits: ['change'],
  setup() {
    const { currentOrg } = useAuth();

    return { currentOrg };
  },
  data() {
    return {
      options: {},
      showMatchIssueModal: false,
    };
  },
  computed: {
    ...mapState(usePartnersStore, ['getPartnerOrgById']),
    ...mapState(usePopulationsStore, [
      'getPopulationById',
      'getPartnerPopulationById',
    ]),
    myPopulations() {
      return this.source.our_population_ids.map((popId) =>
        this.getPopulationById(popId),
      );
    },
    partnerPopulations() {
      const dataShareIds = flatten(
        Object.keys(this.source.elements).map((key) => {
          return 'items' in this.source.elements[key]
            ? flatten(
                this.source.elements[key].items.map(
                  (item) => item.data_share_ids,
                ),
              )
            : this.source.elements[key].data_share_ids;
        }),
      );
      const populationIds = new Set(
        dataShareIds
          .map(this.getDataShareById)
          .filter((val) => val)
          .map((share) => share.partner_population_id),
      );
      const items = Array.from(populationIds).map((popId) => {
        const population = this.getPartnerPopulationById(popId); // TODO: Fix no population
        return {
          population,
          org: this.getPartnerOrgById(population.organization_id),
        };
      });
      return sortBy(
        items,
        (item) => item.org.id,
        (item) => item.population.name,
      );
    },
    isEveryPartnerChecked() {
      return this.partnerPopulations.every((partnerPop) =>
        this.isChecked('population_ids', partnerPop.population.id),
      );
    },
    selectAllText() {
      return this.isEveryPartnerChecked ? 'Unselect All' : 'Select All';
    },
    partnerOrgs() {
      return this.partnerPopulations.map((p) => p.org);
    },
    dataTypes() {
      const hasItemsOrFields = (key) =>
        (this.source.elements[key].items &&
          this.source.elements[key].items.length > 0) ||
        (this.source.elements[key].fields &&
          this.source.elements[key].fields.length > 0);
      return Object.keys(this.source.elements)
        .filter(hasItemsOrFields)
        .map((key) =>
          DATA_TYPES_LABELS.find((dataTypeLabel) => dataTypeLabel.id === key),
        )
        .filter((dataType) => dataType);
    },
    matchIssues() {
      return this.getMatchIssuesBySourceIdAndRecordId(
        this.sourceId,
        this.sourcePrimaryKey,
      );
    },
  },
  async created() {
    this.options = {
      population_ids: new Set(
        this.myPopulations
          .map((item) => item.id)
          .concat(this.partnerPopulations.map((item) => item.population.id)),
      ),
      data_type_ids: new Set(Object.keys(this.source.elements)),
    };
  },
  methods: {
    ...mapActions(useDataSharesStore, ['getDataShareById']),
    ...mapActions(useMatchIssuesStore, ['getMatchIssuesBySourceIdAndRecordId']),
    onChecked(type, id, skipEmit) {
      const options = { ...this.options };
      const optionsType = type !== 'data_type_ids' ? 'population_ids' : type;

      if (!options[optionsType]) {
        options[optionsType] = new Set();
      }

      if (type === 'our_population_ids') {
        if (!this.isChecked('our_population_ids')) {
          this.myPopulations.map((population) => {
            const pop = toRaw(population);
            return options[optionsType].add(pop.id);
          });
        } else {
          this.myPopulations
            .map((population) => {
              const pop = toRaw(population);
              return pop.id;
            })
            .forEach((populationId) =>
              options[optionsType].delete(populationId),
            );
        }
      } else if (!this.isChecked(type, id)) {
        options[optionsType].add(id);
      } else {
        options[optionsType].delete(id);
      }
      this.options = options;
      // we need this because we have to run this function several times on "select all"
      // we don't want an event to be emitted each time, we take care of that in onSelectAll
      if (!skipEmit) {
        this.$emit('change', options);
      }
    },
    isChecked(type, id) {
      if (type === 'our_population_ids') {
        const ourPopulationIds = this.myPopulations.map(
          (population) => population.id,
        );
        return (
          intersection(
            ourPopulationIds,
            Array.from(this.options.population_ids),
          ).length === ourPopulationIds.length
        );
      }
      return this.options[type].has(id);
    },
    getMatchIssuesForPartner(partnerId) {
      return (
        this.matchIssues &&
        this.matchIssues.filter(
          (i) =>
            i.reporting_organization.id === partnerId ||
            i.partner_organization.id === partnerId,
        )
      );
    },
    selectAllPartners() {
      this.partnerPopulations.forEach((partnerPop) => {
        if (!this.isChecked('population_ids', partnerPop.population.id, true)) {
          this.onChecked('population_ids', partnerPop.population.id, true);
        }
      });
      this.$emit('change', this.options);
    },
    selectOrUnselectAllPartners() {
      if (this.isEveryPartnerChecked) {
        this.unselectAllPartners();
      } else {
        this.selectAllPartners();
      }
    },
    unselectAllPartners() {
      const newOptions = {
        ...this.options,
        population_ids: new Set(),
      };
      // if our pops were already checked, preserve that
      if (this.isChecked('our_population_ids')) {
        newOptions.population_ids = new Set(
          this.myPopulations.map((item) => item.id),
        );
      }
      this.options = Object.assign({}, newOptions);
      this.$emit('change', this.options);
    },
    iconType(item) {
      return (
        {
          accounts: ['far', 'building'],
          account_owners: ['far', 'user-crown'],
          contacts: ['far', 'address-book'],
          leads: ['far', 'user'],
        }[item.id] || ['far', 'briefcase']
      );
    },
  },
};
</script>

<style lang="pcss" scoped>
.c-view-options {
  @apply text-brand-navy;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding-top: 12px;
}

.c-view-options__subtitle {
  color: var(--grey1);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  padding-top: 16px;
}

.c-view-options__card {
  border: 1px solid var(--grey6);
  background: var(--white);
  margin-top: 12px;
}

.c-view-options__card--data-types {
  font-size: 12px;
}

.c-view-options__card__row {
  display: grid;
  padding: 12px;
  grid-template-columns: [logo] 36px [label] 1fr [checkbox] 36px;
  width: 100%;
  border-bottom: 1px solid var(--grey6);
  &:last-of-type {
    border-bottom: 0px;
  }
  &:hover {
    @apply bg-neutral-50;
    cursor: pointer;
  }
}

.c-view-options__card__row--data-types {
  grid-template-columns: [icon] 24px [label] 1fr [checkbox] 36px;
  height: 36px;
  padding: 4px;
}

.c-view-options__card__row__logo {
  height: 35px;
  width: 35px;
}

.c-view-options__card__row__icon {
  @apply text-brand-blue;
  height: 16px;
  width: 16px;
}

.c-view-options__card__row__column {
  display: flex;
  align-items: center;
}

.c-view-options__card__row__column--fa {
  justify-content: center;
}

.c-view-options__card__row__column--label {
  padding-left: 4px;
}

.c-view-options__card__row__label {
  display: flex;
  flex-direction: column;
  margin: 0px 8px;
  overflow: hidden;
}

.c-view-options__card__row__label__title {
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-view-options__card__row__label__subtitle {
  @apply text-brand-blue;
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.c-view-options__card__row__checkbox {
  justify-content: flex-end;
}
</style>

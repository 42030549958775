import { defineStore } from 'pinia';

import COPILOT_SOURCES, {
  COPILOT_SOURCE_EXTENSION,
  COPILOT_SOURCE_GONG,
  COPILOT_SOURCE_HUBSPOT,
  COPILOT_SOURCE_OUTREACH,
  COPILOT_SOURCE_SALESFORCE,
} from '../constants/sources';

export const useSalesIntelStore = defineStore('SalesIntelStore', {
  state: () => {
    return {
      recordId: null,
      source: null,
    };
  },
  actions: {
    initSalesIntelStore() {
      const sourceParam = new URLSearchParams(window.location.search)
        .get('source')
        ?.toLowerCase();
      if (COPILOT_SOURCES.some((source) => source === sourceParam)) {
        this.source = sourceParam;
      }
    },
  },
  getters: {
    usePartitionedCookie() {
      // Do not use a partitioned cookie for chrome, otherwise you login for every website.
      return this.source !== COPILOT_SOURCE_EXTENSION;
    },
    showHeader() {
      return this.source !== COPILOT_SOURCE_SALESFORCE;
    },
    showOrgSwitcher() {
      return [
        COPILOT_SOURCE_EXTENSION,
        COPILOT_SOURCE_GONG,
        COPILOT_SOURCE_OUTREACH,
        COPILOT_SOURCE_HUBSPOT,
      ].includes(this.source);
    },
    isExtension() {
      return this.source === COPILOT_SOURCE_EXTENSION;
    },
    requiresEnterprise() {
      return [COPILOT_SOURCE_GONG, COPILOT_SOURCE_OUTREACH].includes(
        this.source,
      );
    },
  },
});

<template>
  <div class="c-easy-select-fields">
    <div class="c-easy-select-fields__header">
      <div class="flex flex-col">
        <BittsSvg
          :svg="feed.integration.type + (isMicrosoftDynamics ? 'Icon' : '')"
        />
        <!-- This logic is required to resize
        the SVGs and position the check mark correctly -->
        <FontAwesomeIcon
          :icon="['fas', 'check-circle']"
          class="text-success-accent self-end"
          :class="{
            'mt-[-24px]': isSalesforce,
            'mr-24': isSalesforce,
            'mt-6': isMicrosoftDynamics,
          }"
          :style="{ height: '24px', width: '24px' }"
        />
      </div>
      <h1> Let's get syncing </h1>
    </div>
    <p class="c-easy-select-fields__summary">
      We'll let you review the selected data before we sync anything. Don't
      worry, you can always change this later.
    </p>
    <p class="text-left mb-4 font-bold text-neutral-text-strong">
      What do you want to sync in?
    </p>
    <BittsRadioGroupCards
      :options="OPTIONS"
      class="mb-24"
      :initial-value="selectedOption"
      @change="handleOptionChange"
    >
      <template #label="{ option }">
        <div class="flex items-center justify-between mb-2 w-full">
          <div class="flex items-center gap-8">
            <p>
              {{ option.label }}
            </p>
            <BittsTag
              v-if="option.value === RECOMMENDED_TEMPLATE.type"
              class="c-easy-select-fields__recommended_tag"
              color="info"
              variant="rounded"
              size="x-small"
            >
              <template #pre>
                <FontAwesomeIcon
                  :icon="['fas', 'stars']"
                  class="text-info-text w-8 h-8"
                />
              </template>
              Most Popular
            </BittsTag>
          </div>
          <span class="text-neutral-text-weak text-sm pr-4">{{
            tagText(option)
          }}</span>
        </div>
      </template>
      <template #popover="{ option }">
        <div class="flex flex-col gap-8 p-8">
          <div
            v-for="[object, columns] in presentObjects({
              info: option.popover,
              columnToFriendlyNameMap,
            })"
            :key="object"
          >
            <div class="text-neutral-text-weak text-sm mb-4">
              {{ startCase(object) }}
            </div>
            <div class="flex flex-wrap justify-start items-center gap-4">
              <BittsTag
                v-for="column in objectColumnsSortedByNickname({
                  object,
                  columns,
                  columnToFriendlyNameMap,
                })"
                :key="column"
                variant="rounded"
                size="x-small"
              >
                {{ columnToFriendlyNameMap[object][column] }}
              </BittsTag>
            </div>
          </div>
          <span
            v-if="option.value === RECOMMENDED_TEMPLATE.type"
            class="text-neutral-text-weak text-sm italic"
          >
            Sometimes we add more fields to this setting to give you the best
            ecosystem insights we can in Crossbeam
          </span>
        </div>
      </template>
    </BittsRadioGroupCards>
    <BittsButton
      size="large"
      class="w-full"
      text="Continue"
      data-testid="easy-select-review-button"
      @click="handlePreselection"
    />
  </div>
</template>
<script setup>
import {
  BittsButton,
  BittsRadioGroupCards,
  BittsSvg,
  BittsTag,
} from '@crossbeam/bitts';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { startCase } from 'lodash';
import { computed, inject, onMounted, ref } from 'vue';

import useDataTemplates from '@/composables/useDataTemplates';
import {
  MD_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
  getRequiredColumns,
  isValidSourceField,
} from '@/constants/data_sources';
import { usePopulationsStore, useSourcesStore } from '@/stores';

const emit = defineEmits(['review', 'customize']);
const props = defineProps({
  sources: {
    type: Array,
    default: () => [],
  },
  feed: {
    type: Object,
    required: true,
  },
});

const {
  CUSTOM,
  RECOMMENDED_TEMPLATE,
  objectColumnsSortedByNickname,
  presentObjects,
} = useDataTemplates();

const OPTIONS = ref([
  {
    value: RECOMMENDED_TEMPLATE.type,
    label: RECOMMENDED_TEMPLATE.friendly_name,
    description: RECOMMENDED_TEMPLATE.description,
    popover: filterInvalidFields({
      template: RECOMMENDED_TEMPLATE[props.feed.integration.type],
      sources: props.sources,
    }),
  },
  {
    value: CUSTOM,
    label: 'Custom',
    description: 'Define a custom selection of your data',
  },
]);

const requiredColumns = ref({});
const columnToFriendlyNameMap = inject('columnToFriendlyNameMap');

onMounted(async () => {
  const { initPopulationsStore } = usePopulationsStore();
  const { refreshSourcesStore } = useSourcesStore();
  await refreshSourcesStore();
  await initPopulationsStore();
  requiredColumns.value = await getRequiredColumns(props.feed);
});

function tagText(option) {
  if (!option.popover) return 'up to 100 fields';
  const fieldCount = Object.values(option.popover).flat().length;
  return `${fieldCount} fields`;
}

/* We only want to override their custom selection if they haven't opened the custom view before,
and if their feed hasn't already synced. In those cases, don't change the selected fields. */
function handlePreselection() {
  if (selectedOption.value === CUSTOM)
    emit('customize', { selectedPreset: CUSTOM });
  else
    emit('review', {
      newCheckedFields: recommendedFieldIds.value,
      selectedPreset: selectedOption.value,
    });
}

const crm = computed(() => props.feed.integration.type);
const isSalesforce = computed(() => crm.value === SALESFORCE_DATA_SOURCE_TYPE);
const isMicrosoftDynamics = computed(() => crm.value === MD_DATA_SOURCE_TYPE);

/* Initial selected option is 'recommended' */
const selectedOption = ref(
  OPTIONS.value.find((v) => v.value === RECOMMENDED_TEMPLATE.type).value,
);

function handleOptionChange(option) {
  selectedOption.value = option;
}

const recommendedFieldIds = computed(() => {
  const template = RECOMMENDED_TEMPLATE[crm.value];
  const sourceTables = Object.keys(template);
  const fields = new Set();
  for (const source of props.sources) {
    if (sourceTables.includes(source.table)) {
      for (const field of source.fields) {
        if (!isValidSourceField(field)) continue;
        if (template[source.table]?.includes(field.column))
          fields.add(field.id);
        if (requiredColumns.value[source.table].includes(field.column))
          fields.add(field.id);
      }
    }
  }
  return Array.from(fields);
});

/* It's possible for a field that's in our recommended template to have a
syncing_disabled reason. It's also possible they're missing an entire
object. If that's the case, we don't want to show those fields
in the tooltip, or count them in the tag, or sync them. */
function filterInvalidFields({ template, sources }) {
  return Object.entries(template).reduce((acc, [object, fields]) => {
    const matchingObject = sources.find((s) => s.table === object);
    if (!matchingObject) return acc;
    const matchingFields = matchingObject.fields
      .filter((sourceField) => fields.includes(sourceField.column))
      .filter(isValidSourceField)
      .map((field) => field.column);

    acc[object] = matchingFields;
    return acc;
  }, {});
}
</script>
<style lang="pcss" scoped>
.c-easy-select-fields {
  margin-top: -24px;
  @apply text-center;
}

.c-easy-select-fields__header {
  @apply flex flex-col items-center mb-8;
}

.c-easy-select-fields__summary {
  @apply text-neutral-text text-m mb-24;
}

h1 {
  @apply text-neutral-text-strong text-xl font-bold mt-16;
}
</style>
<style lang="pcss">
.c-easy-select-fields {
  .ant-radio-wrapper {
    @apply text-left;
  }

  .ant-radio-wrapper > span:not(.ant-radio) {
    @apply w-full;
  }

  .ant-radio-wrapper.slim {
    @apply pr-0;
  }
}

.c-data-templates {
  .c-bitts-modal__content {
    .bitts-tag.bitts-tag__rounded .ant-tag {
      @apply pt-0 pb-0;
    }
  }
}

/* The BittsPopover by default was below the EasySelectFields
modal and took up the entire screen space */
.bitts-radio-group-tooltip.bitts-overflow-menu.ant-popover {
  @apply z-[1037] max-w-[400px];
  .ant-popover-inner {
    @apply border-none;
  }
}

/* Center the FontAwesomeIcon under the #pre slot with the text */
.c-easy-select-fields__recommended_tag.bitts-tag {
  .ant-tag {
    @apply cursor-pointer flex items-center gap-4;
  }
}
</style>

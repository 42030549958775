<template>
  <component :is="tooltipOrDiv" class="bitts-avatar" v-bind="tooltipProps">
    <a-avatar
      v-if="showImage"
      class="bitts-avatar--icon"
      :alt="altText"
      :size="sizeNumber"
      :src="imageURL"
      :shape="shape"
      :load-error="onLoadError"
      :class="allClasses"
    />
    <a-avatar
      v-else
      class="bitts-avatar--icon flex items-center justify-center"
      :alt="altText"
      :class="allClasses"
      :size="sizeNumber"
      :shape="shape"
    >
      <template #icon>
        <div v-if="isIcon" :class="iconClasses" class="bitts-avatar__icon-slot">
          <slot name="icon" />
        </div>
        <BittsSvg v-else-if="customSvg" :class="iconClasses" :svg="customSvg" />
        <div v-else-if="showInitials && initials">
          {{ initials }}
        </div>
        <BittsSvg v-else :svg="fallbackImage" />
      </template>
    </a-avatar>
    <template #title>
      {{ tooltipText }}
      <slot name="additionalInfo" />
    </template>
    <slot name="additional-icon" />
  </component>
</template>

<script setup lang="ts">
import { Avatar as AAvatar } from 'ant-design-vue';
import { Md5 } from 'ts-md5';
import { computed, ref } from 'vue';

import BittsSvg from '../BittsSvg/BittsSvg.vue';
import BittsTooltip from '../BittsTooltip/BittsTooltip.vue';

import { Shape, Size, TOrganization, TShape, TSize, TUser } from './types';

export interface Props {
  altTextFromParent?: string;
  size?: TSize;
  shape?: TShape;
  org?: TOrganization | null;
  user?: TUser | null;
  iconSlotBgColor?: string;
  imageSource?: string;
  includeHoverText?: boolean;
  mountTooltipToBody?: boolean;
  fallbackImageType?: string;
  showBorder?: boolean;
  showInitials?: boolean;
  rounded?: boolean;
  isIcon?: boolean;
  isEntity?: boolean;
  entityUrl?: string | null;
  avatarClasses?: string;
  defaultUserImage?: string;
  customSvg?: string;
  isOwn?: boolean;
  forceCircle?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  altTextFromParent: '',
  size: Size.Large,
  shape: Shape.Circle,
  org: null,
  user: null,
  iconSlotBgColor: '',
  imageSource: '',
  includeHoverText: false,
  mountTooltipToBody: false,
  fallbackImageType: 'company',
  showBorder: false,
  showInitials: false,
  rounded: true,
  isIcon: false,
  isEntity: false,
  entityUrl: null,
  avatarClasses: '',
  defaultUserImage: '',
  customSvg: '',
  isOwn: true,
  forceCircle: false,
});

const emit = defineEmits(['loading-failed']);

const errorOnLoad = ref(false);

const orgDomain = computed(() => {
  if (!props.org) return '';
  return props.org.domain || props.org.clearbit_domain || '';
});

const clearbitURL = computed(() => {
  if (!props.org) return '';
  return `https://logo.clearbit.com/${orgDomain.value}`;
});

const avatarType = computed(() => {
  if (props.isEntity) return 'entity';
  if (props.user) return 'user';
  if (props.org) return 'org';
  if (props.isIcon) return 'icon';
  return '';
});

const gravatarHref = computed(() => {
  if (!props.user) return '';
  if (props.user.picture_url) return props.user.picture_url;
  if (!props.user.email) return '';

  const emailMd5 = Md5.hashStr(props.user.email);
  return 'https://secure.gravatar.com/avatar/'.concat(
    `${emailMd5}?s=300&d=404&r=g`,
  );
});

const imageURL = computed(() => {
  if (props.imageSource) return props.imageSource;
  if (props.org) {
    return props.org.logo_url ? props.org.logo_url : clearbitURL.value;
  }
  if (props.user) {
    return gravatarHref.value ? gravatarHref.value : props.defaultUserImage;
  }
  if (props.isEntity && props.entityUrl) return props.entityUrl;
  return '';
});

const showImage = computed(() => {
  return !!imageURL.value && !errorOnLoad.value;
});

const radiusAmount = computed(() => {
  if (props.forceCircle) return 'rounded-full';
  return {
    large: 'rounded-bts-lg',
    medium: 'rounded-bts-md',
    small: 'rounded-bts-sm',
    'x-small': 'rounded-bts-xs',
    tiny: 'rounded-3',
  }[props.size];
});

const fontClassBySize = (isUser: boolean) => {
  const mapSizeToFontOrg: {
    [key in TSize]: string;
  } = {
    large: 'text-xxxl',
    medium: 'text-lg',
    small: 'text-base',
    'x-small': 'text-xs',
    tiny: 'text-xs',
  };
  const mapSizeToFontUser = {
    large: 'text-xxl',
    medium: 'text-m',
    small: 'text-xs',
    'x-small': 'text-xs',
    tiny: 'text-xs',
  };
  if (isUser) {
    return mapSizeToFontUser[props.size];
  }
  return mapSizeToFontOrg[props.size];
};

const allClasses = computed(() => {
  const isPartnerUser =
    !showImage.value && props.showInitials && !props.isOwn && !!props.user;
  const isPartnerOrg =
    !showImage.value && props.showInitials && !props.isOwn && !props.user;
  const isOwnUser =
    !showImage.value && props.showInitials && props.isOwn && !!props.user;
  const isOwnOrg =
    !showImage.value && props.showInitials && props.isOwn && !props.user;
  const useRadius =
    (avatarType.value === 'org' ||
      avatarType.value === 'icon' ||
      !!props.customSvg) &&
    props.rounded &&
    !props.isEntity;
  const classes: Record<string, boolean> = {
    'border-none': !props.showBorder && (props.isEntity || props.isIcon),
    'rounded-none': !props.rounded || props.isEntity,
    'bg-upsell-background-weak text-upsell-text': isPartnerUser,
    'bg-beta-background-weak text-beta-text font-bold': isPartnerOrg,
    'bg-upsell-background-medium text-white': isOwnUser,
    'bg-beta-background-medium text-white': isOwnOrg,
  };
  classes[radiusAmount.value] = !!useRadius;
  classes[fontClassBySize(!!props.user)] = true;
  if (props.avatarClasses) {
    classes[props.avatarClasses] = true;
  }
  return classes;
});

const altText = computed(() => {
  if (props.altTextFromParent) return props.altTextFromParent;
  if (props.user) {
    if (props.user.first_name) {
      return `${props.user.first_name} ${props.user.last_name}`;
    }
    return props.user.email;
  }
  if (props.org) {
    if (props.org.name) {
      return props.org.name;
    }
    return orgDomain.value;
  }
  // otherwise, it's decorative
  return '';
});

const tooltipText = computed(() => {
  let text = '';
  if (props.user && props.user.first_name) {
    text = props.user.first_name;
    if (props.user.last_name) {
      text += ` ${props.user.last_name}`;
    }
  } else if (props.org && props.org.name) {
    text = props.org.name;
  }
  return text;
});

const iconClasses = computed(() => {
  const classes: Record<string, boolean> = {};
  classes[iconSlotClass.value] = true;
  if (props.iconSlotBgColor) {
    classes[props.iconSlotBgColor] = true;
  }
  return classes;
});

const iconSlotClass = computed(() => {
  return (
    {
      large: 'bitts-avatar__icon-slot-large',
      medium: 'bitts-avatar__icon-slot-medium',
      small: 'bitts-avatar__icon-slot-small',
      'x-small': 'bitts-avatar__icon-slot-x-small',
      tiny: 'bitts-avatar__icon-slot-tiny',
    } as const
  )[props.size];
});

const sizeNumber = computed(() => {
  const mapSizeNameToNumber = {
    large: 80,
    medium: 40,
    small: 24,
    'x-small': 16,
    tiny: 12,
  };
  return mapSizeNameToNumber[props.size];
});

const tooltipOrDiv = computed(() => {
  return props.includeHoverText ? BittsTooltip : 'div';
});

const tooltipProps = computed(() => {
  const propsData: Record<string, string | boolean> = {};
  if (props.includeHoverText) {
    propsData.trigger = 'hover';
    propsData.placement = 'bottom';
    propsData.mountToBody = props.mountTooltipToBody;
  }
  return propsData;
});

const initials = computed(() => {
  if (props.org && props.org.name) {
    let orgInitials = '';
    // we only want two initials max
    const orgWordsArray = props.org.name.split(' ').slice(0, 2);
    orgWordsArray.forEach((word) => {
      orgInitials += word.toUpperCase()[0];
    });
    return ['x-small', 'small'].includes(props.size)
      ? orgInitials[0]
      : orgInitials;
  }
  if (props.user?.first_name) {
    const firstInitial = props.user?.first_name?.toUpperCase()[0] || '';
    const lastInitial = props.user?.last_name?.toUpperCase()[0] || '';
    return props.size === 'large' || props.size === 'medium'
      ? firstInitial + lastInitial
      : firstInitial;
  }
  return '';
});

const fallbackImage = computed(() => {
  return props.user || props.fallbackImageType === 'person'
    ? 'userLogo05'
    : 'orgLogo01';
});

const onLoadError = () => {
  errorOnLoad.value = true;
  emit('loading-failed');
  return true;
};
</script>

<style lang="pcss">
.bitts-avatar {
  @apply flex justify-center;
  .ant-avatar > img {
    object-fit: contain;
  }

  .ant-avatar {
    font-family: inherit;
  }

  .ant-avatar-circle {
    @apply border border-solid border-neutral-border;
  }

  .ant-avatar-square {
    @apply border border-solid border-neutral-border rounded-bts-base;
  }

  .ant-avatar-square.ant-avatar-icon {
    @apply bg-neutral-background-disabled;
  }

  .ant-avatar.ant-avatar-square.ant-avatar-image {
    @apply p-0 bg-white;
  }

  .ant-avatar.ant-avatar-circle.ant-avatar-image {
    @apply p-0;
  }

  .bitts-avatar__icon-slot {
    @apply flex items-center justify-center h-full w-full;
  }

  .bitts-avatar__icon-slot-large {
    svg {
      @apply h-40;
    }
  }

  .bitts-avatar__icon-slot-medium {
    svg {
      @apply h-20;
    }
  }

  .bitts-avatar__icon-slot-small {
    svg {
      @apply h-12;
    }
  }

  .bitts-avatar__icon-slot-x-small {
    svg {
      @apply h-8;
    }
  }

  svg {
    vertical-align: initial;
  }
}
</style>

<template>
  <BittsModal
    class="c-request-data-modal__container"
    save-text="Send request"
    :saving="requestLoading"
    :visible="dataShareRequestModalVisible"
    :width="504"
    :show-buttons="partnerSelectionMade"
    :disabled="noOptionsChecked"
    cancel-type="danger"
    cancel-button-type="outline"
    @closed="modalClosed"
    @saved="requestSharedData"
  >
    <template #title>
      <div
        class="font-bold text-neutral-text-strong text-lg"
        data-testid="modal-title"
      >
        {{ modalTitle }}
      </div>
    </template>
    <template #content>
      <div
        class="text-neutral-text mb-24"
        :class="{
          'text-center text-m': !partnerSelectionMade,
          'text-base': partnerSelectionMade,
        }"
      >
        {{ modalSubtitle }}
      </div>
      <PartnerSelectionModal
        v-if="!partnerSelectionMade"
        :partner-orgs="filteredPartnerOrgs"
        @partner-selected="onPartnerSelected"
      />
      <div v-else>
        <div>
          <div class="font-bold text-base text-neutral-text-strong mb-12">
            Partner Information
          </div>
          <div class="c-request-data-modal__partner-info">
            <div class="c-request-data-modal__company-info">
              <BittsAvatar
                shape="square"
                :org="selectedPartner"
                size="small"
                class="mr-12"
              />
              <div class="c-request-data-modal__partner-org-name">
                {{ selectedPartner?.name }}
              </div>
            </div>
            <div
              v-if="hasSinglePopulation"
              class="c-request-data-modal__population-info"
            >
              <FontAwesomeIcon
                :icon="['far', 'chart-pie-simple']"
                :style="{ height: '16px', width: '16px' }"
                class="mr-8 self-center text-neutral-accent"
              />
              <div class="c-request-data-modal__population-name">
                {{ currentPopulation?.name }}
              </div>
            </div>
            <div v-else>
              <BittsTooltip placement="topRight">
                <BittsTag :text="`${numPops} populations`" variant="rounded">
                  <template #pre>
                    <FontAwesomeIcon
                      :icon="['fas', 'chart-pie-simple']"
                      :style="{ height: '12px', width: '12px' }"
                      class="text-neutral-accent mr-4"
                    />
                  </template>
                </BittsTag>
                <template #title>
                  {{ popTooltipText }}
                </template>
              </BittsTooltip>
            </div>
          </div>
          <div v-if="!hasSinglePopulation" class="flex flex-col gap-8 mb-24">
            <div
              v-for="partnerPop in requestOptions"
              :key="partnerPop.id"
              :data-testid="`request-option--${partnerPop.id}`"
              @click="(e) => onPopulationSelected(partnerPop, e)"
            >
              <div class="flex justify-between">
                <BittsCheckbox
                  :label="partnerPop.name"
                  :checked="partnerPop.checked"
                />
                <BittsTag
                  v-if="partnerPop.standard_type"
                  text="Standard"
                  color="info"
                  variant="rounded"
                  size="x-small"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="c-request-data-modal__message-section">
          <BittsTextArea
            v-model="message"
            placeholder="Send your partner a detailed message describing the data or specific fields you’d like access to. "
            form-label="Add a message (optional)"
          />
        </div>
      </div>
    </template>
  </BittsModal>
</template>
<script setup>
import {
  BittsAvatar,
  BittsCheckbox,
  BittsModal,
  BittsTag,
  BittsTextArea,
  BittsTooltip,
} from '@crossbeam/bitts';

import axios from 'axios';
import { sortBy } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import PartnerSelectionModal from '@/components/partners/PartnerSelectionModal.vue';

import useIteratively from '@/composables/useIteratively';
import { EVENT_SITES } from '@/constants/analytics';
import { SHARING } from '@/constants/data_shares';
import {
  useDataRequestsStore,
  useFlashesStore,
  usePartnersStore,
} from '@/stores';
import urls from '@/urls';

const props = defineProps({
  orgIds: {
    type: Array,
    default: null,
  },
  dataShareRequestModalVisible: {
    type: Boolean,
    required: true,
  },
  partnerPops: {
    type: Object,
    default: null,
  },
});

const emits = defineEmits(['modal-closed']);
const { iteratively } = useIteratively();
const requestLoading = ref(false);
const message = ref(null);
const selectedPartner = ref(null);
const selectedPopIds = ref([]);

const { refreshDataRequestsStore } = useDataRequestsStore();
const { addErrorFlash, addSuccessFlash } = useFlashesStore();
const { getPartnerOrgById } = storeToRefs(usePartnersStore());

const partnerOrgs = computed(() =>
  props.orgIds.map((org) => getPartnerOrgById.value(org)),
);
const filteredPartnerOrgs = computed(() =>
  partnerOrgs.value.filter((org) =>
    props.partnerPops[org?.id]?.some(
      (pop) =>
        !pop.dataShare.visibility.includes(SHARING) &&
        pop.requestDataIsVisible &&
        !pop.dataRequestInformation,
    ),
  ),
);
const currentPopulation = computed(
  () => props.partnerPops[selectedPartner.value.id][0],
);

const modalTitle = computed(() =>
  partnerSelectionMade.value ? 'Request Data' : 'Select a Partner',
);
const modalSubtitle = computed(() =>
  hasSinglePopulation.value
    ? 'Review partner information and add an optional message'
    : partnerSelectionMade.value
      ? 'Review population selections and add an optional message'
      : 'Select a partner to request data from',
);

const numPops = computed(
  () =>
    selectedPartner.value &&
    props.partnerPops[selectedPartner.value?.id]?.length,
);
const popTooltipText = computed(() =>
  props.partnerPops[selectedPartner.value?.id]
    .map((pop) => pop.name)
    .join(', '),
);
const partnerSelectionMade = computed(() => !!selectedPartner.value);
const hasSinglePopulation = computed(() => numPops.value === 1);

const headerFlexDirection = computed(() =>
  partnerSelectionMade.value ? 'row' : 'column-reverse',
);

const requestOptions = ref([]);
function setRequestOptions() {
  requestOptions.value = sortBy(
    props.partnerPops[selectedPartner.value?.id]
      ?.filter((pop) => pop.requestDataIsVisible && !pop.dataRequestInformation)
      .reduce((acc, pop, idx) => {
        selectedPopIds.value.push(pop.id);
        acc[idx] = { checked: true, ...pop };
        return acc;
      }, []),
    ['standard_type'],
  );
}

const noOptionsChecked = computed(
  () =>
    selectedPartner.value &&
    !hasSinglePopulation.value &&
    !requestOptions.value.filter((pop) => !!pop.checked).length,
);

watch(
  () => props.dataShareRequestModalVisible,
  (isCurrentlyVisible) => {
    if (isCurrentlyVisible) {
      selectedPartner.value =
        props.orgIds?.length === 1 ? partnerOrgs.value[0] : null;
      if (selectedPartner.value && !hasSinglePopulation.value)
        setRequestOptions();
    }
  },
);

const router = useRouter();
const route = useRoute();
async function modalClosed(response = null) {
  selectedPopIds.value = [];
  message.value = null;
  if (route.query?.partnerPopId) {
    await router.replace({
      name: 'partner_details',
      params: { partner_org_id: route.params.partnerOrgId },
      query: { tab: 'shared_with_you' },
    });
  }
  emits('modal-closed', response);
}

function onPartnerSelected(partner) {
  selectedPartner.value = partner;
  setRequestOptions();
}

function onPopulationSelected(partnerPop, e) {
  const selectedPop = requestOptions.value.find(
    (opt) => opt.id === partnerPop.id,
  );
  selectedPop.checked = e.target.checked;
  if (selectedPop.checked && !selectedPopIds.value.includes(selectedPop.id)) {
    selectedPopIds.value.push(selectedPop.id);
  } else {
    selectedPopIds.value = selectedPopIds.value.filter(
      (pop) => pop !== selectedPop.id,
    );
  }
}

async function requestSharedData() {
  requestLoading.value = true;
  try {
    const url = urls.shareRequests.default;
    const partnerPopsToRequest = selectedPopIds.value.length
      ? selectedPopIds.value
      : [currentPopulation.value.id];
    const payload = {
      partner_population_ids: partnerPopsToRequest,
      message: message.value,
      partner_org_id: selectedPartner.value.id,
    };
    const response = await axios.post(url, payload);
    let requestId = response?.data?.items[0].id;
    if (response?.data?.items.length > 1) {
      requestId = response.data.items.map((request) => request.id).toString();
    }
    iteratively.userRequestedDataShare({
      share_request_id: requestId,
      partner_org_id: selectedPartner.value.id.toString(),
      partner_population_id: `${partnerPopsToRequest}`,
      event_site: EVENT_SITES.DATA_SHARE_REQUEST_MODAL,
    });
    modalClosed(response.data.items[0]);
    await refreshDataRequestsStore();
    addSuccessFlash({
      message: 'Data Request Sent',
      description:
        "You'll receive a notification if your partner shares fields from this population with you.",
    });
  } catch (_err) {
    addErrorFlash({
      message: 'Data Request Not Sent',
      description:
        'Please try again or contact support@crossbeam.com for help.',
    });
  } finally {
    requestLoading.value = false;
  }
}
</script>
<style lang="pcss">
.c-request-data-modal__container {
  border-radius: 8px !important;
  .ant-modal-content .ant-modal-body div div {
    .c-bitts-modal__header {
      @apply flex flex-row-reverse items-center;
      div {
        flex-direction: v-bind(headerFlexDirection);
      }
    }
  }
}

.c-request-data-modal__partner-info {
  @apply flex flex-row mb-24 justify-between;
}

.c-request-data-modal__company-info,
.c-request-data-modal__population-info {
  @apply flex flex-row text-neutral-text-strong content-center mr-auto;
}

.c-request-data-modal__partner-org-name {
  @apply text-neutral-text-strong text-m font-bold;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 188px !important;
}

.c-request-data-modal__population-name {
  @apply text-neutral-text-strong text-m;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 204px !important;
}
</style>

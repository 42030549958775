<template>
  <div class="c-contacts-card">
    <div class="c-contacts-card__header">
      <CardHeader
        :source="source"
        :title="`${mdmType === 'account' ? entityName + ' Contacts' : 'Contact Information'}`"
        :info-tooltip="infoTooltip"
      />
    </div>
    <div class="c-contacts-card__content">
      <TransitionGroup name="fade">
        <ExpandableView
          v-for="(item, index) in source.items"
          :key="`contact_${index}`"
          class="c-contacts-card__item"
        >
          <template #header>
            <div
              :key="`contact_${index}_header`"
              class="c-contacts-card__item__header"
            >
              <BittsAvatar
                :user="{ email: getField(item, 'Email') }"
                :show-initials="false"
                :is-own="false"
                shape="circle"
                size="medium"
              />
              <div class="c-contacts-card__item__header__label ml-8">
                <span class="c-contacts-card__item__header__label__title">
                  {{ getField(item, 'Name') }}
                </span>
                <span class="c-contacts-card__item__header__label__subtitle">
                  {{ getField(item, 'Title') }}
                </span>
              </div>
              <div class="c-contacts-card__item__header__orgs">
                <CardOrgIcons
                  :orgs="orgs(item)"
                  key-segment="contacts_details"
                  class="c-contacts-card__item__header__orgs__icons"
                />
              </div>
            </div>
          </template>
          <template #collapsed="{ toggle }">
            <div
              :key="`contact_${index}_collapsed`"
              class="c-contacts-card__item__details"
            >
              <CardRecords
                :item="item"
                :show-from-icons="false"
                :key-segment="`contact_${index}`"
                :is-our-data-selected="isOurDataSelected"
              />
              <CardFooter
                v-if="hasOurs(item)"
                :toggle="toggle"
                :item="item"
                mode="collapsed"
              />
            </div>
          </template>
          <template #expanded="{ toggle }">
            <div
              :key="`contact_${index}_details`"
              class="c-contacts-card__item__details"
            >
              <CardRecords
                :item="item"
                :show-from-icons="true"
                :key-segment="`contact_${index}`"
                :is-our-data-selected="isOurDataSelected"
                mode="details"
              />
              <CardFooter
                v-if="hasOurs(item)"
                :toggle="toggle"
                :item="item"
                mode="expanded"
              />
            </div>
          </template>
        </ExpandableView>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import { BittsAvatar } from '@crossbeam/bitts';

import { mapActions, mapState } from 'pinia';

import CardFooter from '@/components/records/Card/CardFooter.vue';
import CardHeader from '@/components/records/Card/CardHeader.vue';
import CardRecords from '@/components/records/Card/CardRecords.vue';
import ExpandableView from '@/components/records/ExpandableView.vue';

import useAuth from '@/composables/useAuth';
import { useDataSharesStore, usePartnersStore } from '@/stores';

import CardOrgIcons from './CardOrgIcons.vue';

export default {
  name: 'ContactsCard',
  components: {
    BittsAvatar,
    ExpandableView,
    CardRecords,
    CardHeader,
    CardFooter,
    CardOrgIcons,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    },
    mdmType: {
      type: String,
      required: true,
    },
    isOurDataSelected: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { currentOrg } = useAuth();

    return {
      currentOrg,
    };
  },
  computed: {
    ...mapState(usePartnersStore, ['getPartnerOrgById']),
    infoTooltip() {
      return this.source.meta?.is_truncated
        ? 'A limited number of contacts appear below'
        : null;
    },
  },
  methods: {
    ...mapActions(useDataSharesStore, [
      'getDataShareById',
      'getDataShareWithPopulationInfo',
    ]),
    orgs(item) {
      const appliedIncomingDataShares = item.data_share_ids.map((dataShareId) =>
        this.getDataShareById(dataShareId),
      );
      const partnerOrgIds = new Set([
        ...appliedIncomingDataShares.map(
          (dataShare) => dataShare.partner_org_id,
        ),
      ]);
      const orgs = [...partnerOrgIds].map((orgId) => {
        const orgReceivingDataShares = appliedIncomingDataShares
          .filter((dataShare) => dataShare.partner_org_id === orgId)
          .map((dataShare) => ({
            ...this.getDataShareWithPopulationInfo(dataShare.id),
            type: 'receiving',
          }));

        return {
          ...this.getPartnerOrgById(orgId),
          dataShares: orgReceivingDataShares,
        };
      });
      if (item.is_ours) {
        orgs.push(this.currentOrg);
      }
      return orgs;
    },
    getField(item, columnName) {
      const field = item.fields.find(
        (field) => field.name === columnName || field.column === columnName,
      );
      return field ? field.value : null;
    },
    hasOurs(item) {
      return item.fields.find((field) => field.is_ours);
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-contacts-card {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.c-contacts-card__content {
  margin: 12px 0px;
  background: var(--white);
  border: 1px solid var(--grey6);
  border-radius: 4px;
}

.c-contacts-card__item {
  border-bottom: 1px solid var(--grey6);
  &:last-of-type {
    border-bottom: 0px;
  }
}

.c-contacts-card__item__header {
  display: grid;
  grid-template-columns: [avatar] 40px [label] 1fr [orgs] 1fr;
  width: 100%;
  height: 40px;
  padding: 12px;
}

.c-contacts-card__item__header__label {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.c-contacts-card__item__header__avatar {
  height: 35px;
  width: 35px;
}

.c-contacts-card__item__header__orgs {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.c-contacts-card__item__header__orgs__icons {
  margin: 0px;
}

.c-contacts-card__item__header__label__title {
  @apply text-brand-navy;
  font-size: 14px;
}

.c-contacts-card__item__header__label__subtitle {
  @apply text-brand-blue;
  font-size: 10px;
}

.c-contacts-card__item__details {
  padding: 12px;
}
</style>

<template>
  <div :class="`bitts-avatar-item-${size}`">
    <div>
      <BittsAvatarPip
        v-if="containsPip"
        :image-source="imageSource"
        :org="org"
        :user="user"
        :include-hover-text="includeHoverText"
        :is-user-main-avatar="isUserMainAvatar"
        :is-own="isOwn"
        :show-initials="showInitials"
        :size="size"
      />
      <BittsAvatar
        v-else
        :shape="shape"
        :image-source="imageSource"
        :org="org"
        :user="user"
        :include-hover-text="includeHoverText"
        :is-user-main-avatar="isUserMainAvatar"
        :is-own="isOwn"
        :show-initials="showInitials"
        :size="size"
      />
    </div>
    <div class="bitts-avatar-item__text">
      <div :class="titleTextClasses" class="bitts-avatar-item__text-title">
        {{ title }}
      </div>
      <div
        v-if="description"
        :class="secondaryTextClasses"
        class="bitts-avatar-item__text-secondary"
      >
        {{ description }}
      </div>
      <slot name="extraLine" />
    </div>
  </div>
</template>

<script>
import BittsAvatar from '../BittsAvatar/BittsAvatar.vue';
import BittsAvatarPip from '../BittsAvatarPip/BittsAvatarPip.vue';

export default {
  name: 'BittsAvatarItem',
  components: {
    BittsAvatar,
    BittsAvatarPip,
  },
  props: {
    spacing: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'condensed'].includes(value);
      },
    },
    shape: {
      type: String,
      default: 'circle',
      validator(value) {
        return ['circle', 'square'].includes(value);
      },
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium'].includes(value);
      },
    },
    org: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    containsPip: {
      type: Boolean,
      default: false,
    },
    imageSource: {
      type: String,
      default: '',
    },
    includeHoverText: {
      type: Boolean,
      default: false,
    },
    isUserMainAvatar: {
      type: Boolean,
      default: true,
    },
    showInitials: {
      type: Boolean,
      default: false,
    },
    isOwn: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isDefault() {
      return this.spacing === 'default';
    },
    isSmall() {
      return this.size === 'small';
    },
    titleTextClasses() {
      if (this.isSmall) {
        return {
          'text-neutral-text-strong': this.isDefault,
          'text-neutral-text': !this.isDefault,
        };
      }
      return {
        'text-m': this.isDefault,
        'text-base': !this.isDefault,
      };
    },
    secondaryTextClasses() {
      if (this.isSmall) {
        return {
          'text-neutral-text': this.isDefault,
          'text-neutral-text-weak': !this.isDefault,
        };
      }
      return {
        'text-base': this.isDefault,
        'text-sm': !this.isDefault,
      };
    },
  },
};
</script>

<style lang="pcss">
.bitts-avatar-item-medium {
  @apply flex items-center;

  .bitts-avatar-item__text {
    @apply flex flex-col justify-center ml-12;
  }

  .bitts-avatar-item__text-title {
    @apply font-bold text-neutral-900;
  }

  .bitts-avatar-item__text-secondary {
    @apply text-neutral-600;
  }
}

.bitts-avatar-item-small {
  @apply flex items-center;

  .bitts-avatar-item__text {
    @apply flex flex-col justify-center ml-8;
  }

  .bitts-avatar-item__text-title {
    @apply font-bold text-sm text-neutral-text-strong;
  }

  .bitts-avatar-item__text-secondary {
    @apply text-xs text-neutral-600;
  }
}
</style>

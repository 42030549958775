<template>
  <DropdownMenu
    :disabled="disabled"
    :close-on-click="closeOnClick"
    :content-extra-short="dropdownMenuHeight === 'extra-short'"
    :content-short="dropdownMenuHeight === 'short'"
    :content-medium="dropdownMenuHeight === 'medium'"
    :dropdown-content-class="dropdownContentClass"
    content-full-width
    @dropdown-opened="$emit('dropdown-opened', $event)"
    @dropdown-closed="$emit('dropdown-closed', $event)"
  >
    <template #dropdown-button>
      <slot :value="value" :placeholder="placeholder" name="dropdown-button">
        <span
          key="selected"
          :class="[
            'o-dropdown-select-button flex justify-between items-center',
            short ? 'o-dropdown-select-button--short' : null,
            selectedClass,
          ]"
        >
          <slot v-if="!value" name="placeholder">
            <div class="o-dropdown-select-button__placeholder">
              {{ placeholder }}
            </div>
          </slot>
          <slot v-else :value="value" name="selected">
            {{ value }}
          </slot>
          <span
            :class="{
              'o-dropdown-select-button__caret': true,
            }"
          >
            <FontAwesomeIcon
              :icon="['fas', 'chevron-down']"
              :style="{
                height: '10px',
                width: '10px',
                color: 'currentColor',
                'vertical-align': 'middle',
                display: 'block',
              }"
            />
          </span>
        </span>
      </slot>
    </template>
    <template #default="{ closeDropdown }">
      <slot :filter="filter" :set-filter="setFilter" name="selector-filter" />
      <slot
        :selector-options="selectorOptions"
        :close-dropdown="closeDropdown"
        :emit-selected="emitSelected"
        :selected-value="value"
        name="selector-items"
      >
        <DropdownMenuItem
          v-if="showPlaceholderDropdown"
          :key="'option_placeholder'"
          :class="{ 'c-dropdown-select__item--short': short }"
          class="c-dropdown-select__item c-dropdown-select__item--single-select"
          @item-chosen="$emit('input', null, closeDropdown)"
        >
          {{ placeholder }}
        </DropdownMenuItem>
        <div v-if="loading" class="c-selector__results--empty-state">
          <img
            class="c-selector__searching-icon"
            src="/images/gifs/loading.gif"
          />
          Searching...
        </div>
        <DropdownMenuItem
          v-for="(option, index) in selectorOptions"
          v-else
          :key="'option_' + index"
          :is-active="value === option"
          :class="[
            short ? 'c-dropdown-select__item--short' : null,
            optionWrapperClass,
          ]"
          class="c-dropdown-select__item c-dropdown-select__item--single-select"
          @item-chosen="$emit('input', option, closeDropdown)"
        >
          <slot :option="option" name="option">
            {{ option }}
          </slot>
        </DropdownMenuItem>
      </slot>
    </template>
  </DropdownMenu>
</template>

<script>
import DropdownMenu from '@/components/DropdownMenu.vue';
import DropdownMenuItem from '@/components/DropdownMenuItem.vue';

export default {
  name: 'Selector',
  components: {
    DropdownMenu,
    DropdownMenuItem,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    filterOnOptionProps: {
      type: Array,
      default: () => [],
    },
    value: {
      required: true,
      validator() {
        return true;
      },
    },
    placeholder: {
      type: String,
      default: 'Please select an option',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showPlaceholderDropdown: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    short: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeOnClick: {
      type: Boolean,
      default: true,
    },
    dropdownMenuHeight: {
      type: String,
      default: 'short',
    },
    selectedClass: {
      type: [String, Array],
      default: null,
    },
    optionWrapperClass: {
      type: [String, Array],
      default: null,
    },
    dropdownContentClass: {
      type: [String, Array],
      default: null,
    },
  },
  emits: ['input', 'dropdown-opened', 'dropdown-closed'],
  data() {
    return {
      filter: null,
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((option) => {
        if (typeof option === 'string' && this.filter) {
          return option.toLowerCase().includes(this.filter.toLowerCase());
        }

        if (
          typeof option === 'object' &&
          this.filter &&
          this.filterOnOptionProps.length > 0
        ) {
          return this.filterOnOptionProps.some((prop) => {
            if (!option[prop]) {
              return false;
            }
            return option[prop]
              .toLowerCase()
              .includes(this.filter.toLowerCase());
          });
        }
        return option;
      });
    },
    selectorOptions() {
      if (!this.$slots['selector-filter']) {
        return this.options;
      }
      return this.filteredOptions;
    },
  },
  watch: {
    value() {
      this.filter = null;
    },
  },
  methods: {
    emitSelected(option, closeDropdown) {
      this.$emit('input', option, closeDropdown);
    },
    setFilter(text) {
      this.filter = text;
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-selector__searching-icon {
  height: 32px;
  width: 32px;
}

.c-selector__results--empty-state {
  @apply text-brand-navy;
  display: flex;
  flex: 1;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  min-height: 80px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<template>
  <Notification :item="item">
    <template #logo>
      <BittsAvatar
        :org="partnerOrg"
        :show-initials="true"
        :is-own="false"
        size="medium"
      />
    </template>
    <template #message> Data Sharing Changed </template>
    <template #description>
      <span data-testid="c-report-population-removed-notification__description">
        {{ item.data.partner_org_name }} has changed sharing settings from their
        {{ item.data.population_name }} Population with you. This change may
        affect the following reports:
        {{ item.data.report_names.join(', ') }}</span
      >
    </template>
    <template #action>
      <BittsButton
        text="View Reports"
        type="neutral"
        variant="outline"
        size="x-small"
        @click="viewReport"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fas', 'eye-slash']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script>
import { BittsAvatar, BittsButton } from '@crossbeam/bitts';

import { mapActions, mapState } from 'pinia';

import Notification from '@/components/notifications/Notification.vue';

import { BASE_ROUTES } from '@/constants/routes';
import { useNotificationsStore, usePartnersStore } from '@/stores';

export default {
  name: 'ReportPopulationRemovedNotification',
  components: {
    BittsAvatar,
    BittsButton,
    Notification,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['action-clicked'],
  computed: {
    ...mapState(usePartnersStore, ['getPartnerOrgById']),
    partnerOrg() {
      return this.getPartnerOrgById(this.item.data.partner_org_id) || {};
    },
  },
  methods: {
    ...mapActions(useNotificationsStore, ['markRead']),
    async viewReport() {
      this.$emit('action-clicked', 'View Reports');
      await this.markRead({ id: this.item.id, isRead: true });
      await this.$router.push({ name: BASE_ROUTES.REPORTS });
    },
  },
};
</script>

import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { captureException } from '@/errors';
import {
  Notification,
  NotificationBase,
  NotificationSetting,
} from '@/interfaces/notifications';
import urls from '@/urls';

import { initStore, pollStore } from './store-utils';

export const useNotificationsStore = defineStore('Notifications', () => {
  const newItemsHref = ref<string | null>(null);
  const allNotifications = ref<Notification[]>([]);
  const settings = ref<NotificationSetting[]>([]);

  const unreadNotifications = computed(() =>
    allNotifications.value.filter((n) => !n.is_read),
  );

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    try {
      const { data } = await axios.get(urls.notifications.all);
      allNotifications.value = data.items.filter(
        (item: Notification) => item.event_type !== 'data_request',
      );
      newItemsHref.value = data.prev_href;
      await refreshSettings();
    } catch (err) {
      captureException(err);
    }
  });

  async function refreshSettings(): Promise<void> {
    const { data } = await axios.get(urls.notifications.settings.default);
    settings.value = data.items;
  }
  async function markRead({ id, isRead }: { id: string; isRead: boolean }) {
    const { data } = await axios.patch(urls.notifications.one(id), {
      is_read: isRead,
    });
    const index = allNotifications.value.findIndex(
      (note: NotificationBase) => note.id === data?.id,
    );
    allNotifications.value.splice(index, 1, data);
  }
  async function markAllRead(): Promise<void> {
    await axios.post(urls.notifications.markAllRead);
    allNotifications.value.map((note) => {
      note.is_read = true;
    });
  }
  async function bulkUpdateSettings({
    items,
  }: {
    items: Notification[];
  }): Promise<void> {
    const url = urls.notifications.settings.bulk;
    await axios.put(url, { items });
    await refreshSettings();
  }

  const { startPolling, stopPolling, isPolling } = pollStore(
    readySync,
    refresh,
  );

  return {
    error,
    ready,
    readySync,
    running,
    refreshNotificationsStore: refresh,
    newItemsHref,
    allNotifications,
    unreadNotifications,
    settings,
    markRead,
    markAllRead,
    bulkUpdateSettings,
    startPolling,
    stopPolling,
    isPolling,
  };
});

<template>
  <loading :is-loading="pageLoading" />
</template>

<script>
import { useHead } from '@unhead/vue';
import axios from 'axios';
import { get } from 'lodash';
import { mapActions } from 'pinia';

import {
  DATA_TEMPLATES_CRMS,
  MD_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { getAndClearLSNextUrl } from '@/local_storage';
import { useFeedsStore, useFlashesStore } from '@/stores';
import { useRootStore } from '@/stores/RootStore';
import urls from '@/urls';

export default {
  setup() {
    useHead({
      title: 'OAuth - Crossbeam',
    });
    const { loadUserProfile } = useRootStore();

    return { loadUserProfile };
  },

  data() {
    return {
      pageLoading: true,
    };
  },
  async created() {
    let nextPage = null;
    const nextFromLS = getAndClearLSNextUrl();
    const params = this.$route.query;

    let response;

    try {
      response = await axios.get(urls.oauth.callback, { params });
    } catch (err) {
      const errMsg = get(err, ['response', 'data', 'error']);
      const toastObj = {
        message: 'Data Source Connection Failed',
        description: errMsg,
      };
      if (errMsg) this.addErrorFlash(toastObj);
      this.pageLoading = false;
      this.$router.push({ name: 'data-sources' });
      return;
    }

    /* Check if we're on the first oAuth of Microsoft Dynamics,
        and if so, then just send the user to the pick-CRM component
        which will make another oAuth for feed creation */
    const isMdDiscovery =
      response.data.integration_type === MD_DATA_SOURCE_TYPE;
    if (isMdDiscovery) {
      const instances = response.data.instances.value;
      this.$router.push({
        name: 'dynamics-pick-crm',
        query: {
          instances: JSON.stringify(instances),
        },
      });
      return;
    }

    try {
      // Hubspot requires a redirect_uri to the /oauth/callback page,
      // so we track our starting point in oauth state on the backend
      nextPage = {
        name: 'data-sources-id',
        params: { id: response.data.feed.id },
        query: { successfulOauth: true },
      };
      if (response.data.feed.status === 'REQUIRE_SOURCES_SELECTION') {
        const toDataTemplates = DATA_TEMPLATES_CRMS.includes(
          response.data.feed.integration?.type,
        );
        nextPage = {
          name: toDataTemplates ? 'data-templates' : 'data-sources-settings',
          params: { id: response.data.feed.id },
        };
        await this.refreshFeedsStore();
      }
      await this.loadUserProfile();
    } catch (err) {
      const errMsg = get(err, ['response', 'data', 'error']);
      const toastObj = {
        message: 'Data Source Connection Failed',
        description: errMsg,
      };
      if (errMsg) {
        this.addErrorFlash(toastObj);
      } else {
        throw err;
      }
    } finally {
      this.pageLoading = false;
      this.$router.push(nextFromLS || nextPage || { name: 'data-sources' });
    }
  },
  methods: {
    ...mapActions(useFlashesStore, ['addErrorFlash']),
    ...mapActions(useFeedsStore, ['refreshFeedsStore']),
  },
};
</script>

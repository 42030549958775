import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import useBilling from '@/composables/useBilling';
import useHasFeature from '@/composables/useHasFeature';
import useRecordExportLimits from '@/composables/useRecordExportLimits';
import appConfig from '@/config';
import { BILLING_CTAS, EVENT_SITES } from '@/constants/analytics';
import {
  ACTIVE,
  CANCELLED,
  LIMIT_REACHED,
  OVER_90,
  PAST_DUE,
} from '@/constants/billing';
import { useBillingStore, useTeamStore } from '@/stores';
import { useRootStore } from '@/stores/RootStore';

export default function () {
  const billingStore = useBillingStore();
  const teamStore = useTeamStore();

  const {
    isSubscriptionPastDue,
    isSubscriptionCancelled,
    subscriptionRenewalDate,
    isConnectorTier,
    chargifyData,
  } = storeToRefs(billingStore);

  const { debookingEnabled, onLatestBilling } = useHasFeature();
  const { isAdminPanelUser, currentOrg } = useAuth();
  const { authorizations } = storeToRefs(teamStore);
  const route = useRoute();
  const router = useRouter();

  /* Admin Alert: Those who are oauth'ed into production as an admin */
  const adminAlert = computed(() =>
    isAdminPanelUser.value && appConfig.env === 'prod'
      ? {
          message: `You are logged into production (${currentOrg.value.name}) as an admin!`,
          type: 'admin',
        }
      : null,
  );

  /* Billing Alert (Overdue Alert or EA Sunsetting Alert) */
  const billingAlert = computed(() => {
    if (isSubscriptionPastDue.value) {
      const billingDate = DateTime.fromISO(
        chargifyData.value?.open_invoice_issue_date,
      );
      const cancellationDate = billingDate
        .plus({ days: 30 })
        .toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' });
      return {
        type: PAST_DUE,
        buttonText: 'Update Billing',
        message: `Your payment failed, we will try the card again until ${cancellationDate} and then suspend your account`,
        path: 'edit-payment',
      };
    }

    const store = useRootStore();
    const currentAuth = store.currentAuth;
    const currentUser = store.currentUser;
    const isAdmin =
      currentAuth?.role?.name === 'Admin' || import.meta.env.MODE === 'test';

    const auth = authorizations.value.find(
      (a) => a.user?.id === currentUser.id,
    );
    const isRetainingAccess = !auth?.losing_access;

    const warningAlertTemplate = {
      icon: ['fad', 'stopwatch'],
      type: CANCELLED,
    };

    if (isSubscriptionCancelled.value && onLatestBilling.value) {
      let msg = 'Your plan has been cancelled, ';
      if (!isAdmin && !isRetainingAccess)
        msg += `you will lose access to Crossbeam on ${subscriptionRenewalDate.value}. Contact your admin with any questions.`;
      else if (!isAdmin && isRetainingAccess)
        msg += `you will lose access to paid features on ${subscriptionRenewalDate.value}, Contact your admin with any questions`;
      else if (isAdmin && !isRetainingAccess)
        msg += `you will lose access to Crossbeam on ${subscriptionRenewalDate.value}`;
      else if (isAdmin && isRetainingAccess)
        msg += `you will lose access to paid features on ${subscriptionRenewalDate.value}`;

      warningAlertTemplate.message = msg;

      /* Only admins can restore a plan */
      if (isAdmin) {
        warningAlertTemplate.buttonText = 'Restore Plan';
        warningAlertTemplate.path = 'restore-plan';
      }
      return warningAlertTemplate;
    }

    /* Folks who are not on latest billing cannot restore after a cancellation */
    if (isSubscriptionCancelled.value) {
      let msg = `Your plan has been cancelled, you will lose access to paid features on ${subscriptionRenewalDate.value}.`;
      msg +=
        isAdmin && isRetainingAccess
          ? ' After that you can upgrade again.'
          : ' Contact your admin with any questions.';
      warningAlertTemplate.message = msg;

      return warningAlertTemplate;
    }

    if (isConnectorTier.value && debookingEnabled.value && isAdmin) {
      return {
        message: `You are in your renewal period. Confirm your plan details and make any changes before ${subscriptionRenewalDate.value}`,
        type: ACTIVE,
        buttonText: 'Learn More',
        path: 'billing',
      };
    }
    return null;
  });

  /* Export Limit Alert (For export limits being reached) */
  const { exportLimitStatus, RECORD_EXPORT_ALERTS } = useRecordExportLimits();
  const exportAlertAndOrgId = computed(
    () => `${exportLimitStatus.value}__${currentOrg.value.id}`,
  );
  const dismissedLimitAlerts = ref([]);

  const showExportLimitAlert = computed(() => {
    if (dismissedLimitAlerts.value.includes(exportAlertAndOrgId.value))
      return false;
    return [OVER_90, LIMIT_REACHED].includes(exportLimitStatus.value);
  });

  watch(exportAlertAndOrgId, (newVal) => {
    const exportLimitAlertDismissal = window.localStorage?.getItem(newVal);
    if (
      exportLimitAlertDismissal &&
      exportLimitAlertDismissal === 'dismissed'
    ) {
      dismissedLimitAlerts.value.push(exportAlertAndOrgId.value);
    }
  });

  /* Handlers for events from alerts */
  function onDismissAlert(type) {
    if ([LIMIT_REACHED, OVER_90].includes(type)) {
      window.localStorage?.setItem(exportAlertAndOrgId.value, 'dismissed');
      dismissedLimitAlerts.value.push(exportAlertAndOrgId.value);
    }
  }

  const { talkToSales } = useBilling();
  async function onAlertClicked({ type, path = null }) {
    if ([LIMIT_REACHED, OVER_90].includes(type)) {
      talkToSales({
        cta: BILLING_CTAS.RECORD_EXPORT_LIMIT,
        talkToSalesReason: 'Record Export limit',
        event_site: EVENT_SITES.BILLING_ALERT_BANNER,
      });
    }
    if (path) await router.push({ name: path, query: { cancel: route.name } });
  }

  const allAlerts = computed(() => {
    const alerts = [];
    if (billingAlert.value) alerts.push(billingAlert.value);
    if (adminAlert.value) alerts.push(adminAlert.value);
    if (showExportLimitAlert.value)
      alerts.push(RECORD_EXPORT_ALERTS[exportLimitStatus.value]);
    return alerts;
  });

  const numAlerts = computed(() => allAlerts.value.length);

  return {
    billingAlert,
    adminAlert,
    showExportLimitAlert,
    onDismissAlert,
    onAlertClicked,
    allAlerts,
    numAlerts,
  };
}

<template>
  <DropdownMenu
    :close-on-click="true"
    :disabled="!checkedReports.length"
    right
    @toggle-self-clicked="toggleMoveReports"
    @close-clickaway="closeMoveReports"
    @dropdown-closed="closeMoveReports"
  >
    <template #dropdown-button>
      <BittsButton
        :disabled="!checkedReports.length"
        size="small"
        type="primary"
        :active="isMoveReportsOpen"
        :variant="isMoveReportsOpen ? 'fill' : 'outline'"
        text="Move Reports"
      />
    </template>
    <FolderDropdown
      :folders="dropdownFolders"
      :move-to-unfiled="moveToUnfiled"
      @item-selected="onFolderSelected"
    />
  </DropdownMenu>
</template>

<script>
import { BittsButton } from '@crossbeam/bitts';

import axios from 'axios';
import { mapActions, mapState } from 'pinia';

import DropdownMenu from '@/components/DropdownMenu.vue';
import FolderDropdown from '@/components/reports/FolderDropdown.vue';

import { useFlashesStore, useReportsStore } from '@/stores';
import urls from '@/urls';

export default {
  name: 'MoveReportsDropdown',
  components: {
    DropdownMenu,
    BittsButton,
    FolderDropdown,
  },
  props: {
    checkedReports: {
      type: Array,
      required: true,
    },
    moveToUnfiled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['reports-moved', 'show-new-folder-modal'],
  data() {
    return {
      isMoveReportsOpen: false,
    };
  },
  computed: {
    ...mapState(useReportsStore, ['folders']),
    currentFolderUuid() {
      return this.$route.params.folder_id;
    },
    dropdownFolders() {
      return this.folders.filter(
        (folder) => folder.id !== this.currentFolderUuid,
      );
    },
  },
  methods: {
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapActions(useReportsStore, ['refreshReportsStore']),
    toggleMoveReports() {
      this.isMoveReportsOpen = !this.isMoveReportsOpen;
    },
    closeMoveReports() {
      this.isMoveReportsOpen = false;
    },
    async onFolderSelected(folder) {
      if (folder.name === 'Move To Unfiled') {
        const currentFolder = this.folders.find(
          (folder) => folder.id === this.currentFolderUuid,
        );
        const filteredReports = currentFolder.reports.filter(
          (reportUuid) => !this.checkedReports.includes(reportUuid),
        );
        const payload = {
          ...currentFolder,
          reports: filteredReports,
        };
        try {
          await axios.patch(urls.reports.folders.all, payload);
          this.addSuccessFlash({
            message: 'Reports moved to Unfiled',
          });
        } catch (_err) {
          this.addErrorFlash({
            message: 'Something went wrong',
            description: 'Reports not moved',
          });
          return;
        }
        this.$emit('reports-moved');
      } else if (folder.name === 'New Folder') {
        this.$emit('show-new-folder-modal');
        // we don't want to refresh for this, so returning early
        return;
      } else {
        // folder.reports can be empty, but will never be null
        const payload = {
          ...folder,
          reports: [...folder.reports, ...this.checkedReports],
        };
        try {
          await axios.patch(urls.reports.folders.all, payload);
          this.addSuccessFlash({
            message: `Reports moved to ${folder.name}`,
          });
          this.$emit('reports-moved');
        } catch (_err) {
          this.addErrorFlash({
            message: 'Something went wrong',
            description: 'Reports not moved',
          });
          return;
        }
      }
      await this.refreshReportsStore();
    },
  },
};
</script>

import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { captureException } from '@/errors';
import { initStore } from '@/stores/store-utils';
import { EcosystemMetrics } from '@/types/ecosystem_insights';
import urls from '@/urls';
export const useEcosystemInsightsStore = defineStore(
  'EcosystemInsightsStore',
  () => {
    const ecosystemMetrics = ref<EcosystemMetrics>({} as EcosystemMetrics);
    const currentQuarterMetrics = ref<EcosystemMetrics>({} as EcosystemMetrics);
    const useQuarterCache = ref(false);

    const { error, ready, readySync, running, refresh } = initStore(
      async () => {
        const { data } = await axios.get(urls.ecosystemMetrics);
        ecosystemMetrics.value = data;
      },
    );

    async function initQuarterMetrics({ useCache = true } = {}) {
      try {
        if (useCache && useQuarterCache.value) return;

        const { data } = await axios.get(urls.ecosystemMetrics, {
          params: {
            time_period: 'quarter_to_date',
          },
        });
        currentQuarterMetrics.value = data;
      } catch (err) {
        captureException(err);
      } finally {
        useQuarterCache.value = true;
      }
    }

    refresh({ useCache: true });

    return {
      error,
      ready,
      readySync,
      running,
      refresh,
      ecosystemMetrics,
      initQuarterMetrics,
      currentQuarterMetrics,
    };
  },
);

<template>
  <div class="flex gap-8">
    <PartnerIcon size="small" :partner-uuid="partnerUuid" />
    <p class="text-neutral-text-strong font-bold">
      {{ partner?.name }}
    </p>
  </div>
</template>
<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';

import PartnerIcon from '@/components/partners/PartnerIcon.vue';

import { usePartnersStore } from '@/stores';

type Props = {
  params: {
    value: string;
  };
};

const props = withDefaults(defineProps<Props>(), {});

type OfflinePartner = {
  uuid: string;
  name: string;
};

const partner = ref<OfflinePartner | null>(null);

const partnersStore = usePartnersStore();
const partnerUuid = ref('');
onBeforeMount(() => {
  partnerUuid.value = props.params.value;
  partner.value = partnersStore.getPartnerOrgByUuid(partnerUuid.value);
});
</script>

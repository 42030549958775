<template>
  <div class="module-list-notification">
    <div
      class="mr-4 mt-8"
      :class="{ 'hover:cursor-pointer': notifIsUnread }"
      data-testid="module-list-notification__indicator"
      @[addClickHandler]="onMarkNotifRead"
    >
      <div
        v-if="notifIsUnread"
        class="module-list-notification__read-indicator"
      />
      <div v-else class="module-list-notification__read-indicator invisible" />
    </div>
    <BittsAvatar v-bind="iconProps">
      <template #icon>
        <FontAwesomeIcon
          :icon="['fas', 'message-lines']"
          :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        />
      </template>
    </BittsAvatar>
    <div class="flex flex-col ml-16">
      <!-- list unshared -->
      <div
        v-if="props.notif.event_type === LIST_SHARING_UPDATED && isListDeleted"
        data-testid="notification-deleted-list"
      >
        <div class="module-list-notification__bold">
          {{ props.notif.data.list_name }}
        </div>
        is no longer shared
      </div>
      <!-- list shared -->
      <div
        v-else-if="props.notif.event_type === LIST_SHARING_UPDATED"
        data-testid="notification-shared-list"
      >
        <div class="module-list-notification__bold">
          {{ props.notif.data.shared_by_user_name }}
        </div>
        has shared
        <span
          class="text-info-text font-bold hover:cursor-pointer"
          @click="onActionClicked"
        >
          {{ props.notif.data.list_name }}
        </span>
      </div>
      <!-- list note updated -->
      <div
        v-else-if="props.notif.event_type === LIST_NOTE_UPDATED"
        data-testid="notification-note-sharing"
      >
        <div class="module-list-notification__bold">
          {{ props.notif.data.user_name }}
        </div>
        {{ noteUpdatedInnerMessage() }}
        <span
          class="text-info-text font-bold hover:cursor-pointer"
          @click="onActionClicked"
        >
          {{ props.notif.data.list_name }}
        </span>
      </div>
      <!-- list records added -->
      <div v-else data-testid="notification-members-added">
        <div class="module-list-notification__bold">
          {{ membersAddedNames }}
        </div>
        {{
          props.notif.data.members.length === 1
            ? 'was added to'
            : 'were added to'
        }}
        <span
          class="text-info-text font-bold hover:cursor-pointer"
          data-testid="notification-members-added__action"
          @click="onActionClicked"
        >
          {{ props.notif.data.list_name }}
        </span>
        by
        <div class="module-list-notification__bold">
          {{ props.notif.data.user }}
        </div>
      </div>
      <div class="module-list-notification__crossbeam-activity">
        <img src="@/assets/logo.png" class="h-16 w-16 mr-4" />
        • Crossbeam Activity
      </div>
    </div>
  </div>
</template>
<script setup>
import { BittsAvatar } from '@crossbeam/bitts';

import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import {
  LIST_MEMBERS_UPDATED,
  LIST_NOTE_UPDATED,
  LIST_SHARING_UPDATED,
  MAX_RECORDS_SHOWN,
} from '@/constants/shared_list';
import { captureException } from '@/errors';
import { useNotificationsStore } from '@/stores';

/*
 list notification event types:
 * list_sharing_updated - e.g. created / deleted list, added removed users
   * this event type has three different branches based off the action the event data
     * data.action = "deleted"
     * data.action = "internal-share"
     * data.action = "external-share"
 * list_members_updated - e.g. added records
 * list_note_updated
 */

const props = defineProps({
  notif: {
    type: Object,
    default: () => {},
  },
});

const router = useRouter();
const notificationsStore = useNotificationsStore();

const loading = ref(false);

const isListDeleted = computed(() => props.notif.data.action === 'deleted');

const membersAddedNames = computed(() => {
  if (props.notif.event_type !== LIST_MEMBERS_UPDATED) return '';
  const allRecords = [...props.notif.data.members];
  if (allRecords.length === 1) return allRecords[0].name;
  const formattedLastRecord =
    allRecords.length > MAX_RECORDS_SHOWN
      ? `${allRecords.length - MAX_RECORDS_SHOWN} more`
      : allRecords.pop().name;
  const allOtherRecords = allRecords
    .slice(0, MAX_RECORDS_SHOWN)
    .map((record) => record.name)
    .join(', ');
  return `${allOtherRecords}, and ${formattedLastRecord}`;
});

const iconProps = computed(() => {
  switch (props.notif.event_type) {
    case LIST_MEMBERS_UPDATED:
      return {
        org: props.notif.data.members[0],
        showInitials: false,
        size: 'small',
      };
    case LIST_NOTE_UPDATED: {
      const [firstName, lastName] = props.notif.data.user_name.split(' ');
      return {
        user: { first_name: firstName, last_name: lastName },
        showInitials: true,
        size: 'small',
        isOwn: false,
      };
    }
    default: {
      if (isListDeleted.value)
        return {
          isIcon: true,
          avatarClasses: 'bg-danger-accent',
          size: 'small',
        };
      const [firstName, lastName] =
        props.notif.data.shared_by_user_name.split(' ');
      return {
        user: { first_name: firstName, last_name: lastName },
        showInitials: true,
        size: 'small',
        isOwn: false,
      };
    }
  }
});

const notifIsUnread = computed(() => !props.notif.is_read);

const addClickHandler = computed(() => (notifIsUnread.value ? 'click' : null));

async function onMarkNotifRead() {
  loading.value = true;
  try {
    await notificationsStore.markRead({ id: props.notif.id, isRead: true });
    await notificationsStore.refreshNotificationsStore();
  } catch (e) {
    captureException(e);
  } finally {
    loading.value = false;
  }
}

function noteUpdatedInnerMessage() {
  // the list_note_updated event data has a version signifying that there are multiple
  // implementations of the data in the notifications db, and we need to be able to render old ones
  switch (props?.notif?.data?.version) {
    case 2:
      return `added a note for ${props.notif.data.record_name} in`;
    default:
      return 'added a note in';
  }
}

async function onActionClicked() {
  if (notifIsUnread.value) await onMarkNotifRead();
  await router.push({
    name: 'collaborate_list',
    params: { id: props.notif.data.list_id },
  });
}
</script>
<style scoped lang="pcss">
.module-list-notification {
  @apply flex;
}
.module-list-notification__bold {
  @apply font-bold inline;
}
.module-list-notification__crossbeam-activity {
  @apply flex items-center text-neutral-text text-sm mt-8;
}

.module-list-notification__read-indicator {
  @apply border border-solid cursor-pointer
  border-info-accent bg-info-accent w-6 h-6;
  border-radius: 50%;
}
</style>

<template>
  <div class="bitts-drawer">
    <ADrawer
      :open="visible"
      :width="width"
      :keyboard="keyboard"
      :placement="placement"
      :mask-closable="true"
      :closable="false"
      :class="wrapperClass"
      :destroy-on-close="destroyOnClose"
      class="bitts-drawer"
      @after-open-change="onAfterVisibleChange"
      @close="onClose"
    >
      <BittsLoading :is-loading="loading" class="h-full">
        <div v-if="!loading" class="flex flex-col justify-between h-full">
          <slot name="header">
            <header v-if="info" class="p-24">
              <div
                class="flex items-center"
                :class="{
                  'fullstory-hide dd-privacy-mask': hideFromSessionReplay,
                }"
              >
                <BittsAvatar
                  v-if="!useCustomIcon"
                  :org="avatarOrg"
                  :is-entity="true"
                  :is-own="false"
                  :show-initials="true"
                  size="medium"
                  shape="square"
                  class="text-center mr-16"
                />
                <slot name="customIcon" />
                <h2
                  class="bitts-drawer__header-title"
                  data-testid="drawer-title"
                >
                  <RouterLink
                    v-if="info.title_router_link"
                    :to="info.title_router_link"
                    data-testid="title-router-link"
                  >
                    {{ info.title }}
                  </RouterLink>
                  <template v-else>
                    {{ info.title }}
                  </template>
                </h2>
                <BittsButton
                  type="neutral"
                  variant="ghost"
                  size="medium"
                  class="text-neutral-text-placeholder"
                  data-testid="drawer-close-button"
                  :center-icon="['fas', 'x']"
                  @click="onClose"
                />
              </div>
            </header>
          </slot>
          <hr
            v-if="info && useHeadingDivider"
            :class="borderClass"
            class="c-bitts-drawer__title-border"
          />
          <slot name="subtitle">
            <div v-if="info && info.subtitle" class="bitts-drawer__status-area">
              <span class="text-base text-neutral-text-strong leading-6">{{
                info.subtitle
              }}</span>
              <BittsTag :color="statusType">
                {{ statusText }}
              </BittsTag>
              <hr
                v-if="useHeadingDivider"
                :class="borderClass"
                class="c-bitts-drawer__subtitle-border"
              />
            </div>
          </slot>
          <div class="flex-1">
            <slot name="content" />
          </div>
          <footer v-if="showFooter" class="p-24 z-30">
            <slot name="footer" />
          </footer>
        </div>
      </BittsLoading>
    </ADrawer>
  </div>
</template>

<script>
import { Drawer as ADrawer } from 'ant-design-vue';

import BittsAvatar from '../BittsAvatar/BittsAvatar.vue';
import BittsButton from '../BittsButton/BittsButton.vue';
import BittsLoading from '../BittsLoading/BittsLoading.vue';
import BittsTag from '../BittsTag/BittsTag.vue';

export default {
  name: 'BittsDrawer',
  components: {
    BittsAvatar,
    BittsLoading,
    BittsButton,
    BittsTag,
    ADrawer,
  },
  props: {
    borderClass: {
      type: String,
      default: 'border-neutral-background-disabled',
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    useHeadingDivider: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    keyboard: {
      type: Boolean,
      default: true,
    },
    placement: {
      type: String,
      default: 'right',
      validator(value) {
        return ['right', 'left'].indexOf(value) !== -1;
      },
    },
    statusType: {
      type: String,
      default: '',
    },
    statusText: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '480',
    },
    info: {
      type: Object,
      default: () => ({}),
    },
    useCustomIcon: {
      type: Boolean,
      default: false,
    },
    /* used to hide elements from fullstory and datadog */
    hideFromSessionReplay: {
      type: Boolean,
      default: false,
    },
    destroyOnClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['visible-change-complete', 'closed'],
  computed: {
    avatarOrg() {
      return this.info.org
        ? this.info.org
        : { clearbit_domain: this.info.domain };
    },
  },
  methods: {
    onAfterVisibleChange() {
      this.$emit('visible-change-complete');
    },
    onClose() {
      this.$emit('closed');
    },
  },
};
</script>

<style lang="pcss">
.ant-drawer {
  font-family: inherit;
}
.ant-drawer .bitts-drawer .ant-drawer-body {
  @apply p-0;
}
.ant-drawer-open {
  &.ant-drawer-left .ant-drawer-content-wrapper {
    @apply ml-16;
  }
  &.ant-drawer-right .ant-drawer-content-wrapper {
    @apply mr-16;
  }
}
.ant-drawer-content {
  @apply rounded-3xl border-neutral-background-disabled;
}
.ant-drawer-content-wrapper {
  @apply shadow-none my-16 rounded-3xl;
}
.bitts-drawer {
  outline: none !important;

  .ant-drawer-body {
    @apply h-full;
  }

  .c-loading {
    min-height: 400px;
  }

  .bitts-drawer__header-title {
    @apply text-lg text-neutral-text-strong flex-1 leading-8;
  }

  .bitts-drawer__status-area {
    @apply flex items-center px-24 py-16 leading-6 justify-between;
  }
}

.ant-drawer-content {
  footer {
    @apply sticky bottom-0 bg-white border-neutral-border border-t;
  }
}
</style>

<template>
  <Loading :is-loading="!ready">
    <div class="downgrade-review-view">
      <h3
        >Here's how your account will change on
        {{ subscriptionRenewalDate }}</h3
      >
      <div>
        <DowngradeGraph
          v-if="salesOnlyUsers.length"
          data-testid="sales-edge-users"
        >
          Your <strong>{{ salesOnlyUsers.length }}</strong> Sales seat
          {{ pluralize(salesOnlyUsers.length, 'user') }} will be removed from
          Crossbeam
        </DowngradeGraph>
        <DowngradeGraph v-if="reports.length" data-testid="reports">
          Your <strong>{{ reports.length }}</strong> saved
          {{ pluralize(reports.length, 'report') }} will be disabled and your
          report notifications will stop sending
        </DowngradeGraph>
        <DowngradeGraph
          v-if="affectedOfflinePartners"
          data-testid="offline-partners"
        >
          Your <strong>{{ affectedOfflinePartners }}</strong> offline
          {{ pluralize(affectedOfflinePartners, 'partner') }} will be deleted
        </DowngradeGraph>
        <DowngradeGraph
          v-if="customPops.length"
          data-testid="custom-populations"
        >
          Your <strong>{{ customPops.length }}</strong> Custom
          {{ pluralize(customPops.length, 'Population') }} will be disabled for
          you and hidden from all partners
        </DowngradeGraph>
        <DowngradeGraph data-testid="integrations">
          You will lose access to any installed integrations other than Slack
        </DowngradeGraph>
        <DowngradeGraph v-if="lists.length" data-testid="lists">
          Your <strong>{{ lists.length }}</strong> Shared
          {{ pluralize(lists.length, 'List') }} will be disabled and no longer
          available to partners
        </DowngradeGraph>
      </div>
      <SeatRemovalSummary
        class="mt-40"
        :user-ids="userIds"
        :invite-ids="inviteIds"
      />
    </div>
  </Loading>
</template>

<script setup>
import { pluralize } from 'humanize-plus';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import DowngradeGraph from '@/components/billing/DowngradeGraph.vue';
import SeatRemovalSummary from '@/components/billing/SeatRemovalSummary.vue';

import {
  allReady,
  useBillingStore,
  useCollaborateStore,
  usePartnersStore,
  usePopulationsStore,
  useReportsStore,
  useTeamStore,
} from '@/stores';

const props = defineProps({
  userIds: {
    type: Set,
    default: () => new Set([]),
  },
  inviteIds: {
    type: Set,
    default: () => new Set([]),
  },
  offlinePartnerUuid: {
    type: String,
    default: '',
  },
});

const teamStore = useTeamStore();
const billingStore = useBillingStore();
const populationsStore = usePopulationsStore();
const reportsStore = useReportsStore();
const collaborateStore = useCollaborateStore();
const partnersStore = usePartnersStore();

const { reports } = storeToRefs(reportsStore);
const { lists } = storeToRefs(collaborateStore);
const { subscriptionRenewalDate } = storeToRefs(billingStore);
const { offlinePartners } = storeToRefs(partnersStore);
const { salesOnlyUsers } = storeToRefs(teamStore);
const ready = allReady(teamStore, collaborateStore);

const customPops = computed(() => populationsStore.getCustomPopulations());

/* Offline partners to be deleted */
const affectedOfflinePartners = computed(
  () => offlinePartners.value.length - (props.offlinePartnerUuid ? 1 : 0),
);
</script>
<style lang="pcss" scoped>
h3 {
  @apply text-neutral-text-strong text-m font-bold mb-16 mt-40;
}
</style>

import axios from 'axios';
import { defineStore } from 'pinia';

import { captureException } from '@/errors';
import { ls } from '@/local_storage';
import urls from '@/urls';

export const usePartnersStore = defineStore('Partners', {
  state: () => {
    return {
      initPromise: null,
      partnerOrgs: [],
      offlinePartners: [],
      partnerOrgsLookup: {},
      proposalsSent: [],
      proposalsReceived: [],
      overlapCounts: {},
      partnersByTag: {},
      partnerTags: [],
      partnerFilters: {},
      publicInviteOrg: null,
      publicInviteCode: null,
    };
  },
  getters: {
    getPartnerOrgById:
      (state) =>
      (id, trimmed = true) => {
        const partnerOrg = state.partnerOrgsLookup[id];
        return trimmed ? trimOrg(partnerOrg) : partnerOrg;
      },
    getPartnerOrgByUuid:
      (state) =>
      (uuid, trimmed = true) => {
        const partnerOrg = state.partnerOrgs.find(
          (partner) => partner.uuid === uuid,
        );
        return trimmed ? trimOrg(partnerOrg) : partnerOrg;
      },
    getPartnersByTag: (state) => (tagId) => {
      return state.partnersByTag[tagId] || [];
    },
    getPartnerTagById: (state) => (id) => {
      return state.partnerTags.find((tag) => tag.id === id);
    },
    getProposalReceivedById: (state) => (id) => {
      return state.proposalsReceived.find((proposal) => proposal.id === id);
    },
    getProposalSentById: (state) => (id) => {
      return state.proposalsSent.find((proposal) => proposal.id === id);
    },
    hasPartners(state) {
      return !!state.partnerOrgs.length;
    },
    getOpenDealsTotal(state) {
      return state.overlapCounts?.overlap_usage?.total_open_deal_amount;
    },
    getBestMetrics() {
      const bestMetrics = {};

      let totalRevenue = 0;

      this.partnerOrgs.forEach((org) => {
        const {
          win_rate_with_partner: winRate,
          time_to_close_with_partner: timeToClose,
          deal_size_with_partner: dealSize,
          total_revenue_with_partner: partnerRevenue,
        } = org.metrics || {};

        if (partnerRevenue) totalRevenue += partnerRevenue;

        if (winRate && (winRate > bestMetrics.winRate || !bestMetrics.winRate))
          bestMetrics.winRate = winRate;
        if (
          timeToClose &&
          (timeToClose < bestMetrics.timeToClose || !bestMetrics.timeToClose)
        )
          bestMetrics.timeToClose = timeToClose;
        if (
          dealSize &&
          (dealSize > bestMetrics.dealSize || !bestMetrics.dealSize)
        )
          bestMetrics.dealSize = dealSize;
        if (
          partnerRevenue &&
          (partnerRevenue > bestMetrics.partnerRevenue ||
            !bestMetrics.partnerRevenue)
        )
          bestMetrics.partnerRevenue = partnerRevenue;
      });
      const averageRevenue = totalRevenue / this.partnerOrgs.length;

      return {
        ...bestMetrics,
        averageRevenue,
      };
    },
  },
  actions: {
    partnersLoadingComplete(data) {
      this.partnerOrgs = data.partner_orgs.sort();
      this.offlinePartners = data.partner_orgs.filter((p) => p.offline_partner);
      this.proposalsSent = data.proposals.map(formatOldStyleProposal);
      this.proposalsReceived = data.proposals_received;
      this.partnerOrgsLookup = this.partnerOrgs.reduce((orgMap, org) => {
        orgMap[org.id] = org;
        return orgMap;
      }, {});
      this.setPartnersByTag();
    },
    setPartnersByTag() {
      this.partnersByTag = this.partnerOrgs.reduce((tagLookup, org) => {
        org.tags.forEach((tag) => {
          const tagId = tag.id;
          if (!tagLookup[tagId]) tagLookup[tagId] = [];
          tagLookup[tagId].push(org.id);
        });
        return tagLookup;
      }, {});
    },
    setTags(tags) {
      tags.forEach((tag) => {
        if (!this.partnerFilters[tag.id]) {
          this.partnerFilters[tag.id] = false;
        }
      });
      this.partnerTags = tags;
    },
    addProposal(proposal) {
      const proposalIds = this.proposalsSent.map((proposal) => proposal.id);
      if (!proposalIds.includes(proposal.id)) {
        this.proposalsSent.push(formatOldStyleProposal(proposal));
      }
    },
    async refreshOverlapUsage() {
      const usageResponse = await axios.get(urls.partners.overlaps);
      this.setOverlapUsage(usageResponse.data);
    },
    setOverlapUsage(overlaps) {
      this.overlapCounts = overlaps;
    },
    setPublicInviteOrg(publicInviteOrg) {
      this.publicInviteOrg = publicInviteOrg;
    },
    setPublicInviteCode(publicInviteCode) {
      this.publicInviteCode = publicInviteCode;
    },
    clearPublicInviteCodeAndOrg() {
      this.publicInviteCode = null;
      this.publicInviteOrg = null;
    },
    setPartnerFilters(tagId) {
      this.partnerFilters[tagId] = !this.partnerFilters[tagId];
    },
    resetPartnerFilters() {
      Object.keys(this.partnerFilters).forEach((tag) => {
        this.partnerFilters[tag] = false;
      });
    },
    removeTag(tagId) {
      this.partnerFilters = delete this.partnerFilters[tagId];
    },
    setRevokedProposal(proposalId) {
      const newProposals = [...this.proposalsSent];
      this.proposalsSent = newProposals.filter(
        (proposal) => proposal.id !== proposalId,
      );
    },
    setDeclinedProposal(proposalId) {
      const newProposals = [...this.proposalsReceived];
      this.proposalsReceived = newProposals.filter(
        (proposal) => proposal.id !== proposalId,
      );
    },
    setProposalsReceived(items) {
      this.proposalsReceived = items;
    },
    async refreshPartnersStore(fromInit = false) {
      try {
        const publicInvite = ls.publicInvite.get();
        if (publicInvite) {
          this.setPublicInviteCode(publicInvite.code);
          this.setPublicInviteOrg(publicInvite.org);
        }
        const promises = [
          axios.get(urls.partnerTags.all),
          axios.get(urls.partners.all),
        ];
        if (fromInit) promises.push(this.refreshOverlapUsage());
        const [tagsResponse, partnersResponse] = await Promise.all(promises);
        this.setTags(tagsResponse.data.items);
        this.partnersLoadingComplete(partnersResponse.data);
      } catch (xhr) {
        captureException(xhr);
      }
    },
    async initPartnersStore() {
      if (!this.initPromise) this.initPromise = this.refreshPartnersStore(true);
      return this.initPromise;
    },
    async updateFilters(tagId) {
      this.setPartnerFilters(tagId);
    },
    async resetFilters() {
      this.resetPartnerFilters();
    },
    async deleteTag(tagId) {
      await axios.delete(urls.partnerTags.delete(tagId));
      this.removeTag(tagId);
      this.refreshPartnersStore();
    },
    async revokeProposal(proposalId) {
      try {
        await axios.put(urls.proposals.remove(proposalId));
      } catch (err) {
        captureException(err);
      } finally {
        this.setRevokedProposal(proposalId);
        this.refreshPartnersStore();
      }
    },
    async declineProposal(proposalId) {
      try {
        await axios.put(urls.proposals.decline(proposalId));
      } catch (err) {
        captureException(err);
      } finally {
        this.setDeclinedProposal(proposalId);
        this.refreshPartnersStore();
      }
    },
    async refreshProposals() {
      try {
        const { data } = await axios.get(urls.partners.proposalsReceived);
        this.setProposalsReceived(data.items);
      } catch (err) {
        captureException(err);
      }
    },
  },
});

const formatOldStyleProposal = (proposal) => {
  // this is a slightly hacky way of reformatting responses from the old routes
  // into a simpler format, which was initially introduced by the v0.3 POST
  // route for creating proposals. The new response format matches the database
  // structure rather than doing arbitrary re-namings.
  if (!proposal.contact) {
    return proposal;
  }
  return {
    id: proposal.id,
    status: proposal.status,
    created_at: proposal.created_at,
    contact_name: proposal.contact.name,
    contact_email: proposal.contact.email,
    contact_company: proposal.contact.company,
    sending_user: proposal.sending_user,
  };
};

export const trimOrg = (org) => {
  if (!org) return undefined;
  const trimmedOrg = { ...org };
  delete trimmedOrg.users;
  delete trimmedOrg.tags;
  return trimmedOrg;
};

import { ref } from 'vue';

import { ATTRIBUTION_PAGE_LIMIT } from '@/constants/attribution';

const rollupMetrics = ref([
  { type: 'total_sourced', value: 0 },
  { type: 'total_influenced', value: 0 },
  { type: 'total_unattributed', value: 0 },
  { type: 'time_to_close_with_partners', value: 0 },
]);

const eiAttributionMetrics = ref({
  total_sourced: null,
  total_influenced: null,
  total_unattributed: null,
  total_attributed_count: null,
  total_unattributed_count: null,
  total_attributed: null,
});

const opportunities = ref([]);
const pagination = ref({
  last_page: 0,
  limit: ATTRIBUTION_PAGE_LIMIT,
  next_href: null,
  page: 1,
  total_count: 0,
});
const unattributedOpportunities = ref([]);
const accountActivities = ref([]);
const recentCustomerMovements = ref([]);
const isCached = ref(false);

function $reset() {
  opportunities.value = [];
  pagination.value = {
    last_page: 0,
    limit: ATTRIBUTION_PAGE_LIMIT,
    next_href: null,
    page: 1,
    total_count: 0,
  };
  unattributedOpportunities.value = [];
  accountActivities.value = [];
  recentCustomerMovements.value = [];
  isCached.value = false;
  rollupMetrics.value = [
    { type: 'total_sourced', value: 0 },
    { type: 'total_influenced', value: 0 },
    { type: 'total_unattributed', value: 0 },
    { type: 'time_to_close_with_partners', value: 0 },
  ];
  eiAttributionMetrics.value = {
    total_attributed: null,
    total_attributed_count: null,
    total_influenced: null,
    total_sourced: null,
    total_unattributed: null,
    total_unattributed_count: null,
  };
}

export function useAttributionState() {
  return {
    rollupMetrics,
    opportunities,
    pagination,
    unattributedOpportunities,
    accountActivities,
    recentCustomerMovements,
    eiAttributionMetrics,
    isCached,
    $reset,
  };
}

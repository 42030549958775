<template>
  <BillingCTA
    :message="V4_BILLING_COPY.outOfSeatsCta.message"
    :description="description"
    button-text="Add seats"
    size="large-vertical"
    :billing-interaction="{
      cta: BILLING_CTAS.AT_SEAT_LIMIT,
      event_site: EVENT_SITES.OUT_OF_BOTH_SEATS_CTA,
    }"
  />
</template>
<script setup>
import { pluralize } from 'humanize-plus';
import { computed } from 'vue';

import BillingCTA from '@/components/billing/BillingCTA.vue';

import useSeats from '@/composables/useSeats';
import { BILLING_CTAS, EVENT_SITES } from '@/constants/analytics';
import { V4_BILLING_COPY } from '@/constants/billing';
import { CORE, SALES } from '@/constants/team_v4';
import { useTeamStore } from '@/stores';
import { injectStrings } from '@/utils';

const props = defineProps({
  users: {
    type: Array,
    default: () => [],
  },
});

const teamStore = useTeamStore();
const { seatsRemainingMap } = useSeats();

const coreUserCount = computed(() =>
  props.users.reduce((agg, user) => {
    const auth = teamStore.getAuthorizationByUserId(user.id);
    if (!auth?.role_id) agg += 1;
    return agg;
  }, 0),
);

const salesUserCount = computed(() =>
  props.users.reduce((agg, user) => {
    const auth = teamStore.getAuthorizationByUserId(user.id);
    if (auth?.role_id) agg += 1;
    return agg;
  }, 0),
);

const neededCoreSeats = computed(() =>
  Math.max(coreUserCount.value - seatsRemainingMap.value[CORE], 1),
);
const neededSalesSeats = computed(() =>
  Math.max(salesUserCount.value - seatsRemainingMap.value[SALES], 1),
);
const injections = computed(() => [
  neededCoreSeats.value,
  pluralize(neededCoreSeats.value, 'seat'),
  neededSalesSeats.value,
  pluralize(neededSalesSeats.value, 'seat'),
]);
const description = computed(() =>
  injectStrings(V4_BILLING_COPY.outOfSeatsCta.description, {
    values: injections.value,
  }),
);
</script>

<template>
  <BittsCard>
    <div class="learn-more-card" :class="props.card.resource">
      <div class="learn-more-card__header">
        <BittsTag
          :text="card.resource"
          size="x-small"
          variant="rounded"
          :color="tagColor"
        />
        <span class="time">{{ card.caption }}</span>
      </div>
      <h4>
        {{ card.title }}
      </h4>
      <p class="mb-24">
        {{ card.description }}
      </p>
      <BittsLink
        class="mt-auto"
        :text="linkText"
        :url="card.link"
        :show-arrow="true"
      />
    </div>
  </BittsCard>
</template>
<script setup>
import { BittsCard, BittsLink, BittsTag } from '@crossbeam/bitts';

import { computed } from 'vue';

const props = defineProps({
  card: {
    type: Object,
    required: true,
  },
});

const tagColor = computed(() => {
  switch (props.card.resource) {
    case 'Video':
      return 'accent';
    case 'Article':
      return 'info';
    case 'Academy':
      return 'neutral';
    default:
      return 'neutral';
  }
});

const linkText = computed(() => {
  return props.card.link.includes('help.crossbeam.com')
    ? 'View on Help Center'
    : 'View Crossbeam Academy';
});
</script>
<style lang="pcss">
.learn-more-card {
  @apply flex flex-col px-24 pb-24 pt-32 rounded-16;
  &.Video {
    background: radial-gradient(
        71.08% 43.5% at 50.09% -18.42%,
        theme(colors.accent.border / 0.4) 0%,
        theme(colors.white / 0) 100%
      ),
      theme(colors.white);
  }
  &.Article {
    background: radial-gradient(
        71.08% 43.5% at 50.09% -18.42%,
        theme(colors.info.border / 0.4) 0%,
        theme(colors.white / 0) 100%
      ),
      theme(colors.white);
  }
  &.Academy {
    background: radial-gradient(
        71.08% 43.5% at 50.09% -18.42%,
        theme(colors.neutral.border-focus / 0.4) 0%,
        theme(colors.white / 0) 100%
      ),
      theme(colors.white);
  }
  .learn-more-card__header {
    @apply flex justify-between mb-24;

    .time {
      @apply text-sm text-neutral-text-weak;
    }
  }

  h4 {
    @apply text-neutral-text-strong font-bold mb-4 text-m;
  }

  p {
    @apply text-neutral-text-weak;
  }
}
</style>

<template>
  <div class="downgrade-copy">
    <FontAwesomeIcon
      :icon="['fad', 'sun-haze']"
      class="text-orange-400 w-72 h-72"
    />
    <h3>Prepare for what's next</h3>
    <p
      >Your access to paid features on Crossbeam's limited-time early adopter
      program is ending soon. Let's explore your options.</p
    >
  </div>
</template>

<script setup></script>
<style lang="pcss" scoped>
.downgrade-copy {
  @apply h-full px-40 py-32 lg:px-80 lg:pt-120;
  background: radial-gradient(
      41.02% 57.15% at 23.19% 83.74%,
      theme(colors.info.border / 0.8) 0%,
      theme(colors.info.border / 0) 100%
    ),
    radial-gradient(
      71.71% 50.59% at 87.36% -3.52%,
      theme(colors.accent.border / 0.8) 0%,
      theme(colors.accent.border / 0) 100%
    ),
    linear-gradient(
      180deg,
      theme(colors.brand-navy) 0%,
      theme(colors.primary.text-button) 100%
    );

  h3 {
    @apply text-xxl text-white font-bold;
  }

  p {
    @apply text-m text-white opacity-75 mt-8;
  }
}
</style>

<template>
  <div class="c-verify-email--wrapper">
    <div class="c-verify-email">
      <div class="header">
        <router-link :to="{ name: 'main' }" class="c-app-bare__logo">
          <span class="hidden">Home</span>
          <BittsSvg svg="logo" class="h-24" alt="Crossbeam" />
        </router-link>
        <BittsButton
          class="logout-button"
          variant="outline"
          type="neutral"
          text="Log out"
          :right-icon="['far', 'arrow-right-from-bracket']"
          @click="handleLogout"
        />
      </div>
      <div class="c-verify-email__content">
        <div class="icon-background">
          <FontAwesomeIcon
            :icon="['fad', 'envelope-open-text']"
            class="text-secondary-accent w-48 h-48 md:w-72 md:h-72"
          />
        </div>
        <h1> Please verify your email address </h1>
        <p>
          We've sent you an email at <strong>{{ email }}</strong>
        </p>
        <BittsButton
          text="Back to Login"
          class="mx-auto mt-40 w-full sm:w-auto"
          @click="handleLogout"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsSvg } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

useHead({ title: 'Verify - Crossbeam' });

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
});

const router = useRouter();

const email = computed(() => props.email);
async function handleLogout() {
  await router.push({ name: 'logout' });
}
</script>

<style lang="pcss" scoped>
.c-verify-email--wrapper {
  background-image: url('../assets/svg/backgrounds/triangles.svg');
  @apply p-0 md:p-16 flex-1 flex flex-col;
}

.c-verify-email {
  @apply text-center flex flex-col gap-8 bg-white my-0 px-16 py-16 md:px-40 md:py-48 h-full rounded-none md:rounded-[40px] flex-1;

  .header {
    @apply hidden md:flex justify-between w-full mb-[-64px] items-center;
  }

  h1 {
    @apply text-brand-navy text-xl mx-24 md:mx-0 md:text-xxxl mb-16 font-bold;
  }

  p {
    @apply text-neutral-text text-m md:text-lg;
  }

  .c-verify-email__content {
    @apply mt-48 md:my-auto flex flex-col items-center;
  }

  .icon-background {
    @apply w-[150px] h-[150px] rounded-full pt-40;
    background: radial-gradient(
      136.96% 159.92% at 50.09% -12.42%,
      color-mix(in srgb, theme(colors.secondary.accent) 20%, white) 0%,
      white,
      white
    );
  }
}
</style>

<style lang="pcss">
.c-app-bare.c-app-bare__verify_email {
  .c-app-bare__nav {
    @apply hidden;
  }
}
</style>

<template>
  <div :class="wrapperClasses">
    <Tooltip
      v-bind="dynamicProps()"
      :arrow="false"
      :placement="placement"
      trigger="hover"
      transition-name="fade"
      :mouse-enter-delay="0"
      :mouse-leave-delay="0.1"
      :overlay-class-name="wrapperClasses"
      :get-popup-container="getPopupContainer"
      :auto-adjust-overflow="true"
      :destroy-tooltip-on-hide="false"
      @open-change="onVisibleChange"
    >
      <!-- default slot is for the item that triggers a popover -->
      <div data-testid="bitts-tooltip-trigger" :class="defaultSlotClasses">
        <slot />
      </div>
      <!-- title slot is for tooltip content -->
      <template v-if="$slots.title" #title>
        <TestHook>
          <slot name="title" />
          <component :is="anchorOrDiv" v-if="includeCta" v-bind="anchorProps">
            <BittsButton
              :text="buttonText || 'Learn More'"
              type="white"
              :left-icon="ctaIcon"
              data-testid="bitts-tooltip-button"
              class="c-bitts-tooltip__button"
              size="x-small"
              @click="onCtaClicked($event)"
            />
          </component>
        </TestHook>
      </template>
    </Tooltip>
  </div>
</template>

<script setup lang="ts">
import { Tooltip } from 'ant-design-vue';
import { computed } from 'vue';

import BittsButton from '../BittsButton/BittsButton.vue';
import { BittsIcon, Placement, PlacementType } from '../types';

import TestHook from './TestHook.vue';

export type Props = {
  overlayClass?: string;
  ctaIcon?: BittsIcon;
  buttonText?: string;
  includeCta?: boolean;
  placement?: PlacementType;
  learnMoreLink?: string | null;
  mountToBody?: boolean;
  showTooltip?: boolean | null;
  stopPropagation?: boolean;
  defaultSlotClasses?: string;
};

const props = withDefaults(defineProps<Props>(), {
  overlayClass: '',
  ctaIcon: null,
  buttonText: '',
  includeCta: false,
  placement: Placement.Top,
  learnMoreLink: null,
  mountToBody: false,
  showTooltip: null,
  stopPropagation: false,
  defaultSlotClasses: '',
});

const emit = defineEmits(['menu-visible', 'cta-clicked']);

const anchorOrDiv = computed(() => (props.learnMoreLink ? 'a' : 'div'));

const anchorProps = computed(() =>
  props.learnMoreLink
    ? {
        href: props.learnMoreLink,
        target: '_blank',
      }
    : {},
);

const wrapperClasses = computed(() => {
  const classes = ['bitts-tooltip'];
  if (props.overlayClass) classes.push(props.overlayClass);
  return classes.join(' ');
});

function onVisibleChange(visible: boolean) {
  emit('menu-visible', { visible });
}

function getPopupContainer(triggerNode: HTMLElement) {
  return (
    props.mountToBody ? document.body : triggerNode?.parentElement
  ) as HTMLElement;
}

function dynamicProps() {
  return props.showTooltip !== null ? { open: props.showTooltip } : {};
}

function onCtaClicked(event: Event) {
  if (props.stopPropagation) event.stopPropagation();
  emit('cta-clicked');
}
</script>

<style lang="pcss">
.c-bitts-tooltip__button {
  @apply w-full my-4;
}
.bitts-tooltip {
  .ant-tooltip {
    font-family: inherit;
  }
  .ant-tooltip-inner {
    @apply text-sm min-h-0 px-8 py-4 bg-neutral-background-tooltip rounded-bts-md;
    opacity: 90% !important;
  }
  &.ant-tooltip-placement-top,
  &.ant-tooltip-placement-topLeft,
  &.ant-tooltip-placement-topRight {
    padding-bottom: 0;
  }
  &.ant-tooltip-placement-right,
  &.ant-tooltip-placement-rightTop,
  &.ant-tooltip-placement-rightBottom {
    padding-left: 0;
  }
  &.ant-tooltip-placement-bottom,
  &.ant-tooltip-placement-bottomLeft,
  &.ant-tooltip-placement-bottomRight {
    padding-top: 0;
  }
  &.ant-tooltip-placement-left,
  &.ant-tooltip-placement-leftTop,
  &.ant-tooltip-placement-leftBottom {
    padding-right: 0;
  }
}
</style>

<template>
  <loading is-loading />
</template>

<script>
import { useHead } from '@unhead/vue';

import { getAndClearLSNextUrl } from '@/local_storage';
import { useRootStore } from '@/stores/RootStore';
import urls from '@/urls';

export default {
  setup() {
    useHead({
      title: 'Slack Callback - Crossbeam',
    });

    const { loadUserProfile } = useRootStore();

    return { loadUserProfile };
  },

  async created() {
    const params = {
      code: this.$route.query.code,
      state: this.$route.query.state,
    };
    // Successful OAuth
    if (params.code) {
      await this.$axios.post(urls.slack.callback, {}, { params });
      await this.loadUserProfile();
    }

    const nextFromLS = getAndClearLSNextUrl();
    this.$router.push(nextFromLS || { name: 'integrations' });
  },
};
</script>

<template>
  <BittsLoading :is-loading="!ready" class="shared-list-module">
    <div v-if="recentlyUpdatedLists.length" class="shared-list-module__tabs">
      <BittsTabs
        :current-value="activeTab"
        :full-width="false"
        :tabs="LIST_MODULE_TABS"
        class="text-m mb-16"
        @change:tab="(val) => (activeTab = val)"
      >
        <template #labelSuffix="{ value }">
          <BittsCount
            v-if="value === ACTIVITY"
            :count="unreadNotifications"
            :type="notificationsCountType"
            class="ml-4 mt-2"
          />
        </template>
      </BittsTabs>
      <div
        v-if="activeTab === RECENTLY_UPDATED"
        class="shared-list-module__tab"
      >
        <ModuleListTile
          v-for="list in recentlyUpdatedLists"
          :key="`module-lists__${list.id}`"
          :list="list"
        />
      </div>
      <div v-else class="shared-list-module__tab pr-16">
        <div v-if="recentlyCreatedNotifs.length">
          <div
            v-for="(day, dayIdx) in listNotifsBucketedByTime.timesInOrder"
            :key="`list-notifications__${day}`"
            class="flex flex-col gap-16"
          >
            <BittsDivider
              :text="day"
              :class="!dayIdx ? 'mt-8' : 'mt-16'"
              class="mb-0"
            />
            <div
              v-for="(notif, notifIdx) in listNotifsBucketedByTime[day]"
              :key="`list-notifications__${notif.id}`"
            >
              <BittsDivider v-if="!!notifIdx" class="my-0 mb-16" />
              <ModuleListNotification :notif="notif" />
            </div>
          </div>
        </div>
        <BittsEmptyState
          v-else
          title="No Shared List activity yet"
          class="shared-list-module-notifications__empty-state"
          font-awesome-icon-class="shared-list-module-notifications__empty-state-icon"
          :font-awesome-icon="['fad', 'list']"
          :font-awesome-icon-style="{ height: '40px', width: '40px' }"
        >
          <template #subtitle>
            <div
              class="shared-list-module-notifications__empty-state-description"
            >
              Check back here for activity in your Shared Lists
            </div>
          </template>
        </BittsEmptyState>
      </div>
    </div>
    <div v-else class="shared-list-module__empty">
      <BittsEmptyState
        :font-awesome-icon="['fad', 'list']"
        font-awesome-icon-class="shared-list-module___empty-state-icon"
        :font-awesome-icon-style="{ height: '60px', width: '60px' }"
        title="Collaborate in Real Time"
        class="shared-list-module___empty-state"
      >
        <template #subtitle>
          <div class="font-bold my-8">
            Share accounts with partners on Shared Lists
          </div>
          <ul class="text-left ml-20">
            <li>Prioritize and action on opportunities</li>
            <li>View greenfield accounts from your partners</li>
            <li>Create organized records of communication</li>
          </ul>
          <BittsButton
            text="Create Shared List"
            class="mt-16"
            @click="onEmptyStateCtaClicked"
          />
        </template>
      </BittsEmptyState>
    </div>
  </BittsLoading>
</template>
<script setup>
import {
  BittsButton,
  BittsCount,
  BittsDivider,
  BittsEmptyState,
  BittsLoading,
  BittsTabs,
} from '@crossbeam/bitts';

import { orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import ModuleListNotification from '@/components/shared-list/ModuleListNotification.vue';
import ModuleListTile from '@/components/shared-list/ModuleListTile.vue';

import { useCollaborateStore, useNotificationsStore } from '@/stores';

const RECENTLY_UPDATED = 'recently-updated';
const ACTIVITY = 'activity';
const RECENTLY_UPDATED_DAY_LIMIT = 30;

const LIST_MODULE_TABS = [
  {
    name: 'Recently Updated',
    icon: ['far', 'message-lines'],
    value: RECENTLY_UPDATED,
  },
  { name: 'Activity', icon: ['far', 'rotate'], value: ACTIVITY },
];

const activeTab = ref(RECENTLY_UPDATED);

const emit = defineEmits(['create-list-clicked']);

const collabStore = useCollaborateStore();
const { lists, ready } = storeToRefs(collabStore);
const oneMonthAgo = DateTime.now()
  .minus({ days: RECENTLY_UPDATED_DAY_LIMIT })
  .toISO();
const recentlyUpdatedLists = computed(() => {
  const recentLists = lists.value.filter(
    (list) => list.updated_at >= oneMonthAgo,
  );
  return orderBy(recentLists, ['updated_at'], ['desc']);
});

async function onEmptyStateCtaClicked() {
  emit('create-list-clicked');
}

const notificationsStore = useNotificationsStore();
const { allNotifications } = storeToRefs(notificationsStore);
function isListNotification(notif) {
  return notif.event_type.startsWith('list');
}
const listNotifications = computed(() =>
  allNotifications.value.filter((notif) => isListNotification(notif)),
);
const recentlyCreatedNotifs = computed(() =>
  listNotifications.value.filter((notif) => notif.created_at >= oneMonthAgo),
);
const unreadNotifications = computed(
  () => recentlyCreatedNotifs.value.filter((notif) => !notif.is_read).length,
);
const notificationsCountType = computed(() =>
  activeTab.value === ACTIVITY ? 'default' : 'neutral',
);
const listNotifsBucketedByTime = computed(() => {
  const listNotifsInDateOrder = orderBy(
    recentlyCreatedNotifs.value,
    ['created_at'],
    ['desc'],
  );
  const buckets = { timesInOrder: [] };
  listNotifsInDateOrder.forEach((notif) => {
    const toRelative = DateTime.fromISO(notif.created_at).toRelative();
    if (!buckets[toRelative]) buckets[toRelative] = [];
    buckets[toRelative].push(notif);
    if (!buckets.timesInOrder.includes(toRelative))
      buckets.timesInOrder.push(toRelative);
  });
  return buckets;
});
</script>

<style lang="pcss" scoped>
.shared-list-module__column-head {
  @apply flex items-center gap-8;
}
.shared-list-module__empty {
  @apply border border-solid border-neutral-border rounded-bts-lg;
}
.shared-list-module___empty-state {
  @apply text-neutral-text;
}
.shared-list-module__tabs {
  @apply h-full border border-solid border-neutral-border rounded-bts-lg p-16 pr-0;
}
</style>
<style lang="pcss">
.shared-list-module {
  .c-bitts-divider {
    @apply text-left font-bold text-neutral-accent;
    span {
      padding-left: 0 !important;
    }
  }
  .module-list-tile {
    @apply mr-16;
    &:nth-child(odd) {
      @apply bg-neutral-background-weak;
    }
    &:hover {
      @apply bg-neutral-background;
    }
  }
  &.collaborate-page__shared-list-module {
    .shared-list-module__tab {
      @apply h-[316px];
    }
  }
  &.home-page__shared-list-module {
    @apply h-full;
    .shared-list-module__tab {
      @apply flex h-full min-h-[516px] max-h-[636px];
    }
  }
}
.shared-list-module___empty-state.c-bitts-empty-state-large-border {
  @apply bg-white border-none m-24 w-auto;
}
.shared-list-module___empty-state {
  li {
    @apply list-item list-disc;
  }
}
.shared-list-module___empty-state-icon.c-bitts-empty-state__fa-icon {
  @apply p-30 text-accent-accent rounded-[360px];
  background: var(
    --radial-accent,
    radial-gradient(
      136.96% 69.92% at 50.09% -12.42%,
      theme(colors.accent.border / 0.2) 0%,
      theme(colors.accent.border / 0) 100%
    ),
    theme(colors.white)
  );
}
.shared-list-module-notifications__empty-state {
  @apply h-full;
  .c-bitts-empty-state-large {
    @apply px-0 py-24 mt-0;
  }
  &.c-bitts-empty-state-large-border {
    @apply bg-white;
  }
}
.shared-list-module-notifications__empty-state-icon {
  @apply text-neutral-text-weak p-20 rounded-[36px];
  background: radial-gradient(
      136.96% 69.92% at 50.09% -12.42%,
      theme(colors.neutral.accent / 0.2) 0%,
      theme(colors.neutral.accent / 0) 100%
    ),
    linear-gradient(0deg, theme(colors.white), theme(colors.white));
}
.shared-list-module-notifications__empty-state-description {
  @apply text-neutral-text text-base;
}
.shared-list-module__tab {
  @apply overflow-auto flex flex-col gap-8;
}
</style>

<template>
  <Notification v-if="seatRequest" :item="item">
    <template #logo>
      <BittsAvatar
        size="medium"
        :user="{
          first_name: seatRequest.first_name,
          last_name: seatRequest.last_name,
        }"
        :show-initials="true"
        class="items-center"
      />
    </template>
    <template #message>
      <span data-testid="seat-request-message">
        {{ message }}
      </span>
    </template>
    <template #description>
      <span data-testid="seat-request-description">
        {{ description }}
      </span>
    </template>
    <template #action>
      <div class="flex items-center gap-4">
        <BittsButton
          data-testid="approve-seat-request-button"
          text="Approve"
          type="primary"
          size="x-small"
          @click="approveRequest"
        />
        <BittsButton
          data-testid="reject-seat-request-button"
          text="Reject"
          type="danger"
          variant="ghost"
          size="x-small"
          @click="rejectRequest"
        />
      </div>
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fas', 'user-group']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script setup>
import { BittsAvatar, BittsButton } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import Notification from '@/components/notifications/Notification.vue';

import { CORE } from '@/constants/team_v4';
import { useNotificationsStore, useSeatRequestsStore } from '@/stores';

const router = useRouter();
const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
    required: true,
  },
});

const seatRequestsStore = useSeatRequestsStore();
const seatRequest = computed(() =>
  seatRequestsStore.getSeatRequestByUuid(props.item.object_id.id),
);

const isCore = computed(() => seatRequest.value?.type === CORE);
const displayableName = computed(() => {
  if (seatRequest.value?.first_name && seatRequest.value?.last_name)
    return `${seatRequest.value?.first_name} ${seatRequest.value?.last_name}`;
  return seatRequest.value?.email;
});
const description = computed(
  () =>
    `${displayableName.value} at ${seatRequest.value?.org_name} has requested access to ${isCore.value ? 'Crossbeam' : 'Crossbeam for Sales'}`,
);
const message = computed(
  () => `${isCore.value ? 'Full Access' : 'Sales'} Seat Request`,
);

async function approveRequest() {
  await notificationsStore.markRead({ id: props.item.id, isRead: true });
  await router.push({
    name: 'approve_seat_request',
    query: { emails: seatRequest.value.email },
  });
}

const notificationsStore = useNotificationsStore();
async function rejectRequest() {
  await notificationsStore.markRead({ id: props.item.id, isRead: true });
  await seatRequestsStore.rejectSeatRequest(seatRequest.value.id);
  await notificationsStore.refreshNotificationsStore();
}
</script>

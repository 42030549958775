<template>
  <RouterLink class="reports-table-cell__wrapper" :to="params.value.reportLink">
    <div class="partners-in-report">
      <div
        v-if="isPartnerTagReport"
        data-testid="partner-tag-cell"
        class="text-neutral-text flex gap-8 items-center"
      >
        <FontAwesomeIcon
          :icon="['fad', 'tags']"
          :style="{ height: '12px', width: '12px', color: 'currentColor' }"
          class="text-neutral-text-button"
        />
        {{ tagInfo.label }}
      </div>
      <div
        v-else-if="isAllPartnersReport"
        data-testid="all-partners-cell"
        class="text-neutral-text"
      >
        All Partners
      </div>
      <div
        v-else-if="!params.value.partnersInReport.length"
        data-testid="empty-cell"
        class="text-neutral-text-placeholder"
      >
        --
      </div>
      <BittsAvatarStack
        v-else
        :org-list="orgsForStack"
        :include-hover="true"
        :transparent="false"
      />
    </div>
  </RouterLink>
</template>

<script>
import { BittsAvatarStack } from '@crossbeam/bitts';

import { mapState } from 'pinia';

import { ALL_PARTNERS_TYPE, ECOSYSTEM } from '@/constants/reports';
import { usePartnersStore } from '@/stores';

export default {
  name: 'PartnersInReportCell',
  components: { BittsAvatarStack },
  computed: {
    ...mapState(usePartnersStore, ['getPartnerTagById']),
    isAllPartnersReport() {
      return (
        this.params.value.report.report_type === ALL_PARTNERS_TYPE ||
        this.params.value.report.consolidated_report_type === ECOSYSTEM
      );
    },
    isPartnerTagReport() {
      return !!this.params.value.report.tag_id;
    },
    orgsForStack() {
      return this.params.value.partnersInReport.map((org) => {
        return { ...org, showInitials: true, isOwn: false };
      });
    },
    tagInfo() {
      if (!this.isPartnerTagReport) return {};
      return this.getPartnerTagById(this.params.value.report.tag_id) || {};
    },
  },
};
</script>

<style scoped lang="pcss">
.partners-in-report {
  @apply flex flex-row items-center justify-start;
}
</style>

import axios from 'axios';
import { sortBy } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';

import useAuth from '@/composables/useAuth';
import {
  COMPLETED_STATUSES,
  CONNECTION_CHECK_ERROR_STATUS,
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  RECORD_LIMIT_EXCEEDED_STATUS,
  REQUIRED_FEED_STATUS,
  WARNING_STATUSES,
} from '@/constants/data_sources';
import { TEMP_NEW_ONBOARDING } from '@/constants/feature_flags';
import {
  ACCOUNT_MAPPING,
  CONNECT_DATA,
  CREATE_POPULATION,
  ERROR,
  FINISH,
  INVITE_PARTNER,
  INVITE_TEAM_MEMBERS,
  PROCESS,
  SHARE_DATA,
  STEPS,
  WAIT,
  WAIT_ON_PRECONDITION,
} from '@/constants/onboarding';
import {
  useDataSharesStore,
  useEcosystemInsightsStore,
  useFeatureFlagStore,
  useFeedsStore,
  useFileUploadsStore,
  useFlashesStore,
  useNotificationsStore,
  usePartnersStore,
  usePopulationsStore,
  useReportsStore,
  useSourcesStore,
  useTeamStore,
} from '@/stores';
import { useRootStore } from '@/stores/RootStore';
import urls from '@/urls';

import useIteratively from './useIteratively';

export default function () {
  const notificationsStore = useNotificationsStore();
  const dataSharesStore = useDataSharesStore();
  const feedsStore = useFeedsStore();
  const fileUploadsStore = useFileUploadsStore();
  const partnersStore = usePartnersStore();
  const populationsStore = usePopulationsStore();
  const reportsStore = useReportsStore();
  const sourcesStore = useSourcesStore();
  const teamStore = useTeamStore();
  const eiStore = useEcosystemInsightsStore();
  const featureFlagStore = useFeatureFlagStore();

  onMounted(async () => {
    const promises = [
      populationsStore.initPopulationsStore(),
      partnersStore.initPartnersStore(),
      dataSharesStore.readySync,
      reportsStore.readySync,
      eiStore.readySync,
    ];

    await Promise.all(promises);
  });

  const hasNewOnboarding = computed(() =>
    featureFlagStore.hasFeatureFlag(TEMP_NEW_ONBOARDING),
  );
  const { allNotifications } = storeToRefs(notificationsStore);
  const { incomingDataShares, isSharingDataWithPartners } =
    storeToRefs(dataSharesStore);
  const { feeds } = storeToRefs(feedsStore);
  const { uploadTables } = storeToRefs(fileUploadsStore);
  const { partnerOrgs, proposalsSent } = storeToRefs(partnersStore);
  const { populations } = storeToRefs(populationsStore);
  const { reports } = storeToRefs(reportsStore);
  const { sources } = storeToRefs(sourcesStore);
  const { authorizations, redeemableInvites } = storeToRefs(teamStore);

  const steps = computed(() => {
    STEPS[INVITE_PARTNER].wait_on_precondition =
      partnerOrgs.value.length === 0 && proposalsSent.value.length > 0;
    STEPS[INVITE_PARTNER].is_complete = partnerOrgs.value.length > 0;
    STEPS[INVITE_TEAM_MEMBERS].is_complete =
      authorizations.value.length + redeemableInvites.value.length > 1;
    STEPS[ACCOUNT_MAPPING].wait_on_precondition =
      !isSharingDataWithPartners.value || incomingDataShares.value.length === 0;
    STEPS[ACCOUNT_MAPPING].is_complete = reports.value.length > 0;
    STEPS[SHARE_DATA].is_complete = isSharingDataWithPartners.value;

    /* In the case of Google Sheets only, we need to check whether they've also added a Google Sheet after creating the feed... */
    const syncingFeeds = feeds.value.filter(
      (feed) => !feed.initial_sync_complete,
    );

    if (syncingFeeds.length > 0) {
      const syncingFeedsWithSources = syncingFeeds.reduce((acc, feed) => {
        if (GOOGLE_SHEETS_DATA_SOURCE_TYPE === feed.integration.type) {
          if (sourcesStore.getSourcesByFeedId(feed.id).length > 0) {
            acc += 1;
          }
        } else acc += 1;
        return acc;
      }, 0);

      if (syncingFeedsWithSources > 0) {
        if (syncingFeeds.every((f) => f.status === REQUIRED_FEED_STATUS)) {
          STEPS[CONNECT_DATA].needsFieldsSelection = true;
        } else {
          STEPS[CONNECT_DATA].wait_on_precondition = true;
        }
      }
    }

    /* We want to check if the feed is active, and if it has an associated source.
     * If so, then that source can be used for populations; mark connect_data as complete.
     * Marking of the step as complete will override the wait_on_precondition state. */

    const hasFeedProcessed = allNotifications.value
      .filter((notification) => notification.event_type === 'feed_processed')
      .map((notification) =>
        feedsStore.getFeedById(notification.object_id.feed_id),
      )
      .some(
        (feed) =>
          ![
            CONNECTION_CHECK_ERROR_STATUS,
            RECORD_LIMIT_EXCEEDED_STATUS,
          ].includes(feed?.status),
      );

    const csvsSynced = feeds.value
      .filter(feedByType([FILE_UPLOAD_DATA_SOURCE_TYPE]))
      .some(isFeedActiveOrWarning);

    const csvsConnected = csvsSynced && uploadTables.value.length > 0;

    const activeGoogleSheetFeeds = feeds.value
      .filter(feedByType([GOOGLE_SHEETS_DATA_SOURCE_TYPE]))
      .filter(isFeedActiveOrWarning);

    const sourceFeedIds = sources.value.map((source) => source.feed_id);

    const googleSheetsConnected = activeGoogleSheetFeeds.some((feed) =>
      sourceFeedIds.includes(feed.id),
    );

    const activeOtherFeeds = feeds.value
      .filter(
        feedByType(
          [FILE_UPLOAD_DATA_SOURCE_TYPE, GOOGLE_SHEETS_DATA_SOURCE_TYPE],
          false,
        ),
      )
      .filter(isFeedActiveOrWarning);

    const otherFeedsConnected = activeOtherFeeds.some((feed) =>
      sourceFeedIds.includes(feed.id),
    );

    STEPS[CONNECT_DATA].error =
      feeds.value.length > 0 &&
      feeds.value.every((feed) =>
        [CONNECTION_CHECK_ERROR_STATUS, RECORD_LIMIT_EXCEEDED_STATUS].includes(
          feed.status,
        ),
      );

    STEPS[CONNECT_DATA].is_complete =
      hasFeedProcessed ||
      googleSheetsConnected ||
      csvsConnected ||
      otherFeedsConnected;

    STEPS[CREATE_POPULATION].is_complete = populations.value.length > 0;

    const steps = sortBy(STEPS, 'order');
    return steps.map((step, i) => ({
      ...step,
      status: getStepStatus(steps, step, i),
    }));
  });

  const numCompletedSteps = computed(() => {
    let stepsNowCompleted = 0;
    for (const key in steps.value) {
      if (steps.value[key].is_complete) stepsNowCompleted += 1;
    }
    return stepsNowCompleted;
  });

  const numErroredSteps = computed(() => {
    let numErroredSteps = 0;
    for (const key in steps.value) {
      if (steps.value[key].error) numErroredSteps += 1;
    }
    return numErroredSteps;
  });

  const allStepsCompleted = computed(() => {
    for (const key in steps.value) {
      if (!steps.value[key].is_complete) return false;
    }
    return true;
  });

  const partnerInviteSent = computed(
    () =>
      steps.value.find((s) => s.name === INVITE_PARTNER).wait_on_precondition,
  );

  const rootStore = useRootStore();
  const flashesStore = useFlashesStore();
  const { currentOrg } = useAuth();
  const onboardingClosed = computed(() => currentOrg.value.setup_menu_hidden);

  const { iteratively } = useIteratively();
  async function removeOnboarding() {
    try {
      iteratively.userCompletesOnboarding();
      await axios.patch(urls.org.patch, { setup_menu_hidden: true });
      rootStore.setIsVgActivationClosed(
        true,
      ); /* TODO: Remove after v2 onboarding */
      flashesStore.addSuccessFlash({
        message: 'Onboarding closed permanently.',
      });
    } catch (_err) {
      flashesStore.addErrorFlash({
        message: 'Could not remove onboarding',
        description:
          'Please try again or contact support@crossbeam.com for help.',
      });
    }
  }

  return {
    onboardingClosed,
    hasNewOnboarding,
    removeOnboarding,
    steps,
    numCompletedSteps,
    numErroredSteps,
    allStepsCompleted,
    partnerInviteSent,
  };
}

/* Helper functions 🤝 */
const feedByType =
  (types, inclusive = true) =>
  (feed) => {
    return inclusive
      ? types.includes(feed.integration.type)
      : !types.includes(feed.integration.type);
  };

function isFeedActiveOrWarning(feed) {
  return (
    [...COMPLETED_STATUSES, ...WARNING_STATUSES].includes(feed.status) &&
    feed.initial_sync_complete
  );
}

const getStepStatus = (steps, step, stepIndex) => {
  if (step.is_complete) return FINISH;
  const { name } = step;

  if (name === INVITE_TEAM_MEMBERS) return PROCESS;
  if (name === CONNECT_DATA) {
    if (step.error) return ERROR;
    if (step.wait_on_precondition) return WAIT_ON_PRECONDITION;
    return PROCESS;
  }
  if (name === INVITE_PARTNER)
    return step.wait_on_precondition ? WAIT_ON_PRECONDITION : PROCESS;
  if (name === CREATE_POPULATION) {
    return steps[1].is_complete ? PROCESS : WAIT;
  }

  /* There is no "wait" possibility. We want to show a wait_on_precondition
        if the user creates a population and does not have sharing turned on
        (either overlap counts or shared data). */
  if (name === SHARE_DATA) {
    return steps[1].is_complete && steps[2].is_complete
      ? WAIT_ON_PRECONDITION
      : WAIT;
  }

  const prevSteps = steps.slice(0, stepIndex);
  const allPrevStepsDone = prevSteps.every((step) => step.is_complete);
  const allPrevStepsDoneOrWaiting = prevSteps.every((step) => {
    return step.is_complete || step.wait_on_precondition;
  });

  if (name === ACCOUNT_MAPPING) {
    if (allPrevStepsDone) {
      return step.wait_on_precondition ? WAIT_ON_PRECONDITION : PROCESS;
    } else if (allPrevStepsDoneOrWaiting) {
      return WAIT_ON_PRECONDITION;
    }
    return WAIT;
  }
};

<template>
  <div class="c-org-details-form">
    <div v-if="showCompanyForm">
      <div class="c-org-details-form__content">
        <div class="icon-background">
          <FontAwesomeIcon
            :icon="['fad', 'building']"
            class="text-secondary-accent w-[40px] h-[40px]"
          />
        </div>
        <h1 class="title"> Register your company </h1>
        <BittsCard
          :no-padding="false"
          class="p-16 md:p-24 max-w-[500px] mx-16 md:mx-24"
        >
          <div class="text-neutral-text-strong font-bold text-start mb-6">
            Company Information
          </div>
          <AutoCompleteClearbit
            class="mb-8"
            data-testid="company-name"
            :initial-value="companyName"
            :errors="v$.companyName.$errors"
            @change="(name) => (companyName = name)"
            @selected="handleCompanySelection"
          />
          <BittsInput
            v-model="clearbitDomain"
            data-testid="company-domain"
            placeholder="Company Website"
            name="clearbitDomain"
            :status="v$.clearbitDomain.$errors.length ? 'danger' : 'default'"
            :danger-text="v$.clearbitDomain.$errors?.at(-1)?.$message || ''"
            @enter-pressed="submitForm"
          />
          <div
            class="bg-neutral-background-weak rounded-t-16 px-12 pt-12 pb-8 mt-24 mb-4"
          >
            <BittsSwitch
              label="Company Discoverable"
              description="Other companies can see you are on Crossbeam"
              :initial-value="isDiscoverable"
              @change="switchOrgDiscoverability"
            />
          </div>
          <div
            class="bg-neutral-background-weak rounded-b-16 px-12 pt-12 pb-8 mb-4"
          >
            <BittsSwitch
              label="Users Discoverable"
              description="Companies can send partnership requests to specific users"
              :is-checked="usersDiscoverable"
              :use-is-checked-prop="true"
              :disabled="!isDiscoverable"
              @change="switchUserDiscoverability"
            />
          </div>
          <div v-if="internalTestOrgEnabled" class="px-12 pt-12 pb-8 mb-4">
            <BittsSwitch
              label="Test Organization"
              description="This is an internal test organization and won't contribute to insights"
              :is-checked="isInternal"
              :use-is-checked-prop="true"
              data-testid="internal-switch"
              @change="switchInternalOrg"
            />
          </div>
          <BittsAlert
            v-if="hasError"
            color="error"
            class="text-left"
            message="Additional steps are required to set you up"
            description="We've emailed you further details, contact support@crossbeam.com with any additional questions"
          />
          <BittsButton
            class="w-full mt-24"
            data-testid="submit-form"
            text="Create company"
            :loading="loading"
            :disabled="loading"
            @click="submitForm"
          />
        </BittsCard>
        <div class="logout-footer-button">
          <BittsButton
            class="w-full"
            :right-icon="['far', 'arrow-right-from-bracket']"
            text="Log out"
            variant="outline"
            type="neutral"
          />
        </div>
      </div>
    </div>
    <FoundYourTeam
      v-else-if="companyName"
      :loading="loading"
      :company-name="companyName"
      :logo="logo"
      :clearbit-domain="clearbitDomain"
      @accept-suggestion="acceptSuggestion"
      @reject-suggestion="rejectSuggestion"
    />
  </div>
</template>

<script setup>
import {
  BittsAlert,
  BittsButton,
  BittsCard,
  BittsInput,
  BittsSwitch,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, minLength, required, url } from '@vuelidate/validators';
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import AutoCompleteClearbit from '@/components/AutoCompleteClearbit.vue';
import FoundYourTeam from '@/components/registration/FoundYourTeam.vue';

import useAuth from '@/composables/useAuth';
import { TEMP_INTERNAL_TEST_ORG } from '@/constants/feature_flags';
import { captureException } from '@/errors';
import { getAndClearLSNextUrl } from '@/local_storage';
import { useFeatureFlagStore } from '@/stores';
import { useRootStore } from '@/stores/RootStore';
import urls from '@/urls';
import { switchOrganization } from '@/utils';

const props = defineProps({
  nextUrl: {
    type: String,
    default: null,
  },
});

const rootStore = useRootStore();

const router = useRouter();

const loading = ref(true);
const isDiscoverable = ref(true);
const usersDiscoverable = ref(false);
const hasError = ref(false);
const showCompanyForm = ref(false);
const companyName = ref('');
const clearbitDomain = ref('');
const logo = ref('');

const featureFlagStore = useFeatureFlagStore();

const rules = computed(() => ({
  companyName: {
    required: helpers.withMessage('Please enter your company name', required),
    validName: helpers.withMessage(
      'Name may not contain special characters' + ' `, < or >.',
      (name) => {
        return /^[^<>`]*$/.test(name);
      },
    ),
    minLength: helpers.withMessage(
      'Name must have at least 2 letters.',
      minLength(2),
    ),
  },
  clearbitDomain: {
    required: helpers.withMessage('Please enter your company domain', required),
    validDomain: helpers.withMessage('Please enter valid URL', (domain) => {
      return url.$validator(domain) || url.$validator(`http://${domain}`);
    }),
  },
}));

const v$ = useVuelidate(rules, { companyName, clearbitDomain, logo });

const { currentUser } = useAuth();

const domain = computed(() => currentUser.value.email?.split('@')?.at(-1));
const isCrossbeamUser = computed(() => domain.value === 'crossbeam.com');
const internalTestOrgEnabled = computed(
  () =>
    isCrossbeamUser.value &&
    featureFlagStore.hasFeatureFlag(TEMP_INTERNAL_TEST_ORG),
);
const isInternal = ref(isCrossbeamUser.value);

onMounted(async () => {
  init();
});

/* Check to see if their organization exists already on Crossbeam. If so, route appropriately
and also fetch the logo for the image (if it can be found in clearbit) */
const queryDomain = ref(null); // Used to query clearbit, may be slightly different from parsed domain
async function init() {
  try {
    const { data } = await axios.get(urls.users.suggestedOrgsByEmail);
    if (data.name) {
      companyName.value = data.name;
      clearbitDomain.value = data.parsed_domain;
      queryDomain.value = data.clearbit_domain;
      useHead({ title: 'Request Access - Crossbeam' });
    } else {
      showCompanyForm.value = true;
    }
  } catch (err) {
    captureException(err);
    clearFormValues();
    showCompanyForm.value = true;
  }

  if (clearbitDomain.value || queryDomain.value) {
    try {
      const url = urls.clearbitAutoComplete(
        queryDomain.value || clearbitDomain.value,
      );
      const { data: clearbitData } = await axios.get(url);
      logo.value = clearbitData.find(
        ({ domain }) =>
          domain === clearbitDomain.value || domain === queryDomain.value,
      )?.logo;
    } catch (err) {
      captureException(err);
    }
  }

  loading.value = false;
}

function clearFormValues() {
  clearbitDomain.value = '';
  companyName.value = '';
  logo.value = '';
}

function acceptSuggestion() {
  showCompanyForm.value = true;
}

function rejectSuggestion() {
  companyName.value = '';
  logo.value = '';
  clearbitDomain.value = '';
  showCompanyForm.value = true;
}

function switchUserDiscoverability() {
  usersDiscoverable.value = !usersDiscoverable.value;
}
function switchOrgDiscoverability() {
  if (isDiscoverable.value && usersDiscoverable.value)
    switchUserDiscoverability();
  isDiscoverable.value = !isDiscoverable.value;
}
function switchInternalOrg() {
  if (isCrossbeamUser.value) {
    isInternal.value = !isInternal.value;
  }
}

async function handleCompanySelection(company) {
  companyName.value = company.name;
  clearbitDomain.value = company.clearbit_domain;
}

async function submitForm() {
  v$.value.$touch();
  if (v$.value.$invalid) {
    return;
  }
  hasError.value = false;
  loading.value = true;
  try {
    const payload = {
      organization: {
        name: companyName.value,
        is_discoverable: isDiscoverable.value,
        users_discoverable: usersDiscoverable.value,
        is_internal: isInternal.value && internalTestOrgEnabled.value,
      },
    };
    if (clearbitDomain.value) {
      const url = clearbitDomain.value.toLowerCase();
      payload.organization.url = url;
      payload.organization.clearbit_domain = url;
    }

    const response = await axios.post(urls.registration.register, payload);
    await rootStore.loadUserProfile();
    const orgId = response.data.organization.id;
    const next = props.nextUrl || getAndClearLSNextUrl() || { name: 'main' };
    switchOrganization(router, orgId, next);
  } catch (_err) {
    hasError.value = true;
    companyName.value = '';
    clearbitDomain.value = '';
    v$.value.$reset();
  } finally {
    loading.value = false;
  }
}
</script>

<style lang="pcss" scoped>
.c-org-details-form {
  @apply flex items-center justify-center;
}

.c-org-details-form__content {
  @apply flex flex-col items-center mt-24 md:mt-0;
}

.title {
  @apply text-xl text-brand-navy text-center mb-40 mt-8 font-bold mx-16 md:mx-24;
}

.c-org-details-form__add-border {
  @apply border-l border-solid border-neutral-200 my-24;
  height: 64px;
}

.c-org-details-form__social-proof {
  @apply flex-1 bg-blue-500;
}

.c-domain-confirmation {
  @apply flex flex-col h-full;
}

.icon-background {
  @apply w-[90px] h-[90px] rounded-full pt-24 flex justify-center;
  background: radial-gradient(
    226.96% 159.92% at 50.09% -12.42%,
    color-mix(in srgb, theme(colors.secondary.accent) 20%, white) 0%,
    white,
    white
  );
}

.logout-footer-button {
  @apply block md:hidden w-full p-16 md:p-24 pt-40;
}
</style>

<template>
  <div class="c-filter-group-selector">
    <div class="px-12 mb-12">
      <BittsInput
        v-model="searchInput"
        placeholder="Search for anything"
        size="small"
        name="population-filter-search"
      />
    </div>
    <loading :is-loading="isLoading">
      <div
        v-if="filteredFieldsForSelector.length === 0"
        class="c-filter-group-selector__no-results"
      >
        <span class="font-bold text-neutral-600"> No Results Found. </span>
        <div class="text-neutral-600"> Try another search term. </div>
      </div>
      <div v-else>
        <div
          v-for="source in filteredFieldsForSelector"
          :key="`org-source__${source.id}`"
        >
          <span
            v-if="!isOnlyOneGroup || showTitleIfOneSource"
            class="c-filter-group-selector__source-name"
          >
            {{ source.table }}
          </span>
          <div
            v-for="field in source.fields"
            :key="`org-source-field__${field.id}`"
          >
            <span
              class="c-filter-group-selector__field-name"
              @click="selectField(field)"
            >
              {{ field.display_name }}
            </span>
          </div>
        </div>
      </div>
    </loading>
  </div>
</template>

<script>
import { BittsInput } from '@crossbeam/bitts';

import { orderBy } from 'lodash';

export default {
  name: 'PopulationFilterGroupSelector',
  components: { BittsInput },
  props: {
    orgSources: {
      type: Array,
      required: true,
    },
    // we want to show the title in the dropdown if you're adding a filter group
    // even if its the last one, this toggles that
    showTitleIfOneSource: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['field-selected'],
  data() {
    return {
      searchInput: '',
      isLoading: false,
      filteredFieldsForSelector: [],
    };
  },
  computed: {
    isOnlyOneGroup() {
      // if there is only one source, we are adding within a group
      return this.orgSources.length === 1;
    },
  },
  watch: {
    searchInput() {
      this.isLoading = true;
      this.filteredFieldsForSelector = this.filterSourceData();
      this.$nextTick(async () => {
        this.isLoading = false;
      });
    },
  },
  created() {
    this.filteredFieldsForSelector = this.orgSources;
  },
  methods: {
    selectField(field) {
      this.$emit('field-selected', field);
    },
    filterSourceData() {
      if (!this.searchInput) {
        return this.orgSources;
      }
      return this.orgSources
        .map((source) => {
          const { fields } = source;
          const newFields = orderBy(
            fields.filter((field) => {
              return (
                field.display_name
                  .toLowerCase()
                  .indexOf(this.searchInput.toLowerCase().trim()) > -1
              );
            }),
            ['display_name'],
          );
          return {
            ...source,
            fields: newFields,
          };
        })
        .filter((source) => source.fields.length > 0);
    },
  },
};
</script>

<style scoped lang="pcss">
.c-filter-group-selector {
  @apply py-12;
  width: 320px;
}

.c-filter-group-selector__no-results {
  @apply flex flex-col justify-center items-center w-full my-24 opacity-50;
}

.c-filter-group-selector__source-name {
  @apply text-neutral-500 tracking-wider uppercase text-xs pl-12;
  line-height: 2.5 !important;
}

.c-filter-group-selector__field-name {
  @apply inline-block text-neutral-800 text-base cursor-pointer w-full pl-12;
  line-height: 2.5 !important;
}

.c-filter-group-selector__field-name:hover {
  @apply bg-neutral-50;
}
</style>

<template>
  <div class="c-current-plan-card-summary">
    <div class="card-title" data-testid="card-title">
      <p class="flex items-center">
        <span class="title">You're on {{ tier }}</span>
        <span
          v-if="isConnectorTier && isSubscriptionCancelled"
          class="cancellation-period"
          >until {{ subscriptionRenewalDate }}</span
        >
      </p>
      <slot name="top-right" />
    </div>
    <p data-testid="card-subtitle" class="text-neutral-text-weak mb-4">
      <span v-if="isSubscriptionCancelled"
        >After that, you will be downgraded to the free Explorer plan</span
      >
      <span v-else>
        Thanks for being on this plan since
        <span class="text-neutral-text font-bold">{{ startDate }}</span>
      </span>
    </p>
    <slot />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useBillingStore } from '@/stores';

const billingStore = useBillingStore();
const { isConnectorTier, isSubscriptionCancelled, subscriptionRenewalDate } =
  storeToRefs(billingStore);
defineProps({
  tier: {
    type: String,
    required: true,
  },
  startDate: {
    type: String,
    required: true,
  },
});
</script>
<style lang="pcss" scoped>
.c-current-plan-card-summary {
  @apply p-16 sm:p-24 flex flex-col rounded-16;
  /* TODO: Refactor the colors out into our tailwind config */
  background: linear-gradient(
      104.9deg,
      theme(colors.secondary.background-weak / 0.8) 0%,
      rgba(226, 247, 253, 0) 100%,
      theme(colors.secondary.accent / 0) 100%
    ),
    linear-gradient(0deg, theme(colors.white), theme(colors.white));
  .title {
    @apply text-lg font-bold text-info-text-strong;
  }
  .card-title {
    @apply mb-8 flex flex-row justify-between;
  }

  .cancellation-period {
    @apply text-secondary-text text-lg ml-4;
  }
}
</style>

<template>
  <RouterLink class="reports-table-cell__wrapper" :to="params.value.reportLink">
    <div class="flex flex-col">
      <span class="reports-user-cell__edited-text">
        {{ `Edited ${getRelativeTime}` }}
      </span>
      <div class="flex flex-row items-center">
        <BittsAvatar
          :user="user"
          :show-initials="true"
          :is-own="true"
          size="x-small"
          class="mr-8 leading-none"
        />
        <span class="reports-user-cell__user-text">
          {{ `${user.first_name} ${user.last_name}` }}
        </span>
      </div>
    </div>
  </RouterLink>
</template>

<script>
import { BittsAvatar } from '@crossbeam/bitts';

import { DateTime } from 'luxon';

export default {
  name: 'ReportUserCell',
  components: { BittsAvatar },
  computed: {
    getRelativeTime() {
      return DateTime.fromISO(this.params.value.report.updated_at).toRelative();
    },
    user() {
      return this.params.value.updatedByUser;
    },
  },
};
</script>

<style lang="pcss" scoped>
.reports-user-cell__edited-text {
  @apply text-sm text-neutral-800 leading-none mb-8;
}

.reports-user-cell__user-text {
  @apply text-base text-neutral-500 leading-none truncate;
}
</style>

import {
  createRouter as _createRouter,
  createWebHistory,
  isNavigationFailure,
} from 'vue-router';

import DowngradeCompleteModal from '@/components/billing/DowngradeCompleteModal.vue';
import DataSourceRenameModal from '@/components/data-sources/DataSourceRenameModal.vue';
import DataSourceSettingsDeux from '@/components/data-sources/DataSourceSettingsDeux.vue';
import DataTemplates from '@/components/data-sources/DataTemplates.vue';
import RemoveSourceModal from '@/components/data-sources/RemoveSourceModal.vue';
import SnowflakeSettingsModal from '@/components/data-sources/SnowflakeSettingsModal.vue';
import CrossbeamCopilotSettingsDrawer from '@/components/integrations/CrossbeamCopilotSettingsDrawer.vue';
import FriggAuthorizationSettingsModal from '@/components/integrations/FriggAuthorizationSettingsModal.vue';
import FriggCallback from '@/components/integrations/FriggCallback.vue';
import FriggSettingsDrawer from '@/components/integrations/FriggSettingsDrawer.vue';
import OAuthSettingsDrawer from '@/components/integrations/OAuthSettingsDrawer.vue';
import SalesforceCustomObjectSettingsDrawer from '@/components/integrations/SalesforceCustomObjectSettingsDrawer.vue';
import OnboardingInviteModal from '@/components/onboarding/InviteModal.vue';
import OnboardingDoneModal from '@/components/onboarding/OnboardingDoneModal.vue';
import DeletePartnershipModal from '@/components/partners/DeletePartnershipModal.vue';
import SharingDashboard from '@/components/partners/SharingSettingsDashboard/SharingDashboard.vue';
import DeletePopulationModal from '@/components/populations/DeletePopulationModal.vue';
import IRPActivityTimeline from '@/components/records/IRPActivityTimeline.vue';
import DeleteReportModal from '@/components/reports/DeleteReportModal.vue';
import DuplicateReportModal from '@/components/reports/DuplicateReportModal.vue';
import ReportNotificationsModalRoute from '@/components/reports/ReportNotificationsModalRoute.vue';
import UserInfo from '@/components/user/UserInfo.vue';

import Academy from '@/pages/Academy.vue';
import AccessRequested from '@/pages/AccessRequested.vue';
import AccountLocked from '@/pages/billing/AccountLocked.vue';
import BillingPage from '@/pages/billing/BillingPage.vue';
import ConnectorSeats from '@/pages/billing/ConnectorSeats.vue';
import ConnectorUpgrade from '@/pages/billing/ConnectorUpgrade.vue';
import Downgrade from '@/pages/billing/Downgrade.vue';
import EditPaymentModal from '@/pages/billing/EditPaymentModal.vue';
import PurchaseSuccessful from '@/pages/billing/PurchaseSuccessful.vue';
import RemoveSeats from '@/pages/billing/RemoveSeats.vue';
import RestorePlan from '@/pages/billing/RestorePlan.vue';
import SelfServe from '@/pages/billing/SelfServe.vue';
import Sunsetting from '@/pages/billing/Sunsetting.vue';
import CoSell from '@/pages/co-sell.vue';
import ConnectDataSource from '@/pages/data-sources/_type/connect.vue';
import DynamicsPickCrm from '@/pages/data-sources/dynamics-pick-crm.vue';
import OauthReturn from '@/pages/data-sources/feed/_id.vue';
import FileUploads from '@/pages/data-sources/file-uploads.vue';
import GoogleSheets from '@/pages/data-sources/google-sheets.vue';
import MapAEColumns from '@/pages/data-sources/map-new-columns.vue';
import DataSources from '@/pages/data-sources.vue';
import DormantCompany from '@/pages/dormant-company.vue';
import ForgotPassword from '@/pages/forgot-password.vue';
import PublicInvite from '@/pages/i/_code.vue';
import OAuthApplicationsCreate from '@/pages/integrations/create.vue';
import ViewInvite from '@/pages/invites/_code.vue';
import LinkAccounts from '@/pages/link-accounts.vue';
import Login from '@/pages/login.vue';
import MaintenanceMode from '@/pages/MaintenanceMode.vue';
import Notifications from '@/pages/notifications.vue';
import OAuthCallback from '@/pages/oauth/callback.vue';
import OrganizationSettings from '@/pages/organization/settings.vue';
import Overlaps from '@/pages/overlaps.vue';
import AcceptPublicInvite from '@/pages/partners/public-invite.vue';
import PartnerstackCallback from '@/pages/partnerstack/callback.vue';
import EditPopulationSharing from '@/pages/populations/_id/edit/sharing.vue';
import EditPopulation from '@/pages/populations/_id/edit.vue';
import CreatePopulation from '@/pages/populations/create.vue';
import CreatePopulationModal from '@/pages/populations/CreatePopulationModal.vue';
import Populations from '@/pages/populations/Populations.vue';
import PopulationsSharing from '@/pages/populations/sharing/_id.vue';
import IndividualRecordPage from '@/pages/records/IndividualRecordPage.vue';
import IndividualRecordUniversal from '@/pages/records/IndividualRecordUniversal.vue';
import RegisterOrSetup from '@/pages/register/RegisterOrSetup.vue';
import SeatRequestPending from '@/pages/register/SeatRequestPending.vue';
import ReportFolder from '@/pages/reports/folders/_id.vue';
import DeleteRoleModal from '@/pages/roles/_id/delete.vue';
import EditRole from '@/pages/roles/_id.vue';
import CreateRole from '@/pages/roles/create.vue';
import Roles from '@/pages/roles.vue';
import IndividualListPage from '@/pages/shared-list/IndividualListPage.vue';
import SharedList from '@/pages/shared-list/List.vue';
import SlackCallback from '@/pages/slack/callback.vue';
import EditUserModal from '@/pages/team/_id/EditUser.vue';
import RemoveUserModal from '@/pages/team/_id/RemoveUserModal.vue';
import RevokeInviteModal from '@/pages/team/_id/RevokeInviteModal.vue';
import EditUsersModal from '@/pages/team/EditUsers.vue';
import InviteTeamMember from '@/pages/team/InviteTeamMember.vue';
import RemoveUsersModal from '@/pages/team/RemoveUsers.vue';
import SeatRequests from '@/pages/team/SeatRequests.vue';
import TeamPage from '@/pages/team/TeamPage.vue';
import SunsetThreads from '@/pages/threads/SunsetThreads.vue';
import UserSettings from '@/pages/user/UserSettings.vue';
import VerifyCompany from '@/pages/verify-company.vue';
import VerifyEmail from '@/pages/verify-email.vue';

import useHasFeature from '@/composables/useHasFeature';
import { SNOWFLAKE_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import {
  TEMP_EI,
  TEMP_EXCLUDE_OFFLINE_PARTNERS,
} from '@/constants/feature_flags';
import {
  beforeEnterMain,
  handleLogout,
  inRenewalPeriod,
  inRenewalPeriodAndCancelled,
  routingOverride,
} from '@/constants/router';
import { ls } from '@/local_storage';
import {
  useAttributionStore,
  useBillingStore,
  useFeatureFlagStore,
  useFeedsStore,
  useFlashesStore,
  usePartnersStore,
  useRolesStore,
} from '@/stores';

import { BASE_ROUTES, CHILD_ROUTES } from './constants/routes';
import {
  COPILOT_BASE_PATH,
  SALES_INTEL_BASE_PATH,
} from './salesintel/constants/router';

const ApplicationLayout = () =>
  import('@/components/layout/ApplicationLayout.vue');
const BareLayout = () => import('@/components/layout/BareLayout.vue');

const AccessDenied = () => import('@/components/AccessDenied.vue');
const NotFound = () => import('@/pages/not-found.vue');
const LoggedOut = () => import('@/pages/logged-out.vue');
const OauthRedirect = () => import('@/pages/OauthRedirect.vue');

const HomePage = () => import('@/pages/HomePage.vue');
const QrReader = () => import('@/components/partners/QrReader.vue');
const NoOrg = () => import('@/pages/no-org.vue');

const PartnerPageTwo = () => import('@/pages/Partners.vue');
const PartnerDetails = () => import('@/pages/partners/_id.vue');
const ProposalsPreAcceptance = () =>
  import('@/pages/partners/pre-acceptance.vue');
const AcceptProposalTwo = () => import('@/pages/partners/accept-two.vue');
const HomeSharingModal = () => import('@/components/dash/HomeSharingModal.vue');
const ProposalModal = () => import('@/pages/partners/invite.vue');
const ProposalModalTwo = () =>
  import('@/components/partners/ProposalModalTwo.vue');
const PartnersTableEditModal = () =>
  import('@/components/partners/PartnersTableEditModal.vue');

const Reports = () => import('@/pages/reports.vue');
const CreateReport = () => import('@/pages/create-report.vue');
const CreateReportModal = () => import('@/pages/reports/CreateReportModal.vue');
const ReportDetail = () => import('@/pages/reports/report-detail.vue');
const BittsPlayground = () => import('@/pages/bitts-playground.vue');

// Pages
const AttributionPage = () => import('@/pages/AttributionPage.vue');
const AttributionEcosystemPage = () =>
  import('@/pages/AttributionEcosystemPage.vue');
const AttributionDrawer = () =>
  import('@/components/attribution/AttributionDrawer.vue');
const AttributionSettingsModal = () =>
  import('@/components/attribution/AttributionSettingsModal.vue');
const IntegrationsPage = () => import('@/pages/IntegrationsPage.vue');

export const createRouter = (store) => {
  const featureFlagStore = useFeatureFlagStore();
  const billingStore = useBillingStore();
  const flashesStore = useFlashesStore();
  const attributionStore = useAttributionStore();

  const hasAttributionPage = store.hasScope('read:attribution');
  const hasOfflinePartners = !featureFlagStore.hasFeatureFlag(
    TEMP_EXCLUDE_OFFLINE_PARTNERS,
  );
  const hasAttributionFullAccess =
    attributionStore.hasAttributionAccess &&
    store.hasScope('write:attribution');
  const hasTempEI = featureFlagStore.hasFeatureFlag(TEMP_EI);

  const { debookingEnabled } = useHasFeature();

  const denyAccess = (to) => {
    const firstChild = to.matched[1].components;
    firstChild.overwritten = firstChild.default;
    firstChild.default = AccessDenied;
  };
  const requireScope = (scope) => (to, _from, next) => {
    if (!store.hasScope(scope)) {
      denyAccess(to);
    }
    next();
  };
  const tabRequireScope = (tab, scope) => (to, _from, next) => {
    if (!store.hasScope(scope) && to.query.tab === tab) {
      const firstChild = to.matched[1].components;
      firstChild.overwritten = firstChild.default;
      firstChild.default = AccessDenied;
    }
    next();
  };
  const checkInvitesIfOrgHasFailed = (to, from, next) => {
    if (store.orgMarkedAsFailed) {
      checkPendingInvitations(to, from, next);
    } else {
      next();
    }
  };
  const checkPendingInvitations = (to, from, next) => {
    const invitation = store.getPendingInvitation;
    if (invitation) {
      next({ name: 'view_invite', params: { code: invitation.invite_code } });
    } else {
      tryAccountLinking(to, from, next);
    }
  };

  function checkPendingSeatRequestsAndInvitations(to, from, next) {
    const seatRequest = store.getPendingSeatRequest;
    if (seatRequest) {
      next({
        name: 'invite-requests',
        params: { invite_request_uuid: seatRequest.id },
      });
    } else {
      return checkPendingInvitations(to, from, next);
    }
  }

  const tryAccountLinking = (to, from, next) => {
    const query = { next: to.fullPath };
    if (store.userCanTryToLinkAccounts && from.name !== 'link-accounts') {
      next({ name: 'link-accounts', query });
    } else {
      next();
    }
  };
  const companySetupIfLoggedIn = (_, from, next) => {
    if (store.isLoggedIn && from.name !== 'register') {
      next({ name: 'company_setup' });
    } else {
      next();
    }
  };
  const enforceAuthentication = async (to, from, next, checkEa = true) => {
    const query = { next: to.fullPath };
    const nextRoute = await routingOverride({ to, from, store }, checkEa);
    if (nextRoute) {
      next({ name: nextRoute.name, query });
    } else {
      next();
    }
  };

  const routerConfig = {
    history: createWebHistory('/'),
    /*
     * FULL WIDTH LAYOUT -- UNAUTHENTICATED ROUTES
     */
    routes: [
      {
        name: 'main',
        path: '/',
        beforeEnter(to, from, next) {
          beforeEnterMain(to, from, next, store);
        },
      },
      {
        path: '/',
        component: BareLayout,
        children: [
          {
            name: 'verify_email',
            path: 'verify-email',
            component: VerifyEmail,
            props: (route) => ({ email: route.query.email }),
          },
          {
            name: 'partners_pre_acceptance',
            path: 'partners/pre-acceptance',
            component: ProposalsPreAcceptance,
          },
          {
            name: 'view_invite',
            path: 'invites/:code',
            beforeEnter: tryAccountLinking,
            component: ViewInvite,
          },
          {
            // The switch_org route doesn't need to be authenticated primarily
            // because the user may be coming from the admin panel and we can
            // go ahead and set the org context before potentially asking the
            // user to login. In other words, if the user is not logged in and
            // they hit this route, we'll first set the org in local storage,
            // then reload the page, then the user will be asked to log in and
            // they'll already be targeting the org they want. We also want
            // this route to not pass through the "userNeedsToRegister" check
            // which happens for full-fledged application routes because the
            // user may have just registered and the pinia store might not have
            // information about the org, thus thinking they need to register
            // when they just have
            name: 'switch_org',
            path: '/switch-org',
            beforeEnter(to) {
              ls.orgId.set(to.query.organization_id);
              window.location.replace(to.query.next ? to.query.next : '/');
            },
          },
          {
            name: 'register-public-params',
            path: 'register/:invitingOrgString/:inviteNextUrl',
            beforeEnter: companySetupIfLoggedIn,
            component: RegisterOrSetup,
            /*
             * Note we never use this pattern of passing props via router
             * We are passing invitingOrgString via route props via query params, see:
             * https://router.vuejs.org/guide/essentials/passing-props.html#boolean-mode
             * to simplify the layouts. It was originally forcing the RegistrationForm
             * into a layout that didn't match.
             */
            props: true,
          },
          {
            name: 'register-public',
            path: 'register',
            beforeEnter: companySetupIfLoggedIn,
            component: RegisterOrSetup,
            props: true,
          },
          {
            name: 'error',
            path: '/not_found',
            component: NotFound,
          },
          {
            name: 'logged_out',
            path: '/logged-out',
            component: LoggedOut,
          },
          {
            name: 'logout',
            path: '/logout',
            beforeEnter(_to, _from, _next) {
              handleLogout('/logged-out');
            },
          },
          {
            name: 'academy',
            path: '/academy/login',
            component: Academy,
          },
          {
            path: '/',
            name: 'maintenance_mode',
            component: MaintenanceMode,
          },
          {
            name: 'oauth_redirect',
            path: '/oauth-redirect',
            component: OauthRedirect,
          },
          {
            name: 'custom-register',
            path: '/i/:code',
            component: PublicInvite,
          },
          {
            /* Guard in case of nav within Crossbeam-web. Redirect to Copilot */
            name: 'sales_intel',
            path: `${SALES_INTEL_BASE_PATH}/:pathMatch(.*)*`,
            beforeEnter: (to) => {
              // Old sales-intel links need to be redirected to copilot
              const newTo = to.fullPath.replace(
                SALES_INTEL_BASE_PATH,
                COPILOT_BASE_PATH,
              );
              window.location.replace(newTo);
            },
            component: { template: '<div></div>' },
          },
          {
            /* Guard in case of nav within Crossbeam-web. Redirect to Copilot */
            name: 'copilot_view',
            path: `${COPILOT_BASE_PATH}/:pathMatch(.*)*`,
            beforeEnter: (to) => {
              window.location.replace(to.fullPath);
            },
            component: { template: '<div></div>' },
          },
        ],
      },
      /*
       * HALF WIDTH LAYOUT -- UNAUTHENTICATED ROUTES
       */
      {
        path: '/',
        component: BareLayout,
        children: [
          {
            name: 'login',
            path: 'login',
            beforeEnter(_to, _from, next) {
              if (store.isLoggedIn) {
                window.location.replace('/');
              } else {
                next();
              }
            },
            component: Login,
          },
          {
            name: 'forgot-password',
            path: 'forgot-password',
            component: ForgotPassword,
          },
          {
            name: 'link-accounts',
            path: 'link-accounts',
            component: LinkAccounts,
          },
        ],
      },
      /*
       * AUTHENTICATED, NON-APPLICATION ROUTES
       * These routes are restricted in the same way the AUTHENTICATED APPLICATION ROUTES are,
       * but they do not use use the full ApplicationLayout.
       */
      {
        path: '/',
        beforeEnter(to, _from, next) {
          if (!store.isLoggedIn) {
            next({ name: 'login', query: { next: to.fullPath } });
          } else {
            next();
          }
        },
        component: BareLayout,
        children: [
          {
            name: 'no_org',
            path: 'no-org',
            beforeEnter: checkPendingInvitations,
            component: NoOrg,
          },
          {
            name: 'verify_company',
            path: 'verify-company',
            beforeEnter: checkInvitesIfOrgHasFailed,
            component: VerifyCompany,
          },
          {
            name: 'dormant_company',
            path: 'dormant-company',
            component: DormantCompany,
          },
          {
            name: 'invite-requests',
            path: '/invite-requests/:invite_request_uuid',
            beforeEnter: checkPendingInvitations,
            component: AccessRequested,
            props: (route) => ({ uuid: route.params.invite_request_uuid }),
          },
          {
            name: 'company_setup',
            path: 'company-setup',
            beforeEnter: checkPendingSeatRequestsAndInvitations,
            component: RegisterOrSetup,
          },
          {
            name: 'seat_request_pending',
            path: 'seat-request-pending',
            component: SeatRequestPending,
          },
        ],
      },
      /*
       *  AUTHENTICATED APPLICATION ROUTES
       *  The routes under this parent route all require an authenticated,
       *  registered user. This is the main application section.
       *
       *  Unauthenticated users will be sent to the login page. Unregistered
       *  users will be sent to the registration page. Otherwise, the request
       *  will be allowed.
       */
      {
        path: '/',
        beforeEnter: enforceAuthentication,
        component: ApplicationLayout,
        props: (route) => ({
          fullWidth: fullWidthAuthenticatedRoutes.some((path) =>
            route.fullPath.startsWith(path),
          ),
        }),
        children: [
          {
            name: BASE_ROUTES.ATTRIBUTION,
            path: 'attribution',
            component: AttributionPage,
            beforeEnter(_to, _from, next) {
              hasAttributionPage ? next() : next({ name: 'not_found' });
            },
            children: [
              {
                name: CHILD_ROUTES.attribution.ATTRIBUTE,
                path: ':master_id/:opportunity_id/attribute',
                component: AttributionDrawer,
                beforeEnter(_to, _from, next) {
                  hasAttributionFullAccess
                    ? next()
                    : next({ name: 'not_found' });
                },
              },
              {
                name: CHILD_ROUTES.attribution.ATTRIBUTION_TIMELINE,
                path: ':master_id/:opportunity_id/timeline',
                component: AttributionDrawer,
                beforeEnter(_to, _from, next) {
                  hasAttributionFullAccess
                    ? next()
                    : next({ name: 'not_found' });
                },
              },
              {
                name: CHILD_ROUTES.attribution.ATTRIBUTION_SETTINGS,
                path: 'settings',
                component: AttributionSettingsModal,
              },
            ],
          },
          {
            name: BASE_ROUTES.ECOSYSTEM_INSIGHTS,
            path: 'measure/ecosystem',
            component: AttributionEcosystemPage,
            children: [
              {
                name: CHILD_ROUTES.ecosystem_insights
                  .ECOSYSTEM_INSIGHTS_TIMELINE,
                path: ':master_id/:opportunity_id/timeline',
                component: AttributionDrawer,
                beforeEnter(_to, _from, next) {
                  hasAttributionFullAccess && hasTempEI
                    ? next()
                    : next({ name: 'not_found' });
                },
              },
              {
                name: CHILD_ROUTES.ecosystem_insights
                  .ECOSYSTEM_INSIGHTS_ATTRIBUTE,
                path: ':master_id/:opportunity_id/attribute',
                component: AttributionDrawer,
                beforeEnter(_to, _from, next) {
                  hasAttributionFullAccess && hasTempEI
                    ? next()
                    : next({ name: 'not_found' });
                },
              },
            ],
          },
          {
            name: 'notifications',
            path: 'notifications',
            component: Notifications,
          },
          {
            name: BASE_ROUTES.REPORTS,
            path: 'reports',
            component: Reports,
            children: [
              {
                name: CHILD_ROUTES.reports.REPORTS_DUPLICATE,
                path: ':id/duplicate',
                component: DuplicateReportModal,
              },
              {
                name: CHILD_ROUTES.reports.REPORTS_DELETE,
                path: ':id/delete',
                component: DeleteReportModal,
              },
              {
                name: CHILD_ROUTES.reports.REPORTS_NOTIFICATIONS,
                path: ':id/notifications',
                component: ReportNotificationsModalRoute,
              },
            ],
          },
          {
            name: BASE_ROUTES.REPORT_FOLDER,
            path: '/reports/folders/:folder_id',
            component: ReportFolder,
            children: [
              {
                name: CHILD_ROUTES.folder.REPORTS_DUPLICATE,
                path: 'duplicate',
                component: DuplicateReportModal,
              },
              {
                name: CHILD_ROUTES.folder.REPORTS_DELETE,
                path: 'delete',
                component: DeleteReportModal,
              },
              {
                name: CHILD_ROUTES.folder.REPORTS_NOTIFICATIONS,
                path: 'notifications',
                component: ReportNotificationsModalRoute,
              },
            ],
          },
          {
            name: 'account_mapping',
            path: '/reports/account-mapping',
            component: CreateReport,
            children: [
              {
                name: 'account_mapping.create',
                path: '/reports/account-mapping/create',
                component: CreateReportModal,
              },
            ],
          },
          {
            name: 'view_report',
            path: '/reports/view',
            component: ReportDetail,
          },
          {
            name: 'edit_report',
            path: '/reports/:report_id',
            component: ReportDetail,
          },

          // -----------------
          //   Home
          // -----------------

          {
            name: 'home',
            path: '/home',
            component: HomePage,
            children: [
              {
                name: 'qr_scan',
                path: 'qr-scan',
                component: QrReader,
                beforeEnter(_to, from, next) {
                  if (from.name === 'home') next();
                  else next({ name: 'home' });
                },
              },
              {
                name: 'onboarding-invite',
                path: '/home/onboarding/invite-team',
                component: OnboardingInviteModal,
              },
              {
                name: 'onboarding_user_info_modal',
                path: '/home/onboarding/edit-user',
                component: UserInfo,
              },
              {
                name: 'onboarding_done_modal',
                path: '/home/onboarding/complete',
                component: OnboardingDoneModal,
              },
              {
                name: 'home_accept_proposal',
                path: 'accept',
                component: AcceptProposalTwo,
              },
              {
                name: 'home_configure_sharing',
                path: 'configure-sharing',
                component: HomeSharingModal,
              },
            ],
          },
          { path: '/dashboard', redirect: { name: 'home' } },

          // -----------------
          //  populations
          // -----------------
          {
            name: 'populations',
            path: '/populations',
            component: Populations,
            children: [
              {
                name: 'new_population',
                path: '/populations/new',
                component: CreatePopulationModal,
                beforeEnter: requireScope('write:populations'),
              },
              {
                name: 'populations.sharing',
                path: '/populations/sharing/:population_id(\\d+)',
                component: PopulationsSharing,
              },
            ],
          },
          {
            name: 'create_population',
            path: '/populations/create',
            component: CreatePopulation,
            beforeEnter(to, from, next) {
              if (to.query?.offlinePartnerId)
                return requireScope('write:offline-partners')(to, from, next);
              return requireScope('write:populations')(to, from, next);
            },
            children: [
              {
                name: 'edit-new-snowflake-population-data',
                path: 'integration_settings',
                beforeEnter: requireScope('write:data-sources'),
                component: SnowflakeSettingsModal,
                props: (route) => ({ feedId: parseInt(route.query.id) }),
              },
              {
                name: 'edit-new-population-data',
                path: ':id/setup',
                component: DataTemplates,
                props: (route) => ({
                  feedId: parseInt(route.params.id),
                  customizing: Boolean(route.query.customizing),
                  closeRoute: route.query.closeRoute,
                }),
                beforeEnter: requireScope('write:data-sources'),
              },
            ],
          },
          {
            name: 'edit_population',
            path: '/populations/:population_id(\\d+)/edit',
            component: EditPopulation,
            beforeEnter(to, from, next) {
              if (to.query?.offlinePartnerId)
                return requireScope('write:offline-partners')(to, from, next);
              return requireScope('write:populations')(to, from, next);
            },
            children: [
              {
                name: 'edit_population__sharing',
                path: 'sharing',
                component: EditPopulationSharing,
              },
              {
                name: 'edit-snowflake-population-data',
                path: 'integration_settings',
                beforeEnter: requireScope('write:data-sources'),
                component: SnowflakeSettingsModal,
                props: (route) => ({ feedId: parseInt(route.query.id) }),
              },
              {
                name: 'edit-population-data',
                path: ':id/setup',
                component: DataTemplates,
                props: (route) => ({
                  feedId: parseInt(route.params.id),
                  customizing: Boolean(route.query.customizing),
                  closeRoute: route.query.closeRoute,
                }),
                beforeEnter: requireScope('write:data-sources'),
              },
              {
                name: 'delete-population-modal',
                path: 'delete',
                component: DeletePopulationModal,
              },
              {
                name: 'offline-delete-population-modal',
                path: 'delete',
                component: DeletePopulationModal,
              },
            ],
          },

          // -----------------
          //  shared-list
          // -----------------

          {
            name: 'collaborate',
            path: '/collaborate',
            component: SharedList,
            beforeEnter(to, from, next) {
              requireScope('write:sharing')(to, from, next);
            },
          },
          {
            name: 'collaborate_list',
            path: '/collaborate/:id',
            component: IndividualListPage,
            beforeEnter(to, from, next) {
              requireScope('write:sharing')(to, from, next);
            },
            props: (route) => ({ listId: route.params.id }),
          },
          // -----------------
          //  threads
          // -----------------
          {
            name: 'threads',
            path: '/threads',
            beforeEnter(_to, _from, next) {
              next({ name: 'sunset-threads' });
            },
          },
          {
            name: 'thread',
            path: '/threads/:thread_id',
            beforeEnter(_to, _from, next) {
              next({ name: 'sunset-threads' });
            },
          },
          {
            name: 'sunset-threads',
            path: '/sunset-threads',
            component: SunsetThreads,
          },
          // -----------------
          //  individual record page
          // -----------------
          {
            name: 'individual_record_universal',
            path: '/records/:context',
            component: IndividualRecordUniversal,
            props: ({ params }) => ({ context: params.context }),
          },
          {
            name: 'individual_record',
            path: '/records/:source_id/:source_primary_key',
            component: IndividualRecordPage,
            props: ({ params }) => ({
              sourceId: Number(params.source_id),
              sourcePrimaryKey: params.source_primary_key,
            }),
            children: [
              {
                name: 'individual_record_threads_list',
                path: 'threads',
                beforeEnter(_to, _from, next) {
                  next({ name: 'sunset-threads' });
                },
              },
              {
                name: 'create_individual_record_thread',
                path: 'threads/create',
                beforeEnter(_to, _from, next) {
                  next({ name: 'sunset-threads' });
                },
              },
              {
                name: 'activity_timeline',
                path: 'timeline',
                component: IRPActivityTimeline,
              },
            ],
          },

          // -----------------
          //  partners
          // -----------------

          {
            name: 'partners',
            path: '/partners',
            component: PartnerPageTwo,
            children: [
              {
                name: 'edit_partner_tags',
                path: ':id/tags',
                beforeEnter: requireScope('write:partnerships'),
                component: PartnersTableEditModal,
              },
              {
                name: 'add_partners_to_tag',
                path: 'tags/:tag_id',
                beforeEnter: requireScope('write:partnerships'),
                component: PartnersTableEditModal,
              },
              {
                name: 'create_proposal',
                path: 'invite',
                component: hasOfflinePartners
                  ? ProposalModalTwo
                  : ProposalModal,
                beforeEnter(to, _from, next) {
                  if (
                    store.hasScope('write:partnerships') ||
                    store.hasScope('write:offline-partners')
                  )
                    next();
                  else denyAccess(to);
                },
              },
              {
                name: 'accept_public_invite',
                path: 'public-invite',
                component: AcceptPublicInvite,
                beforeEnter(to, _from, next) {
                  if (!requireScope('write:partnerships')) denyAccess(to);
                  const lsPublicInviteCode = ls.publicInvite.get()?.code;
                  if (
                    lsPublicInviteCode === store.currentOrg.public_invite_code
                  ) {
                    next({ name: 'home' });
                  } else {
                    next();
                  }
                },
              },
              {
                name: 'accept_proposal',
                path: 'accept',
                component: AcceptProposalTwo,
              },
              {
                name: 'partners_proposals_id',
                path: 'proposals/:proposal_id(\\d+)',
                redirect(to) {
                  return {
                    name: 'accept_proposal',
                    query: { proposal_id: to.params.proposal_id },
                  };
                },
              },
            ],
          },
          {
            name: 'partner_details',
            path: '/partners/:partner_org_id(\\d+)',
            component: PartnerDetails,
            async beforeEnter(to, from, next) {
              const partnerStore = usePartnersStore();
              await partnerStore.initPartnersStore();
              const partner = partnerStore.getPartnerOrgById(
                to.params?.partner_org_id,
              );
              if (!partner) {
                next({ name: 'not_found' });
                return;
              }
              if (to.query?.offlinePartnerId)
                return requireScope('write:offline-partners')(to, from, next);
              return tabRequireScope('threads', 'read:threads')(to, from, next);
            },
            children: [
              {
                name: 'partner_details_edit_tags',
                path: 'edit-tags',
                component: PartnersTableEditModal,
              },
              {
                name: 'offline_partner_file_upload',
                path: 'add-csv',
                component: FileUploads,
              },
              {
                name: 'delete_partner',
                path: 'delete-partnership',
                component: DeletePartnershipModal,
              },
              {
                name: 'offline-file-upload-add-data',
                path: 'file-uploads/add-data/:id',
                component: FileUploads,
                props: (route) => ({ sourceId: parseInt(route.params.id) }),
              },
              {
                name: 'offline-delete-source',
                path: 'source/:source_id/delete',
                component: RemoveSourceModal,
                props: (route) => ({
                  sourceId: parseInt(route.params.source_id),
                }),
              },
              {
                name: 'offline-details-delete-population-modal',
                path: ':population_id/delete',
                component: DeletePopulationModal,
              },
            ],
          },
          {
            name: 'sharing_dashboard',
            path: 'partners/sharing-dashboard',
            component: SharingDashboard,
          },

          // -----------------
          //  data shares
          // -----------------

          {
            name: 'data_shares',
            path: '/data-shares',
            redirect: { name: 'sharing' },
          },

          // -----------------
          //    org settings / team
          // -----------------
          {
            name: 'org_settings',
            path: '/organization/settings',
            component: OrganizationSettings,
          },
          {
            name: 'roles',
            path: '/roles',
            component: Roles,
            children: [
              {
                name: 'create_role',
                path: 'create',
                component: CreateRole,
                beforeEnter: requireScope('write:roles'),
              },
              {
                name: 'role_detail',
                path: ':role_id',
                component: EditRole,
                async beforeEnter(to, from, next) {
                  const { readySync, getRoleById } = useRolesStore();
                  await readySync;
                  const role = getRoleById(to.params.role_id);
                  if (!role.is_editable) {
                    denyAccess(to);
                  }
                  requireScope('write:roles')(to, from, next);
                },
              },
              {
                name: 'delete_role',
                path: ':role_id/delete',
                component: DeleteRoleModal,
                beforeEnter: requireScope('write:roles'),
              },
            ],
          },
          {
            name: 'team',
            path: '/team',
            component: TeamPage,
            children: [
              {
                name: 'invite_user',
                path: 'invite/:invite_request_uuid?',
                component: InviteTeamMember,
                props: (route) => ({
                  inviteRequestId: route.params.invite_request_uuid,
                }),
                beforeEnter(to, _from, next) {
                  if (!store.hasScope('write:members')) {
                    denyAccess(to);
                  }
                  next();
                },
              },
              {
                name: 'remove_user',
                path: ':user_id/remove-user',
                component: RemoveUserModal,
                beforeEnter: requireScope('write:members'),
                props: (route) => ({ userId: parseInt(route.params.user_id) }),
              },
              {
                name: 'remove_users',
                path: 'remove-users',
                component: RemoveUsersModal,
                beforeEnter: requireScope('write:members'),
              },
              {
                name: 'edit_user',
                path: ':user_id/edit-user',
                component: EditUserModal,
                beforeEnter: requireScope('write:members'),
                props: (route) => ({ userId: parseInt(route.params.user_id) }),
              },
              {
                name: 'edit_users',
                path: 'edit-users',
                component: EditUsersModal,
                beforeEnter: requireScope('write:members'),
              },
              {
                name: 'revoke_invite',
                path: ':user_id/revoke-invite/:email',
                component: RevokeInviteModal,
                beforeEnter: requireScope('write:members'),
                props: (route) => ({
                  userId: parseInt(route.params.user_id),
                  email: route.params.email,
                }),
              },
            ],
          },
          {
            name: 'seat_requests',
            path: 'seat-requests',
            component: SeatRequests,
            children: [
              {
                name: 'approve_seat_request',
                path: 'approve-seat-request',
                component: InviteTeamMember,
                beforeEnter(to, _from, next) {
                  if (!store.hasScope('write:members')) {
                    denyAccess(to);
                  }
                  next();
                },
              },
            ],
          },
          {
            name: 'billing',
            path: '/billing',
            component: BillingPage,
            beforeEnter: requireScope('write:billing'),
            children: [
              {
                name: 'edit-payment',
                path: 'edit-payment',
                component: EditPaymentModal,
                beforeEnter(_, __, next) {
                  const isConnectorTier = billingStore.isConnectorTier;
                  if (!isConnectorTier) next({ name: 'not_found' });
                  else next();
                },
                props: () => ({ successDestination: 'billing' }),
              },
              {
                name: 'downgrade-complete',
                path: 'downgrade-complete',
                component: debookingEnabled ? DowngradeCompleteModal : NotFound,
                beforeEnter: inRenewalPeriodAndCancelled,
              },
            ],
          },
          {
            name: 'self-serve',
            path: 'self-serve',
            beforeEnter: requireScope('write:billing'),
            component: SelfServe,
            children: [
              {
                name: 'self-serve-connector-seats',
                path: 'connector-seats',
                component: ConnectorSeats,
              },
              {
                name: 'self-serve-purchase-connector',
                path: 'purchase-connector',
                component: ConnectorUpgrade,
              },
              {
                name: 'welcome-to-the-team',
                path: 'purchase-successful',
                component: PurchaseSuccessful,
              },
              {
                name: 'downgrade',
                path: 'downgrade',
                component: debookingEnabled ? Downgrade : NotFound,
                beforeEnter: inRenewalPeriod,
              },
              {
                name: 'restore-plan',
                path: 'restore-plan',
                component: debookingEnabled ? RestorePlan : NotFound,
                beforeEnter: inRenewalPeriodAndCancelled,
              },
              {
                name: 'remove-seats',
                path: 'remove-seats',
                component: debookingEnabled ? RemoveSeats : NotFound,
                beforeEnter: inRenewalPeriod,
              },
            ],
          },
          {
            name: 'integrations',
            path: '/integrations',
            component: IntegrationsPage,
            children: [
              {
                name: 'create_oauth_application',
                path: 'create',
                component: OAuthApplicationsCreate,
              },
              {
                name: 'oauth-applications',
                path: 'oauth-applications/:id',
                component: OAuthSettingsDrawer,
              },
              // -----------------
              //  SF Modal
              // -----------------
              {
                name: 'salesforce_push_settings',
                path: 'sf-push/manage',
                component: SalesforceCustomObjectSettingsDrawer,
              },
              // -----------------
              // Copilot Settings Modal
              // -----------------
              {
                name: 'crossbeam_copilot_settings',
                path: 'crossbeam-copilot/:integration/manage',
                component: CrossbeamCopilotSettingsDrawer,
              },
              // -----------------
              //  FRIGG Integrations
              // -----------------
              {
                name: 'frigg-integration',
                path: ':integration_type/manage',
                component: FriggSettingsDrawer,
              },
              {
                name: 'frigg-integration-authorize',
                path: ':integration_type/authorize',
                component: FriggAuthorizationSettingsModal,
              },
            ],
          },
          {
            name: 'data-sources',
            path: '/data-sources',
            component: DataSources,
            children: [
              {
                name: 'data-sources-connect',
                path: ':type/connect',
                component: ConnectDataSource,
                beforeEnter: requireScope('write:data-sources'),
              },
              {
                /* Returning from successful oauth to data source */
                name: 'data-sources-id',
                path: 'feed/:id',
                component: OauthReturn,
                props: (route) => ({ feedId: parseInt(route.params.id) }),
              },
              {
                name: 'data-sources-settings',
                path: 'feed/:id/settings',
                component: DataSourceSettingsDeux,
                beforeEnter: async (to, _, next) => {
                  if (store.hasScope('write:data-sources')) {
                    const feedsStore = useFeedsStore();
                    await feedsStore.refreshFeedsStore();
                    const feed = feedsStore.getFeedById(parseInt(to.params.id));
                    if (feed.integration.type === SNOWFLAKE_DATA_SOURCE_TYPE) {
                      next({ name: 'snowflake-settings', params: to.params });
                    } else {
                      next();
                    }
                  } else denyAccess(to);
                },
                props: (route) => ({ feedId: parseInt(route.params.id) }),
              },
              {
                /* NOTE: This is the legacy component until we migrate snowflake into data templates.
                 * Once that migration is complete we can fully delete SnowflakeSettingsModal, DataSourceSettingsSelector,
                 * and many other unused constants and functions that are exclusively used by them */
                name: 'snowflake-settings',
                path: 'feed/:id/settings/snowflake',
                component: SnowflakeSettingsModal,
                beforeEnter: requireScope('write:data-sources'),
                props: (route) => ({ feedId: parseInt(route.params.id) }),
              },
              {
                name: 'data-templates',
                path: 'feed/:id/setup',
                component: DataTemplates,
                props: (route) => ({
                  feedId: parseInt(route.params.id),
                  customizing: Boolean(route.query.customizing),
                }),
                beforeEnter: requireScope('write:data-sources'),
              },
              {
                name: 'delete-source',
                path: 'source/:source_id/delete',
                beforeEnter: requireScope('write:data-sources'),
                component: RemoveSourceModal,
                props: (route) => ({
                  sourceId: parseInt(route.params.source_id),
                }),
              },
              {
                name: 'data-sources-rename',
                path: 'feed/:id/rename',
                component: DataSourceRenameModal,
                beforeEnter: requireScope('write:data-sources'),
                props: (route) => ({
                  feedId: parseInt(route.params.id),
                  nickname: route.query.nickname,
                }),
              },
              {
                name: 'file-uploads',
                path: 'file-uploads',
                component: FileUploads,
              },
              {
                name: 'file-upload-add-data',
                path: 'file-uploads/add-data/:id',
                component: FileUploads,
                props: (route) => ({ sourceId: parseInt(route.params.id) }),
              },
              {
                name: 'map-ae-columns',
                path: 'file-uploads/map-columns/:id',
                component: MapAEColumns,
                props: (route) => ({ sourceId: parseInt(route.params.id) }),
              },
              {
                name: 'google-sheets',
                path: 'google-sheets',
                component: GoogleSheets,
              },
              {
                name: 'dynamics-pick-crm',
                path: 'dynamics-pick-crm',
                component: DynamicsPickCrm,
                props: (route) => ({
                  instances: JSON.parse(route.query.instances),
                }),
                beforeEnter(to, _, next) {
                  const instances = to.query.instances
                    ? JSON.parse(to.query.instances)
                    : null;
                  if (!instances || !instances.length === 0) {
                    /* Either the user refreshes the page before picking a source,
                        or they don't have any CRMs in their MD account */
                    flashesStore.addErrorFlash({
                      message:
                        'There was an issue connecting to your Microsoft Dynamics account',
                    });
                    next({ name: 'data-sources' });
                  } else {
                    next();
                  }
                },
              },
            ],
          },
          {
            name: 'overlaps',
            path: '/overlaps',
            component: Overlaps,
          },

          // -----------------
          //    Co-Sell
          // -----------------
          {
            name: 'co_sell',
            path: '/co-sell/:pathMatch(.*)*',
            component: CoSell,
          },

          // -----------------
          //    user
          // -----------------

          {
            name: 'user_settings',
            path: '/profile/preferences',
            component: UserSettings,
            children: [
              {
                component: UserInfo,
                path: '/profile/preferences/edit',
                name: 'user_info_modal',
              },
            ],
          },

          // -----------------
          // integrations oauth callback
          // -----------------

          {
            name: 'oauth-callback',
            path: '/oauth/callback',
            component: OAuthCallback,
          },
          {
            name: 'slack_callback',
            path: '/slack/callback',
            component: SlackCallback,
          },
          {
            name: 'partnerstack_callback',
            path: '/partnerstack/callback',
            component: PartnerstackCallback,
          },
          {
            name: 'frigg-callback',
            path: '/oauth/integration-callback/:integration_type',
            component: FriggCallback,
          },
          {
            name: 'bitts-playground',
            path: '/bitts-playground',
            component: import.meta.env.DEV ? BittsPlayground : NotFound,
          },
          {
            path: '/:pathMatch(.*)*',
            name: 'not_found',
            component: NotFound,
          },
        ],
      },
      {
        beforeEnter: (to, from, next) =>
          enforceAuthentication(to, from, next, false),
        name: 'early-adopter-sunsetting',
        path: '/sunsetting',
        component: Sunsetting,
      },
      {
        beforeEnter: (to, from, next) =>
          enforceAuthentication(to, from, next, false),
        name: 'upgrade-locked',
        path: '/upgrade',
        component: ConnectorUpgrade,
      },
      {
        name: 'account-locked',
        path: '/account_locked',
        component: AccountLocked,
      },
    ],
    async scrollBehavior(to, from, savedPosition = null) {
      /* Suspense components don't register in time if we use vanilla vue router
        "el" in the returned object. We need to wait for the element to appear */
      const getElementByHash = (hash, tries = 0) => {
        return (
          document.querySelector(hash) ||
          new Promise((resolve, reject) => {
            if (tries === 5)
              reject(new Error(`element with ${hash} could not be found`));
            setTimeout(() => {
              resolve(getElementByHash(hash, (tries += 1)));
            }, 100);
          })
        );
      };
      if (to.hash) {
        try {
          const el = await getElementByHash(to.hash);
          const position = {
            el,
            top: 80, // offset to accommodate universal search area
            behavior: 'smooth',
          };
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(position);
            }, 200);
          });
        } catch (err) {
          console.warn(err);
        }
      }

      // The third argument, savedPosition, is only available if
      // this is a popstate navigation(triggered by the browser's back/forward buttons).
      // Logic here is to go back to correct vertical spot when not using back/forward.
      // The "scroll" query param should be passed to keep track of window.scrollY
      const position = { left: 0, top: 0 };
      const { scroll } = to.query;
      const hasSameBaseRoute = to.matched[1] === from.matched[1];
      if (hasSameBaseRoute && Boolean(scroll)) position.top = window.scrollY;
      // don't hop to top when sorting
      if (to.path === from.path) return false;
      return savedPosition || position;
    },
  };
  const router = _createRouter(routerConfig);
  router.beforeEach((_to, _from, next) => {
    if (store.initializationFailed) {
      return;
    }
    next();
  });
  router.beforeEach((to, _from, next) => {
    if (store.maintenanceModeEvent && to.name !== 'maintenance_mode') {
      next({ name: 'maintenance_mode' });
    } else if (
      to.meta.featureFlag &&
      !featureFlagStore.hasFeatureFlag(to.meta.featureFlag)
    ) {
      next({ name: 'home' });
    } else {
      next();
    }
  });

  router.onError((error) => {
    if (
      error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('Importing a module script failed')
    ) {
      window.location.reload();
    }
  });

  // Want to track page views in our analytics tooling
  router.afterEach(() => {
    if (window.analytics) {
      if (store.state?.currentOrg?.id) {
        window.analytics.page({
          organization_id: store.currentOrg.id.toString(),
        });
      } else {
        window.analytics.page();
      }
    }
  });

  // Handle NavigationDuplicated errors
  // https://github.com/vuejs/vue-router/issues/2881
  const originalPush = router.push;
  router.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch((err) => {
      if (isNavigationFailure(err)) {
        // resolve err
        console.warn(err);
        return err;
      }
      // rethrow error
      return Promise.reject(err);
    });
  };

  const originalReplace = router.replace;
  router.replace = function replace(location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalReplace.call(this, location, onResolve, onReject);
    }
    return originalReplace.call(this, location).catch((err) => {
      if (isNavigationFailure(err)) {
        // resolve err
        console.warn(err);
        return err;
      }
      // rethrow error
      return Promise.reject(err);
    });
  };

  return router;
};

const fullWidthAuthenticatedRoutes = ['/self-serve'];

<template>
  <BittsModal
    :width="500"
    :title="uploadModalTitle"
    :no-padding="true"
    :show-buttons="false"
    class="c-google-sheets"
    :visible="showGoogleSheetsModal"
    @closed="cancel"
  >
    <template #content>
      <GoogleSheetsForm @saved="cancel" @cancel="cancel" />
    </template>
  </BittsModal>
</template>

<script>
import { BittsModal } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';

import GoogleSheetsForm from '@/components/data-sources/GoogleSheetsForm.vue';

export default {
  name: 'GoogleSheets',
  components: {
    BittsModal,
    GoogleSheetsForm,
  },

  setup() {
    useHead({
      title: 'Google Sheets - Crossbeam',
    });
  },

  data() {
    return {
      showGoogleSheetsModal: false,
      isConfirming: false,
    };
  },
  computed: {
    uploadModalTitle() {
      if (this.isConfirming) return 'Confirm your selections';
      return 'Add Google Sheet';
    },
  },
  mounted() {
    this.showGoogleSheetsModal = true;
  },
  methods: {
    async cancel() {
      this.showGoogleSheetsModal = false;
      await this.$router.push({ name: 'data-sources' });
    },
  },
};
</script>

<style lang="pcss" scoped>
.c-google-sheets__header {
  @apply pl-40 pr-32 pt-24;
}

.c-google-sheets__title {
  @apply flex items-center w-full justify-between;
}
</style>

<style lang="pcss">
.c-google-sheets {
  .c-bitts-modal__header {
    @apply py-24 px-24 md:px-36 m-0;
  }
  .ant-tabs-tab-wait .ant-steps-item-icon {
    @apply border-brand-blue;
    background-color: transparent;
  }
  .ant-tabs-tab-wait .ant-steps-item-icon > .ant-steps-icon {
    @apply text-brand-blue font-bold;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    @apply border-neutral-300;
    background-color: transparent;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    @apply text-neutral-400 font-bold;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    @apply text-neutral-500 text-base font-normal;
  }
  .ant-tabs-tab-active .ant-steps-item-icon {
    @apply border-brand-blue;
    background-color: transparent;
  }
  .ant-tabs-tab-active .ant-steps-item-icon > .ant-steps-icon {
    @apply text-brand-blue font-bold;
  }
  .bitts-tabs {
    border-width: 0px;
    width: 100%;
    .ant-tabs {
      overflow: visible;
    }
    .ant-tabs-nav {
      .ant-tabs-tab {
        @apply text-neutral-500;
        width: 50%;
        padding-top: 0px;
        padding-bottom: 11px;
        .ant-steps {
          @apply flex justify-center;
        }
      }
    }
    .ant-tabs-content {
      padding-top: 24px;
      padding-bottom: 0px;
      padding-left: 40px;
      padding-right: 40px;
      z-index: 20;
    }
    .ant-tabs-nav .ant-tabs-tab {
      .bitts-steps
        .ant-steps-item-wait
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title,
      .bitts-steps
        .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title {
        @apply text-neutral-500;
      }
    }
    .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
      .bitts-steps
        .ant-steps-item-process
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title,
      .bitts-steps
        .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title {
        @apply text-brand-blue;
      }
    }
  }
}
</style>

<template>
  <BittsPopover
    trigger="click"
    placement="bottomRight"
    :show-menu="isOverflowMenuOpen"
    class="population-overflow-menu"
    @menu-visible="toggleOverflowMenu"
  >
    <span
      :class="isOverflowMenuOpen ? 'population-overflow-menu__icon-open' : null"
      class="population-overflow-menu__icon-wrapper"
    >
      <FontAwesomeIcon
        :icon="['fas', 'ellipsis-h']"
        :style="{ height: '18px', width: '18px' }"
        class="population-overflow-menu__icon"
      />
    </span>
    <template #content>
      <div class="flex flex-col text-brand-navy text-sm">
        <div class="population-overflow-menu__item" @click="toggleOverflowMenu">
          <RouterLink :to="sharingLink">
            {{ canManagePopulations ? 'Change' : 'View' }}
            sharing default
          </RouterLink>
        </div>
        <div
          v-if="canManagePopulations && !atOrAboveCap"
          class="population-overflow-menu__item"
          @click="toggleAndEmitEvent('duplicate-population')"
        >
          Duplicate Population
        </div>
        <div
          v-if="
            canManagePopulations &&
            standardPopulations.length < 3 &&
            !isStandard
          "
          class="population-overflow-menu__item"
          @click="toggleAndEmitEvent('convert-to-standard')"
        >
          Convert to Standard
        </div>
        <div
          v-if="canManagePopulations && isStandard && !atOrAboveCap"
          class="population-overflow-menu__item"
          @click="toggleAndEmitEvent('convert-to-custom')"
        >
          Convert to Custom
        </div>
        <div
          v-if="canManagePopulations"
          class="population-overflow-menu__item"
          @click="toggleAndEmitEvent('delete-population')"
        >
          Delete Population
        </div>
        <button
          v-if="canManagePopulations && recordNumber"
          class="population-overflow-menu__item"
          @click="performExport(exportParams, exportUrl, recordNumber)"
        >
          Export
        </button>
      </div>
    </template>
  </BittsPopover>
</template>

<script>
import { BittsPopover } from '@crossbeam/bitts';

import useExport from '@/composables/useExport';
import urls from '@/urls';

export default {
  name: 'PopulationOverflowMenu',
  components: { BittsPopover },
  props: {
    atOrAboveCap: {
      type: Boolean,
      default: false,
    },
    canManagePopulations: {
      type: Boolean,
      default: true,
    },
    isStandard: {
      type: Boolean,
      default: false,
    },
    population: {
      type: Object,
      required: true,
    },
    recordNumber: {
      type: Number,
      required: false,
      default: null,
    },
    sharingLink: {
      type: Object,
      required: true,
    },
    standardPopulations: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup() {
    const { performExport } = useExport();
    return { performExport };
  },
  data() {
    return {
      isOverflowMenuOpen: false,
    };
  },
  computed: {
    exportParams() {
      return {
        our_population_ids: [this.population.id],
        overlap_only: false,
        export_name: this.population.name,
      };
    },
    exportUrl() {
      return urls.matches.export;
    },
  },
  methods: {
    toggleAndEmitEvent(event) {
      this.toggleOverflowMenu();
      this.$emit(event);
    },
    toggleOverflowMenu() {
      this.isOverflowMenuOpen = !this.isOverflowMenuOpen;
    },
  },
};
</script>

<style lang="pcss">
.population-overflow-menu__icon {
  @apply h-14 w-14 cursor-pointer text-neutral-600;
}

.population-overflow-menu__icon-open {
  @apply bg-neutral-100 rounded-bts-sm;
}

.population-overflow-menu__icon-wrapper {
  @apply ml-8 flex items-center justify-center;
  height: 24px;
  width: 24px;
}

.population-overflow-menu__item {
  @apply cursor-pointer px-16 py-4 text-left;
}

.population-overflow-menu__item:hover {
  @apply bg-primary-background-weak;
}
</style>

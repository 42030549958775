import { useContactsStore } from '@/salesintel/stores/ContactsStore';
import { useExtensionStore } from '@/salesintel/stores/ExtensionStore';
import { useSalesIntelStore } from '@/salesintel/stores/SalesIntelStore';

export default function useExtensionTracking() {
  const extensionStore = useExtensionStore();
  const salesIntelStore = useSalesIntelStore();
  const contactsStore = useContactsStore();
  return {
    getExtensionFields: () => {
      if (!salesIntelStore.isExtension) {
        return {};
      }
      return {
        'application/website': extensionStore.parentWebsite,
        company_name: contactsStore.account?.name,
        company_domain: contactsStore.account?.domain,
      };
    },
  };
}

<template>
  <div
    :class="{ [`c-empty-state--${backgroundColor}`]: !!backgroundColor }"
    class="c-empty-state"
  >
    <BittsSvg
      v-if="backgroundSvgName"
      :svg="backgroundSvgName"
      :class="{ 'c-empty-state__background--full-width': fullWidth }"
      class="c-empty-state__background"
    />
    <div
      :class="{ [titleClass]: !!titleClass }"
      class="c-empty-state__title c-empty-state__title--flex-column"
    >
      <BittsSvg v-if="svgName" :svg="svgName" />
      {{ title }}
      <slot name="title-info" />
    </div>
    <slot name="subtitle" />
  </div>
</template>

<script>
import { BittsSvg } from '@crossbeam/bitts';

export default {
  name: 'EmptyState',
  components: { BittsSvg },
  props: {
    svgName: {
      type: String,
      default: null,
    },
    backgroundSvgName: {
      type: String,
      default: null,
    },
    titleClass: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="c-partners-table-cell-wrapper">
    <div class="overlaps-table-partner-cell">
      <BittsAvatar
        :org="org"
        :is-own="false"
        :show-initials="true"
        size="medium"
        shape="square"
        class="mr-12"
      />
      <div>
        <p class="overlaps-table-partner-cell__name">
          {{ name }}
        </p>
        <p class="overlaps-table-partner-cell__domain">
          {{ org.domain }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BittsAvatar } from '@crossbeam/bitts';

export default {
  name: 'OverlapsTablePartnerCell',
  components: {
    BittsAvatar,
  },
  data() {
    return {
      name: null,
      org: null,
    };
  },
  beforeMount() {
    this.org = this.params.data.org;
    this.name = this.params.data.name;
  },
};
</script>

<style lang="pcss" scoped>
.overlaps-table-partner-cell {
  @apply flex items-center my-12;
}
.overlaps-table-partner-cell__domain {
  @apply text-base text-neutral-text;
}
.overlaps-table-partner-cell__name {
  @apply text-m text-neutral-text-strong text-left;
}
</style>

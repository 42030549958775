<template>
  <BittsLayout :is-page="true">
    <template #content>
      <div class="data-sources">
        <div class="flex justify-between items-center">
          <PageTitle
            title="Data Sources"
            subtitle="Connect the most data sources to enable your team to get the best insights out of data your partners already share with you"
          />
          <PermissionIndicator resource="data-sources" />
        </div>
        <OnboardingCallout
          v-if="hasNewOnboarding"
          class="mb-40"
          kind="data_sources"
        />
        <OnboardingEmptyState
          v-if="hasNewOnboarding && !onboardingClosed"
          type="data sources"
        />
        <div v-else>
          <div class="flex justify-between mb-16">
            <h3 class="text-lg font-bold text-neutral-text-strong">
              Data Sources
            </h3>
          </div>
          <FeedList />
          <DataSourceBoard
            v-if="hasPermission('manage:data-sources')"
            @input="datasourceChosen"
          />
        </div>
      </div>
      <router-view-wrapper />
    </template>
  </BittsLayout>
</template>

<script setup>
import { BittsLayout } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { useRouter } from 'vue-router';

import DataSourceBoard from '@/components/data-sources/DataSourceBoard.vue';
import FeedList from '@/components/data-sources/FeedList.vue';
import OnboardingEmptyState from '@/components/data-sources/OnboardingEmptyState.vue';
import OnboardingCallout from '@/components/onboarding/OnboardingCallout.vue';
import PageTitle from '@/components/PageTitle.vue';
import PermissionIndicator from '@/components/PermissionIndicator.vue';

import useAuth from '@/composables/useAuth';
import useIteratively from '@/composables/useIteratively';
import useLegacyOnboarding from '@/composables/useLegacyOnboarding';
import { EVENT_SITES } from '@/constants/analytics';
import { FILE_UPLOAD_DATA_SOURCE_TYPE } from '@/constants/data_sources';

useHead({ title: 'Data Sources - Crossbeam' });
const { hasNewOnboarding, onboardingClosed } = useLegacyOnboarding();
const { hasPermission } = useAuth();

const { iteratively } = useIteratively();
const router = useRouter();

async function datasourceChosen(datasource) {
  iteratively.userClickedConnectDataSource({
    data_source_type: datasource.friendly_name,
    event_site: EVENT_SITES.DATA_SOURCE_PAGE_TILE,
  });

  if (datasource.type === FILE_UPLOAD_DATA_SOURCE_TYPE) {
    await router.push({ name: 'file-uploads' });
    return;
  }

  await router.push({
    name: 'data-sources-connect',
    params: { type: datasource.type },
  });
}
</script>

<template>
  <div class="configure-user-settings">
    <div class="info">
      <div class="info__top">
        <div class="background" />
        <BittsAvatar
          :user="currentUser"
          :is-own="true"
          :show-initials="true"
          size="large"
          class="avatar"
        />
      </div>
      <div class="info__bottom">
        <div v-if="userName" class="info_bottom-name">
          {{ userName }}
          <div v-if="userPronouns" class="pronouns">
            {{ `(${userPronouns})` }}
          </div>
        </div>
        <div class="user-info mb-16">
          {{ currentUser.email }}
        </div>
        <div v-if="userJobTitle || userDepartment" class="user-info">
          <FontAwesomeIcon
            :icon="['far', 'id-card']"
            :style="{ height: '16px', width: '14px' }"
          />
          <span>{{ userJobTitle }} </span>
          <span v-if="userDepartment && userJobTitle">•</span>
          <span v-if="userDepartment">{{ userDepartment }}</span>
        </div>
        <BittsButton
          size="small"
          type="neutral"
          variant="outline"
          text="Edit Profile Information"
          class="mt-16 mb-24 self-start"
          @click="toggleUserInfoModal"
        />
      </div>
    </div>
    <BittsCard v-if="!isSsoUser" class="p-24 shadow-component flex-1">
      <div class="card-content">
        <div class="card-section">
          <div class="card-title"> Reset your password </div>
          <div class="card-text">
            If you need a new password, we can send you an email to reset it
          </div>
          <BittsButton
            size="small"
            type="neutral"
            variant="outline"
            text="Reset Password"
            class="self-start"
            @click.prevent="changePassword"
          />
        </div>
        <div class="card-section mt-16">
          <div class="card-title"> Resources </div>
          <div class="card-text">
            Learn more about profile settings, preferences, and notifications
          </div>
          <BittsLink
            text="Profile & Preferences"
            url="https://help.crossbeam.com/en/articles/3160248-profile-preferences"
          />
          <BittsLink
            text="Activity Notifications"
            url="https://help.crossbeam.com/en/articles/3426387-activity-notifications"
            class="mt-4"
          />
        </div>
      </div>
    </BittsCard>
  </div>
</template>

<script setup>
import {
  BittsAvatar,
  BittsButton,
  BittsCard,
  BittsLink,
} from '@crossbeam/bitts';

import axios from 'axios';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import { DEPARTMENTS } from '@/constants/me';
import { useFlashesStore } from '@/stores';
import urls from '@/urls';

const { currentAuth, currentUser } = useAuth();
const userPronouns = computed(() => currentUser.value.pronouns);
const isSsoUser = computed(() => currentAuth.value.sso);

const userName = computed(() => {
  if (currentUser.value.first_name && currentUser.value.last_name) {
    return `${currentUser.value.first_name} ${currentUser.value.last_name}`;
  }
  if (currentUser.value.first_name) {
    return currentUser.value.first_name;
  }
  if (currentUser.value.last_name) {
    return;
  }
  return null;
});

const userJobTitle = computed(() => currentUser.value.job_title);

const userDepartment = computed(() => {
  const matchingDept = DEPARTMENTS.find(
    (d) => d.value === currentUser.value.department,
  );
  return matchingDept?.value === 'other' ? null : matchingDept?.label;
});

const router = useRouter();
async function toggleUserInfoModal() {
  router.push({ name: 'user_info_modal' });
}
const flashesStore = useFlashesStore();
async function changePassword() {
  try {
    await axios.post(urls.forgotPassword.post, {
      email: currentUser.value.email,
    });
  } catch (err) {
    flashesStore.addUnhandledError(err);
    return;
  }
  flashesStore.addSuccessFlash({
    message: 'Request Received',
    description: 'Please check your email and reset your password from there.',
  });
}
</script>

<style scoped lang="pcss">
.configure-user-settings {
  @apply flex-col md:flex-row flex gap-24 mb-44;

  .info {
    @apply rounded-bts-lg border border-solid border-neutral-border shadow-component flex-1;
    .info__top {
      @apply relative z-10 h-[80px];

      .avatar {
        @apply relative flex justify-start ml-24;
        bottom: 40px;
      }

      .background {
        @apply h-full rounded-t-bts-lg bg-info-background-weak;
      }
    }

    .info__bottom {
      @apply pt-60 px-24 flex flex-col;

      .info_bottom-name {
        @apply text-lg text-neutral-text-strong font-bold flex items-center mb-4;

        .pronouns {
          @apply text-neutral-text-weak font-normal text-m pl-4;
        }
      }

      .user-info {
        @apply text-m text-neutral-text-weak whitespace-nowrap overflow-hidden text-ellipsis max-w-full;

        svg {
          @apply mr-8 text-neutral-accent;
        }
        span {
          @apply mr-4;
        }
      }
    }
  }

  .card-content {
    @apply flex flex-col justify-between items-start h-full;
    .card-section {
      @apply flex flex-col justify-start w-auto;

      .card-text {
        @apply text-neutral-text mb-16;
      }
      .card-title {
        @apply text-lg text-neutral-text-strong font-bold mb-4;
      }
    }
  }
}
</style>

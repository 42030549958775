<template>
  <div @click.stop>
    <BittsModal
      :visible="visible"
      :saving="saving"
      :disabled="isNameEmptyOrInvalid"
      title="Convert to Custom Population"
      :show-divider="true"
      @closed="onClose"
      @saved="onConvert"
    >
      <template #subtitle>
        This population needs a new custom name. This name change will be
        reflected in all reports.
      </template>
      <template #content>
        <BittsInput
          v-model="newPopulationName"
          placeholder="Rename Population"
          form-label="New Population Name"
          name="rename-population-input"
          class="mb-8"
          :status="isNameInvalid ? 'danger' : 'default'"
          :danger-text="errorText"
          @click.stop
        />
      </template>
    </BittsModal>
  </div>
</template>

<script>
import { BittsInput, BittsModal } from '@crossbeam/bitts';

import axios from 'axios';
import { mapActions, mapState } from 'pinia';

import { STANDARD_POPULATION_NAMES } from '@/constants/standard_populations';
import { useFlashesStore, usePopulationsStore } from '@/stores';
import urls from '@/urls';

export default {
  name: 'ConvertToCustomModal',
  components: {
    BittsInput,
    BittsModal,
  },
  props: {
    hasPopulationRefresh: {
      type: Boolean,
      default: false,
    },
    population: {
      type: Object,
      required: false,
      default: () => {},
    },
    standardPopulation: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closed', 'population-converted'],
  data() {
    return {
      saving: false,
      newPopulationName: '',
    };
  },
  computed: {
    ...mapState(usePopulationsStore, ['getPopulationByName']),
    errorText() {
      if (this.isNameStandard) {
        return 'Population name cannot be the same as any standard Population name.';
      }
      return 'Population names must be unique.';
    },
    isNameAlreadyInUse() {
      return !!this.getPopulationByName(this.newPopulationName);
    },
    isNameEmptyOrInvalid() {
      return !this.newPopulationName || this.isNameInvalid;
    },
    isNameInvalid() {
      return !this.saving && (this.isNameAlreadyInUse || this.isNameStandard);
    },
    isNameStandard() {
      return STANDARD_POPULATION_NAMES.includes(this.newPopulationName);
    },
  },
  methods: {
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    onClose() {
      this.$emit('closed');
      this.newPopulationName = '';
    },
    async onConvert() {
      try {
        this.saving = true;
        const url = urls.populations.patch_v3(this.standardPopulation.id);
        const payload = {
          name: this.newPopulationName,
          standard_type: null,
        };
        if (this.hasPopulationRefresh) {
          payload.description = this.population.description || null;
        }
        await axios.patch(url, payload);
        this.$emit('population-converted');
        this.addSuccessFlash({ message: 'Population Converted' });
      } catch (_error) {
        this.addErrorFlash({
          message: 'Population Not Saved',
          description:
            'Please try again or contact support@crossbeam.com for help.',
        });
      }
    },
  },
};
</script>

<template>
  <div class="c-opportunities-card">
    <div class="c-opportunities-card__header">
      <CardHeader :source="source" title="Opportunities" />
    </div>
    <div class="c-opportunities-card__content">
      <TransitionGroup name="fade">
        <ExpandableView
          v-for="(item, index) in source.items"
          :key="`opportunity_${index}`"
          class="c-opportunities-card__item"
        >
          <template #header>
            <div
              :key="`opportunity_${index}_header`"
              class="c-opportunities-card__item__header"
            >
              <div class="c-opportunities-card__item__header__label">
                <span class="c-opportunities-card__item__header__label__title">
                  Amount
                </span>
                <span
                  class="c-opportunities-card__item__header__label__subtitle"
                >
                  {{ getField(item, 'Amount') }}
                </span>
              </div>
              <CardOrgIcons
                :orgs="orgs(item)"
                key-segment="opportunities_details"
              />
            </div>
          </template>
          <template #collapsed="{ toggle }">
            <div
              :key="`opportunity_${index}_collapsed`"
              class="c-opportunities-card__item__details"
            >
              <CardRecords
                :item="item"
                :show-from-icons="false"
                :key-segment="`opportunity_${index}`"
                :is-our-data-selected="isOurDataSelected"
              />
              <CardFooter :toggle="toggle" :item="item" mode="collapsed" />
            </div>
          </template>
          <template #expanded="{ toggle }">
            <div
              :key="`opportunity_${index}_details`"
              class="c-opportunities-card__item__details"
            >
              <CardRecords
                :item="item"
                :show-from-icons="true"
                :key-segment="`opportunity_${index}`"
                :is-our-data-selected="isOurDataSelected"
                mode="details"
              />
              <CardFooter :toggle="toggle" :item="item" mode="expanded" />
            </div>
          </template>
        </ExpandableView>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';

import CardFooter from '@/components/records/Card/CardFooter.vue';
import CardHeader from '@/components/records/Card/CardHeader.vue';
import CardOrgIcons from '@/components/records/Card/CardOrgIcons.vue';
import CardRecords from '@/components/records/Card/CardRecords.vue';
import ExpandableView from '@/components/records/ExpandableView.vue';

import useAuth from '@/composables/useAuth';
import { useDataSharesStore, usePartnersStore } from '@/stores';

export default {
  name: 'OpportunitiesCard',
  components: {
    ExpandableView,
    CardRecords,
    CardHeader,
    CardFooter,
    CardOrgIcons,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
    isOurDataSelected: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { currentOrg } = useAuth();

    return { currentOrg };
  },
  computed: {
    ...mapState(usePartnersStore, ['getPartnerOrgById']),
  },
  methods: {
    ...mapActions(useDataSharesStore, ['getDataShareById']),
    getField(item, columnName) {
      const field = item.fields.find(
        (field) => field.name === columnName || field.column === columnName,
      );
      return field ? field.value : null;
    },
    orgs(item) {
      const orgs = new Set(
        item.data_share_ids.map((dataShareId) =>
          this.getPartnerOrgById(
            this.getDataShareById(dataShareId).partner_org_id,
          ),
        ),
      );
      if (item.is_ours) {
        orgs.add(this.currentOrg);
      }
      return [...orgs];
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-opportunities-card {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.c-opportunities-card__content {
  margin: 12px 0px;
  background: var(--white);
  border: 1px solid var(--grey6);
  border-radius: 4px;
}

.c-opportunities-card__item {
  border-bottom: 1px solid var(--grey6);
  &:last-of-type {
    border-bottom: 0px;
  }
}

.c-opportunities-card__item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 12px 12px 0px;
}

.c-opportunities-card__item__header__label {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.c-opportunities-card__item__header__avatar {
  height: 35px;
  width: 35px;
}

.c-opportunities-card__item__header__label__name-and-orgs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.c-opportunities-card__item__header__label__title {
  font-size: 12px;
  color: var(--grey1);
}

.c-opportunities-card__item__header__label__subtitle {
  @apply text-brand-navy;
  font-size: 18px;
}

.c-opportunities-card__item__details {
  padding: 0px 12px 12px;
}
</style>

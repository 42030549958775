<template>
  <Loading :is-loading="loading">
    <div :class="loading ? 'pt-350' : null" class="population-table">
      <div class="population-table__header">
        <BittsTooltip placement="bottom">
          <h2 class="population-table__title"> Record Preview </h2>
          <template #title>
            View the first 100 records to ensure accuracy
          </template>
        </BittsTooltip>
        <div v-if="hasUserRunPopulation" class="text-neutral-text">
          {{ recordCount }}
        </div>
      </div>
      <BittsTable
        v-if="columns.length && rows.length"
        :compress-columns="true"
        :pagination-page-size="50"
        :columns="columnDefinitions"
        :rows="rows"
        :bordered="true"
        :pagination="true"
        :shrink-title="true"
        :class="columnsReady ? 'fade-in' : 'invisible'"
        :show-pagination-results-text="true"
        results-text="preview records"
        :suppress-row-click-selection="!!offlinePartnerUuid"
        @columns-compressed="onColumnsCompressed"
      />
      <BittsEmptyState
        v-else-if="!isUserEditing && !hasUserRunPopulation"
        title="Fill out the fields to the left and hit “Continue”."
        svg-name="emptyStatePopulations"
        class="h-full bg-white"
      >
        <template #subtitle>
          <p class="population-table__empty-state">
            Once we get some basic information you'll be able to filter this
            data down
            <BittsLink
              text="Learn about Populations"
              url="https://help.crossbeam.com/en/articles/3160813-what-are-populations"
            />
          </p>
        </template>
      </BittsEmptyState>
      <BittsEmptyState
        v-else
        title="No records found for this data source"
        svg-name="emptyStatePopulations"
        class="h-full bg-white"
      >
        <template #subtitle>
          <div class="population-table__empty-state">
            If this is a new data source, it may take a few minutes to become
            available. If it’s not, try adjusting your filters. Need just a
            little more help?
            <div>
              View our
              <a
                target="_blank"
                class="text-brand-blue cursor-pointer"
                href="https://help.crossbeam.com/en/articles/3160813-what-are-populations"
              >
                help documentation</a
              >.
            </div>
          </div>
        </template>
      </BittsEmptyState>
    </div>
  </Loading>
</template>

<script setup>
import {
  BittsEmptyState,
  BittsLink,
  BittsTable,
  BittsTooltip,
} from '@crossbeam/bitts';

import { pluralize } from 'humanize-plus';
import { omit } from 'lodash';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import Loading from '@/components/Loading.vue';

import useTableFadeIn from '@/composables/useTableFadeIn';
import { ACCOUNT_OWNER_EMAIL_DISPLAY, MDM_PROPERTIES } from '@/constants/mdm';
import { formatNumberWithCommas } from '@/utils';

import PopulationTableCell from './PopulationTableCell.vue';
import PopulationTableRecordCell from './PopulationTableRecordCell.vue';

const { columnsReady, onColumnsCompressed } = useTableFadeIn();

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  populationPreviewCount: {
    type: Number,
    default: 0,
  },
  data: {
    type: Object,
    default: () => ({}),
  },
  hasUserRunPopulation: {
    type: Boolean,
    default: false,
  },
  offlinePartnerUuid: {
    type: String,
    default: '',
  },
});

const route = useRoute();
const isUserEditing = computed(() => route.name.includes('edit_population'));
const recordCount = computed(
  () =>
    `${formatNumberWithCommas(props.populationPreviewCount)} ${pluralize(props.populationPreviewCount, 'result')}`,
);

const cellRenderer = computed(() =>
  props.offlinePartnerUuid ? PopulationTableCell : PopulationTableRecordCell,
);
const columns = computed(() => {
  if (!props.data.fields) return [];
  const fields = props.data.fields.map((item) => {
    const isFirstColumn =
      item.mdm_property === MDM_PROPERTIES.account.NAME ||
      (item.mdm_property === 'person_email' &&
        item.display_name !== ACCOUNT_OWNER_EMAIL_DISPLAY);
    const obj = {
      data: item,
      resizable: true,
      field: item.display_name,
      cellRenderer: PopulationTableCell,
      suppressMovable: true,
      minWidth: 250,
    };
    if (isFirstColumn) {
      obj.pinned = 'left';
      /* Offline partners populations should not link to the IRP */
      obj.cellRenderer = cellRenderer.value;
      obj.sortable = true;
      obj.initialSort = 'asc';
      obj.comparator = (cellA, cellB) => {
        if (cellA.val === cellB.val) return 0;
        return cellA.val > cellB.val ? 1 : -1;
      };
    }
    return obj;
  });
  return fields;
});

const columnDefinitions = computed(() =>
  columns.value.map((col) => omit(col, ['data'])),
);

const rows = computed(() => {
  if (!props.data.rows) return [];
  return props.data.rows.map((item) =>
    item.values.reduce((agg, val, i) => {
      const column = columns.value[i];
      const columnName = column.field;
      agg[columnName] = {
        val,
        recordId: item.record_id,
        sourceId: column.data.source_id,
        dataType: column.data.pg_data_type,
      };
      return agg;
    }, {}),
  );
});
</script>

<style lang="pcss" scoped>
.population-table {
  @apply flex-1 p-24 overflow-y-auto flex flex-col mb-24;
}
.population-table__header {
  @apply flex items-center justify-between mb-14;

  .population-table__title {
    @apply text-lg font-bold underline decoration-dashed underline-offset-8 decoration-neutral-text-placeholder decoration-1;
  }
}
.population-table__empty-state {
  @apply text-neutral-text text-center;
  width: 500px;
}
</style>

<template>
  <div v-html="simpleMarkup" />
</template>

<script>
import DOMPurify from 'dompurify';
import { marked } from 'marked';

marked.setOptions({
  gfm: true,
  breaks: true,
});

export default {
  name: 'TimelineActivitySimpleThreadConvertMarkdown',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    const content = this.convertToHtml(this.content);
    return {
      simpleMarkup: content,
    };
  },
  watch: {
    content(newVal) {
      const markdownToHtml = this.convertToHtml(newVal);
      this.simpleMarkup = markdownToHtml;
    },
  },
  methods: {
    convertToHtml(markdown) {
      const purified = DOMPurify.sanitize(markdown.replace(/\n\n/g, '<br>'));
      return marked(purified);
    },
  },
};
</script>

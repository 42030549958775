<template>
  <section
    class="pricing-card-sidebar-v4-copy"
    :class="[isSmall ? 'px-24 mb-[120px]' : 'px-40']"
  >
    <Loading
      class="c-pricing-overview sticky top-24"
      :is-loading="loadingCostPreview || !allStoresReady"
      :class="[loadingCostPreview || !allStoresReady ? 'pt-72' : '']"
    >
      <div v-if="!isPreviewErrored">
        <BittsCard class="p-24">
          <div>
            <h3 class="card-title"> Billing Summary </h3>
          </div>
          <CostSummary
            :core-seat-count="coreSeats"
            :cost-per-core-seat="costPerCoreSeat"
            :sales-seat-count="salesSeats"
            :cost-per-sales-seat="costPerSalesSeat"
            :sales-seats-total-cost="salesSeatsTotalCost"
            :core-seats-total-cost="coreSeatsTotalCost"
            :period="period"
            :summary-total="amountDueToday"
            :summary-header="summaryHeader"
            class="my-24"
          />
          <div
            data-testid="cost-summary-alert"
            class="summary-info"
            :class="{ [period]: true }"
          >
            <span
              v-html="
                injectStrings(
                  V4_BILLING_COPY.connectorUpgrade.upgradeCard.alert[period],
                  { values: injections, bold: true },
                )
              "
            />
          </div>
        </BittsCard>
        <div class="feature-card mb-24">
          <h3 class="pricing-card-sidebar-v4-copy__header">
            {{ V4_BILLING_COPY.connectorUpgrade.commitmentTitle }}
          </h3>
          <p
            class="text-white opacity-75"
            v-html="
              injectStrings(
                V4_BILLING_COPY.connectorUpgrade.commitmentDescription,
                { values: [currentDate, newPlanRenewalDate], bold: true },
              )
            "
          />
        </div>
      </div>
      <BittsCard v-else data-testid="connector-preview-errored" class="p-24">
        <template #default>
          <BittsAlert
            color="error"
            :message="V4_BILLING_COPY.connectorUpgrade.upgradeCard.error.title"
            :description="
              V4_BILLING_COPY.connectorUpgrade.upgradeCard.error.description
            "
          />
        </template>
      </BittsCard>
    </Loading>
  </section>
</template>
<script setup>
import { BittsAlert, BittsCard } from '@crossbeam/bitts';
import { useScreenSize } from '@crossbeam/pointbreak';

import { DateTime } from 'luxon';
import { computed, onMounted, ref, watch } from 'vue';

import CostSummary from '@/components/billing/CostSummary.vue';

import useBilling from '@/composables/useBilling';
import {
  BILLING_PLAN_ANNUAL,
  BILLING_PLAN_MONTHLY,
  V4_BILLING_COPY,
} from '@/constants/billing';
import { ordinal } from '@/date_time_utils';
import { captureException } from '@/errors';
import { useFlashesStore } from '@/stores';
import { centsToDollars, injectStrings } from '@/utils';

const emit = defineEmits(['preview', 'preview-failed', 'amount-due']);

const props = defineProps({
  coreSeats: {
    type: Number,
    required: true,
  },
  salesSeats: {
    type: Number,
    required: true,
  },
  period: {
    type: String,
    default: 'year',
  },
});

const flashesStore = useFlashesStore();
const { allStoresReady, processCostDataV4, previewConnector } = useBilling();

const currentDate = DateTime.fromISO(new Date().toISOString()).toFormat('DD');
const currentDay = ordinal(
  DateTime.fromISO(new Date().toISOString()).toFormat('d'),
);
const newPlanRenewalDate = DateTime.fromISO(new Date().toISOString())
  .plus({ years: 1 })
  .toFormat('DD');

const { isSmall } = useScreenSize();
const loadingCostPreview = ref(true);
const centsSaved = ref(0);
const amountDueToday = ref(null);
const isMonthly = computed(() => props.period === 'month');
const injections = computed(() =>
  isMonthly.value
    ? [currentDay, newPlanRenewalDate]
    : [centsToDollars(centsSaved.value)],
);

/* Whenever the component first mounts, and whenever data
is changed, we re-trigger the preview! */
const chosenPeriod = computed(() =>
  isMonthly.value ? BILLING_PLAN_MONTHLY : BILLING_PLAN_ANNUAL,
);
const summaryHeader = computed(() =>
  isMonthly.value ? 'Amount Due Today' : 'Total',
);
const coreSeats = computed(() => props.coreSeats);
const salesSeats = computed(() => props.salesSeats);
const isPreviewErrored = ref(false);
const costPerCoreSeat = ref(0);
const coreSeatsTotalCost = ref(0);
const costPerSalesSeat = ref(0);
const salesSeatsTotalCost = ref(0);

onMounted(async () => {
  previewCost();
});

watch([chosenPeriod, coreSeats, salesSeats], () => {
  previewCost();
});

async function previewCost() {
  emit('preview');
  try {
    loadingCostPreview.value = true;

    /* The backend does not support calculating the difference between two costs so we
    have to do it on the frontend. This is the only reason we have two API calls.
    We only ever show dollars saved if someone chooses the annual plan */
    const [costData, monthlyCostData] = await Promise.all([
      previewConnector({
        period: chosenPeriod.value,
        seats: props.coreSeats,
        salesSeats: props.salesSeats,
      }),
      previewConnector({
        period: BILLING_PLAN_MONTHLY,
        seats: props.coreSeats,
        salesSeats: props.salesSeats,
      }),
    ]);

    const processedData = processCostDataV4({
      costData,
      newCoreSeats: coreSeats.value,
      newSalesSeats: salesSeats.value,
      period: chosenPeriod.value,
    });
    const { amountDueToday: otherAmountDueToday } = processCostDataV4({
      costData: monthlyCostData,
      newCoreSeats: coreSeats.value,
      newSalesSeats: salesSeats.value,
      period: BILLING_PLAN_MONTHLY,
    });

    costPerCoreSeat.value = processedData.costPerCoreSeat;
    coreSeatsTotalCost.value = processedData.coreSeatsTotalCost;
    costPerSalesSeat.value = processedData.costPerSalesSeat;
    salesSeatsTotalCost.value = processedData.salesSeatsTotalCost;
    amountDueToday.value = processedData.amountDueToday;
    centsSaved.value = otherAmountDueToday * 12 - processedData.amountDueToday;
    loadingCostPreview.value = false;
    isPreviewErrored.value = false;
    emit('amount-due', amountDueToday.value);
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({
      message: 'Could not preview cost',
      description: err.message,
    });
    isPreviewErrored.value = true;
    loadingCostPreview.value = false;
    emit('preview-failed');
  }
}
</script>

<style lang="pcss" scoped>
.card-title {
  @apply font-bold text-lg text-neutral-text-strong;
}

.summary-info {
  @apply rounded-8 px-16 py-8 text-center;
  &.year {
    @apply bg-success-background-weak text-success-text;
  }
  &.month {
    @apply bg-accent-background-weak text-accent-text;
  }
}

.pricing-card-sidebar-v4-copy {
  @apply pb-[136px] lg:pb-32 h-full pt-32;
  background: radial-gradient(
      57.15% 41.02% at 23.19% 83.74%,
      theme(colors.upsell.accent / 0.8) 0%,
      theme(colors.upsell.accent / 0) 100%
    ),
    radial-gradient(
      71.71% 50.59% at 87.36% -3.52%,
      theme(colors.info.accent / 0.8) 0%,
      theme(colors.info.accent / 0) 100%
    ),
    linear-gradient(
      180deg,
      theme(colors.brand-navy) 0%,
      theme(colors.primary.text-button) 100%
    );
}

.pricing-card-sidebar-v4-copy__header {
  @apply font-bold text-white text-m mb-8;
  background: linear-gradient(
    92deg,
    theme(colors.info.accent) 0%,
    theme(colors.white) 100%
  );
  -webkit-background-clip: text;
  color: transparent;
}

.feature-card {
  @apply p-24 rounded-16 mt-24;
  background: linear-gradient(
    100.88deg,
    theme(colors.white / 0.2) 0%,
    theme(colors.white / 0) 100%
  );
}
</style>

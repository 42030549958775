<template>
  <BillingCTA
    v-if="isVisible"
    data-testid="out-of-seats-cta"
    :message="message"
    :description="description"
    :button-text="buttonText"
    :no-icon="true"
    size="small"
    :billing-interaction="{
      cta: BILLING_CTAS.AT_SEAT_LIMIT,
      event_site: EVENT_SITES.OUT_OF_SEATS_CTA,
      seatType: seatType,
      talkToSalesReason: mustTalkToSales && 'Out of Seats',
    }"
    :billing-query="{
      [seatType]: seatsNeeded,
      seat_type: seatType,
    }"
  />
</template>

<script setup>
import { pluralize } from 'humanize-plus';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import BillingCTA from '@/components/billing/BillingCTA.vue';

import useHasFeature from '@/composables/useHasFeature';
import useSeats from '@/composables/useSeats';
import { BILLING_CTAS, EVENT_SITES } from '@/constants/analytics';
import { CORE } from '@/constants/team';
import { useBillingStore } from '@/stores';

const billingStore = useBillingStore();
const { isFreeTier, hasSubscription, isSubscriptionCancelled } =
  storeToRefs(billingStore);
const { canGoOverSeatQuota } = useHasFeature();

const { seatsRemainingMap, coreSeatCount, mustTalkToSales } = useSeats();

const props = defineProps({
  seatType: {
    type: String,
    default: null,
  },
  userCount: {
    type: Number,
    default: null,
  },
});

const seatLimit = computed(() => seatsRemainingMap.value[props.seatType]);
const isVisible = computed(
  () => overSeatLimit.value && !isSubscriptionCancelled.value,
);
const overSeatLimit = computed(() => {
  if (canGoOverSeatQuota.value) return false;
  return props.userCount > seatLimit.value;
});
const seatsNeeded = computed(() => {
  if (isFreeTier.value) return props.userCount + coreSeatCount.value;
  return Math.max(props.userCount - seatsRemainingMap.value[props.seatType], 1);
});
const message = computed(
  () =>
    `You only have ${seatLimit.value} available ${pluralize(seatLimit.value, 'seat')} remaining`,
);
const description = computed(() => {
  if (isFreeTier.value)
    return 'Upgrade to add more teammates and unlock new features';
  return hasSubscription.value
    ? `Purchase more to invite more users to ${props.seatType === CORE ? 'Crossbeam Core' : 'Crossbeam for Sales'}`
    : 'Reach out to our team to purchase more';
});

const buttonText = computed(() => {
  if (isFreeTier.value) return 'Upgrade';
  if (hasSubscription.value) return 'Add seats';
  return 'Contact Us';
});
</script>

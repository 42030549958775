import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';

import useAuth from '@/composables/useAuth';
import {
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  HS3_DATA_SOURCE_TYPE,
  HUBSPOT_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { useDataSourcesStore } from '@/stores';

export default function usePreferredCrm() {
  const dataSourcesStore = useDataSourcesStore();

  const { dataSources, unsupportedDataSources } = storeToRefs(dataSourcesStore);
  const { currentOrg } = useAuth();
  onMounted(async () => {
    await dataSourcesStore.readySync;
    const crm =
      HUBSPOT_DATA_SOURCE_TYPE === currentOrg.value.preferred_crm
        ? HS3_DATA_SOURCE_TYPE
        : currentOrg.value.preferred_crm;
    preferredCrm.value = [...crmOptions.value, ...nonCrmOptions].find(
      (ds) => ds.value === crm,
    )?.value;
  });

  const crmOptions = computed(() =>
    dataSources.value
      .filter(
        (ds) =>
          ![
            FILE_UPLOAD_DATA_SOURCE_TYPE,
            HUBSPOT_DATA_SOURCE_TYPE,
            GOOGLE_SHEETS_DATA_SOURCE_TYPE,
          ].includes(ds.type),
      )
      .map((ds) => ({
        value: ds.type,
        label: ds.friendly_name,
        svg: `${ds.type}Icon`,
      }))
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)),
  );
  const nonCrmOptions = [
    { svg: '', value: 'not_listed', label: "I don't see my CRM listed" },
    { svg: '', value: 'no_crm', label: "I don't use a CRM" },
  ];

  const crmDropdownContent = computed(() => {
    return [
      {
        heading: null,
        items: crmOptions.value,
      },
      {
        heading: null,
        items: nonCrmOptions,
      },
    ];
  });

  const preferredCrm = ref(null);
  const isUnsupported = computed(() =>
    unsupportedDataSources.value.some((ds) => ds.type === preferredCrm.value),
  );
  const preferredCrmName = computed(
    () =>
      dataSources.value.find((ds) => ds.type === preferredCrm.value)
        ?.friendly_name,
  );

  const showCrmCallout = computed(
    () => preferredCrm.value && preferredCrm.value !== 'no_crm',
  );
  const crmCalloutTitle = computed(() => {
    if (isUnsupported.value)
      return `We we don't support ${preferredCrmName.value} yet`;
    if (preferredCrm.value === 'not_listed')
      return "We'd love to hear what CRM you use";
    return `Great news, we support ${preferredCrmName.value}`;
  });
  const crmCalloutDescription = computed(() => {
    if (isUnsupported.value)
      return `We recommend Google Sheets for now but can let you know once a ${preferredCrmName.value} is available as a data source`;
    if (preferredCrm.value === 'not_listed')
      return 'If you want to cast a vote for your source of truth, please reach out at support@crossbeam.com';
    return 'You can use this connection for free to keep your data up-to-date';
  });

  const crmCalloutType = computed(() =>
    isUnsupported.value || preferredCrm.value === 'not_listed'
      ? 'info'
      : 'success',
  );

  return {
    isUnsupported,
    crmDropdownContent,
    preferredCrm,
    preferredCrmName,
    showCrmCallout,
    crmCalloutTitle,
    crmCalloutDescription,
    crmCalloutType,
  };
}

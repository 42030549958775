<template>
  <Loading :is-loading="!ready">
    <div class="downgrade-selection-view">
      <h3>Choose {{ EXPLORER_SEATS }} users to stay on Crossbeam</h3>
      <slot name="description">
        <p
          >Anyone else will be removed from your Crossbeam account on
          <b>{{ subscriptionRenewalDate }}</b></p
        >
      </slot>
      <BittsCallout
        v-if="salesOnlyUsers.length"
        :title="`${salesOnlyUsers.length} Sales seat ${pluralize(salesOnlyUsers.length, 'user')} will be removed from Crossbeam`"
        size="small"
        subtitle="You will no longer have access to Crossbeam for Sales on the Explorer plan"
        class="mb-24"
        type="danger"
        :icon="['fad', 'ban']"
      >
        <template #right-item>
          <div v-if="salesOnlyUsers" class="ml-auto">
            <BittsUserStack :users="salesOnlyUsers" size="small" />
          </div>
        </template>
      </BittsCallout>
      <BittsTable
        :columns="columns"
        :enable-cell-text-selection="true"
        :compress-columns="true"
        :rows="rows"
        :passed-context="context"
        :row-height="64"
        :pagination="false"
        class="fade-in"
      />
    </div>
  </Loading>
</template>

<script setup>
import { BittsCallout, BittsTable, BittsUserStack } from '@crossbeam/bitts';

import { pluralize } from 'humanize-plus';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount } from 'vue';

import { EXPLORER_SEATS } from '@/constants/billing';
import { debookingColumns } from '@/constants/debooking';
import { TEMP_LAST_ACTIVITY } from '@/constants/feature_flags';
import { ADMIN_PANEL } from '@/constants/team';
import {
  allReady,
  useBillingStore,
  useFeatureFlagStore,
  useRolesStore,
  useTeamStore,
} from '@/stores';

const props = defineProps({
  checkAll: {
    type: Boolean,
    default: false,
  },
  isSunsetting: {
    type: Boolean,
    default: false,
  },
});

const teamStore = useTeamStore();
const rolesStore = useRolesStore();
const billingStore = useBillingStore();

const { rolesById } = storeToRefs(rolesStore);
const { authorizations, redeemableInvites, salesOnlyUsers } =
  storeToRefs(teamStore);
const { subscriptionRenewalDate } = storeToRefs(billingStore);

const keptUsers = defineModel('userIds', { type: Set, required: true });
const keptInvites = defineModel('inviteIds', { type: Set, required: true });

const ready = allReady(rolesStore, teamStore);

onBeforeMount(async () => {
  await teamStore.refreshTeamStore();
});

function attachRoleName(authOrInvite) {
  return {
    ...authOrInvite,
    roleName: rolesById.value[authOrInvite?.role_id]?.name,
  };
}

/* User Selection Table  */
const authsWithRoles = computed(() =>
  authorizations.value
    .filter((auth) => auth.role_id)
    .map(attachRoleName)
    .filter((auth) => auth.authorizer_type !== ADMIN_PANEL),
);

/* We will not let them keep any outbound sales _only_ invites...
and if they have are "sunsetting" we do not let them keep any
outbound invites with sales roles at all. */
const invitesWithRoles = computed(() =>
  redeemableInvites.value
    .filter((auth) => auth.role_id)
    .filter((auth) => (props.isSunsetting ? !auth.sales_edge_role : true))
    .map(attachRoleName),
);

if (props.checkAll) {
  keptUsers.value.add(...authsWithRoles.value.map((v) => v.id));
  keptInvites.value.add(...invitesWithRoles.value.map((v) => v.id));
}
const context = computed(() => ({
  keptUsers: keptUsers.value,
  keptInvites: keptInvites.value,
  checkAll: props.checkAll,
}));

const featureFlagStore = useFeatureFlagStore();
const columns = computed(() =>
  debookingColumns.filter((col) => {
    if (col.field === 'Last Activity')
      return featureFlagStore.hasFeatureFlag(TEMP_LAST_ACTIVITY);
    return true;
  }),
);
const rows = computed(() => {
  return [...authsWithRoles.value, ...invitesWithRoles.value].map((d) => ({
    ...d,
    isChecked: props.checkAll ?? false,
    callback(id) {
      this.isChecked = !this.isChecked;
      if (d.user) {
        if (keptUsers.value.has(id)) keptUsers.value.delete(id);
        else keptUsers.value.add(id);
      } else {
        if (keptInvites.value.has(id)) keptInvites.value.delete(id);
        else keptInvites.value.add(id);
      }
    },
  }));
});
</script>

<style lang="pcss" scoped>
h3 {
  @apply text-neutral-text-strong text-m font-bold mb-4 mt-40;
}

p {
  @apply text-neutral-text mb-24;
}
</style>

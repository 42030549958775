<template>
  <div>
    <loading :is-loading="loading">
      <div v-if="hasExistingAuth0Profile" class="c-simple-form">
        <h1 class="link-login__title"> Link your login methods </h1>
        <div class="c-simple-form__content">
          <div class="link-login__status">
            <p class="link-login__explainer">
              It looks like you've already created an account using
              {{ existingLoginMethod }}. If you continue, we'll link this
              {{ newLoginMethod }} account with your existing
              {{ existingLoginMethod }} account so you can use either method in
              the future.
            </p>
            <div class="link-login__explainer">
              For security purposes, once we link your accounts, we will prompt
              you to login again.
            </div>
            <p class="link-login__explainer">
              If you don't want to do this,
              <BittsLink text="Log Out" url="logout" />
              and then try logging in with {{ existingLoginMethod }}.
            </p>
          </div>
          <BittsButton
            text="Link logins"
            class="mt-24 mx-auto"
            @click="linkAccounts"
          />
        </div>
      </div>
    </loading>
  </div>
</template>

<script>
import { BittsButton, BittsLink } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { mapActions } from 'pinia';

import useAuth from '@/composables/useAuth';
import { getAndClearLSNextUrl } from '@/local_storage';
import { useFlashesStore } from '@/stores';
import urls from '@/urls';
import { resolveNext } from '@/utils';

export default {
  name: 'LinkAccounts',
  components: {
    BittsButton,
    BittsLink,
  },
  setup() {
    useHead({
      title: 'Link Accounts - Crossbeam',
    });
    const { isLoggedIn, userCanTryToLinkAccounts } = useAuth();

    return { isLoggedIn, userCanTryToLinkAccounts };
  },
  data() {
    return {
      loading: false,
      hasExistingAuth0Profile: false,
      existingAuth0Identity: null,
    };
  },
  computed: {
    existingLoginMethod() {
      return this.existingAuth0Identity.connection ===
        'Username-Password-Authentication'
        ? 'email and password'
        : 'Google';
    },
    newLoginMethod() {
      return this.existingAuth0Identity.connection ===
        'Username-Password-Authentication'
        ? 'Google'
        : 'email and password';
    },
    successfulLinkMessage() {
      return `We have successfully linked your ${this.newLoginMethod} account to your
      ${this.existingLoginMethod} account.
      For your security, please login again with either account.`;
    },
  },
  async created() {
    try {
      if (!this.isLoggedIn) {
        return this.$router.push(this.nextUrl());
      }
      if (this.userCanTryToLinkAccounts) {
        await this.checkIfAnotherLoginMethodExists();
      } else {
        this.$router.push(this.nextUrl());
      }
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(useFlashesStore, ['addUnhandledError']),
    nextUrl() {
      const next = resolveNext(this.$router, getAndClearLSNextUrl());
      return next || this.$route.query.next || { name: 'main' };
    },
    async checkIfAnotherLoginMethodExists() {
      const response = await axios.get(urls.auth0.profiles);
      const existingProfiles = response.data.items;
      if (existingProfiles.length === 1) {
        this.hasExistingAuth0Profile = true;
        // this currently assumes that there can only be one identity
        // per auth0 user. That could change if we ever build support for additional
        // login methods that share the same email
        this.existingAuth0Identity = existingProfiles[0].identities[0];
      } else {
        this.$router.push(this.nextUrl());
      }
    },
    async linkAccounts() {
      this.loading = true;
      try {
        await axios.post(urls.auth0.linkAccounts, {});
        // We need to force the user to login again since the current token/identity
        // is basically null and void after a link operation
        const next = `/login?message=${this.successfulLinkMessage}`;
        this.$router.replace(`/logout?next=${next}`);
      } catch (err) {
        this.addUnhandledError(err);
        // Ignore the error and move on to the next url
        // If we don't do that user would just get stuck here
        // until the error is resolved
        this.$router.push(this.nextUrl());
      }
    },
  },
};
</script>
<style lang="pcss" scoped>
.link-login__title {
  font-weight: 600;
  @apply text-xl text-brand-navy text-center;
}

.link-login__status {
  border: solid 1px;
  @apply mb-16 p-12 rounded-4;
  border-color: var(--yellow);
}

.link-login__explainer {
  @apply text-left text-neutral-text pb-8;
}
</style>

export default {
  test: true,
  salesEdgeBaseUrl: 'https://sales-backend-api.cb.c7m.io',
  salesEdgeRedirectUrl: 'https://sales.cb.c7m.io',
  hubSpotForms: {
    contactSalesForm: '4ca71264-cb55-4f5c-8cae-440686ab46bd',
    envID: '5767578',
  },
  enableGoogleTags: false,
  statusPageEmbedUrl: null,
  iteratively: {
    environment: 'test',
  },
  statuspageBaseUrl: 'https://crossbeamstaging.statuspage.io',
  statuspagePageId: '6dcrv6nnlbkg',
  datadog: {},
  launchDarkly: {}, // This is stubbed in createLaunchDarklyClient()
  chargify: {
    publicKey: 'fake-key',
    serverHost: 'fake-server-host',
  },
  segment: { enabled: false },
} as const;

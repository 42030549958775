<template>
  <loading :is-loading="loading || !ready">
    <div class="c-map-new-columns-form">
      <div v-show="!isConfirming">
        <BittsInput
          v-model.trim="sourceTable"
          placeholder="File Name"
          name="file_name"
          :form-label="`${isCsv ? 'File' : 'Sheet'} Name`"
          :disabled="true"
          :caption="`This ${isCsv ? 'file' : 'sheet'} name will be shared with partners when you share data`"
        />
        <div class="mt-24">
          <BittsAlert
            color="warning"
            message="You can only map columns one time so double-check your selections"
            class="mb-24"
          />
          <MapColumnSelector
            :all-columns="allColumns"
            :unmapped-columns="unmappedColumns"
            :field-mappings="fieldMappings"
            :type="ACCOUNT_OWNER_EMAIL_DISPLAY"
            :is-csv="isCsv"
            @update="updateMappings"
          />
          <div class="text-neutral-text-weak text-sm mt-4">
            Email is required before you can map any
            {{ ACCOUNT_OWNER_DISPLAY }} Data
          </div>
        </div>
        <div class="mt-24">
          <MapColumnSelector
            :all-columns="allColumns"
            :unmapped-columns="unmappedColumns"
            :field-mappings="fieldMappings"
            :disabled="!hasAeEmailsSelected"
            :type="ACCOUNT_OWNER_NAME_DISPLAY"
            :is-csv="isCsv"
            @update="updateMappings"
          />
          <div class="mt-24">
            <MapColumnSelector
              :all-columns="allColumns"
              :disabled="!hasAeEmailsSelected"
              :unmapped-columns="unmappedColumns"
              :field-mappings="fieldMappings"
              :type="ACCOUNT_OWNER_PHONE_DISPLAY"
              :is-csv="isCsv"
              @update="updateMappings"
            />
          </div>
          <div v-if="errorText" class="w-full pt-24 pb-24">
            <BittsAlert
              :description="errorText"
              color="error"
              message="Error"
            />
          </div>
          <div class="flex items-center justify-end gap-8 mt-40 mb-24">
            <BittsButton
              text="Cancel"
              size="large"
              type="neutral"
              @click="cancel"
            />
            <component :is="divOrTooltip">
              <div>
                <BittsButton
                  data-testid="map-new-columns-open-confirm"
                  :disabled="!hasAeEmailsSelected"
                  text="Apply"
                  size="large"
                  @click="toggleConfirmation"
                />
              </div>
              <template #title>
                Please map at least one column including {{ AO_DISPLAY }} Email
                information.
              </template>
            </component>
          </div>
        </div>
      </div>
      <ConfirmMapColumns
        v-if="isConfirming"
        :confirmation-message="`You have opted into mapping your ${AO_DISPLAY} Columns. Did you double-check your selections and map as many columns as you wanted? You can only map your ${AO_DISPLAY} Columns once.`"
        @cancel="toggleConfirmation"
        @upload="mapNewColumns"
      />
    </div>
  </loading>
</template>

<script>
import {
  BittsAlert,
  BittsButton,
  BittsInput,
  BittsRadioGroupCards,
  BittsSelect,
  BittsSteps,
  BittsTooltip,
} from '@crossbeam/bitts';

import axios from 'axios';
import { mapActions } from 'pinia';

import ConfirmMapColumns from '@/components/data-sources/ConfirmMapColumns.vue';
import FileInput from '@/components/data-sources/FileInput.vue';
import MapColumnSelector from '@/components/data-sources/MapColumnSelector.vue';
import Loading from '@/components/Loading.vue';
import VuelidateWrapper from '@/components/VuelidateWrapper.vue';

import { FILE_UPLOAD_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import {
  ACCOUNT_OWNER_DISPLAY,
  ACCOUNT_OWNER_EMAIL_DISPLAY,
  ACCOUNT_OWNER_NAME_DISPLAY,
  ACCOUNT_OWNER_PHONE_DISPLAY,
  AO_DISPLAY,
} from '@/constants/mdm';
import { captureException } from '@/errors';
import {
  allReady,
  useFeedsStore,
  useFileUploadsStore,
  useSourcesStore,
} from '@/stores';
import urls from '@/urls';

export default {
  name: 'MapNewColumnsForm',
  components: {
    Loading,
    VuelidateWrapper,
    BittsAlert,
    FileInput,
    BittsButton,
    BittsInput,
    BittsRadioGroupCards,
    BittsSteps,
    BittsTooltip,
    BittsSelect,
    MapColumnSelector,
    ConfirmMapColumns,
  },
  props: {
    sourceId: {
      type: Number,
      required: true,
    },
  },
  emits: ['confirming', 'cancel', 'success'],
  setup() {
    const ready = allReady(useFileUploadsStore(), useFeedsStore());
    const sourcesStore = useSourcesStore();
    return {
      ready,
      AO_DISPLAY,
      ACCOUNT_OWNER_DISPLAY,
      ACCOUNT_OWNER_EMAIL_DISPLAY,
      ACCOUNT_OWNER_PHONE_DISPLAY,
      ACCOUNT_OWNER_NAME_DISPLAY,
      sourcesStore,
    };
  },
  data() {
    return {
      feed: null,
      errorText: '',
      source: null,
      allColumns: [],
      unmappedColumns:
        [] /* We need to allow the user to select a column only if it hasn't been already mapped to any AE field */,
      isConfirming: false,
      fieldMappings: {
        [this.ACCOUNT_OWNER_NAME_DISPLAY]: [],
        [this.ACCOUNT_OWNER_EMAIL_DISPLAY]: [],
        [this.ACCOUNT_OWNER_PHONE_DISPLAY]: [],
      },
      loading: false,
    };
  },
  computed: {
    hasAeEmailsSelected() {
      return this.fieldMappings[this.ACCOUNT_OWNER_EMAIL_DISPLAY].length > 0;
    },
    divOrTooltip() {
      return this.hasAeEmailsSelected ? 'div' : 'BittsTooltip';
    },
    isCsv() {
      return this.feed?.integration.type === FILE_UPLOAD_DATA_SOURCE_TYPE;
    },
    sourceTable() {
      return this.source?.table;
    },
  },
  async created() {
    this.loading = true;
    await this.sourcesStore.readySync;
    this.source = this.getSourceById(this.sourceId);
    this.feed = this.getFeedById(this.source?.feed_id);
    this.allColumns = this.unusedSourceColumns(this.source);
    this.unmappedColumns = this.unusedSourceColumns(this.source);
    this.loading = false;
  },
  methods: {
    ...mapActions(useFeedsStore, ['getFeedById']),
    ...mapActions(useSourcesStore, ['getSourceById']),
    getErrorText(err) {
      return err.response?.data?.errors?.length > 0
        ? err.response?.data?.errors[0]
        : 'We ran into an error processing your file. Please try again, or contact support.';
    },
    async reset() {
      this.isConfirming = false;
      this.unmappedColumns = this.unusedSourceColumns(this.source);
      this.fieldMappings = {
        [this.ACCOUNT_OWNER_NAME_DISPLAY]: [],
        [this.ACCOUNT_OWNER_EMAIL_DISPLAY]: [],
        [this.ACCOUNT_OWNER_PHONE_DISPLAY]: [],
      };
      this.loading = false;
      this.$emit('confirming');
    },
    cancel() {
      this.$emit('cancel');
    },
    async mapNewColumns() {
      this.errorText = '';
      this.loading = true;
      try {
        const { payload, url } = this.createPayloadAndUrl();
        await axios.post(url, payload);
        this.$emit('success', { source: this.source });
      } catch (err) {
        captureException(err);
        this.errorText = this.getErrorText(err);
        this.reset();
      }
    },
    unusedSourceColumns() {
      const columns =
        this.source?.fields.reduce((acc, field) => {
          if (
            field.is_visible &&
            field.mdm_property === null &&
            !['File Name', 'Upload Time', 'id', 'createdAt'].includes(
              field.column,
            )
          ) {
            acc.push({ value: field.column, label: field.column });
          }
          return acc;
        }, []) ?? [];

      return columns;
    },
    createPayloadAndUrl() {
      if (this.isCsv) {
        const url = urls.sources.mapNewColumns(this.sourceId);
        const payload = {
          field_mappings: {},
        };

        for (const [key, x] of Object.entries(this.fieldMappings)) {
          for (const col of x) {
            payload.field_mappings[col.value] = key;
          }
        }
        return { payload, url };
      }

      /* For Google Sheets */
      const url = urls.sources.allV3;
      const mapping = {
        mdm_type: 'user',
        feed_id: this.feed.id,
        spreadsheet_id: this.source.properties.spreadsheet_id,
        sheet_id: this.source.properties.sheet_id,
        fields: [],
      };

      for (const [key, x] of Object.entries(this.fieldMappings)) {
        for (const col of x) {
          const type = key.split(' ').at(-1).toLowerCase();
          mapping.fields.push({
            mdm_property: `person_${type}`,
            column_name: col.value,
            is_primary_key: false,
          });
        }
      }

      return { payload: [mapping], url };
    },
    toggleConfirmation() {
      this.$emit('confirming');
      this.isConfirming = !this.isConfirming;
    },
    updateMappings({ unmappedColumns, fieldMappings }) {
      this.unmappedColumns = unmappedColumns;
      this.fieldMappings = fieldMappings;
    },
  },
};
</script>
<style lang="pcss">
.c-map-new-columns-form__row--note {
  @apply text-neutral-text-weak text-sm;
  line-height: 16px;
}

.c-map-new-columns-form__link {
  @apply text-brand-blue;
  &:hover {
    @apply no-underline;
  }
}

.c-map-new-columns-form__note {
  @apply text-neutral-text-weak text-sm mt-8;
}
</style>

<template>
  <BittsModal
    class="c-send-to-sales-modal"
    :save-text="`Send ${singularOrPluralRecord === 'record' ? 'Record' : 'Records'}`"
    :visible="true"
    :width="600"
    :disabled="!selectedAE"
    :loading="!ready"
    @saved="onSendRecordsClicked"
    @closed="onModalClosed"
  >
    <template #title>
      <h3
        class="pt-16 text-center text-xl font-bold leading-8 text-neutral-text-strong w-full"
      >
        Send to Account Owner
      </h3>
    </template>
    <template #content>
      <p class="text-neutral-text text-m text-center mb-24">
        Send {{ singularOrPluralRecord }} to account owners to take action on
        the opportunity
      </p>
      <BittsSelect
        v-model="selectedAE"
        option-type="entity"
        :options="aeOptions"
        :form-label="{
          title: 'Account Owner',
          helpText:
            'Account Owners must have a Crossbeam for Sales seat to receive records from Shared Lists',
          includeCta: true,
          ctaText: 'Learn More',
          ctaLink:
            'https://help.crossbeam.com/en/articles/6699011-what-is-sales-edge',
          placement: 'topLeft',
        }"
        placeholder="Select Account Owner"
      />
    </template>
  </BittsModal>
</template>
<script setup>
import { BittsModal, BittsSelect } from '@crossbeam/bitts';

import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import useIteratively from '@/composables/useIteratively';
import { EVENT_SITES } from '@/constants/analytics';
import { CO_SELLER, PARTNER_MANAGER } from '@/constants/team';
import { captureException } from '@/errors';
import { useFlashesStore, useTeamStore } from '@/stores';
import urls from '@/urls';

const props = defineProps({
  rowsSelected: {
    type: Array,
    default: () => [],
  },
  list: {
    type: Object,
    default: () => {},
  },
  isSingleSend: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['closed', 'saved']);

const { iteratively } = useIteratively();

function onModalClosed(response) {
  if (response) {
    emits('saved', response);
  } else {
    emits('closed');
  }
}

const selectedAE = ref(null);
const flashesStore = useFlashesStore();
const teamStore = useTeamStore();
const { ready } = storeToRefs(teamStore);

const aeOptions = computed(() =>
  teamStore.salesSeats
    .filter(
      (user) =>
        user?.user &&
        [CO_SELLER, PARTNER_MANAGER].includes(user.sales_edge_role),
    )
    .map((seUser) => ({
      ...seUser.user,
      label: `${seUser.user.first_name} ${seUser.user.last_name}`,
      value: seUser.user.id,
    })),
);

const singularOrPluralRecord = computed(() =>
  props.rowsSelected.length > 1 ? 'records' : 'record',
);

async function onSendRecordsClicked() {
  try {
    const payload = {
      user_id: selectedAE.value,
      items: props.rowsSelected.map((row) => row.rowId),
    };
    const { data } = await axios.post(
      urls.lists.postToSales(props.list.id),
      payload,
    );
    iteratively.userSendsRecords({
      event_site: EVENT_SITES.SEND_TO_SALES_MODAL,
      action_name: 'Send To Account Owner',
      clicked_from: props.isSingleSend ? 'List Row' : 'Above List',
      list_id: props.list.id,
      list_name: props.list.name,
      location: 'Collaborate',
      number_of_records: props.rowsSelected.length.toString(),
    });
    onModalClosed(data.items);
    flashesStore.addSuccessFlash({
      message: `Your ${singularOrPluralRecord.value} ${singularOrPluralRecord.value === 'records' ? 'have' : 'has'} been sent to Crossbeam for Sales`,
    });
  } catch (e) {
    const isNoOlapsError =
      e.response?.data?.errors[0]?.split(':')[0] === 'NoOverlapsError';
    if (isNoOlapsError) {
      const content = e.response?.data?.errors[0]?.split(':')[1];
      const message = content.split('.')[0];
      const description = content.split('.')[1];
      flashesStore.addWarningFlash({ message, description });
    } else {
      flashesStore.addErrorFlash({
        message: 'Something went wrong, reach out to our support team for help',
      });
    }
    captureException(e);
  }
}
</script>

<style lang="pcss">
.c-send-to-sales-modal {
  .c-bitts-modal__buttons--wrapper {
    @apply justify-between;
  }
  .c-bitts-modal__content {
    @apply pt-8;
  }
  .c-bitts-modal__header {
    @apply rounded-2xl bg-white bg-top-gradient from-neutral-accent/20 to-neutral-accent/0;
  }
}
</style>

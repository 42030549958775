import development from './dev';
import production from './prod';
import staging from './staging';
import test from './test_env';

type Environment = 'development' | 'staging' | 'production' | 'test';
type Config =
  | typeof development
  | typeof production
  | typeof staging
  | typeof test;

const modeLookup: Record<string, Environment> = {
  'app.crossbeam.com': 'production',
  'app.crossbeam-dev.com': 'development',
  'up.crossbeam-dev.com': 'development',
};

const hostname = window?.location?.hostname;

let mode: Environment = 'development';

if (import.meta.env.MODE === 'test') {
  mode = 'test';
} else if (hostname && hostname.endsWith('app.cb.c7m.io')) {
  mode = 'staging';
} else {
  mode = modeLookup[hostname] || 'development';
}

if (!mode) {
  throw new Error('Environment could not be configured');
}

const environmentLookup: Record<Environment, Config> = {
  development,
  test,
  staging,
  production,
};

export default environmentLookup[mode];

<template>
  <button
    :class="{ 'c-dropdown-menu-list__item--active': isActive }"
    class="c-dropdown-menu-list__item"
    @mousedown.left="$emit('item-chosen')"
    @mousemove="$emit('activate')"
    @mouseenter="$emit('mouse-enter')"
    @mouseleave="$emit('mouse-leave')"
    @click="$emit('click', $event)"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['item-chosen', 'activate', 'mouse-enter', 'mouse-leave', 'click'],
};
</script>
<style lang="pcss" scoped>
.c-dropdown-menu-list__item {
  cursor: pointer;
  display: block;
}
</style>

<template>
  <div
    class="c-feed-list-item-row"
    :class="{
      'c-feed-list-item-row--disabled': sourceDisabled,
      'c-feed-list-item-row--offline': !!partner,
    }"
  >
    <div class="c-feed-list-item-row-value">
      <div class="left-content flex">
        <div v-if="sourceDisabled" :style="{ paddingRight: '5px' }">
          <FontAwesomeIcon
            :icon="['fa', 'spinner']"
            :style="{ height: '18px', width: '18px', color: 'currentColor' }"
            class="text-neutral-border mr-8 animate-spin"
          />
        </div>
        <FontAwesomeIcon
          v-if="!!partner"
          :icon="['fas', 'file-csv']"
          class="text-neutral-accent w-16 h-16 mr-8"
        />
        <span
          class="c-feed-list-item-row__source-name mr-8"
          :class="{
            'text-neutral-text-placeholder': sourceDisabled,
          }"
        >
          {{ source.table }}
        </span>
        <span
          v-if="source.created_at"
          :class="{
            'text-neutral-text-placeholder': sourceDisabled,
            'ml-2 text-neutral-text-weak': !sourceDisabled,
          }"
        >
          {{ date }}
        </span>
        <BittsBadge
          v-if="sourceErrored"
          status="danger"
          text="Error"
          class="ml-8"
        />
        <BittsBadge
          v-if="sourceDeleting"
          status="danger"
          text="Deleting"
          class="ml-8"
        />
        <BittsBadge
          v-else-if="hasMissingRequiredCells || sourceWarning"
          status="warning"
          text="Warning"
          class="ml-8"
        />
      </div>
      <div class="flex">
        <div v-if="canManageDatasource" class="c-feed-list-item-row-value">
          <BittsButton
            v-if="!partner && !disableMapColumnsButton"
            class="c-feed-list-item-row__map-columns-button"
            :disabled="disableMapColumnsButton"
            text="Map Account Owner"
            size="x-small"
            type="primary"
            variant="ghost"
            @click="showMapNewColumns(source.id)"
          />
          <BittsButton
            v-if="isCSVFeed && !hasBigCSVFileLimit"
            :disabled="sourceDisabled"
            :left-icon="['fas', 'plus']"
            text="Add Data"
            size="x-small"
            type="primary"
            variant="ghost"
            data-testid="add-data-to-source-button"
            @click="showCSVUploadModalForSource(source.id)"
          />
          <BittsButton
            :disabled="sourceDeleting"
            text="Remove"
            size="x-small"
            type="danger"
            variant="ghost"
            @click="showDeleteSourceModal(source)"
          />
        </div>
      </div>
    </div>
    <BittsAlert
      v-if="sourceErrored || sourceWarning"
      v-bind="sourceDetails"
      class="c-feed-list-item-row__problem"
    />
    <BittsAlert
      v-else-if="hasMissingRequiredCells"
      message="Blank required cells detected"
      description="Rows with blank required cells will be skipped"
      class="c-feed-list-item-row__problem"
      color="warning"
    />
  </div>
</template>

<script>
/* This component is only relevant for CSVs and Google Sheets, which have feed tables */
import { BittsAlert, BittsBadge, BittsButton } from '@crossbeam/bitts';

import { DateTime } from 'luxon';
import { mapActions, mapState } from 'pinia';

import useAuth from '@/composables/useAuth';
import {
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  SOURCE_STATUS_MAP,
} from '@/constants/data_sources';
import {
  BIG_CSV_FILE_LIMIT_500MB,
  BQ_CSV_ACCOUNT_EXECUTIVE_FIELDS,
} from '@/constants/feature_flags';
import { todayYesterdayTomorrowOrCustom } from '@/date_time_utils';
import { useFeatureFlagStore, useSourcesStore } from '@/stores';

export default {
  name: 'FeedListItemRow',
  components: {
    BittsButton,
    BittsAlert,
    BittsBadge,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
    feed: {
      type: Object,
      required: true,
    },
    partner: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { hasPermission } = useAuth();

    return { hasPermission };
  },
  computed: {
    ...mapState(useFeatureFlagStore, ['hasFeatureFlag']),
    isCSVFeed() {
      return this.feed.integration.type === FILE_UPLOAD_DATA_SOURCE_TYPE;
    },
    sourceErrored() {
      /* For Google Sheets specifically, we want to
      show an error on the feed if any of the sources are errored.
      Errors on sources supercede errors on feeds and cause the
      feed to be in an error state. */

      /* The missing_required property isn't counted as an error. During a refactor
      of the API this should all be simplified + changed */
      return (
        SOURCE_STATUS_MAP.error.includes(this.source.status) &&
        !this.hasMissingRequiredCells
      );
    },
    sourceWarning() {
      /* Missing required cells is a warning */
      return SOURCE_STATUS_MAP.warning.includes(this.source.status);
    },
    sourceProcessing() {
      return SOURCE_STATUS_MAP.process.includes(this.source.status);
    },
    sourceDeleting() {
      return SOURCE_STATUS_MAP.deleting.includes(this.source.status);
    },
    sourceDisabled() {
      return (
        SOURCE_STATUS_MAP.process.includes(this.source.status) ||
        SOURCE_STATUS_MAP.deleting.includes(this.source.status)
      );
    },
    hasMissingRequiredCells() {
      /* Missing required cells is a warning. This is confusing and should
        be cleaned up in an API refactor */
      const missingCount =
        this.source?.properties?.row_errors?.missing_required;
      return !!missingCount;
    },
    canManageDatasource() {
      return this.partner
        ? this.hasPermission('manage:offline-partners')
        : this.hasPermission('manage:data-sources');
    },
    date() {
      return this.formatDate(this.source.created_at);
    },
    sourceDetails() {
      return {
        color: this.sourceErrored ? 'error' : 'warning',
        message: this.sourceErrored
          ? `We're having trouble with this ${this.isCSVFeed ? 'CSV File' : 'Google Sheet'}`
          : `Some rows in your ${this.feed.integration.friendly_name} have been skipped`,
        description: this.source.error_message,
      };
    },
    hasBqCsvAEFields() {
      return this.hasFeatureFlag(BQ_CSV_ACCOUNT_EXECUTIVE_FIELDS);
    },
    hasBigCSVFileLimit() {
      return this.hasFeatureFlag(BIG_CSV_FILE_LIMIT_500MB);
    },
    disableMapColumnsButton() {
      return (
        this.sourceDisabled ||
        this.hasMappedAEData(this.source) ||
        this.hasBqCsvAEFields ||
        this.hasBigCSVFileLimit
      );
    },
  },
  methods: {
    ...mapActions(useSourcesStore, ['getRelatedSources']),
    formatDate(asIso) {
      const formatter = (d) => d.toFormat("D 'at' t");
      return todayYesterdayTomorrowOrCustom(
        DateTime.fromISO(asIso),
        true,
        formatter,
      );
    },
    addOfflineIds(route) {
      if (this.partner) {
        route.query = {
          offlinePartnerUuid: this.partner.uuid,
          offlinePartnerId: this.partner.id,
        };
      }
      return route;
    },
    showCSVUploadModalForSource(sourceId) {
      const route = this.addOfflineIds({
        name: this.partner
          ? 'offline-file-upload-add-data'
          : 'file-upload-add-data',
        params: { id: sourceId },
      });
      this.$router.push(route);
    },
    async showDeleteSourceModal(sourceToDelete) {
      const route = this.addOfflineIds({
        name: this.partner ? 'offline-delete-source' : 'delete-source',
        params: { source_id: sourceToDelete.id },
      });
      await this.$router.push(route);
    },
    showMapNewColumns(sourceId) {
      const route = this.addOfflineIds({
        name: 'map-ae-columns',
        params: { id: sourceId },
      });
      this.$router.push(route);
    },
    hasMappedAEData(source) {
      return this.getRelatedSources(source).some(
        (source) => source.mdm_type === 'user',
      );
    },
  },
};
</script>

<style lang="pcss" scoped>
.c-feed-list-item-row {
  @apply flex flex-col justify-between py-12 px-16 border-neutral-border border-t;
}

.c-feed-list-item-row-value {
  @apply flex justify-between;
}

.c-feed-list-item-row--disabled {
  @apply bg-neutral-100 border-neutral-border;
}

.c-feed-list-item-row__problem {
  @apply mt-12;
}
</style>

<template>
  <div class="bitts-switch">
    <div class="flex-1" :class="{ 'opacity-50': disabled }">
      <div class="bitts-switch__label">
        <slot name="label">
          {{ label }}
        </slot>
      </div>
      <div class="bitts-switch__description">
        <slot name="description">
          {{ description }}
        </slot>
      </div>
    </div>
    <a-switch
      :id="id"
      class="ml-8"
      tab-index="0"
      :checked="useIsCheckedProp ? isChecked : checked"
      checked-children="ON"
      un-checked-children="OFF"
      :disabled="disabled"
      @change="onChange"
    />
  </div>
</template>

<script setup lang="ts">
import { Switch as ASwitch } from 'ant-design-vue';
import { ref } from 'vue';

interface Props {
  description?: string;
  disabled?: boolean;
  id?: string | undefined;
  initialValue?: boolean;
  isChecked?: boolean;
  label?: string;
  useIsCheckedProp?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  description: '',
  disabled: false,
  id: undefined,
  initialValue: false,
  isChecked: false,
  label: '',
  useIsCheckedProp: false,
});

const emit = defineEmits(['change']);

const checked = ref<boolean>(props.initialValue);

function onChange(isChecked: string | number | boolean, _event: Event) {
  checked.value = !!isChecked;
  emit('change', checked.value);
}
</script>

<style lang="pcss">
.bitts-switch {
  @apply flex items-center;

  .bitts-switch__label {
    @apply font-bold text-base text-start;
  }

  .bitts-switch__description {
    @apply mt-4 text-sm text-neutral-text text-start;
  }

  button {
    &:focus-visible:hover,
    &:focus-visible {
      outline: 1px solid theme('colors.primary.border-pressed');
      box-shadow: inset 0px 0px 0px 2px theme(colors.white);
    }
  }

  .ant-switch {
    width: 52px;
    font-family: inherit;
    height: 26px;
    border-radius: 16px;
    box-shadow: inset 2px 2px 8px theme(colors.black / 0.25);
  }

  .ant-switch-handle::before {
    @apply w-16 h-16 mt-[3px];
  }

  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    @apply bg-success-accent;
  }

  .ant-switch.ant-switch-checked {
    @apply bg-success-accent;
    .ant-switch-inner {
      @apply ml-0;
    }
  }

  .ant-switch:not(.ant-switch-checked) {
    @apply bg-neutral-background-medium;
    .ant-switch-inner {
      @apply ml-0;
    }
    .ant-switch-handle::before {
      @apply ml-2;
    }
  }

  .ant-switch .ant-switch-inner .ant-switch-inner-unchecked,
  .ant-switch .ant-switch-inner .ant-switch-inner-checked {
    @apply text-xs flex items-center;
  }

  .ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
    @apply mt-0;
  }

  .ant-switch-inner {
    @apply inline-flex text-xs h-full leading-5 ps-0 pe-0;
    transform: scaleY(1.1);
  }
}
</style>

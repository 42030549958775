<template>
  <div
    class="bitts-segment"
    :class="{
      'opacity-50': props.disabled,
      'bitts-segment__segmented-control': isSegmentedControl,
    }"
  >
    <div
      v-for="(
        { disabled: tabDisabled = false, description, title, count, id, icon },
        idx
      ) in props.tabs"
      :key="id"
      :class="{
        active: idx === activeInitialOrProp,
        'cursor-not-allowed': props.disabled || tabDisabled,
        'opacity-50': props.disabled || tabDisabled,
        'justify-center': isSegmentedControl && !description,
      }"
      class="bitts-segment__tab"
      data-testid="segment-tab"
      @click="toggleSegment(id, idx, tabDisabled)"
    >
      <slot :id="id" name="pre-title" />
      <span
        :class="{ 'mr-4': !icon }"
        data-testid="segment-title"
        class="flex items-center"
      >
        <FontAwesomeIcon
          v-if="icon"
          :icon="icon"
          :class="{
            'mr-8': !!title && !description,
            'h-16': !description,
            'h-24 w-24 mr-12': !!description,
          }"
          class="bitts-segment__icon"
        />
        <div class="flex flex-col">
          <span>
            {{ title }}
          </span>
          <span class="bitts-segment__description">
            {{ description }}
          </span>
        </div>
      </span>
      <slot :id="id" name="post-title" />
      <BittsCount
        v-if="(count || count === 0) && !isSegmentedControl"
        :count="count"
        :disabled="idx !== activeInitialOrProp"
        :show-zero="true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed, ref } from 'vue';

import BittsCount from '../BittsCount/BittsCount.vue';

const emit = defineEmits(['change:segment']);

const SEGMENTED_CONTROL = 'segmented_control';

export interface Tab {
  disabled?: boolean;
  description?: string;
  title: string;
  count?: number | null;
  id: string;
  icon?: string | null;
}

export interface Props {
  tabs?: Tab[];
  initialActiveIndex?: number;
  disabled?: boolean;
  variant?: string;
  activeIndex?: number | null;
}

const props = withDefaults(defineProps<Props>(), {
  tabs: () => [],
  initialActiveIndex: 0,
  disabled: false,
  variant: 'segment',
  activeIndex: null,
});

const activeIdx = ref(props.initialActiveIndex);
const activeInitialOrProp = computed(
  () => props.activeIndex ?? activeIdx.value,
);
const isSegmentedControl = computed(() => props.variant === SEGMENTED_CONTROL);

const toggleSegment = (id: string, idx: number, tabDisabled: boolean) => {
  if (props.disabled || tabDisabled) return;
  if (props.activeIndex === null) {
    activeIdx.value = idx;
  }
  emit('change:segment', id, idx);
};

defineExpose({ toggleSegment });
</script>

<style lang="pcss" scoped>
.bitts-segment {
  @apply text-neutral-text-weak bg-white md:bg-neutral-background rounded-6 inline-flex text-base transition-opacity flex-wrap gap-4 md:gap-0 md:flex-nowrap;

  .bitts-segment__tab {
    @apply flex items-center cursor-pointer rounded-6 px-4 py-2 md:px-8 md:py-4
    whitespace-nowrap border border-neutral-background font-bold bg-neutral-background;
    &:hover {
      @apply text-neutral-text-strong;
    }
  }

  .bitts-count {
    @apply relative;
  }

  .bitts-segment__description {
    @apply font-normal text-neutral-text-weak text-sm;
  }

  .bitts-segment__icon {
    @apply text-neutral-accent;
  }

  .active {
    @apply bg-white border border-neutral-border text-neutral-text-strong;
    .bitts-segment__icon {
      @apply text-info-accent;
    }
    .bitts-segment__description {
      @apply text-neutral-text;
    }
  }
}

.bitts-segment__segmented-control {
  @apply rounded-bts-lg w-full md:flex-nowrap;
  .bitts-segment__tab {
    @apply m-4 py-8 px-16 rounded-[12px] w-full whitespace-normal;
    &:hover {
      @apply text-neutral-text-strong;
      .bitts-segment__description {
        @apply text-neutral-text;
      }
    }
  }
}
</style>

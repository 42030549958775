<template>
  <div class="bitts-form-label">
    <div class="flex">
      <label
        :class="{
          'opacity-50': disabled,
        }"
        class="bitts-form-label__text"
      >
        {{ labelText }}
      </label>
      <BittsTooltip
        v-if="formLabel?.helpText"
        :button-text="formLabel?.ctaText"
        :include-cta="!!formLabel?.ctaText"
        :learn-more-link="formLabel?.ctaLink"
        :placement="formLabel?.placement"
      >
        <div class="leading-4">
          <FontAwesomeIcon
            :icon="['far', 'info-circle']"
            :style="{ height: '12px', width: '12px', color: 'currentColor' }"
            class="text-neutral-text-button"
          />
        </div>
        <template #title>
          {{ formLabel?.helpText }}
        </template>
      </BittsTooltip>
    </div>
    <span
      v-if="formLabel?.secondaryText"
      class="text-neutral-text-weak text-right text-sm"
    >
      {{ formLabel.secondaryText }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

import BittsTooltip from '../BittsTooltip/BittsTooltip.vue';

import { FormLabelType } from './types';

export type Props = {
  label: FormLabelType | string;
  disabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
});

const formLabel = props.label as FormLabelType;
const labelText = computed(() =>
  typeof props.label === 'string' ? props.label : props.label?.title,
);
</script>

<style lang="pcss" scoped>
.bitts-form-label {
  @apply flex justify-between items-center mb-6;
}
.bitts-form-label__text {
  @apply text-neutral-text-strong font-bold text-sm mr-4;
}
</style>

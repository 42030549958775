<template>
  <div class="c-found-your-team">
    <h1>We found your team!</h1>
    <p class="overview">
      Based on your email address, it looks like your company is already on
      Crossbeam. Request access to be approved by one of your team’s admins.
    </p>
    <BittsCallout
      v-if="rejectedRequest"
      data-testid="rejected-request-callout"
      type="danger"
      :title="`Your last request was rejected ${rejectedRequestDate}`"
      subtitle="Request access again to join your team on Crossbeam"
      class="mb-16"
    />
    <BittsCard class="p-24" :no-padding="false">
      <div class="flex justify-between">
        <div class="flex gap-12">
          <img
            v-if="logo"
            :src="logo"
            :alt="companyName"
            class="c-company-logo__img"
          />
          <div>
            <div class="company-name">
              {{ companyName }}
            </div>
            <div class="company-domain">
              {{ clearbitDomain }}
            </div>
          </div>
        </div>
        <BittsTag color="info" variant="rounded"> Recommended </BittsTag>
      </div>
      <BittsTextArea
        v-model="message"
        data-testid="seat-request-message-input"
        :form-label="{ title: 'Message to Admins', secondaryText: textCount }"
        class="my-24"
        :status="messageTooLong ? 'danger' : 'default'"
        danger-text="Your message may not exceed 200 characters"
        placeholder="Add a message to your request (you'll be more likely to be added)"
      />
      <BittsButton
        data-testid="request-to-join-button"
        class="w-full"
        :loading="loading || sendingRequest"
        :disabled="messageTooLong || loading || sendingRequest"
        text="Request to join"
        @click="handleSendSeatRequest"
      />
    </BittsCard>
    <p class="not-your-team">
      Not your team?
      <button class="action-button" @click="emit('reject-suggestion')">
        Register a new company
      </button>
      or
      <button class="action-button" @click="contactSupport">
        contact our support team
      </button>
    </p>
    <BittsButton
      class="flex sm:hidden w-full"
      variant="outline"
      type="neutral"
      text="Log out"
      :right-icon="['far', 'arrow-right-from-bracket']"
      @click="handleLogout"
    />
  </div>
</template>
<script setup>
import {
  BittsButton,
  BittsCallout,
  BittsCard,
  BittsTag,
  BittsTextArea,
} from '@crossbeam/bitts';

import axios from 'axios';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import { CORE } from '@/constants/team';
import { captureException } from '@/errors';
import { useFlashesStore } from '@/stores';
import { useRootStore } from '@/stores/RootStore';
import urls from '@/urls';
import { intercomOrEmailMessage } from '@/utils';

const emit = defineEmits(['reject-suggestion']);

const props = defineProps({
  companyName: {
    type: String,
    required: true,
  },
  logo: {
    type: String,
    required: true,
  },
  clearbitDomain: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();
const flashesStore = useFlashesStore();
const rootStore = useRootStore();

const { seatRequests } = storeToRefs(rootStore);

const message = ref('');
const textCount = computed(() => `${message.value.length}/200 characters`);
const messageTooLong = computed(() => message.value.length > 200);

function contactSupport() {
  intercomOrEmailMessage(
    'support@crossbeam.com',
    'Registration Support',
    `Hello, I've been recommended to join ${props.companyName}, but this is not my company`,
  );
}

const rejectedRequest = computed(() =>
  seatRequests.value.find((r) => r.status === 'rejected'),
);
const rejectedRequestDate = computed(() =>
  DateTime.fromISO(rejectedRequest.value?.created_at).toFormat('DD'),
);

const sendingRequest = ref(false);
async function handleSendSeatRequest() {
  try {
    sendingRequest.value = true;
    const { data } = await axios.post(urls.seatRequests.create, {
      type: CORE,
      message: message.value,
    });
    await axios.post(urls.inviteRequests.notify(data.id)); // Notify admins of the access request
    router.go(); // After the request is created, router guards will send us to 'invite-requests' with the UUID
  } catch (err) {
    flashesStore.addErrorFlash({
      message: 'Something went wrong creating your seat request',
    });
    captureException(err);
  } finally {
    sendingRequest.value = false;
  }
}

async function handleLogout() {
  await router.push({ name: 'logout' });
}
</script>
<style lang="pcss" scoped>
.c-found-your-team {
  @apply max-w-[600px] mx-16;
  h1 {
    @apply text-neutral-text-strong text-xl text-center mb-16 font-bold;
  }

  p.overview {
    @apply text-neutral-text-weak text-m text-center mb-40;
  }

  .c-company-logo__img {
    @apply mb-16 h-40 w-40 rounded-8 border-neutral-border border;
  }

  .company-name {
    @apply text-neutral-text-strong text-m font-bold;
  }

  .company-domain {
    @apply text-neutral-text text-base;
  }

  .not-your-team {
    @apply mt-24 mb-40 sm:mb-0 sm:mt-40 text-neutral-text-weak text-center;
    .action-button {
      @apply text-secondary-text;
      &:hover {
        @apply opacity-80;
      }
    }
  }
}
</style>

<template>
  <div class="c-google-sheets-form">
    <BittsTabs
      class="mt-12"
      :current-value="activeTab"
      :tabs="tabs"
      @change:tab="(val) => (activeTab = val)"
    >
      <template #labelPrefix="{ icon }">
        <FontAwesomeIcon :icon="icon" />
      </template>
    </BittsTabs>
    <loading class="c-google-sheets-form__form-content" :is-loading="loading">
      <div v-if="activeTab === ADD_SHEET_TAB">
        <BittsAlert
          v-if="submissionErrors && submissionErrors.length"
          data-testid="submission-error"
          class="py-8 w-full mb-12"
          color="error"
          message="We are having trouble with this sheet"
        >
          <template #body>
            <div
              v-if="submissionErrors.length === 1"
              class="gs-connection-error-item"
            >
              {{ submissionErrors[0] }}
            </div>
            <ul v-else class="list-disc ml-30">
              <li
                v-for="(error, index) in submissionErrors"
                :key="index"
                class="gs-connection-error-item"
              >
                {{ error }}
              </li>
            </ul>
          </template>
        </BittsAlert>
        <BittsInput
          v-model.trim="sheetsURL"
          form-label="Google Sheet URL"
          placeholder="docs.google.com/spreadsheets/12345"
          data-testid="sheet-url-input"
          name="Google Sheet URL"
          class="mb-8"
          :status="
            sheetsURL && v$.sheetsURL.$silentErrors.length
              ? 'danger'
              : 'default'
          "
          :danger-text="v$.sheetsURL.$silentErrors?.at(-1)?.$message || ''"
          @blur="getSpreadSheetDetails"
        >
          <template #suffix>
            <FontAwesomeIcon
              v-if="sheetNamesLoading"
              :icon="['fa', 'spinner']"
              :style="{ height: '18px', width: '18px', color: 'currentColor' }"
              class="text-neutral-border mr-8 animate-spin"
            />
          </template>
        </BittsInput>
        <div class="c-google-sheets-form__row--note mt-8">
          This sheet needs to be shared with
          <span class="font-bold">{{ feed.external_user_email }}</span>
        </div>
        <div>
          <VuelidateWrapper property="selectedSheetName" :errors="v$.$errors">
            <BittsSelect
              v-model="selectedSheetName"
              data-testid="sheet-selector"
              :options="sheetNames"
              :disabled="!sheetNames.length"
              :loading="sheetNamesLoading"
              placeholder="Select Sheet"
              form-label="Which sheet do you want to use?"
              class="mt-24"
              @update:model-value="setSelectedSheetName"
            />
          </VuelidateWrapper>
          <div class="mt-8">
            <span class="c-google-sheets-form__row--note">
              This sheet name will be shared with partners when you share data
            </span>
          </div>
        </div>
        <BittsRadioGroupCards
          class="mt-24"
          form-label="What type of data is this?"
          :options="sheetTypes"
          :initial-value="selectedUploadType"
          @change="selectedUploadType = $event"
        />
      </div>
      <div v-else>
        <div>
          <BittsAlert
            :message="connectSuccessMessage"
            :raw-html="true"
            class="mb-25 py-8"
            color="success"
          />
          <div v-if="showAccountFields">
            <VuelidateWrapper property="selectedNameField" :errors="v$.$errors">
              <BittsSelect
                v-model="selectedNameField"
                data-testid="name-selector"
                :options="availableColumns"
                placeholder="Select Sheet"
                form-label="Select Company Name Column"
              />
            </VuelidateWrapper>
          </div>
        </div>
        <div v-if="showAccountFields" class="mt-24">
          <BittsSelect
            v-model="selectedWebsiteField"
            data-testid="website-selector"
            :form-label="{ title: 'Website', secondaryText: 'Recommended' }"
            :options="availableColumns"
            placeholder="Select Company Website Column"
          />
        </div>
        <div v-if="showLeadFields">
          <span class="c-google-sheets-form__subtitle">
            Which column contains values for
            <b>Email</b>?
          </span>
          <VuelidateWrapper property="selectedEmailField" :errors="v$.$errors">
            <BittsSelect
              v-model="selectedEmailField"
              :options="availableColumns"
              placeholder="Select Email Column"
            />
          </VuelidateWrapper>
        </div>
        <div class="c-google-sheets-form__ae-mapping">
          <div class="flex items-center justify-between">
            <div class="flex-1">
              <div
                class="text-neutral-text-strong text-base font-bold map-ae-columns-modal__option"
              >
                <span>Map {{ ACCOUNT_OWNER_DISPLAY }} Columns</span>
              </div>
              <p class="text-sm">
                This is optional and you can map columns later
              </p>
            </div>
            <div class="flex flex-col items-center gap-4 min-w-[60px]">
              <label
                for="show-ae-fields-toggle"
                class="text-neutral-text-weak text-sm block"
              >
                Optional
              </label>
              <BittsSwitch
                id="show-ae-fields-toggle"
                :is-checked="showAeFields"
                :use-is-checked-prop="true"
                @change="toggleAeMapping"
              />
            </div>
          </div>
          <BittsAlert
            v-if="showAeFields"
            data-testid="ae-mapping-warning"
            color="warning"
            message="You can only map columns one time so double-check your selections"
          />
          <template v-if="showAeFields">
            <div class="c-file-upload-form__row--no-margin">
              <div class="flex">
                <span class="c-file-upload-form__subtitle">
                  Which column contains
                  <b>{{ ACCOUNT_OWNER_EMAIL_DISPLAY }}</b
                  >?
                </span>
              </div>
              <VuelidateWrapper
                property="selectedAEEmailField"
                :errors="v$.$errors"
              >
                <BittsSelect
                  ref="ae-email-selector"
                  v-model="selectedAEEmailField"
                  data-testid="ae-email-selector"
                  :options="availableColumns"
                  :placeholder="
                    selectedAEEmailField
                      ? selectedAEEmailField
                      : `Select ${ACCOUNT_OWNER_EMAIL_DISPLAY} Column`
                  "
                  :allow-clear="true"
                />
              </VuelidateWrapper>
            </div>
            <div class="c-file-upload-form__row--no-margin">
              <div class="flex">
                <span class="c-file-upload-form__subtitle">
                  Which column contains
                  <b>{{ ACCOUNT_OWNER_NAME_DISPLAY }}</b
                  >?
                </span>
                <span
                  class="c-file-upload-form__subtitle ml-auto text-neutral-400"
                >
                  Optional
                </span>
              </div>
              <BittsSelect
                v-model="selectedAENameField"
                :disabled="requiredAeEmailMissing"
                :options="availableColumns"
                :placeholder="
                  selectedAENameField
                    ? selectedAENameField
                    : `Select ${ACCOUNT_OWNER_NAME_DISPLAY} Column`
                "
                :allow-clear="true"
              />
            </div>
            <div class="c-file-upload-form__row--no-margin">
              <div class="flex">
                <span class="c-file-upload-form__subtitle">
                  Which column contains
                  <b>{{ ACCOUNT_OWNER_PHONE_DISPLAY }}</b
                  >?
                </span>
                <span
                  class="c-file-upload-form__subtitle ml-auto text-neutral-400"
                >
                  Optional
                </span>
              </div>
              <BittsSelect
                v-model="selectedAEPhoneField"
                :disabled="requiredAeEmailMissing"
                :options="availableColumns"
                :placeholder="
                  selectedAEPhoneField
                    ? selectedAEPhoneField
                    : `Select ${ACCOUNT_OWNER_PHONE_DISPLAY} Column`
                "
                :allow-clear="true"
              />
            </div>
          </template>
        </div>
      </div>
    </loading>
    <div class="mb-40 mx-24">
      <BittsAlert
        v-if="showRecordLimitError"
        message="Can't add this sheet. It will put you over your Google Sheets row limit"
        :description="recordLimitErrorMessage"
        color="error"
      />
      <BittsAlert
        v-else-if="showRecordLimitWarning"
        :message="recordLimitWarningMessage"
        :description="recordLimitWarningDescription"
        color="warning"
      />
    </div>
    <div class="c-google-sheets-form__footer">
      <BittsButton
        text="Cancel"
        type="neutral"
        variant="outline"
        size="large"
        @click="cancel"
      />
      <BittsButton
        v-if="activeTab === ADD_SHEET_TAB"
        ref="next-button"
        :disabled="tabIncomplete || showRecordLimitError"
        data-testid="next-button"
        text="Next"
        size="large"
        @click="nextTab"
      />
      <BittsButton
        v-else
        ref="add-google-sheet-button"
        :disabled="showRecordLimitError || requiredAeEmailMissing"
        text="Add Google Sheet"
        data-testid="add-google-sheet-button"
        size="large"
        @click="addGoogleSheet"
      />
    </div>
  </div>
</template>

<script>
import {
  BittsAlert,
  BittsButton,
  BittsInput,
  BittsRadioGroupCards,
  BittsSelect,
  BittsSwitch,
  BittsTabs,
} from '@crossbeam/bitts';

import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import axios from 'axios';
import Humanize from 'humanize-plus';
import { mapActions } from 'pinia';

import Loading from '@/components/Loading.vue';
import VuelidateWrapper from '@/components/VuelidateWrapper.vue';

import { EVENT_SITES } from '@/constants/analytics';
import {
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  RECORD_LIMIT_THRESHOLD,
} from '@/constants/data_sources';
import {
  ACCOUNT_OWNER_DISPLAY,
  ACCOUNT_OWNER_EMAIL_DISPLAY,
  ACCOUNT_OWNER_NAME_DISPLAY,
  ACCOUNT_OWNER_PHONE_DISPLAY,
  AO_DISPLAY,
  MDM_PROPERTIES,
} from '@/constants/mdm';
import { useFeedsStore, useFlashesStore, useSourcesStore } from '@/stores';
import urls from '@/urls';

const ACCOUNT = 'account';
const LEAD = 'lead';

const isGoogleSheetUrl = (sheetsUrl) => sheetsUrl.includes('docs.google.com');

const ADD_SHEET_TAB = 'add_sheet_tab';
const MAP_COLUMNS_TAB = 'map_columns_tab';

export default {
  name: 'GoogleSheetsForm',
  components: {
    Loading,
    VuelidateWrapper,
    BittsAlert,
    BittsButton,
    BittsInput,
    BittsTabs,
    BittsSwitch,
    BittsSelect,
    BittsRadioGroupCards,
  },
  emits: ['saved', 'cancel'],
  setup() {
    const sourcesStore = useSourcesStore();
    return {
      ACCOUNT_OWNER_EMAIL_DISPLAY,
      ACCOUNT_OWNER_NAME_DISPLAY,
      ACCOUNT_OWNER_PHONE_DISPLAY,
      ACCOUNT_OWNER_DISPLAY,
      AO_DISPLAY,
      sourcesStore,
    };
  },
  data() {
    return {
      ADD_SHEET_TAB,
      MAP_COLUMNS_TAB,
      activeTab: ADD_SHEET_TAB,
      v$: useVuelidate(),
      loading: false,
      feed: {},
      newRowsCount: 0,
      sheetsURL: '',
      sheetName: null,
      submissionErrors: null,
      selectedNameField: null,
      selectedWebsiteField: null,
      selectedEmailField: null,
      selectedUploadType: ACCOUNT,
      sheets: [],
      columns: [],
      successfulSheetConnection: false,
      sheetNamesLoading: false,
      selectedSheetName: null,
      showAeFields: false,
      selectedAENameField: null,
      selectedAEEmailField: null,
      selectedAEPhoneField: null,
    };
  },
  validations() {
    return {
      sheetsURL: {
        required: helpers.withMessage(
          'A google sheet URL is required',
          required,
        ),
        isGoogleSheetUrl: helpers.withMessage(
          'URL must contain docs.google.com',
          isGoogleSheetUrl,
        ),
      },
      selectedNameField: {
        required: helpers.withMessage(
          'Please choose a column for company name',
          requiredIf(() => {
            return this.selectedUploadType === ACCOUNT;
          }),
        ),
      },
      selectedSheetName: {
        required: helpers.withMessage(
          'Please choose a sheet name',
          requiredIf(() => {
            return (
              this.selectedUploadType === ACCOUNT && this.sheetNames.length > 0
            );
          }),
        ),
      },
      selectedEmailField: {
        required: helpers.withMessage(
          'Please choose a column for email',
          requiredIf(() => {
            return this.selectedUploadType === LEAD;
          }),
        ),
      },
      selectedAEEmailField: {
        required: helpers.withMessage(
          `Please choose an option when any other ${this.ACCOUNT_OWNER_DISPLAY} field is mapped`,
          requiredIf(() => {
            return this.selectedAENameField || this.selectedAEPhoneField;
          }),
        ),
      },
    };
  },
  computed: {
    tabs() {
      return [
        {
          name: 'Add Sheet',
          value: ADD_SHEET_TAB,
          icon: ['far', 'circle-1'],
        },
        {
          name: 'Map Columns',
          value: MAP_COLUMNS_TAB,
          disabled: !this.tab1complete,
          icon: ['far', 'circle-2'],
        },
      ];
    },
    selectedColumns() {
      return [
        this.selectedNameField,
        this.selectedWebsiteField,
        this.selectedEmailField,
        this.selectedAEEmailField,
        this.selectedAENameField,
        this.selectedAEPhoneField,
      ];
    },
    sheetNames() {
      return this.sheets.map((sheet) => {
        return { label: sheet.sheet_name, value: sheet.sheet_name };
      });
    },
    availableColumns() {
      return this.columns
        .filter((col) => !this.selectedColumns.includes(col))
        .sort()
        .map((col) => {
          return { label: col, value: col };
        });
    },
    tabIncomplete() {
      if (this.activeTab === ADD_SHEET_TAB) {
        return !this.sheetsURL || !this.selectedSheetName;
      }
      return !this.tab2complete;
    },
    showAccountFields() {
      return this.selectedUploadType === ACCOUNT;
    },
    showLeadFields() {
      return this.selectedUploadType === LEAD;
    },
    tab1complete() {
      if (!this.v$.sheetsURL.$invalid) {
        return this.selectedSheetName;
      }
      return '';
    },
    sheetTypes() {
      return [
        {
          value: ACCOUNT,
          label: 'Companies',
        },
        {
          value: LEAD,
          label: 'People',
        },
      ];
    },
    tab2complete() {
      if (this.showAccountFields) {
        return this.selectedNameField && this.selectedWebsiteField;
      }
      return this.selectedEmailField;
    },
    disabledTabs() {
      return !this.tab1complete ? [2] : [];
    },
    connectSuccessMessage() {
      return `Successfully connected to ${this.spreadsheetName} (${this.sheetName})`;
    },
    stepTab1() {
      return [
        {
          title: 'Add Sheet',
          status: this.tab1complete ? 'finish' : 'process',
        },
      ];
    },
    stepTab2() {
      let status = 'wait';
      if (this.tab1complete) {
        status = 'process';
      }
      if (this.tab2complete) {
        status = 'finish';
      }
      return [
        {
          status,
          title: 'Map Columns',
        },
      ];
    },
    requiredAeEmailMissing() {
      return this.showAeFields ? !this.selectedAEEmailField : false;
    },
    showRecordLimitError() {
      const nextTotal = this.feed.active_record_count + this.newRowsCount;
      return (
        !this.loading &&
        this.feed.integration?.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE &&
        this.feed.max_record_count &&
        (this.feed.active_record_count >= this.feed.max_record_count ||
          nextTotal > this.feed.max_record_count)
      );
    },
    recordLimitErrorMessage() {
      const nextTotal = this.feed.active_record_count + this.newRowsCount;
      const amountOverDisplay = Humanize.intComma(
        nextTotal - this.feed.max_record_count,
      );
      const limitDisplay = Humanize.intComma(this.feed.max_record_count);
      return `This sheet will put you over your ${limitDisplay}
        row limit across all Google Sheets by ${amountOverDisplay} rows.`;
    },
    showRecordLimitWarning() {
      const nextTotal = this.feed.active_record_count + this.newRowsCount;
      return (
        !this.loading &&
        this.feed.integration?.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE &&
        this.feed.max_record_count &&
        nextTotal >= this.feed.max_record_count - RECORD_LIMIT_THRESHOLD
      );
    },
    recordLimitWarningMessage() {
      const limit = Humanize.intComma(this.feed.max_record_count);
      return `You are about to reach your ${limit} row limit`;
    },
    recordLimitWarningDescription() {
      const nextTotal = this.feed.active_record_count + this.newRowsCount;
      const available = Humanize.intComma(
        this.feed.max_record_count - nextTotal,
      );
      return `After adding this sheet, you will have ${available} rows available.
              Delete rows in other Google sheets to reduce rows used`;
    },
  },
  async created() {
    const feedsStore = useFeedsStore();
    await Promise.all([feedsStore.readySync, this.sourcesStore.readySync]);
    this.feed = feedsStore.getFeedByDataSourceType('google_sheets');
  },
  methods: {
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapActions(useFeedsStore, ['refreshFeed']),
    getErrorText(err, defaultText) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.errors &&
        err.response.data.errors.length > 0
      ) {
        return err.response.data.errors[0];
      }
      return defaultText;
    },
    getSheetIdFromName(name) {
      const id = this.sheets.find(
        (sheet) => sheet.sheet_name === name,
      ).sheet_id;
      return id;
    },
    clearSelectedFields() {
      this.selectedNameField = null;
      this.selectedWebsiteField = null;
      this.selectedEmailField = null;
    },
    async getSpreadSheetDetails() {
      if (!this.sheetsURL) return;
      this.sheetNamesLoading = true;
      try {
        const response = await axios.get(
          urls.sources.googleSheetsNames(this.feed.id),
          { params: { sheet_url: this.sheetsURL } },
        );

        this.spreadsheetId = response.data.spreadsheet_id;
        this.spreadsheetName = response.data.spreadsheet_name;
        this.sheets = response.data.sheets;
        this.submissionErrors = null;
        this.successfulSheetConnection = true;
        this.columns = ['url', 'name', 'id', 'created'];
      } catch (err) {
        this.submissionErrors = err.response.data.errors;
        this.successfulSheetConnection = false;
      } finally {
        /* These need to be deselected when the google sheets URL changes */
        this.selectedSheetName = null;
        this.clearSelectedFields();
        this.sheetNamesLoading = false;
      }
    },
    getFields() {
      const accountLookup = {
        [MDM_PROPERTIES.account.NAME]: this.selectedNameField,
      };
      if (this.selectedWebsiteField) {
        accountLookup.company_website = this.selectedWebsiteField.trim();
      }
      const peopleLookup = { person_email: this.selectedEmailField };
      const columnsToSend =
        this.selectedUploadType === ACCOUNT ? accountLookup : peopleLookup;
      const fields = Object.entries(columnsToSend).reduce((acc, [key, val]) => {
        const columnObj = {
          mdm_property: key,
          column_name: val,
          is_primary_key: key === 'company_key' || key === 'person_key',
        };
        acc.push(columnObj);
        return acc;
      }, []);
      return fields;
    },
    getAEFields() {
      const selectedAEFields = [];
      function buildAEField(field, mdm) {
        return {
          mdm_property: mdm,
          column_name: field,
          is_primary_key: false,
        };
      }
      if (this.showAeFields && this.selectedAEEmailField) {
        selectedAEFields.push(
          buildAEField(this.selectedAEEmailField, 'person_email'),
        );
      }
      if (this.showAeFields && this.selectedAENameField) {
        selectedAEFields.push(
          buildAEField(this.selectedAENameField, 'person_name'),
        );
      }
      if (this.showAeFields && this.selectedAEPhoneField) {
        selectedAEFields.push(
          buildAEField(this.selectedAEPhoneField, 'person_phone'),
        );
      }

      return {
        mdm_type: 'user',
        feed_id: this.feed.id,
        spreadsheet_id: this.spreadsheetId,
        sheet_id: this.getSheetIdFromName(this.selectedSheetName),
        fields: selectedAEFields,
      };
    },
    findTabWithError(vuelidate) {
      // figures out which tab is causing the vuelidate error
      if (vuelidate.sheetsURL.$error) {
        return ADD_SHEET_TAB;
      }
      return MAP_COLUMNS_TAB;
    },
    async addGoogleSheet() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.activeTab = this.findTabWithError(this.v$);
        return;
      }

      this.loading = true;
      try {
        const { payload, url } = this.makePayloadAndUrl();
        const { data: response } = await axios.post(url, payload);

        this.$iteratively.userConnectedDataSource({
          event_site: EVENT_SITES.GOOGLE_SHEETS_MODAL,
          data_source_type: 'google_sheets',
          feed_id: response.feed_id.toString(),
          custom_presets: [],
          initial_sync_selection: '',
        });

        await this.sourcesStore.refreshSourcesStore();

        this.$emit('saved');
        const flashObject = {
          message: 'We have connected your Google Sheet.',
          description: 'This sheet is currently processing',
        };
        this.addSuccessFlash(flashObject);

        await this.refreshFeed(response.feed_id);
      } catch (err) {
        this.addErrorFlash({
          message: this.getErrorText(err, 'Failed to connect Google Sheet'),
        });
      } finally {
        this.loading = false;
        this.$router.push({ name: 'data-sources' });
      }
    },
    async getSheetDetails() {
      this.v$.sheetsURL.$touch();
      if (!this.v$.sheetsURL.$invalid) {
        this.loading = true;
        try {
          const resp = await axios.get(
            urls.sources.googleSheetsColumnsV2(this.feed.id),
            {
              params: {
                spreadsheet_id: this.spreadsheetId,
                sheet_id: this.getSheetIdFromName(this.selectedSheetName),
              },
            },
          );
          this.columns = resp.data.columns;
          this.sheetName = resp.data.sheet_name;
          this.spreadsheetName = resp.data.spreadsheet_name;
          this.newRowsCount = resp.data.row_count;
          this.submissionErrors = null;
          this.successfulSheetConnection = true;
        } catch (err) {
          this.submissionErrors = err.response.data.errors;
          this.successfulSheetConnection = false;
        } finally {
          this.loading = false;
        }
      }
    },
    nextTab() {
      this.activeTab = MAP_COLUMNS_TAB;
    },
    cancel() {
      this.$emit('cancel');
    },
    activeTabChanged({ newTab }) {
      if (newTab === 2) this.nextTab();
      else {
        this.newRowsCount = 0;
        this.activeTab = ADD_SHEET_TAB;
      }
    },
    async setSelectedSheetName(sheet) {
      if (typeof sheet !== 'string') return;
      this.selectedSheetName = sheet;
      await this.getSheetDetails();
    },
    makePayloadAndUrl() {
      const url = urls.sources.allV3;
      const baseData = {
        mdm_type: this.selectedUploadType,
        feed_id: this.feed.id,
        spreadsheet_id: this.spreadsheetId,
        sheet_id: this.getSheetIdFromName(this.selectedSheetName),
        fields: this.getFields(),
      };

      const payload = [baseData];
      if (this.showAeFields) {
        payload.push(
          this.getAEFields(),
        ); /* They are actually mapping fields, include these in the request */
      }

      return { payload, url };
    },
    toggleAeMapping() {
      this.selectedAENameField = null;
      this.selectedAEEmailField = null;
      this.selectedAEPhoneField = null;
      this.showAeFields = !this.showAeFields;
    },
  },
};
</script>

<style lang="pcss">
.c-google-sheets-form {
  .ant-tabs-nav {
    @apply mb-0;
  }
  .ant-tabs-nav-list {
    @apply w-full mt-12;
  }
  .ant-tabs-tab {
    @apply flex justify-center;
  }
}

.c-google-sheets-form__form-content {
  @apply mx-24 my-24;
}

.c-google-sheets-form__footer {
  @apply flex justify-between gap-16 mx-24 pb-24;
}

.c-google-sheets-form__row--note {
  @apply text-neutral-500 text-sm;
}

.c-google-sheets-form__subtitle {
  @apply text-neutral-text-strong text-sm inline-block mb-8;
}

.google-sheets-form__tip {
  @apply text-neutral-500 text-sm mt-8;
}

.c-google-sheets-form__ae-mapping {
  @apply mt-16 p-16 flex flex-col gap-16 bg-neutral-100 border border-neutral-300 rounded-8;
}

.c-google-sheets-form .bitts-select .ant-select-disabled .ant-select-selection {
  @apply bg-neutral-border border border-neutral-400;
}

.map-ae-columns-modal__option {
  @apply flex gap-8;
}

.c-google-sheets-form {
  .bitts-simple-tabs__disabled {
    @apply opacity-50;
  }
}
</style>

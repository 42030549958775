import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import { LIMIT_REACHED, OVER_75, OVER_90 } from '@/constants/billing';
import { useBillingStore } from '@/stores';
import urls from '@/urls';

export const RECORD_EXPORT_ALERTS = {
  [OVER_90]: {
    type: OVER_90,
    buttonIcon: ['fad', 'wand-magic-sparkles'],
    buttonText: 'Talk to Sales',
    message:
      'Your organization has exceeded 90% of its record export limit. Contact us to increase the number of record exports',
    dismissable: true,
  },
  [LIMIT_REACHED]: {
    type: LIMIT_REACHED,
    buttonIcon: ['fad', 'wand-magic-sparkles'],
    buttonText: 'Talk to Sales',
    message:
      'Your organization has exceeded 100% of its record export limit. Contact us to increase the number of record exports',
    dismissable: true,
  },
};

export default function useRecordExportLimits() {
  const NINETY_PERCENT = 0.9;
  const SEVENTY_FIVE_PERCENT = 0.75;

  const billingStore = useBillingStore();
  const { recordExportLimit } = storeToRefs(billingStore);

  const exportLimit = ref(recordExportLimit.value);
  const recordsExported = ref(null);
  const endDate = ref(null);

  const fetchRecordsExported = async function () {
    const { data } = await axios.get(urls.recordExportUsage);
    endDate.value = data.record_export_term_end_date;
    recordsExported.value = data.consumed_quota;
  };

  if (recordExportLimit.value) fetchRecordsExported();

  const limitUsedDecimal = computed(() => {
    if (!exportLimit.value) return 0;
    return (
      Math.min(recordsExported.value, exportLimit.value) / exportLimit.value
    );
  });
  const limitUsedPercent = computed(() => {
    const percent = Math.round(limitUsedDecimal.value * 100);
    return `${percent}%`;
  });

  const exportLimitStatus = computed(() => {
    if (limitUsedDecimal.value >= 1) return LIMIT_REACHED;
    if (limitUsedDecimal.value >= NINETY_PERCENT) return OVER_90;
    if (limitUsedDecimal.value >= SEVENTY_FIVE_PERCENT) return OVER_75;
    return null;
  });

  const progressBarMessage = computed(() => {
    let roundedPercent = Math.round(limitUsedDecimal.value * 100);
    // edge case rounding 99% to 100% but the exported count is still lower than the limit
    if (roundedPercent === 100 && exportLimitStatus.value !== LIMIT_REACHED)
      roundedPercent = 99;
    if (exportLimitStatus.value === OVER_90)
      return `Reached ${roundedPercent}% of limit`;
    if (exportLimitStatus.value === LIMIT_REACHED)
      return 'Exceeded 100% of limit';
    return 'Records exported';
  });

  return {
    endDate,
    exportLimit,
    exportLimitStatus,
    limitUsedDecimal,
    limitUsedPercent,
    RECORD_EXPORT_ALERTS,
    recordsExported,
    progressBarMessage,
  };
}

<template>
  <div
    ref="bittsRadioGroupCardsRef"
    class="bitts-radio-group-cards"
    :class="{
      horizontal: orientation === 'horizontal',
    }"
  >
    <BittsFormLabel
      v-if="formLabel"
      class="bitts-radio-group-cards__label"
      :label="formLabel"
      :disabled="disabled"
    />
    <radio-group
      :disabled="disabled"
      :value="initialValue"
      class="w-full"
      @change="onChange"
    >
      <component
        :is="radioWrapperComponent(option)"
        v-for="(option, i) in options"
        :key="option.value"
        class="bitts-radio-group-cards__option"
        :placement="orientation === 'horizontal' ? 'top' : 'right'"
        :mount-to-body="true"
        trigger="hover"
        overlay-class="bitts-radio-group-tooltip"
      >
        <BittsRadio :option="option" :options="options" :index="i">
          <template #label>
            <slot name="label" :option="option">
              {{ option.label }}
            </slot>
          </template>
          <template #right>
            <slot name="right" :option="option" />
          </template>
        </BittsRadio>
        <template v-if="option.tooltip" #title>
          <slot name="tooltip" :option="option">
            {{ option.tooltip }}
          </slot>
        </template>
        <template v-else-if="option.popover" #content>
          <slot name="popover" :option="option">
            {{ option.popover }}
          </slot>
        </template>
      </component>
    </radio-group>
  </div>
</template>

<script setup>
import { Radio } from 'ant-design-vue';
import { onMounted, onUnmounted, ref, watch } from 'vue';

import BittsFormLabel from '../BittsFormLabel/BittsFormLabel.vue';
import BittsPopover from '../BittsPopover/BittsPopover.vue';
import BittsRadio from '../BittsRadio/BittsRadio.vue';
import BittsTooltip from '../BittsTooltip/BittsTooltip.vue';

const RadioGroup = Radio.Group;

const emit = defineEmits(['change']);
const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  initialValue: {
    type: [String, Boolean],
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  orientation: {
    type: String,
    default: 'vertical',
    validator: (value) => ['horizontal', 'vertical'].includes(value),
  },
  formLabel: {
    type: [Object, String],
    default: '',
  },
});

const value = ref(props.initialValue);

watch(value, (newValue) => {
  value.value = newValue;
});

function onChange(e) {
  emit('change', e.target.value);
}

function radioWrapperComponent(option) {
  if (option.tooltip) return BittsTooltip;
  if (option.popover) return BittsPopover;
  return 'div';
}

/* We can't control the background color of a wrapper div in Bitts
when an ANT DOM element gets focus without vanilla Javascript.
I'm initializing some event listeners that will manually attach
the right styles when the focus changes. */
const bittsRadioGroupCardsRef = ref(null);
let elements;

const setOrLoseFocus = (focus) => (el) => {
  const parent = el.target?.closest('.ant-radio-wrapper');
  if (!parent) return;
  if (focus) parent.classList.add('bg-neutral-50');
  else parent.classList.remove('bg-neutral-50');
};

const blurHandler = setOrLoseFocus(false);
const focusHandler = setOrLoseFocus(true);
onMounted(() => {
  elements = bittsRadioGroupCardsRef.value.querySelectorAll('input');
  for (const el of Array.from(elements)) {
    el.addEventListener('focus', focusHandler);
    el.addEventListener('blur', blurHandler);
  }
});

onUnmounted(() => {
  for (const el of Array.from(elements)) {
    el.removeEventListener('focus', focusHandler);
    el.removeEventListener('blur', blurHandler);
  }
});
</script>

<style lang="pcss">
.bitts-radio-group-cards__label {
  @apply text-neutral-text-strong text-base h-24;
}

.bitts-radio-group-cards {
  .ant-radio-group {
    font-family: inherit;
  }
  .c-bitts-radio {
    &:hover:not(.ant-radio-wrapper-disabled) {
      @apply bg-neutral-50;
    }
  }

  .ant-radio-wrapper {
    @apply flex items-center py-8 px-16 mr-0 border-neutral-border border-solid mb-16;
    font-family: inherit;
    /* The "right" slot */
    > span:last-of-type {
      @apply flex items-center justify-between w-full;
    }
  }

  .ant-radio-wrapper .ant-radio-inner::after {
    @apply h-[22px] w-[22px] ms-[-11px];
    margin-block-start: -11px;
  }

  .bitts-radio-group-cards__option-label {
    @apply text-neutral-text;
  }

  .bitts-radio-group-cards__option {
    @apply flex-1;
  }

  .bitts-radio-group-cards__description {
    @apply text-neutral-text text-sm;
  }

  .ant-radio-wrapper {
    @apply mb-0 border border-b-0;
  }

  .ant-radio-wrapper.first {
    @apply rounded-t-lg border-t;
  }

  .ant-radio-wrapper.last {
    @apply border-b rounded-b-lg;
  }

  .ant-radio {
    @apply mr-8;
  }
}

/* Horizontal alignment */
.bitts-radio-group-cards.horizontal {
  .ant-radio-group-outline {
    @apply flex rounded-l-lg;

    .ant-radio-wrapper {
      @apply border-t border-b h-full;
    }
    .ant-radio-wrapper.first {
      @apply rounded-l-lg rounded-r-none border-r-0;
    }
    .ant-radio-wrapper.last {
      @apply rounded-r-lg rounded-l-none;
    }
    .ant-radio-wrapper.only {
      @apply rounded-lg border;
    }
  }
}
</style>

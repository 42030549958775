<template>
  <BittsCallout
    v-if="showCallout"
    :title="title"
    :subtitle="subtitle"
    data-testid="onboarding-callout"
    size="small"
    type="info"
  >
    <template #left-item>
      <BittsSvg svg="pamFace" class="h-40 w-40 mr-16" />
    </template>
    <template #right-item>
      <BittsButton
        variant="outline"
        type="primary"
        :left-icon="['fas', 'circle-play']"
        text="Watch a video"
        class="ml-auto"
        size="small"
        @click="routeToVideo"
      />
      <BittsButton
        :center-icon="['fas', 'x']"
        size="x-small"
        class="ml-16"
        type="neutral"
        variant="ghost"
        @click="handleCloseCallout"
      />
    </template>
  </BittsCallout>
</template>

<script setup>
import { BittsButton, BittsCallout, BittsSvg } from '@crossbeam/bitts';

import { computed, ref } from 'vue';

import { ls } from '@/local_storage';

const DATA_SOURCES = 'data_sources';
const POPS = 'populations';
const OFFLINE_PARTNER_DATA = 'offline_partner_data';

const props = defineProps({
  kind: {
    type: String,
    required: true,
    validator(val) {
      return ['data_sources', 'populations', 'offline_partner_data'].includes(
        val,
      );
    },
  },
});

/* Blue Onboarding Helper Callout */
const calloutStates = ref(
  ls.onboardingCallouts.get() || {
    data_sources: true,
    populations: true,
    offline_partner_data: true,
  },
);
const showCallout = computed(() => calloutStates.value[props.kind]);

const TITLE_MAP = {
  [DATA_SOURCES]: 'Data sources are how you feed data into Crossbeam',
  [POPS]:
    'Populations are how you segment your data to share strategic accounts with partners',
  [OFFLINE_PARTNER_DATA]: 'You fully control the data for offline partners',
};

const DESCRIPTION_MAP = {
  [DATA_SOURCES]:
    'This is all the data valuable to you, not what is shared with partners. We will segment & share with partners later.',
  [POPS]:
    'You have full control over how these segments are defined and shared with each partner',
  [OFFLINE_PARTNER_DATA]:
    'Upload data on your partners behalf, it will only be shared with members of your team',
};

const VIDEO_MAP = {
  [DATA_SOURCES]:
    'https://www.loom.com/share/23ce02b7d4c24c02a549b67d8c14628b?sid=7cb4fc5e-1277-4222-b342-af3feb7827f0',
  [POPS]:
    'https://www.loom.com/share/a025b5e38fa041059c852d8e582a5c3e?sid=9e27086c-b36f-4dfd-bab2-de9c16ca655c',
  [OFFLINE_PARTNER_DATA]:
    'https://www.loom.com/share/3441ef989c174bcd8fa4f5fc878e9e9c?sid=d016d849-86b3-4929-bd9f-b8ada678a4b6',
};

const title = computed(() => TITLE_MAP[props.kind]);
const subtitle = computed(() => DESCRIPTION_MAP[props.kind]);

function routeToVideo() {
  const video = VIDEO_MAP[props.kind];
  window.open(video, '_blank');
}

function handleCloseCallout() {
  const newState = { ...calloutStates.value, [props.kind]: false };
  ls.onboardingCallouts.set(newState);
  calloutStates.value = newState;
}
</script>

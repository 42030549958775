<template>
  <div
    ref="bittsNumberRef"
    class="bitts-number"
    :class="{
      'cursor-not-allowed': disabled,
    }"
  >
    <BittsFormLabel v-if="formLabel" :label="formLabel" :disabled="disabled" />
    <InputNumber
      v-bind="formatter ? { formatter } : null"
      v-model:value="value"
      class="w-full bitts-number__input"
      :class="{
        danger: status === 'danger',
        success: status === 'success',
        disabled: disabled,
      }"
      :decimal-separator="decimal ? '.' : undefined"
      :default-value="defaultValue"
      :disabled="disabled"
      :max="max"
      :min="min"
      :step="step"
      @change="emit('change', $event)"
    />
    <div v-if="note" class="text-neutral-text-weak mt-4 text-sm">
      {{ note }}
    </div>
    <BittsStatus
      :status="status"
      :disabled="disabled"
      :success-text="successText"
      :danger-text="dangerText"
    />
  </div>
</template>

<script setup lang="ts">
import { InputNumber } from 'ant-design-vue';
import { computed, onMounted, ref } from 'vue';

import BittsFormLabel from '../BittsFormLabel/BittsFormLabel.vue';
import BittsStatus, {
  BittsStatusDefaults,
  BittsStatusProps,
} from '../BittsStatus/BittsStatus.vue';

const emit = defineEmits(['change', 'update:modelValue']);

const SIZE_TO_CLASS_MAP = {
  small: '50',
  medium: '200',
};

export type Props = BittsStatusProps & {
  decimal?: boolean;
  defaultValue?: number;
  max?: number;
  min?: number;
  step?: number;
  formLabel?: string;
  note?: string | null;
  size?: 'small' | 'medium';
  placeholder?: string | null;
  modelValue?: number;
  formatter?: (
    value: string | number,
    info: { userTyping: boolean; input: string },
  ) => string;
  width?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  decimal: false,
  defaultValue: 0,
  max: undefined,
  min: undefined,
  step: 1,
  formLabel: '',
  note: null,
  size: 'medium',
  placeholder: null,
  modelValue: undefined,
  formatter: undefined,
  width: null,
  ...BittsStatusDefaults,
});

const size = computed(
  () => `${props.width || SIZE_TO_CLASS_MAP[props.size]}px`,
);

const bittsNumberRef = ref(null);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

onMounted(() => {
  if (!bittsNumberRef.value || !props.placeholder) return;
  const input = (bittsNumberRef.value as HTMLInputElement).querySelector(
    'input',
  );
  input?.setAttribute('placeholder', props.placeholder);
});
</script>

<style lang="pcss">
.bitts-number {
  max-width: v-bind(size);
  .ant-input-number {
    @apply rounded-8 border-neutral-border h-40 outline-1 outline shadow-component outline-transparent;
    font-family: inherit;

    &:hover:not(.danger):not(.success):not(.disabled) {
      @apply border-neutral-border-focus outline-neutral-border-focus;
    }

    .ant-input-number-handler-wrap {
      @apply w-16;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      span {
        @apply border-neutral-border;
      }
    }

    .ant-input-number-handler-up {
      border-top-right-radius: 8px;
    }

    .ant-input-number-handler-down {
      border-bottom-right-radius: 8px;
    }

    .ant-input-number-handler-up:hover,
    .ant-input-number-handler-down:hover {
      @apply h-2/3;
    }

    .ant-input-number-input-wrap {
      @apply h-full;
    }
    .ant-input-number-input {
      @apply text-neutral-text h-full;
    }
  }

  .ant-input-number-focused {
    @apply border-neutral-border-focus outline-neutral-border-focus;
  }

  .ant-input-number.success:not(.disabled) {
    @apply border-success-border-focus outline-success-border;
  }
  .ant-input-number.danger:not(.disabled) {
    @apply border-danger-border-focus outline-danger-border-focus;
  }

  .ant-input-number.disabled {
    @apply bg-neutral-background-disabled border-neutral-border;

    .ant-input-number-input {
      @apply text-neutral-text-placeholder;
    }
  }
}
</style>

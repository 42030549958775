<template>
  <BittsLoading class="team-table" :is-loading="!ready">
    <div>
      <div class="team-table__header-section">
        <h3 class="team-table__title" data-testid="team-table-title">
          Team Members
        </h3>
        <div class="interactivity">
          <BittsInput
            v-model="search"
            type="search"
            data-testid="team-table-search"
            size="small"
            placeholder="Search for users"
            name="team-search"
            class="min-w-[240px] mt-1"
          />
          <div v-if="!selectedCount" class="flex items-center gap-x-6">
            <component
              :is="invitesDisabledReason ? UpsellTooltip : 'div'"
              :hide-upsell="!canEditUsers || isSubscriptionCancelled"
              overlay-class="team-table-disabled-tooltip"
              :button-text="inviteTooltipButtonText"
              placement="bottomLeft"
              :billing-interaction="{
                cta: isFreeTier
                  ? BILLING_CTAS.FREE_NEEDS_MORE_SEATS
                  : BILLING_CTAS.BUY_SEATS,
                event_site: EVENT_SITES.TEAM_TABLE_UPSELL_TOOLTIP,
                talkToSalesReason: mustTalkToSales && 'Need more seats',
              }"
            >
              <template #title>
                <span data-testid="invites-disabled-reason">
                  {{ invitesDisabledReason }}
                </span>
              </template>
              <span>
                <BittsButton
                  text="Invite user"
                  :disabled="!!invitesDisabledReason"
                  type="primary"
                  size="small"
                  data-testid="invite-user"
                  :left-icon="['fas', 'plus']"
                  @click="handleInvite"
                />
              </span>
            </component>
          </div>
          <div v-else class="bulk-actions">
            <BittsButton
              text="Edit users"
              variant="outline"
              type="neutral"
              size="small"
              :left-icon="['fas', 'pencil']"
              @click="handleEdit"
            />
            <component
              :is="deleteDisabled ? BittsTooltip : 'div'"
              placement="topLeft"
            >
              <BittsButton
                text="Delete users"
                :disabled="deleteDisabled"
                variant="outline"
                type="danger"
                size="small"
                :left-icon="['fas', 'trash']"
                @click="handleDelete"
              />
              <template #title>
                {{ TOOLTIP_SELF }}
              </template>
            </component>
          </div>
        </div>
      </div>
      <BittsTable
        :columns="columns"
        :enable-cell-text-selection="true"
        :suppress-row-click-selection="true"
        :compress-columns="true"
        :passed-context="passedContext"
        selection-state="'all'"
        :rows="rows"
        row-selection="multiple"
        :row-height="64"
        :pagination="false"
        class="fade-in"
      />
    </div>
  </BittsLoading>
</template>

<script setup>
import {
  BittsButton,
  BittsInput,
  BittsLoading,
  BittsTable,
  BittsTooltip,
} from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import UpsellTooltip from '@/components/billing/UpsellTooltip.vue';
import TeamTableActionsCell from '@/components/team/TeamTableActionsCell.vue';
import TeamTableLoginMethodCell from '@/components/team/TeamTableLoginMethodCell.vue';
import TeamTableRoleCell from '@/components/team/TeamTableRoleCell.vue';
import TeamTableSeatCell from '@/components/team/TeamTableSeatCell.vue';
import TeamTableUserCell from '@/components/team/TeamTableUserCell.vue';

import useAuth from '@/composables/useAuth';
import useSeats from '@/composables/useSeats';
import { BILLING_CTAS, EVENT_SITES } from '@/constants/analytics';
import { TOOLTIP_SELF, sortRows } from '@/constants/team';
import {
  allReady,
  useBillingStore,
  useRolesStore,
  useTeamStore,
} from '@/stores';
import { shallowObjectSearch } from '@/utils';

const billingStore = useBillingStore();
const teamStore = useTeamStore();
const rolesStore = useRolesStore();

const { rolesById } = storeToRefs(rolesStore);
const { currentUser, hasScope } = useAuth();
const { isFreeTier, hasSubscription, isSubscriptionCancelled } =
  storeToRefs(billingStore);
const { atSeatLimit, mustTalkToSales } = useSeats();

const { authorizations, redeemableInvites } = storeToRefs(teamStore);

const ready = allReady(teamStore, rolesStore, billingStore);
const canEditUsers = computed(() => hasScope('write:members'));

/* Searched Rows */
const search = ref('');
const authsWithRoles = computed(() =>
  authorizations.value.map(attachRoleAndChecked),
);
const invitesWithRoles = computed(() =>
  redeemableInvites.value.map(attachRoleAndChecked),
);
const searchedAuths = computed(() =>
  searchByFields(authsWithRoles.value, ['email', 'first_name', 'last_name']),
);
const searchedInvites = computed(() =>
  searchByFields(invitesWithRoles.value, ['email']),
);
const rows = computed(() => [...searchedAuths.value, ...searchedInvites.value]);

function attachRoleAndChecked(authOrInvite) {
  return {
    ...authOrInvite,
    roleName: rolesById.value[authOrInvite?.role_id]?.name,
    isChecked: selectedUserIds.value.has(authOrInvite.user?.id),
  };
}

/* Row selection */
/* Using AG Grid's native row selection does not work because the table is re-rendered when the search is applied.
Instead each row has an "isChecked" property that is used to render a checkbox as checked or not, and
calls the handlePickUser callback to set this state in the parent */
const selectedUserIds = ref(new Set([]));
const singleSelectedUserId = computed(() =>
  selectedUserIds.value.size === 1
    ? selectedUserIds.value.values().next().value
    : null,
);
const selectedCount = computed(() => selectedUserIds.value.size);
const headerRowChecked = ref(false);
const headerRowIndeterminate = ref(false);
function updateHeaderRow() {
  setTimeout(() => {
    /* Performance requirement for AG Grid */
    headerRowChecked.value =
      selectedUserIds.value.size === authsWithRoles.value.length;
    headerRowIndeterminate.value =
      !headerRowChecked.value && selectedUserIds.value.size > 0;
  });
}
const passedContext = {
  handlePickUser(authOrInvite) {
    const userId = authOrInvite.user?.id;
    if (selectedUserIds.value.has(userId)) {
      selectedUserIds.value.delete(userId);
    } else {
      selectedUserIds.value.add(userId);
    }
    updateHeaderRow();
  },
};

/* Columns Definitions + Sorting */
const columns = computed(() => {
  const base = [
    {
      field: 'Name',
      cellRenderer: TeamTableUserCell,
      suppressMovable: true,
      autoHeight: true,
      sortable: true,
      sort: 'asc',
      headerComponentParams: {
        displayName: 'Name',
        showArrow: true,
        showCheckbox: canEditUsers.value,
        checked: headerRowChecked.value,
        indeterminate: headerRowIndeterminate.value,
        onChecked(val) {
          const newIds = val ? authsWithRoles.value.map((v) => v.user.id) : [];
          selectedUserIds.value = new Set(newIds);
          updateHeaderRow();
        },
      },
      comparator: (_, __, { data: a }, { data: b }) => {
        const firstValue = a.user ? a.user.first_name : a.email;
        const secondValue = b.user ? b.user.first_name : b.email;
        if (firstValue === secondValue) return 0;
        return firstValue?.localeCompare(secondValue);
      },
      width: 300,
      suppressSizeToFit: true,
    },
    {
      field: 'Seat',
      cellRenderer: TeamTableSeatCell,
      suppressMovable: true,
      sortable: true,
      headerComponentParams: { displayName: 'Seat', showArrow: true },
      comparator: (_, __, { data: a }) => {
        const aRole = a.role_id;
        return aRole ? 1 : -1;
      },
      width: 160,
      suppressSizeToFit: true,
    },
    {
      field: 'Core Role',
      suppressMovable: true,
      headerComponentParams: {
        displayName: 'Full Access Role',
        showArrow: true,
      },
      cellRenderer: TeamTableRoleCell,
      sortable: true,
      comparator: (_, __, { data: a }, { data: b }) => {
        return sortRows(a.roleName?.toLowerCase(), b.roleName?.toLowerCase());
      },
    },
    {
      suppressMovable: true,
      field: 'Login Method',
      cellRenderer: TeamTableLoginMethodCell,
    },
    {
      suppressMovable: true,
      field: '',
      cellRenderer: TeamTableActionsCell,
    },
  ];

  if (!isFreeTier.value) {
    base.splice(3, 0, {
      field: 'Sales Role',
      suppressMovable: true,
      headerComponentParams: {
        displayName: 'Sales Role',
        icon: ['fak', 'sales'],
        showArrow: true,
      },
      cellRenderer: TeamTableRoleCell,
      sortable: true,
      comparator: (_, __, { data: a }, { data: b }) => {
        return sortRows(
          a.sales_edge_role?.toLowerCase(),
          b.sales_edge_role?.toLowerCase(),
        );
      },
    });
  }
  return base;
});

/* Invites */
const router = useRouter();
const invitesDisabledReason = computed(() => {
  if (!canEditUsers.value) return 'You do not have permission to send invites';
  if (atSeatLimit.value && isFreeTier.value)
    return 'Upgrade to Connector to purchase more seats';
  if (atSeatLimit.value && !isSubscriptionCancelled.value)
    return 'Unblock your team by adding more seats to Crossbeam';
  if (atSeatLimit.value && isSubscriptionCancelled.value)
    return 'Your subscription is cancelled and you are at your seat limit';
  return null;
});
const inviteTooltipButtonText = computed(() => {
  if (!isFreeTier.value && hasSubscription.value) return 'Buy Seats';
  if (!isFreeTier.value && !hasSubscription.value) return 'Contact Sales';
  return 'Purchase Connector';
});
async function handleInvite() {
  await router.push({ name: 'invite_user' });
}

/* Deletes */
const isSelfSelected = computed(() =>
  selectedUserIds.value.has(currentUser.value.id),
);
const deleteDisabled = computed(
  () => isSelfSelected.value || !canEditUsers.value,
);

async function handleEdit() {
  if (singleSelectedUserId.value)
    await router.push({
      name: 'edit_user',
      params: { user_id: singleSelectedUserId.value },
    });
  else
    await router.push({
      name: 'edit_users',
      query: { ids: [...selectedUserIds.value] },
    });
}

async function handleDelete() {
  if (singleSelectedUserId.value)
    await router.push({
      name: 'remove_user',
      params: { user_id: singleSelectedUserId.value },
    });
  else
    await router.push({
      name: 'remove_users',
      query: { ids: [...selectedUserIds.value] },
    });
}

/* Helpers 🤝 */
function searchByFields(invitesOrAuths, fields) {
  return search.value === ''
    ? invitesOrAuths
    : invitesOrAuths.filter((inviteOrAuth) =>
        shallowObjectSearch(
          inviteOrAuth.user ?? inviteOrAuth,
          fields,
          search.value,
        ),
      );
}
</script>

<style lang="pcss" scoped>
.team-table__header-section {
  @apply flex justify-between items-center mb-16;
  .team-table__title {
    @apply text-neutral-text-strong text-lg font-bold;
  }
}

.team-table {
  @apply overflow-hidden mt-40;
  .ag-cell {
    padding: 0;
  }

  .bulk-actions,
  .interactivity {
    @apply flex justify-between items-end gap-8 ml-8;
  }
}
</style>

<style lang="pcss">
.team-table-disabled-tooltip {
  .ant-tooltip-content {
    @apply max-w-[200px];
  }
}

.team-table {
  .ag-header-cell .svg-inline--fa.fa-sales {
    @apply text-success-accent pr-2;
  }

  /* This is the "actions" cell, right-aligned */
  .ag-row .ag-cell:last-of-type {
    @apply justify-end;
  }
}
</style>

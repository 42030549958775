<template>
  <BittsModalTwo
    save-text="Continue"
    variant="info"
    :icon="['fad', 'hand-wave']"
    title="You'll be downgraded soon"
    primary-button-text="Continue"
    :description="`Your team will keep access to paid features until ${subscriptionRenewalDate}. After that, your organization will be downgraded to our free Explorer account.`"
    :use-mask-to-close="true"
    @action="handleClick"
  />
</template>

<script setup lang="ts">
import { BittsModalTwo } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

import { useBillingStore } from '@/stores';

const router = useRouter();

const billingStore = useBillingStore();
const { subscriptionRenewalDate } = storeToRefs(billingStore);

async function handleClick() {
  await router.push({ name: 'billing' });
}
</script>

<template>
  <div class="remove-seats-view">
    <Loading :is-loading="!ready">
      <div>
        <div class="seat-removal-heading">
          <div class="flex justify-between">
            <h3 data-testid="view-title">
              Confirm users with
              {{ props.seatType === SALES ? 'Sales' : 'Full Access' }} seats
            </h3>
            <div
              class="seat-changer"
              :class="{ 'bg-danger-background-weak': newSeatLimit < seatLimit }"
            >
              <span class="text-neutral-text-weak">{{ seatLimit }}</span>
              <FontAwesomeIcon
                class="text-neutral-text-placeholder w-10"
                :icon="['fa', 'arrow-right']"
              />
              <span
                class="font-bold"
                :class="[
                  newSeatLimit < seatLimit
                    ? 'text-danger-text'
                    : 'text-neutral-text',
                ]"
              >
                {{ newSeatLimit }}
              </span>
            </div>
          </div>
          <p
            >Anyone not checked in this list will lose access today, but you can
            always re-add them later</p
          >
        </div>
        <BittsCallout
          v-if="initialUnfilledSeats"
          data-testid="unfilled-seats"
          size="small"
          :title="unfilledSeatsCalloutTitle"
          subtitle="This seat will be removed immediately, and you will see this change in your next invoice"
          class="unfilled-seats-callout"
          :type="unfilledCount === 0 ? 'danger' : 'neutral'"
        >
          <template #left-item>
            <BittsCheckbox
              data-testid="unfilled-seats-checkbox"
              class="mr-12"
              :checked="!unfilledCount"
              @input="handleToggleUnfilled"
            />
          </template>
        </BittsCallout>
        <BittsTable
          v-if="rows.length"
          :columns="columns"
          :compress-columns="true"
          :rows="rows"
          :passed-context="context"
          :row-height="64"
          :pagination="false"
          class="fade-in"
        />
      </div>
    </Loading>
  </div>
</template>

<script setup>
import { BittsCallout, BittsCheckbox, BittsTable } from '@crossbeam/bitts';

import { pluralize } from 'humanize-plus';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import useAuth from '@/composables/useAuth';
import useSeats from '@/composables/useSeats';
import { debookingColumns, salesEdgeRoleColumn } from '@/constants/debooking';
import { TEMP_LAST_ACTIVITY } from '@/constants/feature_flags';
import { CORE, SALES } from '@/constants/team';
import { useFeatureFlagStore, useTeamStore } from '@/stores';

const props = defineProps({
  seatType: {
    type: String,
    default: CORE,
    validator: (value) => [CORE, SALES].includes(value),
  },
});

const { salesSeatLimit, coreSeatLimit } = useSeats();

const idsToRetain = defineModel({ type: Set });
const unfilledCount = defineModel('unfilled', { type: Number });
const initialUnfilledSeats = unfilledCount.value;

const teamStore = useTeamStore();
const { ready, coreInvites, coreUsers, salesOnlyUsers, salesOnlyInvites } =
  storeToRefs(teamStore);

const relevantUsersAndInvites = computed(() => {
  if (props.seatType === CORE)
    return [...coreUsers.value, ...coreInvites.value];
  return [...salesOnlyUsers.value, ...salesOnlyInvites.value];
});

const relevantUsersAndInviteIds = computed(() =>
  relevantUsersAndInvites.value.map((v) => v.user?.id || v.id),
);

/* User and Unfilled Seat Selection 🪑 */
const seatLimit = computed(() => {
  if (props.seatType === CORE) return coreSeatLimit.value;
  return salesSeatLimit.value;
});

const unfilledSeatsCalloutTitle = computed(
  () =>
    `Remove ${initialUnfilledSeats} unused ${pluralize(initialUnfilledSeats, 'seat')}`,
);

function handleToggleUnfilled() {
  unfilledCount.value = unfilledCount.value > 0 ? 0 : initialUnfilledSeats;
  newSeatLimit.value = keptUsers.value.size + unfilledCount.value;
}

/* User Selection Table  */

/* Add the current user to the IDs to retain no matter what */
const { currentUser, isAdminPanelUser } = useAuth();
const set = new Set(relevantUsersAndInviteIds.value);
if (props.seatType === CORE && !isAdminPanelUser.value)
  set.add(currentUser.value.id);
const keptUsers = ref(set);
idsToRetain.value = keptUsers.value;

const newSeatLimit = computed(
  () => keptUsers.value?.size + unfilledCount.value,
);
const context = computed(() => ({
  keptUsers: keptUsers.value,
  checkAll: true,
}));

const featureFlagStore = useFeatureFlagStore();
const columns = computed(() => [
  ...debookingColumns.filter((col) => {
    if (col.field === 'Last Activity')
      return featureFlagStore.hasFeatureFlag(TEMP_LAST_ACTIVITY);
    return true;
  }),
  salesEdgeRoleColumn,
]);

const rows = computed(() => {
  return relevantUsersAndInvites.value.map((d) => ({
    ...d,
    isChecked: true,
    callback(id) {
      this.isChecked = !this.isChecked;
      if (keptUsers.value.has(id)) keptUsers.value.delete(id);
      else keptUsers.value.add(id);
      idsToRetain.value = keptUsers.value;
    },
  }));
});
</script>

<style lang="pcss" scoped>
.seat-removal-heading {
  @apply mb-16;

  h3 {
    @apply text-m;
  }

  p {
    @apply text-neutral-text;
  }
}

h3 {
  @apply text-neutral-text-strong text-m font-bold mb-4;
}

p {
  @apply text-neutral-text mb-24;
}
</style>

<style lang="pcss">
.remove-seats-view {
  .unfilled-seats-callout.bg-danger-background-weak {
    .bitts-checkbox {
      .ant-checkbox-checked,
      .ant-checkbox-inner {
        @apply bg-danger-accent border-danger-accent rounded-[3px];
      }

      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-inner:hover {
        @apply border-danger-accent;
      }
    }
  }

  .unfilled-seats-callout {
    @apply mb-16;
  }

  .seat-changer {
    @apply flex items-center gap-4 bg-info-background-weak rounded-bts-xxl px-8;
  }
}
</style>

<template>
  <BittsModal
    class="c-delete-override-modal"
    title="Delete override?"
    content-text="This will delete the override set at the population level for this partner."
    save-text="Delete"
    :visible="deleteOverrideModalVisible"
    confirm-type="danger"
    :width="400"
    @saved="onConfirmDelete"
    @closed="onCancel"
  />
</template>
<script setup>
import { BittsModal } from '@crossbeam/bitts';

import { useDataSharesStore, useFlashesStore } from '@/stores';

const { removeAndDeleteDataShare, refreshDataSharesStore } =
  useDataSharesStore();
const { addErrorFlash, addSuccessFlash } = useFlashesStore();

const props = defineProps({
  dataShareId: {
    type: Number,
    default: null,
  },
  deleteOverrideModalVisible: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['modal-closed']);

async function onConfirmDelete() {
  try {
    await removeAndDeleteDataShare(props.dataShareId);
    await refreshDataSharesStore();
    addSuccessFlash({
      message: 'Override has successfully been deleted',
    });
    emits('modal-closed', props.dataShareId);
  } catch (err) {
    addErrorFlash({
      message: 'There was a problem deleting the override',
    });
    emits('modal-closed');
  }
}

function onCancel() {
  emits('modal-closed');
}
</script>
<style lang="pcss">
.c-delete-override-modal {
  .c-bitts-modal__header div > .c-bitts-modal__title {
    @apply text-center text-xl font-bold leading-8 text-neutral-text-strong w-full;
  }
  .c-bitts-modal__content {
    @apply pt-8 text-center;
  }
  .c-bitts-modal__buttons--wrapper {
    @apply justify-between;
  }
}
</style>

<template>
  <BittsLayout variant="thirds" :is-page="true" class="remove-seats-page">
    <template #left>
      <RemoveSeatsCopy />
    </template>
    <template #right>
      <BittsLoading
        :label="
          removingSeats ? 'Removing seats. Please do not close the page...' : ''
        "
        :is-loading="removingSeats || loading"
      >
        <div class="right-content relative">
          <div class="right-content__inner">
            <h1>Manage Seats</h1>
            <BittsBreadcrumbs
              :icon="['far', 'wallet']"
              :crumbs="crumbs"
              class="mb-36 mt-4"
              :active-crumb-index="activeCrumbIndex"
              :last-crumb-index="crumbs.length - 1"
              @root-clicked="handleClosePage"
              @breadcrumb-clicked="handleBreadcrumbClicked"
            />
            <RemoveSeatsView
              v-show="activeCrumbName === CORE"
              v-model="coreIdsToRetain"
              v-model:unfilled="unfilledCoreSeats"
              data-testid="remove-core-seats-view"
              :seat-type="CORE"
            />
            <RemoveSeatsView
              v-show="activeCrumbName === SALES"
              v-model="salesIdsToRetain"
              v-model:unfilled="unfilledSalesSeats"
              data-testid="remove-sales-seats-view"
              :seat-type="SALES"
            />
            <div v-if="activeCrumbName === 'review' && changed">
              <SeatRemovalSummary
                :user-ids="allIdsToRetain"
                :is-cancellation="false"
                class="mb-40"
              />
              <h3 class="mb-16"> Here's how your invoice changes </h3>
              <BittsLoading :is-loading="isPreviewing">
                <CostSummary
                  class="cost-summary"
                  :period="billingPeriod"
                  :core-seat-count="newCoreSeats"
                  :cost-per-core-seat="costPerCoreSeat"
                  :core-seats-total-cost="coreSeatsTotalCost"
                  :sales-seat-count="newSalesSeats"
                  :cost-per-sales-seat="costPerSalesSeat"
                  :sales-seats-total-cost="salesSeatsTotalCost"
                  :summary-total="amountDueOnRenewal"
                  summary-header="Amount Due on Renewal"
                >
                  <template #total="{ total }">
                    <div>
                      <span class="old-cost">
                        {{ centsToDollars(grandTotal) }}
                      </span>
                      <span class="new-cost">
                        {{ total }}
                      </span>
                    </div>
                  </template>
                </CostSummary>
              </BittsLoading>
            </div>
            <div
              v-if="activeCrumbName === 'review' && !changed"
              class="empty-state mb-30"
            >
              <div class="empty-state-background">
                <FontAwesomeIcon
                  :icon="['fad', 'person-seat']"
                  :style="{ height: '72px', width: '72px' }"
                  class="text-neutral-accent mx-auto w-full"
                />
              </div>
              <h3 data-testid="no-seat-changes-title">
                You didn't remove any seats
              </h3>
              <p class="text-neutral-text" data-testid="no-seat-changes-info">
                No changes to see here, have a great day
              </p>
            </div>
          </div>
          <div class="footer">
            <BittsDivider class="mb-0" />
            <div class="flex justify-between w-full py-40 px-120">
              <BittsButton
                v-if="activeCrumbIndex < crumbs.length - 1"
                size="large"
                text="Continue"
                data-testid="continue-button"
                variant="outline"
                type="neutral"
                @click="handleNextPage"
              />
              <BittsButton
                v-else
                text="Make changes"
                :disabled="!changed"
                type="danger"
                size="large"
                data-testid="make-changes-button"
                @click="handleRemoveSeats"
              />
              <BittsButton
                size="large"
                text="Keep current plan"
                data-testid="keep-plan-button"
                @click="handleClosePage"
              />
            </div>
          </div>
        </div>
      </BittsLoading>
    </template>
  </BittsLayout>
</template>
<script setup>
import {
  BittsBreadcrumbs,
  BittsButton,
  BittsDivider,
  BittsLayout,
  BittsLoading,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import CostSummary from '@/components/billing/CostSummary.vue';
import RemoveSeatsCopy from '@/components/billing/RemoveSeatsCopy.vue';
import RemoveSeatsView from '@/components/billing/RemoveSeatsView.vue';
import SeatRemovalSummary from '@/components/billing/SeatRemovalSummary.vue';

import useBilling from '@/composables/useBilling';
import useSeats from '@/composables/useSeats';
import {
  CORE_SEAT_COMPONENTS,
  SALES_SEAT_COMPONENTS,
  normalizeBillingPeriod,
} from '@/constants/billing';
import { reduceToIds } from '@/constants/debooking';
import { CORE, SALES } from '@/constants/team';
import { captureException } from '@/errors';
import { useBillingStore, useFlashesStore, useTeamStore } from '@/stores';
import { centsToDollars } from '@/utils';

useHead({ title: 'Remove Seats - Crossbeam' });

const flashesStore = useFlashesStore();
const billingStore = useBillingStore();

const { subscriptionType, billingPeriod, grandTotal } =
  storeToRefs(billingStore);

const teamStore = useTeamStore();
const {
  coreSeats,
  coreUsers,
  coreInvites,
  salesOnlyUsers,
  salesOnlyInvites,
  salesOnlySeats,
} = storeToRefs(teamStore);

const { coreSeatCount, salesSeatCount, coreSeatLimit, salesSeatLimit } =
  useSeats();

const router = useRouter();
async function handleClosePage() {
  await router.push({ name: 'billing' });
}

/* Page Navigation Logic */
const crumbs = computed(() => {
  const steps = [{ text: 'Crossbeam Core', name: CORE }];
  if (salesSeatLimit.value) steps.push({ text: 'Sales Seats', name: SALES });
  steps.push({ text: 'Review', name: 'review' });
  return steps;
});

const activeCrumbIndex = ref(0);
const activeCrumbName = computed(
  () => crumbs.value.at(activeCrumbIndex.value)?.name,
);

function handleBreadcrumbClicked(crumb) {
  const index = crumbs.value.reduce((acc, c, i) => {
    if (c.name === crumb.name) return i;
    return acc;
  }, 0);

  if (index < activeCrumbIndex.value) {
    activeCrumbIndex.value = index;
  }
}

function handleNextPage() {
  activeCrumbIndex.value += 1;
  if (activeCrumbName.value === 'review') previewCost();
}

/* User and Unfilled Seat Selection 🪑 */
const coreIdsToRetain = ref(new Set());
const salesIdsToRetain = ref(new Set());
const allIdsToRetain = computed(
  () => new Set([...coreIdsToRetain.value, ...salesIdsToRetain.value]),
);
const loading = ref(true);
onMounted(async () => {
  await Promise.all([teamStore.readySync, billingStore.readySync]);
  coreIdsToRetain.value = new Set(getIds(coreSeats.value));
  salesIdsToRetain.value = new Set(getIds(salesOnlySeats.value));
  loading.value = false;
});

const unfilledCoreSeats = ref(getUnfilledSeats(CORE));
const unfilledSalesSeats = ref(getUnfilledSeats(SALES));
function getUnfilledSeats(seatType) {
  if (seatType === CORE) return coreSeatLimit.value - coreSeatCount.value;
  return salesSeatLimit.value - salesSeatCount.value;
}

/* API Calls */
const removingSeats = ref(false);
const { reduceSeats, previewComponents, processCostDataV4 } = useBilling();

async function handleRemoveSeats() {
  removingSeats.value = true;
  await deleteAuthorizationsAndInvites(); /* Delete user accounts first */
  const seatsReduced = await reduceSeats({ payload: payload.value });
  removingSeats.value = false;
  if (seatsReduced) await router.push({ name: 'billing' });
}

async function deleteAuthorizationsAndInvites() {
  /* Delete these users */
  const coreUsersToRemove = userPromises(
    reduceToIds(coreUsers.value, coreIdsToRetain.value, true),
  );
  const coreInvitesToRemove = invitePromises(
    reduceToIds(coreInvites.value, coreIdsToRetain.value, true),
  );
  const salesUsersToRemove = userPromises(
    reduceToIds(salesOnlyUsers.value, salesIdsToRetain.value, true),
  );
  const salesInvitesToRemove = invitePromises(
    reduceToIds(salesOnlyInvites.value, salesIdsToRetain.value, true),
  );
  try {
    await Promise.all([
      ...coreUsersToRemove,
      ...coreInvitesToRemove,
      ...salesUsersToRemove,
      ...salesInvitesToRemove,
    ]);
  } catch (_err) {
    flashesStore.addErrorFlash({
      message: 'Could not revoke invites and remove users',
      description: 'If this error persists contact support@crossbeam.com',
    });
  }
}

const newCoreSeats = computed(
  () => unfilledCoreSeats.value + coreIdsToRetain.value.size,
);
const newSalesSeats = computed(
  () => unfilledSalesSeats.value + salesIdsToRetain.value.size,
);

/* Cost Preview */
const isPreviewing = ref(false);
const amountDueOnRenewal = ref(0);
const coreSeatsTotalCost = ref(0);
const costPerCoreSeat = ref(0);
const salesSeatsTotalCost = ref(0);
const costPerSalesSeat = ref(0);
async function previewCost() {
  if (!changed.value) return;
  try {
    isPreviewing.value = true;
    const { nextSubscriptionCost, components } = await previewComponents(
      payload.value.components,
      true,
    );
    const data = processCostDataV4({
      costData: { components },
      newCoreSeats: newCoreSeats.value,
      newSalesSeats: newSalesSeats.value,
      period: normalizeBillingPeriod(billingPeriod.value),
    });
    costPerCoreSeat.value = data.costPerCoreSeat;
    coreSeatsTotalCost.value = data.coreSeatsTotalCost;
    costPerSalesSeat.value = data.costPerSalesSeat;
    salesSeatsTotalCost.value = data.salesSeatsTotalCost;
    amountDueOnRenewal.value = nextSubscriptionCost;
  } catch (err) {
    flashesStore.addErrorFlash({
      message: 'Could not calculate cost of seat reduction',
    });
    captureException(err);
  } finally {
    isPreviewing.value = false;
  }
}

/* Helpers 🤝 */
function userPromises(ids) {
  return ids.map((id) => teamStore.removeAuthorization({ userId: id }));
}

function invitePromises(ids) {
  return ids.map((id) => teamStore.revokeInvite({ inviteId: id }));
}

function getIds(authsOrInvites) {
  return authsOrInvites.map(
    (authOrInvite) => authOrInvite.user?.id || authOrInvite.id,
  );
}

const payload = computed(() => ({
  components: [
    {
      component_handle: CORE_SEAT_COMPONENTS[subscriptionType.value],
      quantity: unfilledCoreSeats.value + coreIdsToRetain.value.size,
    },
    {
      component_handle: SALES_SEAT_COMPONENTS[subscriptionType.value],
      quantity: unfilledSalesSeats.value + salesIdsToRetain.value.size,
    },
  ],
}));

/* We use this to detect whether component quantities are changed and we need to calculate the preview of the cost */
const changed = computed(() =>
  componentQuantitiesChanged({
    components: payload.value.components,
    period: subscriptionType.value,
  }),
);
const findNewQuantity = (cs, h) =>
  cs.find((c) => c.component_handle === h)?.quantity;
function componentQuantitiesChanged({ components, period }) {
  const newCoreSeatLimit = findNewQuantity(
    components,
    CORE_SEAT_COMPONENTS[period],
  );
  const newSalesSeatLimit = findNewQuantity(
    components,
    SALES_SEAT_COMPONENTS[period],
  );
  return (
    coreSeatLimit.value !== newCoreSeatLimit ||
    salesSeatLimit.value !== newSalesSeatLimit
  );
}
</script>

<style lang="pcss" scoped>
.right-content {
  @apply h-full flex flex-col justify-between;

  .footer {
    @apply w-full;
  }

  .right-content__inner {
    @apply px-24 mt-36 lg:px-120 lg:pt-48 flex-1 flex flex-col;

    .empty-state {
      @apply border border-neutral-200 rounded-12 w-full flex-1 min-h-[250px] my-auto flex flex-col items-center justify-center;

      .empty-state-background {
        background: radial-gradient(
            136.96% 69.92% at 50.09% -12.42%,
            theme(colors.neutral.accent / 0.3) 0%,
            theme(colors.white / 0) 100%
          ),
          theme(colors.white);
        @apply rounded-full w-120 h-120 p-24 mt-[-24px];
      }
    }

    h1,
    h3 {
      @apply text-neutral-text-strong text-lg font-bold;
    }

    h3 {
      @apply text-m;
    }
  }

  .old-cost {
    @apply line-through text-neutral-text-placeholder mr-4;
  }

  .new-cost {
    @apply text-success-text font-bold;
  }
}
</style>
<style lang="pcss">
.remove-seats-page.bitts-layout {
  @apply p-0;
}
</style>

import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { initStore } from '@/stores/store-utils';
import urls from '@/urls';

export const useIntegrationProfileStore = defineStore(
  'IntegrationProfileStore',
  () => {
    const integrationProfiles = ref([]);

    const { error, ready, readySync, running, refresh } = initStore(
      async () => {
        const { data } = await axios.get(urls.integrationProfiles.all);
        if (data) integrationProfiles.value = data;
      },
    );

    refresh({ useCache: true });

    function getIntegrationProfileByType(integrationType) {
      return integrationProfiles.value.find(
        (profile) => profile.integration_type === integrationType,
      );
    }

    async function postIntegrationProfileSettings(payload) {
      const url = urls.integrationProfiles.post;
      return axios.post(url, payload);
    }

    async function putIntegrationProfileSettings(profileId, payload) {
      const url = urls.integrationProfiles.put(profileId);
      return axios.put(url, payload);
    }

    return {
      error,
      ready,
      readySync,
      running,
      refreshIntegrationProfileStore: refresh,
      integrationProfiles,
      getIntegrationProfileByType,
      postIntegrationProfileSettings,
      putIntegrationProfileSettings,
    };
  },
);

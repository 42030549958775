export const COPILOT_SOURCE_EXTENSION = 'extension';
export const COPILOT_SOURCE_GONG = 'gong';
export const COPILOT_SOURCE_HUBSPOT = 'hubspot';
export const COPILOT_SOURCE_OUTREACH = 'outreach';
export const COPILOT_SOURCE_SALESFORCE = 'salesforce';

const COPILOT_SOURCES = [
  COPILOT_SOURCE_HUBSPOT,
  COPILOT_SOURCE_SALESFORCE,
  COPILOT_SOURCE_EXTENSION,
  COPILOT_SOURCE_GONG,
  COPILOT_SOURCE_OUTREACH,
];

export default COPILOT_SOURCES;

<template>
  <div :class="rootClasses">
    <div v-if="showHeaderSection" class="bitts-table__cta-wrapper">
      <div class="bitts-table__cta-left">
        <slot name="cta-left">
          <div
            :class="shrinkTitle ? null : 'flex-1'"
            class="text-neutral-900 text-lg"
          >
            {{ title }}
          </div>
        </slot>
      </div>
      <div class="bitts-table__cta-right">
        <slot name="cta-right" />
      </div>
    </div>
    <div class="bitts-table__ag-grid-container">
      <AgGridVue
        :column-defs="columns"
        :default-col-def="defaultColumnDef"
        :modules="modules"
        :row-data="rows"
        :enable-cell-text-selection="enableCellTextSelection"
        :pagination="pagination"
        :suppress-pagination-panel="true"
        :pagination-page-size="paginationPageSize"
        :suppress-cell-focus="true"
        :suppress-row-hover-highlight="true"
        :suppress-click-edit="true"
        :sorting-order="sortOrder"
        :row-selection="rowSelection"
        :row-height="rowHeight"
        :suppress-browser-resize-observer="true"
        :suppress-row-virtualisation="suppressRowVirtualisation"
        :suppress-column-virtualisation="suppressColumnVirtualisation"
        :row-multi-select-with-click="rowMultiSelectWithClick"
        :suppress-row-click-selection="suppressRowClickSelection"
        :components="components"
        :context="context"
        class="ag-theme-alpine"
        dom-layout="autoHeight"
        v-on="extraHandlers"
        @row-clicked="onRowClicked"
        @first-data-rendered="onDataRendered"
        @grid-size-changed="onDataRendered"
        @grid-ready="onGridReady"
        @pagination-changed="onPaginationChanged"
      />
    </div>
    <div v-if="showPagination" class="bitts-table__pagination-container">
      <div
        v-if="rows.length"
        :class="[!showPaginationResultsText ? 'opacity-0' : '']"
        class="flex-1 text-sm text-neutral-500 hidden sm:block"
      >
        {{ paginationResultsText }}
      </div>
      <BittsPaginator
        v-if="showPagination && paginationData.page"
        :page="Number(paginationData.page)"
        :last-page="paginationData.last_page"
        @page-click="onPageClick"
      />
      <BittsPaginator
        v-if="showPagination && !paginationData.page"
        :page="Number(page) || 1"
        :last-page="lastPage || 1"
        @page-click="onPageClick"
      />
    </div>
    <div class="h-24 invisible" />
  </div>
</template>

<script>
/* eslint-disable vue/one-component-per-file */
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AgGridVue } from '@ag-grid-community/vue3';
import { ref } from 'vue';

import BittsPaginator from '../BittsPaginator/BittsPaginator.vue';

import CustomColumnHeader from './CustomColumnHeader.vue';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';

export default {
  name: 'BittsTable',
  components: {
    AgGridVue,
    BittsPaginator,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    passedContext: {
      type: Object,
      default() {
        return {};
      },
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    defaultColumnDef: {
      type: Object,
      default: () => {},
    },
    rows: {
      type: Array,
      default() {
        return [];
      },
    },
    onRowSelected: {
      type: Function,
      default: null,
    },
    rowSelection: {
      type: String,
      default: null,
    },
    searchQuery: {
      type: String,
      default: '',
    },
    suppressRowClickSelection: {
      type: Boolean,
      default: null,
    },
    rowMultiSelectWithClick: {
      type: Boolean,
      default: null,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    paginationData: {
      type: Object,
      default() {
        return {};
      },
    },
    paginationPageSize: {
      type: Number,
      default: 20,
    },
    rowHeight: {
      type: Number,
      default: null,
    },
    compressColumns: {
      type: Boolean,
      default: false,
    },
    resultsText: {
      type: String,
      default: 'results',
    },
    sortOrder: {
      type: Array,
      default: () => ['asc', 'desc'],
    },
    showPaginationResultsText: {
      type: Boolean,
      default: true,
    },
    shrinkTitle: {
      type: Boolean,
      default: false,
    },
    enableCellTextSelection: {
      type: Boolean,
      default: false,
    },
    useSearch: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['pageClick', 'columns-compressed', 'row-clicked', 'grid-ready'],
  setup() {
    const page = ref(1);
    return { page };
  },

  data() {
    return {
      displayedRowCount: 0,
      gridApi: {},
      gridColumnApi: {},
      components: null,
      lastPage: null,
      context: {},
      suppressRowVirtualisation: false,
      suppressColumnVirtualisation: false,
      modules: [ClientSideRowModelModule],
    };
  },
  computed: {
    hasCTA() {
      return !!this.$slots['cta-left'];
    },
    rootClasses() {
      return {
        'bitts-table': true,
        bordered: this.bordered,
        pagination: this.showPagination,
        'with-header': this.showHeaderSection,
      };
    },
    extraHandlers() {
      const handlers = {};
      if (this.onRowSelected) {
        handlers['row-selected'] = this.onRowSelected;
      }

      return handlers;
    },
    showHeaderSection() {
      return this.title || this.$slots['cta-left'];
    },
    pageStartIndex() {
      if (this.paginationData.page) {
        return 1 + this.paginationPageSize * (this.paginationData.page - 1);
      }
      if (!this.gridApi.paginationGetCurrentPage) return;
      return this.paginationPageSize * (this.page - 1) + 1;
    },
    pageEndIndex() {
      if (this.paginationData.page) {
        return Math.min(
          this.paginationData.page * this.paginationPageSize,
          this.paginationRows,
        );
      }
      if (!this.gridApi.paginationGetCurrentPage) return;
      return Math.min(this.page * this.paginationPageSize, this.paginationRows);
    },
    paginationResultsText() {
      if (!this.pageStartIndex || !this.pageEndIndex)
        return `Showing 0 ${this.resultsText}`;
      return `Showing ${this.pageStartIndex} - ${this.pageEndIndex} of ${this.paginationRows} ${this.resultsText}`;
    },
    paginationRows() {
      if (!this.searchQuery)
        return this.paginationData.total_count || this.rows.length;
      return this.displayedRowCount;
    },
    showPagination() {
      return this.pagination && !this.hidePagination;
    },
  },

  watch: {
    page(newPageValue) {
      if (!newPageValue) return;
      this.gridApi.paginationGoToPage(newPageValue - 1);
    },
    searchQuery(query) {
      if (!this.useSearch) return;
      this.gridApi.setQuickFilter(query);
      this.displayedRowCount = this.gridApi.getDisplayedRowCount();
    },
  },

  beforeMount() {
    // We need to suppress row virtualisation when testing. If we don't, all rows won't render as expected
    // See https://blog.ag-grid.com/testing-ag-grid-react-jest-enzyme/#querying-dom
    this.suppressRowVirtualisation = this.suppressColumnVirtualisation =
      import.meta.env.MODE === 'test';
    this.components = { agColumnHeader: CustomColumnHeader };
    this.context = this.passedContext;
  },
  methods: {
    onPageClick(page) {
      this.gridApi.paginationGoToPage(page);
      this.page = page;
      this.$emit('pageClick', page);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      this.lastPage = this.gridApi.paginationGetTotalPages();
      this.$emit('grid-ready', params);
    },
    onPaginationChanged() {
      if (!this.gridApi.paginationGetCurrentPage) return;
      this.lastPage = this.gridApi.paginationGetTotalPages();
    },
    onDataRendered(params) {
      if (this.compressColumns) {
        params.api.sizeColumnsToFit();
        this.$emit('columns-compressed');
      } else {
        const columns = params.columnApi.getColumns();
        const numberOfColumns = columns.length;
        if (numberOfColumns > 0) {
          const columnWidth = columns[0].actualWidth;
          const gridPixelRange = params.api.getHorizontalPixelRange();
          const gridWidth = gridPixelRange.right;
          // if grid width is greater than sum total of all column width's
          // we need to call sizeColumnsToFit so that columns fill entire width of grid
          if (gridWidth > numberOfColumns * columnWidth) {
            params.api.sizeColumnsToFit();
          }
        } else {
          params.columnApi.autoSizeAllColumns();
        }
      }
    },
    onRowClicked(e) {
      this.$emit('row-clicked', e.data);
    },
  },
};
</script>

<style lang="pcss">
.bitts-table {
  /* main styles */
  .ag-root-wrapper {
    @apply rounded-2xl border-neutral-border;
  }
  &.with-header .ag-root-wrapper {
    @apply rounded-t-none;
  }
  .ag-header {
    @apply bg-white border-b border-neutral-border border-solid;
  }
  .ag-row {
    @apply border-l-0 border-r-0 border-t-0 bg-white border-neutral-border;
  }
  .ag-row-last {
    @apply border-b-0;
  }
  .ag-header {
    min-height: 40px !important;
    height: 40px !important;
  }
  .ag-header-row {
    height: 40px !important;
  }
  .ag-cell,
  .ag-header-cell {
    @apply px-16;
  }

  .ag-cell {
    @apply flex items-center overflow-x-hidden overflow-y-hidden;
  }

  .ag-header-cell {
    @apply leading-6;
  }
  .ag-paging-panel {
    @apply border-t-0;
  }
  .ag-ltr .ag-header-cell-resize {
    @apply right-[-5px];
  }
  /* This class adds some weird spacing above the pagination section, removing it for now */
  .ag-center-cols-clipper {
    min-height: 0 !important;
  }
  /* bordered table styles */
  &.bordered {
    .ag-cell,
    .ag-header-cell {
      @apply border-0 border-r border-solid border-neutral-background-disabled;
    }
  }
  /* custom pagination styles */
  &.pagination {
    .ag-root-wrapper {
      @apply border-b-0 rounded-bl-none rounded-br-none;
    }
  }
  .bitts-table__pagination-container {
    @apply flex items-center bg-white p-16 border rounded-b-2xl
    border-neutral-border border-t-0 shadow-component justify-end;
  }

  .bitts-table__column-header {
    @apply flex items-center w-full;
  }
  [aria-sort='ascending'] .c-bitts-table__sort-toggle,
  .manual-sort-ascending .c-bitts-table__sort-toggle {
    @apply rotate-180;
  }
  .bitts-table__cta-wrapper {
    @apply flex items-center bg-white py-12 px-16 w-full overflow-auto
    rounded-t-2xl border-x border-t border-neutral-border justify-between shadow-component;
  }

  .ag-row {
    &.ag-row-selected::before {
      @apply bg-white;
    }
  }

  /* Zebra pattern */
  .ag-row-odd,
  .ag-row-even {
    @apply border-none;
  }

  .ag-row-even {
    @apply bg-neutral-background-weak;
    &.ag-row-selected::before {
      @apply bg-neutral-background-weak rounded-16;
    }
  }

  /* Checkboxes... */
  .ag-input-wrapper.ag-checkbox-input-wrapper {
    &:focus-within {
      @apply shadow-none;
    }
  }
  .ag-theme-alpine.ag-checked.ag-checkbox-input-wrapper,
  .ag-theme-alpine :not(.ag-checked).ag-checkbox-input-wrapper {
    @apply border-none cursor-pointer rounded-3;
    box-shadow: theme(colors.neutral.accent) inset 0px 0px 0px 1px;
    &:hover {
      box-shadow: theme(colors.violet.700) inset 0px 0px 0px 1px;
    }
  }
  .ag-theme-alpine .ag-checkbox-input-wrapper:hover {
    @apply cursor-pointer;
  }
  .ag-theme-alpine .ag-checkbox-input-wrapper.ag-indeterminate::after {
    @apply bg-secondary-accent mt-4 ml-4 rounded-bts-xs;
    content: '';
    height: 8px;
    width: 8px;
  }
  .ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    @apply text-secondary-accent;
  }
  .ag-theme-alpine :not(.ag-checked).ag-checkbox-input-wrapper::after {
    content: '';
  }
}
</style>

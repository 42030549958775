import axios from 'axios';
import { DateTime } from 'luxon';

import { ATTRIBUTION_FILTER_QUERY_PARAMS } from '@/constants/attribution';
import { captureException } from '@/errors';
import urls from '@/urls';

import { usePartnersStore } from '../PartnersStore';

import { useAttributionState } from './state';
import { extractFiltersFromQuery } from './utils';

const {
  ATTRIBUTION_EXISTS,
  DISMISSED,
  ORDER,
  LIMIT,
  CLOSE_DATE_GTE,
  CLOSE_DATE_LTE,
  IS_CLOSED,
  PAGE,
  IS_WON,
} = ATTRIBUTION_FILTER_QUERY_PARAMS;

const { isCached, opportunities, pagination, unattributedOpportunities } =
  useAttributionState();

async function fetchOpportunity({ masterUUID, opportunityID }) {
  const url = urls.attribution.opportunity(masterUUID, opportunityID);
  const { data } = await axios.get(url);
  return data.data[0] || {};
}

async function fetchUnattributedOpportunities() {
  const dateMonthAway = DateTime.local()
    .plus({ months: 1 })
    .toFormat('yyyyMMdd');
  const dateToday = DateTime.now().toFormat('yyyyMMdd');

  const { data: response } = await axios.get(urls.attribution.all, {
    params: {
      [IS_CLOSED]: false,
      [IS_WON]: false,
      [DISMISSED]: false,
      [ATTRIBUTION_EXISTS]: false,
      [ORDER]: 'asc',
      [LIMIT]: 40,
      [CLOSE_DATE_GTE]: dateToday,
      [CLOSE_DATE_LTE]: dateMonthAway,
      [PAGE]: 1,
    },
  });
  unattributedOpportunities.value = response.data;
}

async function refreshOpportunities(query) {
  const page = query?.page || 1;
  const filterParams = extractFiltersFromQuery(query);
  const params = {
    ...filterParams,
    [LIMIT]: 20,
    page,
  };

  const { data: response } = await axios.get(urls.attribution.all, {
    params,
  });
  opportunities.value = response.data;
  pagination.value = response.pagination;
}

async function refreshClosedWonOppies(query = {}) {
  try {
    const page = query?.page || 1;
    const params = {
      ...query,
      [LIMIT]: 20,
      page,
    };
    const { data: response } = await axios.get(urls.attribution.closedWon, {
      params,
    });
    opportunities.value = response.data;
    pagination.value = response.pagination;
  } catch (err) {
    isCached.value = false;
    captureException(err);
  }
}

async function fetchOpportunityOverlaps({ recordId }) {
  const { data } = await axios.get(urls.attribution.overlaps, {
    params: {
      record_id: recordId,
    },
  });

  const partnerStore = usePartnersStore();

  const partnerOverlapLookup = data.data?.reduce((acc, curr) => {
    const { partner_populations: partnerPopulations, partner_id: partnerUUID } =
      curr;

    const uniquePopulations = new Set(
      partnerPopulations?.map((pop) => pop.name),
    );

    if (Object.hasOwn(acc, partnerUUID)) {
      const populations = acc?.[partnerUUID]?.populations || [];
      acc[partnerUUID].populations = new Set([
        ...populations,
        ...uniquePopulations,
      ]);
    } else {
      const partner = partnerStore.getPartnerOrgByUuid(partnerUUID);

      if (partner) {
        acc[partnerUUID] = {
          ...partner,
          populations: new Set(uniquePopulations),
        };
      }
    }

    return acc;
  }, {});

  return Object.values(partnerOverlapLookup).map((curr) => ({
    ...curr,
    populations: sortPops([...curr.populations]),
  }));
}

const sortPops = (pops) => {
  return pops.sort((a, b) => {
    if (a === 'Customers') return -1;
    else if (b === 'Customers') return 1;
    return a.localeCompare(b);
  });
};

function findOpportunityByID(oppId) {
  return opportunities.value
    .concat(unattributedOpportunities.value)
    .find((opp) => opp.id === oppId);
}

function getOpportunityInCurrentStateByID(oppId) {
  return opportunities.value.find((opp) => opp.id === oppId);
}

export default {
  fetchOpportunity,
  fetchUnattributedOpportunities,
  refreshOpportunities,
  refreshClosedWonOppies,
  fetchOpportunityOverlaps,
  findOpportunityByID,
  getOpportunityInCurrentStateByID,
  sortPops,
};

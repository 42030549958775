<template>
  <div class="learn-more">
    <div class="header">
      <h4 class="text-info-text text-m font-bold">
        Learn more about {{ type }}
      </h4>
      <button class="collapser" @click="isOpen = !isOpen">
        <FontAwesomeIcon
          :class="{
            'rotate-180 transition': isOpen,
            'rotate-[-180] transition': !isOpen,
          }"
          class="w-12 h-12 text-neutral-accent mr-6"
          :icon="['fas', 'chevron-up']"
        />
        <span class="text-neutral-text-weak">{{
          isOpen ? 'Collapse' : 'Expand'
        }}</span>
      </button>
    </div>
    <div
      :class="{
        'max-h-0 pb-0': !isOpen,
        'pb-12 mt-16': isOpen,
      }"
      class="cards"
    >
      <LearnMoreCard
        v-for="(card, i) in cards"
        :key="`${i}__card`"
        :card="card"
        class="flex-1"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

import LearnMoreCard from '@/components/onboarding/LearnMoreCard.vue';

defineProps({
  type: {
    type: String,
    required: true,
  },
  cards: {
    type: Array,
    required: true,
  },
});
const isOpen = ref(true);
</script>

<style lang="pcss" scoped>
.learn-more {
  @apply px-24 py-16 bg-neutral-background-weak rounded-16 w-full mt-40;
  .header {
    @apply flex items-center justify-between;
    .collapser {
      @apply cursor-pointer;
      &:hover {
        @apply opacity-90;
      }
    }
  }

  .cards {
    @apply flex-col md:flex-row flex gap-16 w-full justify-around overflow-hidden;
  }
}
</style>

<template>
  <div class="c-datasource-settings">
    <BittsDrawer
      :visible="true"
      :mask-closable="true"
      :show-footer="true"
      :loading="loading"
      @closed="closeDrawer"
    >
      <template #header>
        <div class="c-datasource-settings__header">
          <div class="flex items-center">
            <BittsSvg
              class="w-32 h-32 mr-8"
              :svg="feed.integration.type + 'Icon'"
            />
            <h2> {{ feed.integration.friendly_name }} Settings </h2>
          </div>
          <FontAwesomeIcon
            :icon="['fas', 'x']"
            :style="{ height: '16px', width: '16px' }"
            class="cursor-pointer"
            @click="closeDrawer"
          />
        </div>
      </template>
      <template #content>
        <div class="c-datasource-settings__content pt-12">
          <div class="c-datasource-settings__section">
            <h3> Integration Status </h3>
            <div
              :class="[feedStatus.statusBgColor, feedStatus.textColor]"
              class="c-datasource-settings__status"
            >
              <span class="status-circle" :class="feedStatus.bgColor" />
              {{ feedStatus.text }}
            </div>
          </div>
          <BittsAlert
            v-if="feedHasErrorMessage"
            :message="feed.error_message"
            color="error"
            class="mt-8 mx-24"
          />
          <BittsDivider class="my-12 border-neutral-border" />
          <div class="c-datasource-settings__section">
            <div class="flex justify-between flex-grow">
              <h3> Connection Details </h3>
              <BittsButton
                text="Reauthorize"
                variant="outline"
                size="x-small"
                data-testid="c-datasource-settings-reauthorize"
                @click="handleReauthorize"
              />
            </div>
            <div class="text-sm text-neutral-text flex flex-col">
              <span>
                <span v-if="feedConnectedAt">
                  {{ feedConnectedAt }}
                </span>
                <span v-if="feedConnectedAt && connectorName">
                  by {{ connectorName }}
                </span>
              </span>
              <span v-if="feedConnectedAt && authorizingUser">
                Authorization username: {{ authorizingUser }}
              </span>
            </div>
          </div>
          <BittsDivider class="my-12 border-neutral-border" />
          <div class="c-datasource-settings__section">
            <h3>Sync Data from {{ feed.integration.friendly_name }}</h3>
            <BittsSwitch
              data-testid="c-datasource-settings-source-toggle"
              :initial-value="!isPaused"
              @change="isPaused = !isPaused"
            />
          </div>
          <BittsDivider class="my-12 border-neutral-border" />
          <div class="c-datasource-settings__section">
            <h3>Update Frequency</h3>
            <BittsSelect
              v-model="currentFrequency"
              :options="FREQUENCIES"
              :disabled="feed.integration.type === SNOWFLAKE_DATA_SOURCE_TYPE"
              :dropdown-match-select-width="false"
              :searchable="false"
              class="c-datasource-frequency-selector"
            />
          </div>
          <BittsDivider class="my-12 border-neutral-border" />
          <div
            v-if="hasDataSyncCustomization"
            class="c-datasource-settings__section"
            data-testid="c-datasource-settings__data-sync"
          >
            <div class="flex items-center justify-between mb-8 w-full">
              <h3> Data Sync </h3>
              <component :is="isPaused ? BittsTooltip : 'div'" placement="left">
                <div>
                  <BittsButton
                    text="Edit Data Sync"
                    variant="outline"
                    size="x-small"
                    :disabled="isPaused"
                    @click="customizeFields"
                  />
                </div>
                <template #title>
                  Syncing must be turned on in order to edit this
                </template>
              </component>
            </div>
            <ReviewFields
              :all-checked-field-ids="allCheckedFieldIds"
              :feed="feed"
              :sources="sources"
            />
            <p class="text-neutral-text-weak text-sm mt-4">
              Changes to your data sync may take up to an hour to take effect
            </p>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="c-datasource-settings__footer">
          <BittsButton
            :disabled="loading"
            :loading="loading"
            type="danger"
            text="Remove Data Source"
            data-testid="c-datasource-settings-delete"
            class="mr-16 flex-1"
            @click="toggleDeleteModal"
          />
          <BittsButton
            :disabled="loading"
            :loading="loading"
            text="Save Changes"
            data-testid="c-datasource-settings-save"
            class="flex-1"
            @click="handleSaveChanges"
          />
        </div>
      </template>
    </BittsDrawer>
    <BittsModal
      title="Delete Data Source"
      content-text="Are you sure you want to delete your connection? Removing this data source will permanently delete all associated populations and sharing rules. This cannot be undone."
      save-text="Yes, Continue"
      cancel-text="No, Cancel"
      :visible="showConfirmDelete"
      confirm-type="danger"
      @saved="deleteFeed"
      @closed="toggleDeleteModal"
    />
  </div>
</template>

<script setup>
import {
  BittsAlert,
  BittsButton,
  BittsDivider,
  BittsDrawer,
  BittsModal,
  BittsSelect,
  BittsSvg,
  BittsSwitch,
  BittsTooltip,
} from '@crossbeam/bitts';

import { DateTime } from 'luxon';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import ReviewFields from '@/components/data-sources/data-templates/ReviewFields.vue';

import useFeedDetails from '@/composables/useFeedDetails';
import {
  HS3_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
  SNOWFLAKE_DATA_SOURCE_TYPE,
  isValidSourceField,
  patchFeed,
} from '@/constants/data_sources';
import { captureException } from '@/errors';
import {
  useDataSharesStore,
  useFeedsStore,
  useFlashesStore,
  usePopulationsStore,
  useSourcesStore,
  useTeamStore,
} from '@/stores';

const props = defineProps({
  feedId: {
    type: Number,
    required: true,
  },
});

const router = useRouter();
const feed = ref(null);
const sources = ref([]);
const allCheckedFieldIds = ref([]);
const isPaused = ref(false);

const feedsStore = useFeedsStore();

const sourcesStore = useSourcesStore();

/* Change frequency */
const FREQUENCIES = [
  { label: '6 Hours', value: 360 },
  { label: '12 Hours', value: 720 },
  { label: '24 Hours', value: 1440 },
  { label: '1 Week', value: 10080 },
];

const currentFrequency = ref(null);

onMounted(async () => {
  const feedsStore = useFeedsStore();
  await Promise.all([feedsStore.readySync, sourcesStore.readySync]);
  feed.value = feedsStore.getFeedById(props.feedId);
  isPaused.value = feed.value.is_paused;
  sources.value = sourcesStore.getSourcesByFeedId(props.feedId);

  /* This is necessary for the review tree, it needs the IDs
  of all the checkd fields for the data source */
  allCheckedFieldIds.value = sources.value.reduce((acc, source) => {
    const fields = source.fields
      .filter((sourceField) => {
        return (
          isValidSourceField(sourceField) &&
          sourceField.is_visible &&
          sourceField.is_filterable
        );
      })
      .map((sourceField) => sourceField.id);

    acc.push(...fields);

    return acc;
  }, []);

  currentFrequency.value = feed.value.frequency;
  loading.value = false;
});

const loading = ref(true);

const { feedStatus, feedHasErrorMessage, reauthorize } = useFeedDetails({
  feedId: props.feedId,
});

const feedConnectedAt = computed(() => {
  if (feed.value?.connected_at) {
    const time = DateTime.fromISO(feed.value?.connected_at);
    return `Established ${time.toFormat('M/d/yyyy')} at ${time.toFormat('h:m a')}`;
  }
  return null;
});

const connectorName = computed(() => {
  const teamStore = useTeamStore();
  const connector = teamStore.authorizations.filter(
    (authorization) => authorization.user.email === feed.value?.connected_by,
  )[0];
  return connector
    ? `${connector.user.first_name} ${connector.user.last_name}`
    : feed.value?.connected_by;
});

const authorizingUser = computed(() => feed.value?.external_user_email);

const hasDataSyncCustomization = computed(() => {
  return [
    SALESFORCE_DATA_SOURCE_TYPE,
    MD_DATA_SOURCE_TYPE,
    HS3_DATA_SOURCE_TYPE,
  ].includes(feed.value?.integration.type);
});

async function closeDrawer() {
  await router.push({ name: 'data-sources' });
}

async function customizeFields() {
  await router.push({ name: 'data-templates', query: { customizing: true } });
}

const showConfirmDelete = ref(false);
function toggleDeleteModal() {
  showConfirmDelete.value = !showConfirmDelete.value;
}

async function deleteFeed() {
  const flashesStore = useFlashesStore();
  const feedsStore = useFeedsStore();
  const populationsStore = usePopulationsStore();
  const dataSharesStore = useDataSharesStore();

  loading.value = true;
  try {
    await feedsStore.removeFeed(feed.value.id);
    await Promise.all([
      dataSharesStore.refreshDataSharesStore(),
      populationsStore.refreshPopulationsStore(),
      feedsStore.refreshFeedsStore(),
    ]);
    await router.push({ name: 'data-sources' });
    flashesStore.addSuccessFlash({ message: 'Data deletion initiated' });
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({
      message: 'There was an issue with your delete',
      description: 'If the error persists contact support@crossbeam.com',
    });
    loading.value = false;
  }
}

async function handleSaveChanges() {
  const flashesStore = useFlashesStore();
  const dataSharesStore = useDataSharesStore();
  loading.value = true;
  try {
    await patchFeed(feed.value.id, {
      is_paused: isPaused.value,
      frequency: currentFrequency.value,
    });
    flashesStore.addSuccessFlash({ message: 'Settings saved' });
    await Promise.all([
      dataSharesStore.refreshDataSharesStore(),
      feedsStore.refreshFeedsStore(),
      sourcesStore.refreshSourcesStore(),
    ]);
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({
      message: 'Could not save settings',
      description: 'If the error persists contact support@crossbeam.com',
    });
  } finally {
    loading.value = false;
  }
}

async function handleReauthorize() {
  const flashesStore = useFlashesStore();
  try {
    await reauthorize(feed.value);
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({
      message: 'Could not reauthorize feed',
      description: 'If the error persists contact support@crossbeam.com',
    });
  }
}
</script>
<style lang="pcss" scoped>
.c-datasource-settings__content {
  @apply flex flex-col;
}

.c-datasource-settings__status {
  @apply text-sm px-8 py-4 rounded-bts-sm flex items-center gap-4;
}

.c-datasource-settings__header {
  @apply flex items-center justify-between p-24;

  h2 {
    @apply text-xl font-bold text-neutral-text-strong;
  }
}

.c-datasource-settings__section {
  @apply flex justify-between items-center px-24 py-8;

  h3 {
    @apply font-bold text-neutral-text-strong text-base normal-case tracking-normal;
  }
}

.status-circle {
  @apply w-8 h-8 rounded-full inline-block;
}

.c-datasource-frequency-selector {
  width: 140px;
}

.c-datasource-settings__footer {
  @apply flex items-center justify-between;
}
</style>

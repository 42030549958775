import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { SALESFORCE_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import { initStore } from '@/stores/store-utils';
import urls from '@/urls';

export const useConnectionsStore = defineStore('Connections', () => {
  const connections = ref([]);

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    const response = await axios.get(urls.connections.all);
    connections.value = response.data.items;
  });

  refresh({ useCache: true });

  const activeConnections = computed(() => {
    return connections.value.filter(
      (s) =>
        s.integration_type === SALESFORCE_DATA_SOURCE_TYPE ||
        (!s.is_paused && !s.error_message),
    );
  });

  function getConnectionByType(integrationType) {
    return connections.value.find(
      (s) => s.integration_type === integrationType,
    );
  }

  function getConnectionsByType(integrationType) {
    return connections.value.filter(
      (s) => s.integration_type === integrationType,
    );
  }

  return {
    error,
    ready,
    readySync,
    running,
    refreshConnectionsStore: refresh,
    connections,
    activeConnections,
    getConnectionByType,
    getConnectionsByType,
  };
});

<template>
  <Notification :item="item">
    <template #logo>
      <BittsAvatar
        :org="partnerOrg || {}"
        :show-initials="true"
        :is-own="false"
        size="medium"
      />
    </template>
    <template #message> Data Sharing Changed </template>
    <template #description>
      <span>{{ description }}</span>
    </template>
    <template #action>
      <BittsButton
        :text="buttonText"
        type="neutral"
        variant="outline"
        size="x-small"
        @click="onButtonClick"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fas', 'chart-pie-simple']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script>
import { BittsAvatar, BittsButton } from '@crossbeam/bitts';

import { isEmpty } from 'lodash';
import { mapActions, mapState } from 'pinia';

import Notification from '@/components/notifications/Notification.vue';

import { BASE_ROUTES } from '@/constants/routes';
import { useNotificationsStore, usePartnersStore } from '@/stores';

export default {
  name: 'ReportPopulationStoppedSharingNotification',
  components: {
    BittsAvatar,
    BittsButton,
    Notification,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['action-clicked'],
  computed: {
    ...mapState(usePartnersStore, ['getPartnerOrgById']),
    hasReports() {
      return this.item.data && !isEmpty(this.item.data.reports);
    },
    partnerOrg() {
      return this.getPartnerOrgById(this.item.data.partner_org_id);
    },
    description() {
      const partnerName = this.item.data.partner_name;
      const partnerPopulationName = this.item.data.partner_population_name;
      const sharingStoppedMsg = `${partnerName} has changed sharing settings from their ${partnerPopulationName} Population with you`;

      if (this.hasReports) {
        const reportNamesList = this.item.data.reports
          .map(({ name }) => name)
          .join(', ');

        return `${sharingStoppedMsg}. This change may affect the following reports: ${reportNamesList}.`;
      }
      return `${sharingStoppedMsg}.`;
    },
    buttonText() {
      return this.hasReports ? 'View Reports' : 'View Sharing';
    },
  },
  methods: {
    ...mapActions(useNotificationsStore, ['markRead']),
    async onButtonClick() {
      return this.hasReports ? this.viewReports() : this.viewSharing();
    },
    async viewReports() {
      this.$emit('action-clicked', 'View Reports');
      await this.markRead({ id: this.item.id, isRead: true });
      await this.$router.push({ name: BASE_ROUTES.REPORTS });
    },
    async viewSharing() {
      this.$emit('action-clicked', 'View Sharing');
      await this.markRead({ id: this.item.id, isRead: true });
      await this.$router.push({
        name: 'partner_details',
        params: { partner_org_id: this.item.data.partner_org_id },
        query: { tab: 'shared_with_you', from: 'partners' },
      });
    },
  },
};
</script>

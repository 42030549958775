<template>
  <BittsModal
    :visible="true"
    class="c-onboarding-done-modal"
    :show-buttons="false"
    :mask-closable="true"
    :width="600"
    @closed="handleCloseModal"
  >
    <template #content>
      <div class="modal-content">
        <div class="icons">
          <BittsAvatar :org="currentOrg" :show-initials="true" size="large" />
          <BittsAvatar
            :user="currentUser"
            :is-own="true"
            :show-initials="true"
            class="user-icon"
            size="large"
          />
        </div>
        <h2 class="text-center max-w-[400px]">
          {{ currentOrg.name }} is now fully set up on Crossbeam!
        </h2>
        <p
          >Congratulations! You’ve created your first report and fully unlocked
          the power of ecosystem led growth.</p
        >
        <BittsInput
          v-model="publicInviteCode"
          form-label="Invite URL"
          class="mb-40 max-w-[420px]"
          name="public-code"
          :read-only="true"
          :allow-copy="true"
          caption="Share your partner link with your network to quickly pair on Crossbeam."
        />
        <BittsButton size="large" text="Close" @click="handleCloseModal" />
      </div>
    </template>
  </BittsModal>
</template>

<script setup>
import {
  BittsAvatar,
  BittsButton,
  BittsInput,
  BittsModal,
} from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';

const { currentOrg, currentUser } = useAuth();

const publicInviteCode = computed(
  () => `${window.location.origin}/i/${currentOrg.value.public_invite_code}`,
);

const router = useRouter();
async function handleCloseModal() {
  await router.push({ name: 'home' });
}
</script>
<style lang="pcss">
.c-onboarding-done-modal {
  .status-badge {
    @apply rounded-8;
  }

  .ant-modal-content {
    background-image: url('../../assets/svg/onboarding/confetti.svg');
    background-opacity: 50%;
  }

  .modal-content {
    @apply flex flex-col items-center justify-center px-12 mb-48;

    .icons {
      @apply flex mb-40;

      .user-icon {
        @apply ml-[-24px];
        img {
          @apply outline outline-white outline-2;
        }
      }
    }

    h2 {
      @apply text-neutral-text-strong text-xl font-bold;
    }

    p {
      @apply text-center text-neutral-text mb-40 mt-8 text-m;
    }
  }

  .user-icon.bitts-avatar {
    .ant-avatar-circle {
      @apply border-none outline-8 outline outline-white;
    }
  }
}
</style>

<template>
  <BittsModal
    :visible="showConnectDataSourceModal"
    :show-buttons="false"
    class="c-connect-data-source"
    :loading="loading"
    :width="useDataTemplatesFlow ? 400 : 500"
    @closed="modalClosed"
  >
    <template #content>
      <div v-if="useDataTemplatesFlow">
        <DontWorry
          :data-source="dataSource"
          @redirect-retrieved="redirectRetrieved"
        />
      </div>
      <!-- The below modal is shown for non-CRM data sources -->
      <ConnectDataSource
        v-else
        :data-source="dataSource"
        @post-snowflake-feed="postSnowflakeFeed"
        @redirect-retrieved="redirectRetrieved"
      >
        <template #submit-button="{ submit }">
          <div class="c-connect-form__button-bar">
            <BittsButton
              v-if="!isGoogleSheets"
              id="createIntegrationButton"
              :text="submitButtonText"
              class="w-full flex justify-center"
              @click="submit"
            />
            <BittsButton
              v-else
              variant="outline"
              text="Authenticate with Google"
              @click="submit"
            >
              <template #icon>
                <img src="@/assets/google-icon.png" class="h-12 w-12 mr-12" />
              </template>
            </BittsButton>
          </div>
        </template>
      </ConnectDataSource>
    </template>
  </BittsModal>
</template>

<script>
import { BittsButton, BittsModal } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { mapActions } from 'pinia';
import { computed, onUnmounted, ref } from 'vue';

import ConnectDataSource from '@/components/data-sources/ConnectDataSource.vue';
import DontWorry from '@/components/data-sources/DontWorry.vue';

import { EVENT_SITES } from '@/constants/analytics';
import {
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  HS3_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import {
  useDataSourcesStore,
  useFeedsStore,
  useFlashesStore,
  useSourcesStore,
} from '@/stores';
import urls from '@/urls';

export default {
  components: {
    ConnectDataSource,
    BittsButton,
    BittsModal,
    DontWorry,
  },

  setup() {
    const dataSource = ref({});

    const dataSourcesStore = useDataSourcesStore();

    useHead({
      title: computed(() => dataSource.value?.type ?? ''),
      titleTemplate: computed(
        () => `Connect ${dataSource.value?.friendly_name ?? ''} - Crossbeam`,
      ),
    });

    onUnmounted(() => {
      useHead({
        title: 'Data Sources - Crossbeam',
        titleTemplate: null,
      });
    });

    return {
      dataSource,
      dataSourcesStore,
    };
  },

  data() {
    return {
      loading: true,
      showConnectDataSourceModal: false,
    };
  },
  computed: {
    isGoogleSheets() {
      return this.dataSource.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE;
    },
    isSalesforceIntegration() {
      return this.dataSource.type === SALESFORCE_DATA_SOURCE_TYPE;
    },
    submitButtonText() {
      const actionObject = {
        snowflake: 'Add',
        google_sheets: 'Go connect to',
      };
      const verb = actionObject[this.dataSource.type] || 'Connect';
      return `${verb} ${this.dataSource.friendly_name}`;
    },
    useDataTemplatesFlow() {
      const isHubspot = [HS3_DATA_SOURCE_TYPE].includes(this.dataSource.type);
      const isMicrosoftDynamics = this.dataSource.type === MD_DATA_SOURCE_TYPE;
      return isMicrosoftDynamics || isHubspot || this.isSalesforceIntegration;
    },
  },
  async created() {
    try {
      await this.dataSourcesStore.readySync;
      this.dataSource = this.dataSourcesStore.getDataSource(
        this.$route.params.type,
      );
      this.dataSource.settings_usecase = this.useDataTemplatesFlow
        ? 'custom'
        : 'recommended';
    } finally {
      this.loading = false;
    }
  },
  mounted() {
    this.showConnectDataSourceModal = true;
  },
  methods: {
    ...mapActions(useFeedsStore, ['getFeedByDataSourceType']),
    ...mapActions(useFlashesStore, ['addUnhandledError']),
    ...mapActions(useFeedsStore, ['refreshFeedsStore']),
    ...mapActions(useSourcesStore, ['refreshSourcesStore']),
    modalClosed() {
      this.$router.push({ name: 'data-sources' });
      this.showConnectDataSourceModal = false;
    },
    async redirectRetrieved(params) {
      const resp = await axios.post(urls.oauth.authorize, { params });
      window.open(resp.data.authorization_url, '_self');
    },
    async postSnowflakeFeed(params) {
      try {
        this.loading = true;
        await axios.post(urls.feeds.all, params);
        await this.refreshSourcesStore();
        await this.refreshFeedsStore();

        // track snowflake add via iteratively / segment
        const { id } = this.getFeedByDataSourceType('snowflake');
        if (id) {
          const iterativelyPayload = {
            data_source_type: 'snowflake',
            feed_id: id.toString(),
            custom_presets: [],
            initial_sync_selection: '',
            event_site: EVENT_SITES.LEGACY_CONNECTION_MODAL,
          };
          this.$iteratively.userConnectedDataSource(iterativelyPayload);
        }
        this.loading = false;
        this.$router.push({ name: 'data-sources' });
      } catch (error) {
        this.loading = false;
        this.addUnhandledError(error);
        this.$router.push({ name: 'data-sources' });
      }
    },
  },
};
</script>

<style lang="pcss">
.c-connect-data-source .c-bitts-modal__content .c-loading {
  @apply flex items-center min-h-[300px];
}
</style>

<template>
  <div class="c-population-sharing-visibility flex flex-1 flex-col text-sm">
    <div
      v-for="population in populationsWithDefaultSettings"
      :key="`population-visibility-${population.id}`"
      class="flex items-center justify-between flex-1 border-b-2 text-neutral-800 last:border-b-0 border-neutral-200 p-16"
    >
      <div class="w-180 break-words font-bold mr-8 text-base">
        {{ population.name }}
      </div>

      <selector
        :value="visibilityLevel(population)"
        :options="visibilityOptions"
        dropdown-content-class="border-2 border-neutral-100 rounded-bts-md"
        option-wrapper-class="p-0"
        style="width: 200px"
        @input="onSelect(population, $event)"
      >
        <template #dropdown-button="{ value: selectedValue }">
          <span
            :class="[
              'flex w-full items-center justify-between rounded py-4 px-8',
              visibilityOptionsText[selectedValue].cssClasses,
            ]"
            class="c-population-sharing-visibility__visibility-title"
          >
            <span class="mr-8 font-bold">
              {{
                visibilityOptionsText[selectedValue].selectorTitle ||
                visibilityOptionsText[selectedValue].title
              }}
              <span class="font-normal text-neutral-text-weak">
                {{
                  population.sharingSetting.visibility ===
                  visibilityLevel(population)
                    ? '(default)'
                    : ''
                }}
              </span>
            </span>
            <FontAwesomeIcon
              :icon="['fas', 'chevron-down']"
              :style="{ height: '8px', width: '8px', color: 'currentColor' }"
              class="text-neutral-400"
            />
          </span>
        </template>
        <template
          #selector-items="{ selectedValue, selectorOptions, emitSelected }"
        >
          <DropdownMenuItem
            v-for="(option, index) in selectorOptions"
            :key="'option_' + index"
            :with-border="true"
            :is-active="selectedValue === option"
            :class="
              getClassesForDropdownItem(option, selectedValue, population)
            "
            @item-chosen="emitSelected(option)"
          >
            <div class="flex flex-col border-b border-neutral-100">
              <div
                :class="[
                  visibilityOptionsText[option].cssClasses,
                  'rounded p-4 font-bold max-w-content m-4 ml-8 text-sm mt-8',
                ]"
              >
                {{
                  visibilityOptionsText[option].selectorTitle ||
                  visibilityOptionsText[option].title
                }}
                <span class="font-normal">
                  {{
                    population.sharingSetting.visibility === option
                      ? '(default)'
                      : ''
                  }}
                </span>
              </div>
              <span
                v-if="
                  population.sharingSetting.visibility !== SHARING &&
                  option === SHARING
                "
                class="c-population-sharing-visibility-option"
              >
                You can share data in your Sharing Settings for
                {{ partnerName }} once this partnership is accepted
              </span>
              <span v-else class="c-population-sharing-visibility-option">
                {{
                  visibilityOptionsText[option].subtitle({
                    partnerName,
                    populationName: population.name,
                    isTerse: true,
                  })
                }}
              </span>
            </div>
          </DropdownMenuItem>
        </template>
      </selector>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';

import DropdownMenuItem from '@/components/DropdownMenuItem.vue';
import Selector from '@/components/Selector.vue';

import {
  HIDDEN,
  OLAPS_ONLY,
  POPULATION_RULE,
  SHARING,
  VISIBILITY_OPTIONS_TEXT,
} from '@/constants/data_shares';
import { useDataSharesStore } from '@/stores';

export default {
  name: 'PopulationSharingVisibility',
  components: {
    Selector,
    DropdownMenuItem,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    populations: {
      type: Array,
      required: true,
    },
    partnerName: {
      type: String,
      required: true,
    },
  },
  emits: ['input'],
  data() {
    return {
      populationVisibilityLevels: this.value,
      SHARING,
    };
  },
  computed: {
    visibilityOptions() {
      return [SHARING, OLAPS_ONLY, HIDDEN];
    },
    visibilityOptionsText() {
      return VISIBILITY_OPTIONS_TEXT;
    },
    populationsWithDefaultSettings() {
      return this.populations.map((population) => {
        const sharingSetting = this.sharingSetting(population);
        const noSetting = { visibility: HIDDEN };
        return {
          ...population,
          sharingSetting: sharingSetting || noSetting,
          sharingSettingVisibility: sharingSetting
            ? sharingSetting.visibility
            : noSetting.visibility,
        };
      });
    },
  },
  watch: {
    value() {
      this.populationVisibilityLevels = this.value;
    },
  },
  methods: {
    ...mapActions(useDataSharesStore, ['getOutgoingSharingRules']),
    getClassesForDropdownItem(option, selectedValue, population) {
      let classes =
        'c-dropdown-select__itemc-dropdown-select__item--single-select p-0 bg-white';
      // we can't override a non-sharing rule to sharing from here
      if (
        population.sharingSetting.visibility !== SHARING &&
        option === SHARING
      ) {
        classes += ' pointer-events-none opacity-50';
      }
      if (option === selectedValue) {
        classes += ' bg-neutral-50';
      }
      return classes;
    },
    resetToDefault() {
      this.$emit('input', []);
    },
    visibilityValue(visibility) {
      if (!visibility) return HIDDEN;
      return visibility;
    },
    sharingSetting(population) {
      return this.getOutgoingSharingRules({ ruleType: POPULATION_RULE }).find(
        (setting) => setting.population_id === population.id,
      );
    },
    onSelect(population, visibilityLevel) {
      this.populationVisibilityLevels = this.populationVisibilityLevels
        .filter(
          (populationWithVisibility) =>
            populationWithVisibility.id !== population.id,
        )
        .concat({
          id: population.id,
          visibilityLevel,
        });
      this.$emit('input', [...this.populationVisibilityLevels]);
    },
    visibilityLevel(population) {
      const existing = this.populationVisibilityLevels.find(
        (populationWithVisibility) =>
          populationWithVisibility.id === population.id,
      );
      if (!existing) {
        return this.visibilityValue(population.sharingSettingVisibility);
      }
      return existing.visibilityLevel;
    },
  },
};
</script>

<style lang="pcss" scoped>
.c-population-sharing-visibility-option {
  @apply text-base text-neutral-800 mb-16 mx-8;
}
.c-partner-invite {
  @apply pb-24;
}
</style>

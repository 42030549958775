import { BittsTag } from '@crossbeam/bitts';

import { capitalize } from 'lodash';
import { markRaw } from 'vue';

import InlineOwnPopulation from '@/components/attribution/InlineOwnPopulation.vue';
import InlinePartnerPopulation from '@/components/attribution/InlinePartnerPopulation.vue';

import {
  ATTRIBUTION_COLORS,
  ATTRIBUTION_EVENT_TYPES,
} from '@/constants/attribution';
import { usePartnersStore } from '@/stores';

export default function useTimelineContent() {
  const ACTIVITY_SOURCE_ICON_BY_ACTIVITY_SOURCE = {
    [ATTRIBUTION_EVENT_TYPES.PARTNER_MENTION]: 'gong',
    [ATTRIBUTION_EVENT_TYPES.REQUEST_SENT]: 'logoNoText',
    [ATTRIBUTION_EVENT_TYPES.REQUEST_RECEIVED]: 'logoNoText',
    [ATTRIBUTION_EVENT_TYPES.OVERLAP_ENTRY]: ['fas', 'database'],
    [ATTRIBUTION_EVENT_TYPES.OVERLAP_EXIT]: ['fas', 'database'],
    [ATTRIBUTION_EVENT_TYPES.PARTNERSTACK_LEAD_CREATED]: 'partnerStack',
  };

  const ACTION_COPY_BY_SOURCE_AND_EVENT = {
    [ATTRIBUTION_EVENT_TYPES.PARTNER_MENTION]: 'was mentioned on a call with',
    [ATTRIBUTION_EVENT_TYPES.REQUEST_SENT]: 'received a request about',
    [ATTRIBUTION_EVENT_TYPES.REQUEST_RECEIVED]: 'sent a request about',
    [ATTRIBUTION_EVENT_TYPES.POPULATION_EXIT]: 'was removed from your',
    [ATTRIBUTION_EVENT_TYPES.POPULATION_ENTRY]: 'was added to your',
    [ATTRIBUTION_EVENT_TYPES.OVERLAP_ENTRY]: 'was added to',
    [ATTRIBUTION_EVENT_TYPES.OVERLAP_EXIT]: 'was removed from',
    [ATTRIBUTION_EVENT_TYPES.PARTNERSTACK_LEAD_CREATED]: 'sent a lead to',
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_CREATED]: 'was attributed to',
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_DELETED]: 'was removed from',
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_UPDATED]: 'was attributed to',
    [ATTRIBUTION_EVENT_TYPES.THREAD_CLOSED]: 'closed a Thread with',
    [ATTRIBUTION_EVENT_TYPES.THREAD_CREATED]: 'created a Thread with',
    [ATTRIBUTION_EVENT_TYPES.THREAD_REOPENED]: 'reopened a Thread with',
    [ATTRIBUTION_EVENT_TYPES.THREAD_MESSAGE]: 'messaged',
    [ATTRIBUTION_EVENT_TYPES.THREAD_OWNER_CHANGED]:
      'changed the Thread owner for',
  };
  const partnersStore = usePartnersStore();

  const bold = (text) => ({
    element: 'strong',
    text,
  });

  const normal = (text) => ({
    element: 'span',
    text,
  });

  const custom = (component, props) => ({
    element: markRaw(component),
    props,
  });

  const buildSimplePhrase = (firstVal, action, secondVal) => [
    bold(firstVal),
    normal(action),
    bold(secondVal),
  ];

  const buildPopMovementPhrase = (account, action, activity) => {
    const { population_id: populationId, population_name: populationName } =
      activity.data;

    const populationComponent = custom(InlineOwnPopulation, {
      populationId,
      populationName,
    });

    const phrase = [
      bold(account),
      normal(action),
      populationComponent,
      normal('Population'),
    ];

    return phrase;
  };

  const buildOverlapPhrase = (account, action, activity, partnerName) => {
    const {
      partner_population_name: partnerPopulationName,
      partner_population_id: partnerPopulationId,
    } = activity.data;

    const populationComponent = custom(InlinePartnerPopulation, {
      partnerPopulationName,
      partnerPopulationId,
    });

    const phrase = [
      bold(account),
      normal(action),
      bold(`${partnerName}'s`),
      populationComponent,
      normal('Population'),
    ];

    return phrase;
  };

  const buildAttributionPhrase = (account, action, activity, partnerName) => {
    const { attribution_type: attributionType } = activity.data;

    const phrase = [
      bold(account),
      normal(action),
      bold(partnerName),
      normal('as'),
      custom(BittsTag, {
        text: capitalize(attributionType),
        color: ATTRIBUTION_COLORS(attributionType),
        variant: 'rounded',
      }),
    ];

    return phrase;
  };

  const buildThreadPhrase = (account, action, _, partnerName) => {
    const phrase = [bold(account), normal(action), bold(partnerName)];

    return phrase;
  };

  const buildGongPhrase = (account, activity, partnerName) => {
    const numMentions = activity.data?.mentions?.length;

    let mentionsCopy = 'was mentioned';

    if (numMentions > 0) {
      mentionsCopy += numMentions > 1 ? ` ${numMentions} times` : ' once';
    }

    const phrase = [
      bold(partnerName),
      normal(mentionsCopy),
      normal('on a call with'),
      bold(account),
    ];

    return phrase;
  };

  const phraseByActivityType = {
    [ATTRIBUTION_EVENT_TYPES.POPULATION_EXIT]: buildPopMovementPhrase,
    [ATTRIBUTION_EVENT_TYPES.POPULATION_ENTRY]: buildPopMovementPhrase,
    [ATTRIBUTION_EVENT_TYPES.OVERLAP_ENTRY]: buildOverlapPhrase,
    [ATTRIBUTION_EVENT_TYPES.OVERLAP_EXIT]: buildOverlapPhrase,
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_CREATED]: buildAttributionPhrase,
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_DELETED]: buildAttributionPhrase,
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_UPDATED]: buildAttributionPhrase,
    [ATTRIBUTION_EVENT_TYPES.THREAD_CLOSED]: buildThreadPhrase,
    [ATTRIBUTION_EVENT_TYPES.THREAD_CREATED]: buildThreadPhrase,
    [ATTRIBUTION_EVENT_TYPES.THREAD_REOPENED]: buildThreadPhrase,
    [ATTRIBUTION_EVENT_TYPES.THREAD_MESSAGE]: buildThreadPhrase,
    [ATTRIBUTION_EVENT_TYPES.THREAD_OWNER_CHANGED]: buildThreadPhrase,
  };

  const buildMarkupByActivity = (activity, accountName) => {
    const partner = partnersStore.getPartnerOrgByUuid(
      activity.partner_organization_uuid,
    );

    const partnerName = partner?.name ?? 'Deactivated Partner';
    const nameOrDeactivated = accountName ?? 'Deactivated Account';

    const { type: activityType, activity_source: activitySource } = activity;

    const phraseSubject = activity?.data?.submitter_name ?? partnerName;
    const phraseDirectObject =
      activity?.data?.partnerstack_partner ?? nameOrDeactivated;

    const action = ACTION_COPY_BY_SOURCE_AND_EVENT[activityType];

    if (activitySource === 'gong') {
      return buildGongPhrase(accountName, activity, partnerName);
    }

    const phrase =
      activitySource === 'crossbeam-core'
        ? phraseByActivityType[activityType](
            phraseDirectObject,
            action,
            activity,
            partnerName,
          )
        : buildSimplePhrase(phraseSubject, action, phraseDirectObject);

    return action ? phrase : [normal('All event information not found')];
  };

  return {
    buildMarkupByActivity,
    bold,
    normal,
    custom,
    ACTIVITY_SOURCE_ICON_BY_ACTIVITY_SOURCE,
  };
}
